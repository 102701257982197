import InputUserProfile from 'client/components/Form/InputProfileContainer';
import { LanguageSelector } from 'client/components/LanguageSelector';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import InputRow from '../InputRow';
import SwitchRow from '../SwitchRow';
import { useSettingsTab } from './useSettingsTab';
const SettingsTab = () => {
  const { t, currentUser, currentLanguage } = useSettingsTab();

  return (
    <>
      <div className="body-title">
        <span>{t('ProfileContainer.SettingPage.title')}</span>
      </div>
      <SwitchRow
        checked={currentUser && currentUser.isNotificationEnabled}
        name="isNotificationEnabled"
        label={t('ProfileContainer.SettingPage.notifications')}
      />
      <InputRow
        name="password"
        label={t('ProfileContainer.ProfileTab.password')}
        defaultvalue="********"
      >
        <InputUserProfile
          name="password"
          type="password"
          placeholder="********"
          validate={Validators([
            { validation: 'required' },
            { validation: 'minLength', minParams: 8 },
          ])}
        />
      </InputRow>
      <InputRow
        name="language"
        label={t('ProfileContainer.ProfileTab.languages')}
        defaultvalue={currentLanguage}
      >
        <LanguageSelector />
      </InputRow>
    </>
  );
};

export default SettingsTab;
