import React from 'react';

const LogoReam: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272.07 71.02" {...props}>
    <defs>
      <style>{'.prefix__cls-1{fill:#ec223b}.prefix__cls-2{fill:white}'}</style>
    </defs>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__Layer_1-2" data-name="Layer 1">
        <path
          className="prefix__cls-1"
          d="M182.12 3.54a3.54 3.54 0 10-3.54 3.54 3.54 3.54 0 003.54-3.54z"
        />
        <path
          className="prefix__cls-2"
          // eslint-disable-next-line max-len
          d="M272.06 25.49a25.47 25.47 0 00-42.46-19 25.42 25.42 0 00-25.47-5V.15h-15.59a1.41 1.41 0 00-1.42 1.42v68a1.41 1.41 0 001.42 1.43h14.17a1.42 1.42 0 001.42-1.41v-44.1a8.49 8.49 0 019.06-8.49 8.67 8.67 0 017.9 8.76v43.78a1.42 1.42 0 001.42 1.46h14.17a1.42 1.42 0 001.42-1.41v-43.8A8.68 8.68 0 01246 17a8.49 8.49 0 019.06 8.46v44.08a1.42 1.42 0 001.42 1.46h14.17a1.41 1.41 0 001.42-1.41v-44.1zM32.78 2.35A30.69 30.69 0 0021.27.11 21.29 21.29 0 000 21.38v48.19A1.41 1.41 0 001.42 71h14.17A1.42 1.42 0 0017 69.57V21.38a4.23 4.23 0 014.11-4.26 13.7 13.7 0 014.32.65 1.4 1.4 0 001.68-.71l6.42-12.75a1.43 1.43 0 00-.75-1.96zM70.87.15A35.38 35.38 0 0059.4 2.06c-.49.17-1 .35-1.47.54a1.4 1.4 0 00-.72 1.94l6.41 12.77a1.41 1.41 0 001.68.69 16.75 16.75 0 011.88-.5 18.62 18.62 0 013.69-.37 18.41 18.41 0 0116.31 9.9H58.82a2.13 2.13 0 00-2.13 2.13v10.65A4.26 4.26 0 0061 44.07h40.78a4.25 4.25 0 004.22-3.64A35.41 35.41 0 0070.87.15z"
        />
        <path
          className="prefix__cls-2"
          // eslint-disable-next-line max-len
          d="M81.3 52.48a1.42 1.42 0 00-1.92-.62A18.12 18.12 0 0170.87 54a18.43 18.43 0 01-18.41-18.43l.08-3.69a32 32 0 00-7.46-20.55 35.24 35.24 0 00-9.54 21.94c0 .76-.09 1.52-.09 2.3a35.42 35.42 0 0051.63 31.48 1.42 1.42 0 00.6-1.89zM176.13 38.54a11.33 11.33 0 01-21.94 0 11 11 0 010-5.67 11.33 11.33 0 0121.94 0H182A35.42 35.42 0 00149.6.33v5.83a11.35 11.35 0 11-5.67 0V.33a35.53 35.53 0 00-32.5 32.54h5.93a11.32 11.32 0 0121.93 0 10.77 10.77 0 010 5.67 11.32 11.32 0 01-21.93 0h-5.92a35.42 35.42 0 0032.49 32.39V65a11.34 11.34 0 012.81-22.31 11.74 11.74 0 012.86.37 11.33 11.33 0 010 21.92v6a35.35 35.35 0 0015.55-5v3.7a1.41 1.41 0 001.41 1.32h14.18a1.41 1.41 0 001.41-1.42V38.54z"
        />
        <path
          className="prefix__cls-1"
          // eslint-disable-next-line max-len
          d="M136.79 35.68a8.5 8.5 0 00-7.1-8.38 19 19 0 000 16.76 8.51 8.51 0 007.1-8.38zM164 27.26a8.49 8.49 0 000 16.84 19 19 0 000-16.84zM146.79 25.66a8.5 8.5 0 008.39-7.2 18.92 18.92 0 00-16.77.08 8.5 8.5 0 008.38 7.12zM146.79 45.52a8.51 8.51 0 00-8.41 7.28 19 19 0 0016.83.08 8.5 8.5 0 00-8.42-7.36z"
        />
      </g>
    </g>
  </svg>
);

export default LogoReam;
