import React, { useState, useCallback, useMemo } from 'react';
import SlideOne from './SlideOne/SlideOne';
import SlideThree from './SlideThree';
import SlideTwo from './SlideTwo/SlideTwo';

export const useCompleteSignUpInformation = () => {
  const slidersArray = [0, 1, 2];
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = useCallback(
    (index: number) => {
      if (index < selectedIndex) setSelectedIndex(index);
    },
    [selectedIndex],
  );
  const slide = useMemo(() => {
    switch (selectedIndex) {
      case 0:
        return <SlideOne nextSlide={() => setSelectedIndex(1)} />;
      case 1:
        return <SlideTwo nextSlide={() => setSelectedIndex(2)} />;
      default:
        return <SlideThree />;
    }
  }, [selectedIndex, setSelectedIndex]);

  return {
    selectedIndex,
    setSelectedIndex,
    slidersArray,
    handleClick,
    slide,
  };
};
