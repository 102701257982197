import { useTranslation } from 'react-i18next';
import { Grade } from 'server/models/User';

export const useFormatVideoGrapherGrade = (grade: Grade): string => {
  const [t] = useTranslation();
  let userGrade: string;

  switch (grade) {
    case 'BRONZE':
      userGrade = t('ProfileContainer.ProfileTab.bronze');
      break;
    case 'GOLD':
      userGrade = t('ProfileContainer.ProfileTab.gold');
      break;
    case 'SILVER':
      userGrade = t('ProfileContainer.ProfileTab.silver');
  }

  return userGrade;
};
