import { getAllPosts } from 'client/_redux/actions/post';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
export const useGalleryMidSectionContainer = (
  scrollRef: React.MutableRefObject<null | { offsetTop: number }>,
) => {
  const [countryLetter, setCountryLetter] = useState('');
  const [category, setCategory] = useState('RECENT');

  const [t] = useTranslation();

  const OnLetterChange = (letter: string) => {
    if (letter && letter !== countryLetter) {
      setCountryLetter(letter);
    }
  };
  const OnSwitchChange = (value: string) => {
    if (value !== category) setCategory(value);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPosts({ category, countryLetter }));
  }, [category, countryLetter, dispatch]);

  return {
    OnLetterChange,
    OnSwitchChange,
    category,
    t,
  };
};
