import React from 'react';
import SideBar, { SidebarLink } from '@edonec/sidebar';
import { useTranslation } from 'react-i18next';
import useSideBar from './useSideBar';

const Sidebar = () => {
  const [t] = useTranslation();
  const { T, logout } = useSideBar();

  return (
    <SideBar primary="#292b33">
      <SidebarLink
        icon={<i className="fas fa-user" />}
        title={t('dashboard.videoGrapherPage.videoGrapher')}
        to="/dashboard/videographers"
      />
      <SidebarLink
        icon={<i className="fas fa-video" />}
        title={t('dashboard.videosPage.title')}
        to="/dashboard/videos"
      />
      <SidebarLink
        icon={<i className="fas fa-cash-register" />}
        title={`${t('dashboard.withdrawsPage.title')}`}
        to="/dashboard/withdraw"
      />
      <SidebarLink
        icon={<i className="fas fa-bell" />}
        title={`${t('dashboard.NotificationPage.title')}`}
        to="/dashboard/notification"
      />
      <li className="css-3708oq">
        <b className="css-1pfh8d6" />
        <b className="css-1pfh8d6" />
        <div className="css-uc5g40">
          <div className="css-1ndckr9" onClick={logout}>
            <i className="fas fa-sign-out-alt" />
          </div>
          <button className="border-0 bg-transparent text-white css-159auep " type="button">
            {T('ProfileContainer.deconnexion')}
          </button>
        </div>
      </li>
    </SideBar>
  );
};

export default Sidebar;
