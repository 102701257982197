import React from 'react';

export interface IProps {
  name?: string;
}
const SubmitButton: React.FC<IProps> = ({ name }) => (
  <button
    data-testid="submitButton"
    className="submit-button text-white shadow py-3 border-0 rounded px-4 bg-primary-lighter"
    type="submit"
    name="submit"
  >
    {name}
  </button>
);

export default SubmitButton;
