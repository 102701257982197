/* eslint-disable jsx-a11y/media-has-caption */
import { getImageUrl } from 'client/helpers/getImageUrl';
import React from 'react';

interface IProps {
  name: string;
  tags: string[];
  className?: string;
  country: string;
  city?: string;
  thumbnailUrl?: string;
  videoUrl?: string;
  date?: string;
  addedBy?: string;
}

const VideoCard: React.FC<IProps> = ({
  name,
  thumbnailUrl,
  tags,
  addedBy,
  date,
  country,
  city,
  videoUrl,
  className,
}) => (
  <div className={`p-2 text-dark ${className || ''}`}>
    {videoUrl ? (
      <video className="img-video-form" poster={getImageUrl(thumbnailUrl)} controls>
        <source src={getImageUrl(videoUrl)} />
      </video>
    ) : (
      <img src={thumbnailUrl} className="img-video-form" alt={`${name} by ${addedBy}`} />
    )}
    <div className="row mt-2">
      <div className="col-md-8 col-12">
        <div className="pl-1 pl-md-3 pl-lg-3 ">
          <p className="h6 mb-1 font-weight-normal text-primary ellipsis-text">{name}</p>
          <div className="d-flex s">
            {tags.map((tag, i) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={`mb-0 badge badge-pill badge-primary-darker font-weight-light font-size-xxs px-4 ${
                  i < tags.length - 1 ? 'mr-1' : ''
                }`}
              >
                {tag}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="border-left border-sm-0 border-primary col-md-4 col-12 font-weight-light font-size-1 mt-3 mt-md-0 ">
        <p className=" mb-1 ellipsis-text">{addedBy}</p>
        <p className="mb-0 font-size-xxs text-primary ellipsis-text ">
          {date} - {country} {city}
        </p>
      </div>
    </div>
  </div>
);

export default VideoCard;
