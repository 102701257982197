/* eslint-disable max-len */
import React from 'react';

const UserProfile = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.737"
    height="28.013"
    viewBox="0 0 21.737 28.013"
  >
    <g id="Group_111" data-name="Group 111" transform="translate(-12.525 -5.433)">
      <path
        id="Path_83"
        data-name="Path 83"
        d="M25.685,20.585a7.329,7.329,0,0,0,5.356-2.22,7.327,7.327,0,0,0,2.219-5.356,7.33,7.33,0,0,0-2.219-5.356,7.573,7.573,0,0,0-10.713,0,7.33,7.33,0,0,0-2.219,5.356,7.329,7.329,0,0,0,2.219,5.356A7.332,7.332,0,0,0,25.685,20.585ZM21.512,8.836a5.9,5.9,0,0,1,8.345,0,5.711,5.711,0,0,1,1.729,4.173,5.9,5.9,0,0,1-5.9,5.9,5.9,5.9,0,0,1-5.9-5.9A5.713,5.713,0,0,1,21.512,8.836Z"
        transform="translate(-2.468 0)"
        fill="#292b33"
      />
      <path
        id="Path_84"
        data-name="Path 84"
        d="M34.207,39.841a15.663,15.663,0,0,0-.216-1.683,13.129,13.129,0,0,0-.409-1.674,7.954,7.954,0,0,0-.664-1.5,5.465,5.465,0,0,0-.959-1.254,4.068,4.068,0,0,0-1.324-.828,4.6,4.6,0,0,0-1.577-.307.78.78,0,0,0-.416.1c-.069.036-.191.105-.389.234-.335.219-.71.462-1.146.74a7.365,7.365,0,0,1-1.662.738,6.552,6.552,0,0,1-4.105,0,7.293,7.293,0,0,1-1.66-.737c-.427-.273-.813-.522-1.149-.741-.2-.128-.32-.2-.388-.234a.768.768,0,0,0-.416-.1,4.6,4.6,0,0,0-1.577.307,4.048,4.048,0,0,0-1.324.828,5.45,5.45,0,0,0-.959,1.252,7.949,7.949,0,0,0-.664,1.507,13.247,13.247,0,0,0-.41,1.674,15.857,15.857,0,0,0-.216,1.682c-.036.517-.054,1.054-.054,1.6a3.874,3.874,0,0,0,1.131,2.934,4.14,4.14,0,0,0,3,1.076H30.129a4.2,4.2,0,0,0,3-1.076,3.876,3.876,0,0,0,1.131-2.934C34.262,40.89,34.244,40.353,34.207,39.841Zm-2.231,3.317a2.52,2.52,0,0,1-1.847.615H16.658a2.521,2.521,0,0,1-1.848-.615,2.217,2.217,0,0,1-.611-1.721c0-.5.017-1,.05-1.481a14.059,14.059,0,0,1,.194-1.5,11.6,11.6,0,0,1,.356-1.461,6.344,6.344,0,0,1,.525-1.189,3.789,3.789,0,0,1,.661-.871,2.382,2.382,0,0,1,.781-.48,2.8,2.8,0,0,1,.777-.177l.075.048c.339.221.729.473,1.162.749a8.932,8.932,0,0,0,2.045.919,8.252,8.252,0,0,0,5.135,0,8.972,8.972,0,0,0,2.048-.92c.442-.282.821-.527,1.16-.748l.075-.048a2.808,2.808,0,0,1,.777.177,2.4,2.4,0,0,1,.782.48,3.78,3.78,0,0,1,.661.872,6.316,6.316,0,0,1,.525,1.188,11.264,11.264,0,0,1,.355,1.459,14.288,14.288,0,0,1,.194,1.5c.033.473.05.97.05,1.479A2.217,2.217,0,0,1,31.977,43.158Z"
        transform="translate(0 -12.001)"
        fill="#292b33"
      />
      <circle
        id="Ellipse_9"
        data-name="Ellipse 9"
        cx="0.558"
        cy="0.558"
        r="0.558"
        transform="translate(28.717 29.55)"
        fill="#292b33"
      />
      <path
        id="Path_85"
        data-name="Path 85"
        d="M30.306,48.648H19.167a.558.558,0,1,0,0,1.116H30.306a.558.558,0,1,0,0-1.116Z"
        transform="translate(-2.689 -19.098)"
        fill="#292b33"
      />
    </g>
  </svg>
);

export default UserProfile;
