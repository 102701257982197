import React from 'react';
type VideoCardFormFragmetProps = {
  title: string;
  isText?: boolean;
  isAddCard?: boolean;
  clicked?: (name: string, title: string, value: string) => void;
  name: string;
};

const VideoCardFormFragment: React.FC<VideoCardFormFragmetProps> = ({
  title,
  children,
  isText = false,
  isAddCard = false,
  clicked,
  name,
}) => {
  if (isAddCard)
    return (
      <div className="row align-items-start justify-content-between ">
        <div className="row fSize col-6 col-sm-6 ">
          <p className="text-oxygen text-left">
            {title} <label className="text-primary">*</label>
          </p>
        </div>
        <div className="dataSize col-6 col-sm-6 px-1 text-oxygen">{children}</div>
      </div>
    );

  return (
    <div
      className="align-items-start align-items-md-center row mx-0 col-md-6 col-12 mb-md-0 mb-2"
      style={{
        paddingRight: '0px',
        paddingLeft: '0px',
      }}
    >
      <label
        className="mt-0 mt-md-3 mb-0 mb-md-1 fSize col-6 card-form-text"
        style={{ paddingRight: '0px' }}
      >
        {title} <label className="text-primary">*</label>
      </label>
      <div
        onClick={(e) => {
          e.stopPropagation();
          clicked?.(name, title, '');
        }}
        className="dataSize col-6"
      >
        {children}
      </div>
    </div>
  );
};

export default VideoCardFormFragment;
