import * as React from 'react';

interface IProps {
  title: string | JSX.Element;
  backgroundStyle:
    | 'dark-image-background'
    | 'dark-background'
    | 'white-background'
    | 'dark-image-background-alt';

  ImageUrl?: string;
  number?: number;
  numberStyle?: 'section-number' | 'section-number-alt';
  miscComponent?: JSX.Element;
  firstDescription?: string | JSX.Element;
  secondDescription?: string;
  checkList?: string[];
  titleStyle: string;
  subtitleStyle?: string;
  descriptionStyle?: string;
  isReversed?: boolean;
  className?: string;
  styledTextSection?: React.Component;
  innerDivRef?: React.LegacyRef<HTMLDivElement>;
  isNotFullPage?: boolean;

  mobileTopMargin?: string;
  contentClassName?: string;
}

const SectionContainer: React.FC<IProps> = ({
  title,
  backgroundStyle,
  ImageUrl,
  number,
  numberStyle,
  miscComponent,
  firstDescription,
  secondDescription,
  checkList,
  titleStyle,
  subtitleStyle,
  descriptionStyle,
  isReversed = false,
  className,
  children,
  innerDivRef,
  isNotFullPage,
  mobileTopMargin,
  contentClassName,
}) => (
  <div
    className={`bg-cover ${backgroundStyle} ${
      isNotFullPage ? '' : 'min-vh-100'
    } d-flex justify-content-center  ${className || ''} `}
    ref={innerDivRef}
  >
    <div
      className={`row container ${
        isReversed ? 'flex-row-reverse' : ''
      } justify-content-between align-items-center mh-100 ${contentClassName || ''}`}
    >
      {number == null ? null : (
        <div className={numberStyle ? `text-oxygen ${numberStyle}` : ''}>{number}</div>
      )}
      <div className={`col-lg-6 col-md-12 mobile-top-${mobileTopMargin || '30'}`}>
        <div className="mb-3 mt-sm-3">
          <span
            className={`text-oxygen ${
              titleStyle || ''
            } font-weight-bold font-size-3 title-section`}
          >
            {title}
          </span>
        </div>
        {firstDescription && (
          <div className="mb-3">
            <span className={`font-size-lg ${subtitleStyle || ''} subtitle-section`}>
              {firstDescription}
            </span>
          </div>
        )}
        {secondDescription && (
          <div className="mb-5">
            <span
              style={{ font: 'normal normal normal 24px/34px Inter' }}
              className={` ${descriptionStyle || ''} description-section`}
            >
              {secondDescription}
            </span>
          </div>
        )}
        {checkList && (
          <ul
            className={`mt-2 mb-4 font-size-1 description-section ${descriptionStyle || ''}`}
          >
            {checkList.map((e, index) => (
              <li key={e} className="my-4 ">
                <i className="fas fa-check text-primary mr-5 description-section" />
                {e}
              </li>
            ))}
          </ul>
        )}
        <div>{children}</div>
      </div>
      <div className="col-lg-4 col-md-12 mb-sm-3">
        {ImageUrl && (
          <img className="img-fluid misc-section" src={`${ImageUrl}`} alt="section rep" />
        )}
        {miscComponent && <div className="misc-section">{miscComponent}</div>}
      </div>
    </div>
  </div>
);

export default SectionContainer;
