/* eslint-disable max-lines */
import React from 'react';
import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import FormSelect from 'client/components/Form/FormSelect';
import { CountryCodes } from 'client/constants/CountryCodes';
import { ISelectorOption } from 'types/ISelectorOption';
import { SelectComponents } from 'react-select/src/components';
import SubmitButton from 'client/components/Form/SubmitButton';
import { FormProvider } from 'react-hook-form';

import { styledSelect } from './MenuListArrow';
import { useSlideOne } from './useSlideOne';
import UpdatePassword from './updatePassword';
interface IProps {
  nextSlide: () => void;
}

const SlideOne: React.FC<IProps> = ({ nextSlide }) => {
  const {
    formMethods,
    t,
    handleSubmit,
    _onSubmit,
    setPassword,
    error,
    handlePasswordChange,
    signUpData,
  } = useSlideOne(nextSlide);

  return (
    <div className="w-100 flex-column align-content-center justify-content-center">
      <div className="h4 text-secondary-lighter font-weight-normal  text-center ">
        <span className="text-primary-lighter">1.</span>
        {t('LoginForm.completeProfile')}
      </div>
      <div className="w-60 mx-auto mt-5">
        <UpdatePassword
          username={signUpData?.username}
          t={t}
          error={error}
          handlePasswordChange={handlePasswordChange}
          setPassword={setPassword}
        />
        <div>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(_onSubmit)}>
              <div>
                <div className="d-flex">
                  <div className="col-6 pl-0">
                    <Input
                      className="bg-light-grey h-80"
                      data-testid=""
                      type="text"
                      name="lastName"
                      placeholder={t('LoginForm.Name')}
                      validate={Validators([{ validation: 'alpha_space' }])}
                    />
                  </div>
                  <div className="col-6 pr-0">
                    <Input
                      className="bg-light-grey h-80"
                      data-testid=""
                      type="text"
                      name="firstName"
                      placeholder={t('LoginForm.SurName')}
                      validate={Validators([{ validation: 'alpha_space' }])}
                    />
                  </div>
                </div>
                {/* <div>
                  <Input
                    className="bg-light-grey h-80"
                    data-testid=""
                    type="text"
                    name="companyName"
                    placeholder={`${t('LoginForm.CompanyName')} *`}
                    validate={Validators([
                      { validation: 'required' },
                      { validation: 'alpha_space' },
                    ])}
                  />
                </div> */}
                <div className="d-flex">
                  <div className="col-6 pl-0">
                    <Input
                      className="bg-light-grey h-80"
                      type="text"
                      name="country"
                      placeholder={`${t('ProfileContainer.ProfileTab.country')} *`}
                      validate={Validators([
                        { validation: 'required' },
                        { validation: 'alpha_space' },
                      ])}
                    />
                  </div>
                  <div className="col-6 pr-0">
                    <Input
                      className="bg-light-grey h-80"
                      data-testid=""
                      type="text"
                      name="city"
                      placeholder={`${t('ProfileContainer.ProfileTab.city')} *`}
                      validate={Validators([
                        { validation: 'required' },
                        { validation: 'alpha_space' },
                      ])}
                    />
                  </div>
                </div>
                <div>
                  <Input
                    className="bg-light-grey h-80"
                    data-testid=""
                    type="textarea"
                    name="description"
                    placeholder={t('ProfileContainer.ProfileTab.description')}
                  />
                </div>
                {/* <div>
                  <Input
                    className="bg-light-grey h-80"
                    data-testid=""
                    type="text"
                    name="immatriculation"
                    placeholder={`${t('LoginForm.CompanyNumber')} *`}
                    validate={Validators([
                      { validation: 'required' },
                      { validation: 'numeric' },
                    ])}
                  />
                </div>
                <div>
                  <Input
                    className="bg-light-grey h-80"
                    data-testid="emailInput"
                    type="email"
                    name="emailCompany"
                    placeholder={`${t('LoginForm.CompanyEmail')} *`}
                    validate={Validators([
                      { validation: 'required' },
                      { validation: 'email' },
                    ])}
                  />
                </div> */}
                <div className="d-flex align-content-center mb-3">
                  <div className=" col-3 px-0 ">
                    <FormSelect
                      components={
                        styledSelect.components as Partial<
                          SelectComponents<ISelectorOption, boolean>
                        >
                      }
                      variant="countryCode"
                      defaultValue={{ value: 'France', label: '+33' }}
                      name="phoneCountryCode"
                      options={CountryCodes.map((el) => ({
                        label: el.dial_code,
                        value: el.name,
                      }))}
                    />
                  </div>
                  <div className="col-9 pr-0">
                    <Input
                      className="bg-light-grey h-80"
                      data-testid=""
                      type="tel"
                      name="phoneNumber"
                      placeholder={`${t('LoginForm.Phone')} *`}
                      validate={Validators([
                        { validation: 'required' },
                        { validation: 'numeric' },
                      ])}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <SubmitButton name={t('LoginForm.Next')} />
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default SlideOne;
