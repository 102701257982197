import Axios from 'axios';
import { IFileWithPreview } from 'types/IFileWithPreview';
import { getImageUrl } from './getImageUrl';

export const initializeImagePicker = async (newFiles: string[]) => {
  const promiseArray = newFiles.map(async (el, index) => {
    try {
      const response = (
        await Axios.get(getImageUrl(el), {
          responseType: 'blob',
        })
      ).data;
      const mimeType = response.type;
      const extension = mimeType.split('/')[1];

      const file = new File([response], `${new Date().getTime() + index}.${extension}`, {
        type: mimeType,
      });

      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    } catch (error) {
      return null;
    }
  });

  return (await Promise.all(promiseArray)).filter(
    (file) => file !== null,
  ) as IFileWithPreview[];
};
