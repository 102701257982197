import React, { useCallback } from 'react';
import Slider, { Settings } from 'react-slick';
import { LeftArrow, RightArrow } from './Arrows';

const settings: Settings = {
  dots: false,
  dotsClass: 'slick-dots mt-n4',
  pauseOnFocus: false,
  slidesToScroll: 1,
  slidesPerRow: 1,
  autoplay: false,
  autoplaySpeed: 4000,
  pauseOnHover: false,
  draggable: true,
  arrows: false,
  focusOnSelect: false,
  initialSlide: -1,
  prevArrow: <LeftArrow />,
  nextArrow: <RightArrow />,
};
const VideoCarousel: React.FC<Partial<Settings>> = ({ children, ...rest }) => {
  const childrenLength = React.Children.toArray(children).length;
  const calculateSlidesToShow = useCallback(
    (slidesNumber) => Math.min(childrenLength, slidesNumber),
    [childrenLength],
  );

  return (
    <Slider
      {...{
        ...settings,
        slidesToShow: calculateSlidesToShow(2.5),
        variableWidth: childrenLength < 3,

        responsive: [
          {
            breakpoint: 1921,
            settings: {
              slidesToShow: calculateSlidesToShow(2.5),
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: calculateSlidesToShow(2),
            },
          },
          {
            breakpoint: 851,
            settings: {
              slidesToShow: calculateSlidesToShow(1),
              variableWidth: false,
            },
          },
        ],
        ...rest,
      }}
    >
      {children}
      {/* {React.Children.toArray(children).map((child, i) => (
        // <div className="my-0 my-md-3 p-2 ml-0 ml-md-8" key={`${i + 1}`}>
        <>{child}</>
      ))} */}
    </Slider>
  );
};

export default VideoCarousel;
