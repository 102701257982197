import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { IPost } from 'client/models/IPost';
import { format } from 'date-fns';
export const useGallery = () => {
  const [t] = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<{
    imageUrl?: string;
    nickName: string;
    datePlace: string;
    description: string;
    previewUrl: string;
  }>({
    imageUrl: '',
    nickName: '',
    datePlace: '',
    description: '',
    previewUrl: '',
  });
  const [isSwiping, setIsSwiping] = useState(false);

  const cancelModal = () => setIsModalOpen(false);
  const handleClick = (galleryElement: IPost) => {
    if (isSwiping) return;
    setIsModalOpen(true);
    setModalData({
      imageUrl: galleryElement?.mainVideo?.thumbnailUrl,
      previewUrl: galleryElement?.mainVideo?.previewUrl,
      nickName: galleryElement?.addedBy?.username
        ? galleryElement?.addedBy?.username
        : 'username',
      datePlace: `${format(galleryElement?.createdAt, 'DD-MM-YYYY')} - ${
        galleryElement?.city
      }`,
      description: galleryElement.description,
    });
  };

  const { currentPosts } = useSelector(({ post: { currentPosts } }: RootState) => ({
    currentPosts,
  }));

  return {
    currentPosts,
    handleClick,
    isModalOpen,
    cancelModal,
    modalData,
    t,
    setIsSwiping,
  };
};
