import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { getWithdrawRequests } from 'dashboard/_redux/actions/users';
import CRUDDataTable from 'dashboard/containers/CRUDDataTable';
import { useWithdrawRequestList } from './useWithdrawRequestList';

const WithdrawRequestList: React.FC = () => {
  const { withdrawRequests, loaded, dataTableRef, columns } = useWithdrawRequestList();

  const [t] = useTranslation();

  return (
    <div className="card card-body">
      <CRUDDataTable
        myRef={dataTableRef}
        loading={!loaded}
        data={withdrawRequests}
        title={t('dashboard.withdrawsPage.withdrawList')}
        columns={columns}
        fetchDataFn={getWithdrawRequests}
      />
    </div>
  );
};

export default WithdrawRequestList;
