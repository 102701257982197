/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

interface ProgressBarProps {
  progress: number;
  className?: string;
  widthBar: string;
  heightBar?: string;
  checkProgressComplet?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  className,
  heightBar,
  widthBar,
  checkProgressComplet = false,
}) => (
  <div className="d-flex-column text-center">
    {progress === 100 && checkProgressComplet && (
      <i className="fas fa-check text-white font-size-sm pb-2 " />
    )}
    <div
      style={{ width: `${widthBar}`, height: `${heightBar}` }}
      className={`progress  ${className || ``}`}
    >
      <div
        className="progress-bar progress-bar-striped "
        role="progressbar"
        style={{
          width: `${progress}%`,
        }}
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  </div>
);

export default ProgressBar;
