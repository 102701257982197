import AddVideoDashboard from 'client/containers/AddVideoDashboard';
import React from 'react';
import VideoCardInfo from 'client/containers/VideoCardInfo';
import VideoCarousel from 'client/containers/VideoCarousel';
import NavBarContainer from 'client/containers/NavBarContainer';
import ProfileContainer from 'client/containers/ProfileContainer';
import TabSwitcher from 'client/components/TabSwitcher/TabSwitcher';
import SliderAlphabet from 'client/components/Form/RangeInput';
import SearchInput from 'client/components/SearchInput';
import Footer from 'client/containers/Footer';
import { useVideoLibrary } from './useVideoLibrary';

const VideoLibrary: React.FC = () => {
  const {
    currentUser,
    OnSwitchChange,
    OnLetterChange,
    onSearchChange,
    videoGrapherPosts,
    t,
    isApproved,
    userReports,
    category,
    isAbleToWithdraw,
  } = useVideoLibrary();

  return (
    <>
      <div className="">
        <NavBarContainer isDarkBackground />
      </div>
      <div className="video-library-container">
        <div className="container-xl conatiner-md">
          <div className="column-display-title col-md-12 pl-md-0">
            {t('videoMakerDashboard.welcome')}{' '}
            <label className="text-primary"> {currentUser?.username}</label>
          </div>
          <div className="video-library-first-section justify-content-center  justify-content-xl-between ">
            <div className=" col-12 col-xl-8 no-gutters justify-content-between d-flex flex-column">
              <div className="padding-video-card">
                <div className="title">
                  {t('videoMakerDashboard.welcome')}{' '}
                  <label className="text-primary"> {currentUser?.username}</label>
                </div>
                {isApproved ? (
                  <>
                    <div className="d-flex justify-content-center">
                      <div className="library-body font-size-3">
                        {t('videoMakerDashboard.note')}
                      </div>
                    </div>
                    <AddVideoDashboard />
                  </>
                ) : (
                  <div className="library-body ml-5 ml-md-0">
                    {t('videoMakerDashboard.accountInProgress')}
                  </div>
                )}
              </div>
              <div>
                <div className="d-flex justify-content-center justify-content-xl-start ml-xl-5 align-items-center py-5">
                  <div className="text-primary mr-2">
                    <img
                      src="assets/svg/icons/icon-68.svg"
                      alt="iconVideo"
                      width="60px"
                      height="43px"
                    />
                  </div>
                  <label className="video-library-title my-auto ml-5">
                    {t('videoMakerDashboard.myLibrary')}
                    {videoGrapherPosts?.totalPosts !== 0 && (
                      <span className="video-count-text ml-2">{` ${
                        videoGrapherPosts?.totalPosts
                      } ${t('videoMakerDashboard.video')}${
                        videoGrapherPosts?.totalPosts !== 1 ? 's' : ''
                      }`}</span>
                    )}
                  </label>
                </div>
                <div className="d-flex row justify-content-center justify-content-xl-start align-items-center">
                  <div className="col-md-5 col-lg-5 col-12 input-search-section mb-lg-0 mb-2 mb-md-0">
                    <TabSwitcher
                      onSwitch={(value) => OnSwitchChange(value)}
                      typeId={category}
                      options={[
                        { label: t('filters.recents'), value: 'RECENT' },
                        { label: t('filters.country'), value: 'PAYS' },
                      ]}
                    />
                  </div>
                  <div className="col-md-5 col-lg-5 col-12 input-search-section">
                    <SearchInput
                      name="search"
                      placeHolder={t('searchPlaceholder')}
                      onChange={(e) =>
                        onSearchChange((e.target as EventTarget & { value: string }).value)
                      }
                    />
                  </div>
                  <div className="col-md-10 col-lg-10 col-12 pt-0 pt-md-4 pt-lg-4 input-search-section">
                    {category === 'PAYS' && (
                      <SliderAlphabet
                        onChangeLetters={(letter) => OnLetterChange(letter)}
                        isVideoPage
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4">
              {currentUser && (
                <ProfileContainer
                  isAbleToWithdraw={isAbleToWithdraw}
                  userReports={userReports}
                  currentUser={currentUser}
                />
              )}
            </div>
          </div>
        </div>
        <VideoCarousel className="mt-10">
          {videoGrapherPosts?.posts.map((post) => (
            <VideoCardInfo key={post?._id} post={post} />
          ))}
        </VideoCarousel>
      </div>
      <Footer />
    </>
  );
};

export default VideoLibrary;
