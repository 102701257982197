import { LoadingTypes } from 'client/_redux/types/loadingTypes';
import { IReduxAction } from 'types/IReduxAction';

export interface LoadingState {
  initiated: boolean;
}
const initialState: LoadingState = {
  initiated: false,
};

export default (state: LoadingState = initialState, action: IReduxAction): LoadingState => {
  const { type } = action;

  switch (type) {
    case LoadingTypes.INITIATE:
      return { ...state, initiated: true };
    default:
      return state;
  }
};
