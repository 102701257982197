import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IUserInvoice } from 'server/models/UserInvoice';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { withdrawSalesBalance } from 'client/_redux/actions/auth/withdraw';
import { toast } from 'react-toastify';

const useWalletTab = (userReports: IUserInvoice[]) => {
  const [isOpen, setIsOpen] = useState(false);
  const openReport: () => void = () => {
    setIsOpen(!isOpen);
  };

  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const lastReport = useMemo(
    () =>
      userReports.length > 0 &&
      format(new Date(userReports[userReports.length - 1].createdAt || 1), 'MMM'),
    [userReports],
  );

  const onPressWithdraw = useCallback(async () => {
    setIsWithdrawLoading(true);
    await dispatch(withdrawSalesBalance());
    toast(t('ProfileContainer.WalletTab.toastMessage'), {
      type: 'success',
      autoClose: 10 * 1000,
    });
    setIsWithdrawLoading(false);
  }, [dispatch, t]);

  return { isOpen, openReport, t, lastReport, onPressWithdraw, isWithdrawLoading };
};

export default useWalletTab;
