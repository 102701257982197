/* eslint-disable max-len */
import React from 'react';
import NavBarElement from 'client/components/NavBarElement';
import SocialMediaButton from 'client/components/SocialMediaButton';
import { LanguageSelector } from 'client/components/LanguageSelector';
import { Link } from 'react-router-dom';
import UserProfile from 'client/components/SVG/UserProfile';
import NavBarBurger from 'client/components/NavBarBurger';
import { LogoReam } from 'client/components/SVG';
import { useIsVideoGrapherApproved } from 'client/hooks/useIsVideographerApproved';
import { useNavBarContainer } from './useNavBarContainer';

interface IProps {
  isDarkBackground?: boolean;
  className?: string;
}

const NavBarContainer: React.FC<IProps> = ({ isDarkBackground, className }) => {
  const { isLoggedIn, handleDisconnect, t } = useNavBarContainer();

  return (
    <div className={className}>
      <div
        className={`navBar-container w-100 justify-content-center align-item-center z-index-2 ${
          isDarkBackground ? 'navBar-dark-background' : 'bg-transparent'
        }`}
      >
        <div className="navBar-social-container">
          <div className="w-40 w-lg-30 ml-3">
            <SocialMediaButton
              colorIcon="white"
              backgroundIcon="dark"
              className="w-15 navBar-social-buttons"
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span className="mr-5 text-dark text-16">
              {t('navBar.compteProfessionelPourVideaste')}
            </span>
            <div className="d-flex justify-content-between align-items-center">
              <UserProfile />
              {isLoggedIn ? (
                <Link className="navBar-button ml-2" to="/" onClick={handleDisconnect}>
                  {t('navBar.seDeconnecter')}
                </Link>
              ) : (
                <Link className="navBar-button ml-2" to="/login">
                  {t('navBar.seConnecter')}
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between sub-nav-bar-container align-items-center mt-3">
          <Link to="/">
            <div>
              <LogoReam width="174px" height="50px" />
            </div>
          </Link>
          <div className="navBar-links-container">
            <NavBarElement text={t('navBar.commentCaMarche')} page="/how-to" />
            <NavBarElement text={t('navBar.devenirVideaste')} page="/video-maker" />
            <NavBarElement text={t('navBar.galerie')} page="/gallery" />
            {useIsVideoGrapherApproved() && (
              <NavBarElement text={t('navBar.myLibrary')} page="/video-library" />
            )}

            <LanguageSelector />
          </div>
          <div className="NavBarBurger-button">
            <NavBarBurger isConnected={isLoggedIn} handleDisconnect={handleDisconnect} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBarContainer;
