/* eslint-disable max-len */
import Input from 'client/components/Form/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { Link } from 'react-router-dom';
import FormCheckBox from 'client/components/Form/FormCheckbox';
import { FormProvider } from 'react-hook-form';
import SubmitButton from 'client/components/Form/SubmitButton';
import { ISignUpFormValues, useSignUp } from './useSignUp';

interface IPorps {
  nextSlide: () => void;
}

const LoginFormSignUp: React.FC<IPorps> = ({ nextSlide }) => {
  const { formMethods, handleSubmit, _onSubmit, password, t } = useSignUp<ISignUpFormValues>(
    nextSlide,
  );

  return (
    <div className=" bg-white shadow rounded py-5 h-100">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(_onSubmit)}>
          <div className="flex-column  d-flex align-items-center align-items-lg-start mb-3 mx-lg-11 font-size-2 font-weight-bold text-primary-lighter text-center">
            {t('LoginForm.Become')}
          </div>
          <div className="body-login-form row flex-column justify-content-center align-items-center no-gutters mb-3">
            <div className="col-lg-6 col-10 ">
              <Input
                className="bg-light-grey text-center"
                data-testid="usernameInput"
                type="text"
                name="username"
                placeholder={t('LoginForm.UserName')}
                validate={Validators([
                  { validation: 'required' },
                  { validation: 'alpha_space' },
                ])}
              />
              <Input
                className="bg-light-grey h-80 text-center"
                data-testid="emailInput"
                type="email"
                name="email"
                placeholder={t('LoginForm.UserEmail')}
                validate={Validators([{ validation: 'required' }, { validation: 'email' }])}
              />

              <Input
                className="bg-light-grey  text-center"
                data-testid="passwordInput"
                type="password"
                name="password"
                placeholder={t('LoginForm.PassWord')}
                validate={Validators([
                  { validation: 'required' },
                  { minParams: 8, validation: 'minLength' },
                ])}
              />
              <Input
                className="bg-light-grey text-center"
                data-testid="reapeatPassWordInput"
                type="password"
                name="passwordConfirmation"
                placeholder={t('LoginForm.ReapeatPassWord')}
                validate={Validators([
                  { validation: 'required' },
                  { minParams: 8, validation: 'minLength' },
                  { validation: 'passwordConfirm', passwordConfirm: password },
                ])}
              />
            </div>
            <div className=" mt-3 col-lg-6 col-10 d-flex justify-content-center  align-content-center">
              <div className="d-inline-flex  justify-content-center mb-4  align-items-center">
                <FormCheckBox name="conditionsAccepted" required />
              </div>

              <div className="d-flex font-size-checkbox-label text-secondary align-items-center justify-content-center">
                <p>
                  {t('LoginForm.ReadAndAccept')}
                  <span>
                    <Link
                      className=" ml-1 font-size-checkbox-label text-primary-lighter"
                      to="/terms-and-conditions"
                      target="_blank"
                    >
                      {t('LoginForm.Conditions')}
                    </Link>
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <SubmitButton name={t('LoginForm.Register')} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default LoginFormSignUp;
