import InfoCard from 'client/components/InfoCard';
import HandMoney from 'client/components/SVG/HandMoney';
import Paper from 'client/components/SVG/Paper';
import YoutubeIcon from 'client/components/SVG/YoutubeIcon';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface IProps {
  isHomePage?: boolean;
}

const InfoCardContainer: React.FC<IProps> = ({ isHomePage }) => {
  const [t] = useTranslation();

  const infoCardData = [
    {
      title: `${t('VideoMakerPage.infoCardSlide1.title')}`,
      body: (
        <span className="text-white text-left content-text">
          {t('VideoMakerPage.infoCardSlide1.description')}
        </span>
      ),
      icon: (
        <div className="text-center left-50 top-50">
          <Paper width={60} />
        </div>
      ),
      cardTitle: `${t('VideoMakerPage.infoCardSlide1.cardTitle')}`,
    },
    {
      title: `${t('VideoMakerPage.infoCardSlide2.title')}`,
      body: (
        <span className="text-white text-left content-text">
          {t('VideoMakerPage.infoCardSlide2.description')}
        </span>
      ),
      icon: (
        <div className="text-center left-50 top-50">
          <YoutubeIcon width={60} />
        </div>
      ),
      cardTitle: `${t('VideoMakerPage.infoCardSlide2.cardTitle')}`,
    },
    {
      title: `${t('VideoMakerPage.infoCardSlide3.title')}`,
      body: (
        <span className="text-white text-left mb-6 content-text">
          {t('VideoMakerPage.infoCardSlide3.description')}
        </span>
      ),
      icon: (
        <div className="text-center left-50 top-50">
          <HandMoney width={75} />
        </div>
      ),
      cardTitle: `${t('VideoMakerPage.infoCardSlide3.cardTitle')}`,
    },
  ];

  const infoCardHomePageData = [
    {
      title: `${t('HomePage.infoCardSlide1.title')}`,
      body: (
        <span className="text-white text-left content-text">
          {t('HomePage.infoCardSlide1.description')}
        </span>
      ),
      cardTitle: `${t('HomePage.infoCardSlide1.cardTitle')}`,
    },
    {
      title: `${t('HomePage.infoCardSlide2.title')}`,
      body: (
        <span className="text-white text-left content-text">
          {t('HomePage.infoCardSlide2.description')}
        </span>
      ),
      cardTitle: `${t('HomePage.infoCardSlide2.cardTitle')}`,
    },
    {
      title: `${t('HomePage.infoCardSlide3.title')}`,
      body: (
        <span className="text-white text-left mb-6 content-text">
          {t('HomePage.infoCardSlide3.description')}
        </span>
      ),
      cardTitle: `${t('HomePage.infoCardSlide3.cardTitle')}`,
    },
  ];

  if (isHomePage) {
    return <InfoCard data={infoCardHomePageData} isHomePage />;
  }

  return <InfoCard data={infoCardData} isHomePage={false} />;
};

export default InfoCardContainer;
