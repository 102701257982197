import * as React from 'react';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import ImagePicker, { IProps as IComponentProps } from 'client/components/Form/ImagePicker';
import get from 'lodash/get';

interface IProps extends Omit<IComponentProps, 'errors' | 'onChange'> {
  name: string;
  validate?: ValidationRules;
}

const FormImagePicker: React.FC<IProps> = ({ name, validate, ...imagePickerProps }) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      render={({ onChange, value }) => (
        <ImagePicker
          name={name}
          {...imagePickerProps}
          initialValue={value}
          onChange={onChange}
          errors={get(errors, name)}
        />
      )}
    />
  );
};

export default FormImagePicker;
