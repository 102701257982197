import FormSwitchBox from 'client/components/Form/FormSwitchBox';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  name: string;
  value: boolean;
  checked?: boolean;
}

const SwitchBoxProfile: React.FC<IProps> = ({ checked, name, value }) => {
  const [t] = useTranslation();

  return (
    <div className="form-check form-switch d-flex flex-row align-items-baseline justify-content-center ">
      <h6 className="pr-3 mr-1 m-auto toggle-switch-val">
        {value ? t('ProfileContainer.SettingPage.Yes') : t('ProfileContainer.SettingPage.No')}
      </h6>
      <div className="mt-2 pr-5">
        <FormSwitchBox
          checked={checked}
          name={name}
          className="p-0 m-0 toggle-switch btn-modify "
          autoFocus
        />
      </div>
    </div>
  );
};

export default SwitchBoxProfile;
