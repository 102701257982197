/* eslint-disable no-bitwise */
import React from 'react';
import { ReactConfirmAlertProps } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

const ConfirmationModal: React.FC<ReactConfirmAlertProps> = ({
  buttons,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onClose,
}) => {
  const [t] = useTranslation();

  return (
    <div className="card py-3 px-7 min-width-21 align-items-center bg-dark ">
      <span className="h2 font-weight-normal text-white text-oxygen">
        {t('misc.confirmation')}
      </span>
      <span className="h6 mt-3 font-weight-normal text-white text-oxygen">
        {t('misc.areYouSure')}
      </span>
      <div className="d-flex w-100 justify-content-around mt-5" style={{ gap: 5 }}>
        {buttons?.map((el, i) => (
          <button
            key={el.label}
            id="basic"
            type="button"
            className={`btn btn-sm flex-grow-1 ${
              i & 1 ? 'btn-soft-success' : 'btn-soft-danger'
            }`}
            onClick={() => {
              el.onClick();
              onClose();
            }}
          >
            {el.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ConfirmationModal;
