import React from 'react';

const useImagePickerPreview = () => {
  const handlePictureClick = (
    event:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | React.KeyboardEvent<HTMLSpanElement>,
    index: number,
    fn: (index: number) => void,
  ) => {
    event.stopPropagation();
    fn(index);
  };

  return { handlePictureClick };
};

export default useImagePickerPreview;
