import React from 'react';
import { FormProvider } from 'react-hook-form';
import SwitchBoxProfile from 'client/components/Form/SwitchBoxUserProfile/SwitchBoxProfile';
import useSwitchRow from './useSwitchRow';

export interface IPorps {
  name: string;
  checked?: boolean;
  label: string;
}

const SwitchRow: React.FC<IPorps> = ({ checked, name, label }) => {
  const { formMethods } = useSwitchRow({
    name,
  });

  return (
    <FormProvider {...formMethods}>
      <form className="w-100">
        <div className="border-top pb-2 pt-2 m-0 InputRow row justify-content-between">
          <div className="info-name col-4 pr-0 m-0 my-auto">
            <h1>{label}</h1>
          </div>
          <div className="col-3 third-col m-0">
            <SwitchBoxProfile checked={checked} name={name} value={!!checked} />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default SwitchRow;
