import { clientApi } from 'api';

export const checkEmailAndUsernameAvailability = (isServer = false) => async ({
  email,
  username,
}: {
  email: string;
  username: string;
}) => {
  const emailAvailabilityResponse = await clientApi.post('/users/check-email', {
    email,
  });
  const usernameAvailabilityResponse = await clientApi.post('/users/check-username', {
    username,
  });

  return {
    emailIsAvailable: emailAvailabilityResponse.data.available,
    usernameIsAvailable: usernameAvailabilityResponse.data.available,
  };
};
