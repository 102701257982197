import React from 'react';
import { useTranslation } from 'react-i18next';
import TimonialsSlides from '../TimonialsSlides';

const Testimonials = () => {
  const [t] = useTranslation();

  const data = [
    {
      pictureUrl: 'assets/img/michael-dam-mEZ3PoFGs_k-unsplash.jpeg',
      body: t('HomePage.testimonials.testimonial'),
    },
    {
      pictureUrl: 'assets/img/michael-dam-mEZ3PoFGs_k-unsplash.jpeg',
      body: t('HomePage.testimonials.testimonial'),
    },
  ];

  return (
    <div className="testimonial-container testimonial-background ">
      <div className="container w-100 w-md-80" style={{ height: '100%' }}>
        <div className="w-auto pt-3 pb-3 p-md-4 text-center ">
          <p className="text-white font-weight-bold font-size-3 testimonial-title">
            {t('HomePage.testimonials.title')}
          </p>

          <div className="justify-content-center testimonial-subtitle-section">
            <p className="testimonial-subtitle-section">{t('HomePage.testimonials.text')}</p>

            <div
              style={{
                backgroundImage: `url("/assets/svg/custom/homePage/google-reviews.png")`,
                width: 122,
                height: 40,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                marginRight: '1rem',
                marginLeft: '1rem',
                marginBottom: '1rem',
              }}
            />
            <div
              style={{
                backgroundImage: `url("/assets/svg/custom/homePage/trustpilot.png")`,
                width: 122,
                height: 40,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                marginBottom: '1rem',
              }}
            />
          </div>
        </div>
        <TimonialsSlides data={data} />
      </div>
    </div>
  );
};

export default Testimonials;
