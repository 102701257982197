/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import Footer from 'client/containers/Footer';
import NavBarContainer from 'client/containers/NavBarContainer';
const LicencePage: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  return (
    <>
      <div>
        <NavBarContainer isDarkBackground />
      </div>

      <main
        id="content"
        role="main"
        style={{ overflow: 'hidden' }}
        className="position-relative"
      >
        <div
          className="container col-12 col-sm-7"
          style={{ marginTop: '10rem', marginBottom: '7rem' }}
        >
          <p
            style={{
              marginTop: '0pt',
              marginBottom: '8pt',
              textAlign: 'center',
              pageBreakBefore: 'always',
              lineHeight: '106%',
              fontSize: '24pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              CONTRAT DE LICENCE
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              textAlign: 'center',
              lineHeight: '106%',
              fontSize: '24pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Veuillez consulter à nouveau les présentes CGU lorsque vous achetez des licences
              pour des contenus. Ream se réserve le droit de modifier les CGU à tout moment et
              à sa seule discrétion. Avant l’entrée en vigueur de telles modifications, Ream
              mettra en œuvre des efforts raisonnables pour vous informer de ces changements.
              Vous pourrez être averti(e) par un e-mail envoyé à l’adresse enregistrée sur
              votre compte Ream, par un avis sur cette page ou votre page de connexion et/ou
              par d’autres moyens. Les modifications apportées aux présentes CGU ne
              s’appliqueront qu’aux achats à venir (renouvellements automatiques inclus). En
              proposant du contenu sous licence après de telles modifications, vous acceptez
              d’être lié(e) par les CGU modifiées.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              CECI CONSTITUE UNE LICENCE MONO-UTILISATEUR AUTORISANT UNE PERSONNE PHYSIQUE À
              ATTRIBUER UNE LICENCE, TÉLÉCHARGER ET UTILISER LES CONTENUS.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Le terme « Contenu » s’applique à tout le contenu disponible avec la licence sur
              le site Ream, y compris les Images, les Vidéos.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Le terme « Vidéo » désigne toutes les images animées, les animations, les films,
              les vidéos, d’autres représentations visuelles ou audio, les éléments de vidéos,
              les éléments d’effets visuels, les modèles, les packs graphiques et les projets
              de motion design.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              1. SECTION I : LICENCES RELATIVES AUX CONTENUS VISUELS
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              1.1 Par les présentes, Ream vous accorde un droit non exclusif et non cessible
              vous permettant d’utiliser, de modifier (à l’exception de ce qui est expressément
              interdit dans les présentes) et de reproduire des contenus visuels à l’échelle
              internationale, à perpétuité, dans toute la mesure permise par la licence
              applicable et sous réserve des restrictions définies dans les présentes
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>:</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp; a. LICENCES POUR LES VIDÉOS </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              &nbsp;&nbsp;&nbsp;&nbsp; i. UNE LICENCE D’UTILISATION DES VIDÉOS vous accorde le
              droit d’utiliser les Vidéos :
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1. Dans une production multimédia
              affichée ou distribuée via le web, les médias sociaux, des « Applis » ou par tout
              autre moyen conformément aux présentes (voir les restrictions des limitations en
              matière de distribution).
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              &nbsp;&nbsp;&nbsp;&nbsp; ii. UNE LICENCE POUR MAQUETTE VIDÉO
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              La Maquette vidéo peut être modifiée, mais vous ne pouvez pas supprimer ni
              modifier le filigrane Ream. La Maquette vidéo est disponible avec une licence
              dans le cadre de la licence d’utilisation des vidéos au moment du téléchargement
              en tant que maquette vidéo, mais Ream ne garantit pas et n’a aucune obligation de
              s’assurer de la disponibilité de la licence pour la maquette vidéo à n’importe
              quel moment par la suite.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              1.2 RESTRICTIONS CONCERNANT L’UTILISATION DE CONTENUS VISUELS VOUS NE POUVEZ PAS
              :
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              a. Utiliser les contenus visuels d’une manière autre que celle expressément
              définie par la licence que vous avez achetée pour de tels contenus visuels.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              b. Représenter une personne dans des contenus visuels (un « Modèle ») d’une
              manière qu’une personne sensée trouverait offensante, y compris, mais sans s’y
              limiter, la représentation d’un modèle : a&rpar; dans un contexte en lien avec la
              pornographie, les « vidéos pour adultes », les lieux de divertissement pour
              adultes, les services d’escorte, les services de rencontre, ou autres ; b&rpar;
              dans un contexte en lien avec la publicité ou la promotion de produits du tabac ;
              c&rpar; dans un contexte politique, comme la promotion, la publicité ou le
              soutien à un parti politique, un candidat, un représentant élu ou en lien avec
              une stratégie politique ou une opinion ; d&rpar; comme souffrant de, ou sous
              traitement pour, une affection mentale ou physique ; ou e&rpar; s’engageant dans
              des activités criminelles ou immorales.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              c. Utiliser des contenus visuels dans un contexte pornographique, diffamatoire ou
              trompeur, ou d’une manière qui pourrait être considérée comme diffamatoire,
              obscène ou illégale.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              d. Modifier des contenus visuels portant la mention « Usage éditorial uniquement
              » d’une manière qui change le contexte de ce qui est représenté.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              e. Utiliser des contenus visuels portant la mention « Usage éditorial uniquement
              » (notamment, mais sans s’y limiter, les contenus éditoriaux) à des fins
              commerciales, notamment pour référence, dans des publicités, des produits à usage
              commercial ou d’autres contextes qui ne sont pas éditoriaux.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              f. Revendre, redistribuer, des contenus visuels.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              g. Utiliser tout contenu visuel d’une manière qui contrevient à toute marque
              déposée ou à toute propriété intellectuelle d’un tiers, ou qui pourrait donner
              lieu à une réclamation pour publicité trompeuse ou concurrence déloyale.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              h. Utiliser des contenus visuels (en intégralité ou en partie) comme marque
              déposée, marque de service, logo ou toute autre indication d’origine, ou comme en
              faisant partie intégrante.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              i. Prétendre faussement, de façon explicite ou suffisamment trompeuse, que des
              contenus visuels ont été créés par vous ou une personne autre que le détenteur
              des droits d’auteur desdits contenus visuels.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>1.3 RESTRICTIONS PROPRES AUX VIDÉOS </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              a. Utiliser des vidéos dans une production multimédia distribuée par diffusion
              télévisée, réseau câblé, service vidéo OTT (par ex. Netflix, Hulu, Amazon) ou
              dans des salles de cinéma.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              b. Utiliser des images fixes capturées à partir d’une vidéo à d’autres fins que
              pour du marketing, de la promotion et de la publicité pour vos œuvres dérivées
              intégrant la vidéo dans le contexte.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '14pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '14pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              2. SECTION II : LICENCES POUR LA MUSIQUE
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>2.1 LICENCES RELATIVES À LA MUSIQUE </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Ream vous accorde le droit non exclusif et non cessible de synchroniser la
              musique dans une production, cette production pouvant être distribuée à l’échelle
              internationale, à perpétuité, conformément à la licence que vous avez acquise.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              2.2 RESTRICTIONS RELATIVES À L’UTILISATION DE LA MUSIQUE. VOUS NE POUVEZ PAS :
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              a. Concéder en sous-licence, revendre, louer, céder, donner ou transférer ou
              distribuer par un autre moyen à un tiers la musique ou le droit d’utiliser la
              musique de manière séparée et distincte de la Production dans laquelle (selon
              votre licence) elle est intégrée ;
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              b. Revendre, redistribuer, donner accès à, partager ou transférer de la musique,
              quelle qu’elle soit, sauf disposition spécifiquement prévue dans les présentes
              conditions ;
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              c. Fabriquer, distribuer, vendre ou exploiter d’une autre manière les «
              enregistrements », CD, fichiers mp3 et tout autre produit audio exclusivement
              sonore qui intègre la musique, en dehors de la production exclusivement sonore
              expressément décrite dans la définition de « Production » ci-dessus. Pour les
              besoins de cette sous-section, le terme « enregistrements » s’applique à toutes
              les formes de reproduction, actuelles ou à venir, de fabrication ou de
              distribution principalement pour une utilisation domestique, scolaire, en
              juke-box, comprenant exclusivement du son (à l’exclusion des supports
              synchronisés) ;
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              d. Utiliser la musique en lien avec un autre contenu à caractère pornographique,
              diffamatoire, injurieux, obscène, immoral ou illicite, ou violant le ou les
              droits de tiers ;
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              e. Enfreindre les lois, réglementations et restrictions en matière d’exportation
              par l’envoi, le transfert ou l’exportation de la musique vers un pays en
              enfreignant des lois, réglementations ou restrictions en matière d’exportation ;
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              f. Utiliser, vendre, concéder en sous-licence, reproduire, distribuer, diffuser,
              intégrer dans une œuvre ou rendre disponible d’une autre manière toute ou partie
              de la Musique dans une banque de musique ou sous la forme de fichiers
              téléchargeables, ou inclure la Musique ou toute œuvre dérivée dans tout autre
              produit, bibliothèque ou collection libre de droits ;
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              g. Mixer, remixer ou altérer d’une autre manière la Musique.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              h. Utiliser la Musique dans une Production purement audio dans laquelle la
              musique est le contenu principal.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              2.3 Vous ne pouvez pas revendiquer la propriété de la musique ou enregistrer
              d’une autre manière une musique sur une Plate-forme UGC, même si elle est
              synchronisée avec votre propre production. Si vous apprenez qu’une tierce partie
              réclame des intérêts de propriété sur une musique, vous acceptez d’informer
              rapidement Ream de chacune de ces réclamations.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '14pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '14pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              3. SECTION III : GARANTIES ET DÉCLARATIONS
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>3.1 Ream garantit et déclare que : </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              a. Les contributeurs de Ream ont accordé à Ream tous les droits nécessaires
              relatifs à leurs contenus afin d’accorder les droits définis dans la Section I ou
              la Section II, le cas échéant.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              b. Les Vidéos et Images dans leur forme inchangée d’origine et utilisés en totale
              conformité avec les présentes CGU et les lois applicables : i&rpar; ne violeront
              aucun droit d’auteur et aucune marque déposée, ni aucun autre droit de propriété
              intellectuelle ; ii&rpar; ne violeront pas les droits au respect de la vie privée
              et de publicité de tiers ; iii&rpar; ne violeront pas les lois, actes,
              ordonnances ou réglementations&rpar; ne seront pas diffamatoires, calomnieux, à
              caractère pornographique ou obscènes.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              3.2 Bien que Ream déploie des efforts commercialement raisonnables pour garantir
              l’exactitude des mots-clés et des descriptions, ainsi que l’intégrité des
              Contenus visuels désignés comme étant à « Usage éditorial uniquement », REAM NE
              DONNE AUCUNE GARANTIE ET/OU NE FAIT AUCUNE DÉCLARATION QUANT AUX : I&rpar;
              MOTS-CLÉS, TITRES OU DESCRIPTIONS ; OU II&rpar; CONTENUS AUDIO DANS LES VIDÉOS.
              Autrement dit, Ream ne procédera à aucune indemnisation et ne sera pas tenu
              responsable en cas de réclamation découlant de mots-clés, titres ou descriptions
              inexacts, et de tout contenu audio des vidéos.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              3.3 REAM NE FAIT AUCUNE DÉCLARATION OU NE DONNE AUCUNE GARANTIE AUTRE QUE CELLES
              EXPRESSÉMENT FORMULÉES DANS CETTE SECTION « GARANTIES ET DÉCLARATIONS ».
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              4. SECTION IV : INDEMNISATION ET RESPONSABILITÉ
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              4.1 Sous réserve des conditions stipulées dans les présentes, et à condition que
              vous n’ayez pas enfreint les clauses de ce contrat ou de tout autre contrat
              conclu avec Ream, Ream devra vous défendre, vous indemniser et vous dégager de
              toute responsabilité dans la « Limite de responsabilité » applicable (telle que
              définie ci-après). Une telle indemnisation est uniquement limitée aux dommages
              directs subis par le client, découlant d’une réclamation de la part d’un tiers
              directement attribuable à la violation des déclarations et garanties expresses de
              Ream définies. L’indemnisation s’applique à condition que vous avisiez Ream, par
              écrit, de toute réclamation ou menace de réclamation, au plus tard cinq (5) jours
              ouvrables à compter de la date à laquelle vous avez pris connaissance ou devriez
              raisonnablement avoir pris connaissance de la réclamation ou de la menace de
              réclamation. Cette notification doit inclure tous les détails de la réclamation
              dont vous avez alors connaissance (par exemple l’utilisation des contenus posant
              un problème, le nom et les coordonnées de la personne et/ou de l’entité à
              l’origine de la réclamation, les copies de toute correspondance reçue et/ou
              envoyée dans le cadre de ladite réclamation). La notification doit être envoyée
              par e-mail à Ream à l’adresse contact@ream-movie.com. Un exemplaire papier doit
              également être adressé à «
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              Chez Calliopée SARL, Panelprod, Rue de Chantepoulet 10, 1201 Genève
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              ». Attention : Conseiller juridique de l’entreprise, par courrier recommandé avec
              accusé de réception ; ou par courrier express, remis sur signature. Ream a le
              droit de prendre en charge le traitement, le règlement ou la défense pour toute
              réclamation ou tout litige auxquels s’applique cette indemnisation. Vous acceptez
              de coopérer avec Ream dans la défense d’une telle réclamation et vous aurez le
              droit de participer à toute procédure contentieuse, à vos frais. Vous acceptez
              que Ream
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>
              ne soit pas tenu responsable des frais juridiques et/ou tout autre coût encouru
              par vous ou pour votre compte, avant que Ream n’ait eu l’opportunité raisonnable
              d’analyser la validité d’une telle réclamation.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              4.2 Ream ne pourra pas être tenu pour responsable en cas de dommages, coûts ou
              pertes résultant des modifications apportées aux contenus ou en raison du
              contexte dans lequel vous utilisez les contenus.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              4.3 Vous indemniserez Ream, ses représentants légaux, employés, actionnaires,
              directeurs, responsables, membres et fournisseurs, et leur éviterez tout dommage
              ou les dégagerez de toute responsabilité découlant de toute utilisation des
              contenus visuels autre que les utilisations expressément autorisées par les
              présentes CGU. Vous acceptez également d’indemniser Ream pour tous les frais et
              dépenses encourus par Ream en cas de non-respect de votre part de l’une des
              conditions de ce contrat ou de tout autre contrat qui vous lie à Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              5. SECTION V : CONDITIONS SUPPLÉMENTAIRES
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.1 Sauf si la loi l’exige, en aucun cas, Ream n’est tenue d’effectuer des
              remboursements. Tous les frais sont non remboursables. Vous autorisez Ream à
              facturer l’intégralité des frais pour la période convenue au moment de l’achat.
              Si Ream détermine que vous êtes en droit de recevoir un remboursement pour une
              partie ou l’intégralité des montants que vous avez déboursés, ce remboursement
              pourra être effectué avec le mode de paiement que vous avez initialement utilisé
              pour effectuer votre achat. Si vous résidez dans l’Union européenne et résiliez
              votre compte dans les quatorze (14) jours suivant le paiement versé à Ream et à
              condition que vous n’ayez pas encore téléchargé ou attribué de licence aux
              contenus visuels, Ream vous remboursera l’intégralité du montant que vous avez
              versé en rapport avec ledit compte résilié.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.2 Si Ream doit percevoir certaines taxes indirectes et/ou transactionnelles
              (par exemple, taxe de vente, taxe sur la valeur ajoutée, taxe sur les biens et
              les services et autres) en vertu des lois de votre État ou pays de résidence,
              vous êtes tenu(e) de payer ces taxes indirectes. Si Ream ou vous devez percevoir
              ou verser des taxes indirectes ou indirectes, il peut vous être demandé d’évaluer
              vous-même la taxe concernée en vertu des lois applicables dans votre pays de
              résidence.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.3 L’expression « non cessible », ou non transmissible utilisée dans les
              présentes signifie que, à l’exception de ce qui est expressément prévu dans les
              présentes CGU, vous ne pouvez pas vendre, louer, prêter, donner, concéder en
              sous-licence ni transmettre de toute autre manière à qui que ce soit des contenus
              ou le droit d’utiliser des contenus. Si vous vous rendez compte qu’un site web de
              médias sociaux utilise des contenus d’une manière qui outrepasse vos droits de
              licence aux termes des présentes, vous acceptez de supprimer toutes les œuvres
              dérivées intégrant des contenus d’un tel Site de médias sociaux, et d’informer
              immédiatement Ream de chaque utilisation par ledit site web de médias sociaux.
              Vous consentez à prendre toutes les mesures commercialement raisonnables pour
              empêcher des tiers de reproduire des contenus. Si vous avez connaissance d’une
              reproduction non autorisée de tout contenu.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.4 À la réception d’un avis de Ream, ou si vous prenez connaissance du fait
              qu’un contenu fait l’objet d’une réclamation réelle ou potentielle pour
              infraction, violation d’un autre droit, ou de toute autre réclamation dont Ream
              peut être responsable, ou si Ream supprime le contenu en raison d’un risque
              commercial perçu, à la seule discrétion de Ream, et vous informe de cette
              suppression, vous devez supprimer le contenu de vos systèmes informatiques et de
              vos périphériques de stockage (électroniques ou physiques) et, si possible, vous
              devez cesser toute utilisation ultérieure du contenu supprimé à vos propres
              frais. Ream vous fournira un contenu similaire (dont la similarité sera
              déterminée par Ream, selon son jugement commercial raisonnable) gratuitement,
              mais sous réserve de conditions générales des présentes CGU.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.5 Si vous utilisez des contenus dans le cadre d’un produit de travail créé pour
              ou livré à un client, vous communiquerez les identités de ces clients à Ream, à
              la demande raisonnable de Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>5.6 Arbitrage. </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              1. Toute réclamation ou tout différend en rapport avec les présentes CGU ou avec
              une violation de celles-ci sera soumis à un arbitrage exécutoire individuel (non
              collectif). Un arbitre sera désigné d’un commun accord par les parties. Chacune
              des parties assumera ses propres frais d’arbitrage. Les deux parties
              reconnaissent que les réclamations suivantes constituent des exceptions au
              contrat d’arbitrage et qu’elles seront présentées dans le cadre d’une procédure
              devant un tribunal compétent : (i) toute réclamation concernant une violation ou
              un détournement, réel ou sous forme de menaces, des droits d’auteur d’une partie,
              de sa marque déposée, ses secrets commerciaux, ses brevets ou d’autres droits de
              propriété intellectuelle ; (ii) toute réclamation demandant des mesures
              injonctives urgentes fondées sur des circonstances urgentes (par ex. un danger
              imminent ou un acte criminel, un piratage ou une cyberattaque) ; (iii) toute
              réclamation découlant uniquement d’une incapacité présumée du client à payer les
              frais dus à Ream. La présente disposition relative à l’arbitrage restera en
              vigueur après la résiliation des présentes CGU.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              2. VOUS ET REAM VOUS ENGAGEZ À N’ÉMETTRE DES RÉCLAMATIONS À L’ENCONTRE DE L’AUTRE
              PARTIE QUE POUR VOTRE COMPTE OU EN QUALITÉ DE PARTICULIER ET NON EN TANT QUE
              PLAIGNANT OU MEMBRE D’UN GROUPE QUI INTENTE UNE ACTION DE GROUPE OU COLLECTIVE.
              En cas de désaccord entre vous et Ream, aucun arbitre ou juge ne prendra en
              considération les réclamations émanant de plus d’un individu ou ne présidera
              aucune forme d’action de groupe ou collective. L’arbitre ne prendra des mesures
              injonctives qu’en faveur de l’individu demandeur. Ces dernières permettront
              uniquement de répondre à la réclamation effectuée par cet individu. Vous et Ream
              reconnaissez et acceptez que tous les différends relatifs aux présentes CGU
              puissent être arbitrés par un jury dans le cadre d’un procès.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              3. Si un tribunal décide que la loi applicable est contraire à l’application
              d’une des limitations décrites dans le cadre d’une réclamation particulière
              ouvrant droit à réparation, cette réclamation (et uniquement celle-ci) sera
              traitée séparément de l’arbitrage et pourra être portée au tribunal.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.7 Aucune des parties ne peut céder le présent accord sans le consentement écrit
              préalable de l’autre partie, Ream pouvant toutefois céder le présent accord à une
              filiale, à une société affiliée du
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>
              groupe Ream, à l’entité résultant d’une fusion ou de toute autre restructuration
              impliquant Ream ou à une entité qui acquiert la totalité ou la quasi-totalité des
              actifs ou du capital social de Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.8 Les présentes CGU ne doivent pas être interprétées comme étant à
              l&apos;encontre ou en faveur de l&apos;une ou l&apos;autre des parties, mais
              selon leur juste signification dans la langue dans laquelle elles sont fournies.
              Les présentes CGU sont régies par le droit de la juridiction applicable définie
              et doivent être interprétées en conséquence, indépendamment des dispositions en
              matière de conflits des lois.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.9 Si vous acceptez les présentes CGU pour le compte de votre employeur ou d’une
              autre entité, vous garantissez et déclarez que vous disposez des pleins pouvoirs
              et des droits pour ce faire. Si vous ne disposez pas de cette autorité, vous
              acceptez d’assumer personnellement la responsabilité envers Ream pour toute
              violation des dispositions des présentes CGU. Vous accordez par la présente à
              Ream une licence mondiale, non exclusive, limitée lui permettant d’utiliser vos
              marques déposées dans les supports promotionnels de Ream, y compris une liste
              publique de clients. L’utilisation que fait Ream de vos marques déposées doit
              être en permanence conforme aux politiques d’utilisation de vos marques déposées
              alors en vigueur, telles qu’elles sont mises à la disposition de Ream et doit en
              tout temps vous avantager. Ream accepte également de consentir à des efforts
              commerciaux raisonnables afin de cesser tout usage particulier de votre marque
              déposée au plus tard trente (30) jours après la date de réception par Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.10 Sauf mention contraire sur le bon de réduction, tout bon ou code de
              réduction appliqué à un achat en vertu des présentes ne s’appliquera qu’au
              premier paiement effectué en lien avec cet achat.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.11 Si l’une des dispositions des présentes CGU est déclarée non valide ou non
              applicable d’un point de vue légal ou réglementaire par une juridiction
              compétente, cette constatation sera uniquement limitée à la section non valide ou
              non applicable, sans affecter les autres sections d’une telle disposition, ou de
              toute autre disposition des présentes CGU, de sorte que les présentes CGU
              demeurent pleinement en vigueur et valides.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.12 Vous reconnaissez expressément que tout commentaire qui vous est communiqué
              par Ream ou ses représentants concernant toute question que vous pourriez vous
              poser sur les présentes CGU ou votre utilisation du contenu sous licence en vertu
              des présentes, est uniquement formulé à des fins d’interprétation des présentes
              CGU et ne constitue pas un conseil juridique. Ream n’est pas en mesure de vous
              donner des conseils juridiques et décline expressément toute responsabilité
              quelle qu’elle soit en ce qui concerne les commentaires communiqués par Ream ou
              ses représentants.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.13 Il est expressément entendu et convenu que les présentes CGU sont établies
              uniquement pour l’avantage mutuel des parties ci-incluses et aucun avantage,
              droit, devoir et obligation n’est prévu par les présentes CGU concernant des
              tiers.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.15 Dans l’éventualité où vous violeriez les conditions du présent contrat ou de
              tout autre contrat conclu avec Ream, Ream aura le droit de résilier votre compte
              sans préavis, outre les autres droits de Ream en matière de lois et/ou d’équité.
              Ream n’est pas tenu de rembourser les frais que vous avez acquittés dans
              l’éventualité où votre compte serait résilié en cas de violation.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '16pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.16 Sauf mention explicite contraire dans les sections applicables des présentes
              relatives aux licences et aux garanties, Ream n’accorde aucun droit et ne donne
              aucune autre garantie. Ream dispose uniquement d’autorisations du propriétaire ou
              du modèle lorsque cela est expressément indiqué sur le site web Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.17 La responsabilité de Ream en vertu de toute licence individuelle achetée
              selon les modalités des présentes ne dépassera pas la « Limite de responsabilité
              » applicable à la licence en vigueur au moment où vous avez ou auriez dû avoir
              connaissance de la réclamation, sans égard au nombre de fois où vous attribuez
              des licences ou utilisez les contenus.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.18 Sauf disposition spécifique dans la Section IV des présentes, en aucun cas
              la responsabilité totale de Ream envers vous ou un tiers faisant une réclamation
              par votre intermédiaire, attribuable ou lié à votre utilisation ou incapacité à
              utiliser les sites web de Ream et/ou les contenus de ces derniers (que ce soit de
              manière contractuelle, délictuelle ou autre), ne pourra dépasser le montant perçu
              par Ream de votre part pour la licence de contenus applicable.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.19 Ni Ream, ni l’un de ses dirigeants, employés, responsables, membres,
              actionnaires, directeurs ou fournisseurs ne sera tenu responsable envers vous ou
              toute autre personne ou entité pour des dommages généraux, punitifs, spéciaux,
              indirects, consécutifs ou accessoires, ou pour des pertes de profits ou tous
              autres dommages, coûts ou pertes découlant de votre utilisation des contenus,
              d’une violation de ce contrat par Ream, ou autre, sauf disposition expresse dans
              les présentes, même si Ream a été informé de la possibilité de tels dommages,
              coûts ou pertes.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.20 Sauf disposition expresse, tous les contenus sont fournis « tels quels »
              sans garantie d’aucune sorte, expresse ou tacite, y compris sans s’y limiter,
              toute garantie implicite d’absence de contrefaçon, de valeur marchande, ou
              d’adéquation à un usage particulier. Certains contenus peuvent contenir des
              éléments qui nécessitent des autorisations supplémentaires si les contenus sont
              modifiés ou utilisés dans un contexte particulier. Si vous effectuez de telles
              modifications ou utilisez les contenus dans un tel contexte, vous êtes seul(e)
              responsable de l’obtention des approbations supplémentaires requises.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.21 Ream ne garantit pas que les contenus visuels, les sites Web Ream ou
              d’autres supports répondront à vos exigences ou que l’utilisation sera
              ininterrompue ou exempte d’erreurs. Vous assumez seul tous les risques quant à la
              qualité, les performances et l’utilisation des contenus.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.22 Dans l’éventualité où vous utiliseriez des informations de carte bancaire
              frauduleuses pour ouvrir un compte ou participer d’une autre manière à des
              activités délictueuses affectant Ream,
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>
              Ream déposera immédiatement une plainte. le centre des plaintes pour les délits
              commis sur Internet.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '106%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.23 Partie contractante de Ream et détermination de la loi applicable. Achats
              effectués avant le
            </span>
            <span style={{ fontFamily: 'Oxygen', color: '#ff0000' }}>15 mars 2021 </span>
            <span style={{ fontFamily: 'Oxygen' }}>
              sont souscrits auprès de Ream, Inc., y compris tous les paiements des échéances
              ultérieures. Pour les nouveaux achats effectués à partir du
            </span>
            <span style={{ fontFamily: 'Oxygen', color: '#ff0000' }}>15 </span>
            <span style={{ fontFamily: 'Oxygen', color: '#ff0000' }}>mars 2021</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              , votre pays de résidence est le même que votre adresse de facturation, telle que
              vous l’avez indiquée à Ream en lien avec votre compte.
            </span>
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default LicencePage;
