import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  isModifing: boolean;
  startModifing: () => void;
}

const ModifyBtn: React.FC<IProps> = ({ isModifing, startModifing }) => {
  const [t] = useTranslation();

  return (
    <div className="d-flex flex-row align-items-center justify-content-center">
      <i className="fas fa-angle-right pr-1" />
      <button
        type="button"
        className={`btn p-0 m-0   ${isModifing ? 'd-none' : ''}`}
        onClick={startModifing}
      >
        {t('ProfileContainer.modifyBtn')}
      </button>
      <button
        type="submit"
        data-testid="submitButton"
        className={`btn p-0 m-0 ${!isModifing ? 'd-none' : ''}`}
      >
        Submit
      </button>
    </div>
  );
};

export default ModifyBtn;
