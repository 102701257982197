/* eslint-disable max-lines */
import * as AuthServices from 'client/_redux/services/auth';
import { ISignInResponse } from 'client/models/IUser';
import { AuthTypes } from 'client/_redux/types/authTypes';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { errorHandler } from 'client/helpers/errorHandler';
import { resetAuthToken, db, clientApi } from 'api';
import { IThunkAction } from 'types/IThunkAction';
import { ILoginFormValues } from 'client/containers/LoginForm/SignIn/useSignIn';
import { ISignUp, ISignUpFormValues } from 'client/containers/LoginForm/SignUp/useSignUp';
import { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { getFromData } from 'client/helpers/getFromData';
import * as UserServices from 'client/_redux/services/user';
import { IUserFormUpdate } from 'types/IUserFormUpdate';
import { LoadingTypes } from 'client/_redux/types/loadingTypes';
import { TFunction } from 'react-i18next';
import { UploadProgressPayload } from 'types/IUploadingProgress';
export const login: (values: ILoginFormValues, onSuccess: () => void) => IThunkAction = (
  values,
  onSuccess,
) => async (dispatch) => {
  dispatch(startLoading());

  try {
    const signInData = await AuthServices.login()({ ...values });
    const { token, ...userData } = signInData.data;

    saveUserRefreshToken(signInData.data);

    dispatch({ type: AuthTypes.SIGNIN_SUCCESS, payload: userData.user });
    onSuccess();
  } catch (error) {
    errorHandler(error);

    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};
export const googleFacebookLogin: (
  idToken: string,
  loginProvider: 'google' | 'facebook',
  onSuccess: () => void,
) => IThunkAction = (idToken, loginProvider, onSuccess) => async (dispatch) => {
  dispatch(startLoading());

  try {
    const signInData = await AuthServices.googleFacebookLogin()(idToken, loginProvider);
    const { token, ...userData } = signInData.data;

    saveUserRefreshToken(signInData.data);

    dispatch({ type: AuthTypes.SIGNIN_SUCCESS, payload: userData.user });
    onSuccess();
  } catch (error) {
    errorHandler(error);

    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};
export const appendData: (
  values: ISignUpFormValues,
  translation: TFunction<'translation'>,
  onSuccess?: () => void,
) => IThunkAction = ({ ...values }, translation, onSuccess) => async (dispatch, getState) => {
  const {
    auth: { signUpData },
  } = getState();

  dispatch(startLoading());
  try {
    const { email, username } = values;
    const {
      emailIsAvailable,
      usernameIsAvailable,
    } = await AuthServices.checkEmailAndUsernameAvailability()({ email, username });

    if (!usernameIsAvailable)
      toast(translation('authPage.invalidUsername'), { type: 'error' });
    else if (!emailIsAvailable) toast(translation('authPage.invalidEmail'), { type: 'error' });
    else {
      dispatch({ type: AuthTypes.APPEND_SIGN_UP_DATA, payload: { ...signUpData, ...values } });
      onSuccess?.();
    }
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const signUp: (
  values: ISignUp,
  uploadProgress: (payload: UploadProgressPayload) => void,
  onSuccess?: () => void,
) => IThunkAction = (values, uploadProgress, onSuccess) => async (dispatch) => {
  dispatch(startLoading());

  try {
    const form = new FormData();

    getFromData(form, { ...values });

    const config: AxiosRequestConfig = {
      headers: {
        ...clientApi.defaults.headers,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        uploadProgress({ loaded, total });
      },
    };

    const response = await clientApi.post('/users/signup-web', form, config);
    const { token, user } = response.data;

    saveUserRefreshToken({ token, user });

    dispatch({ type: AuthTypes.SIGN_UP_SUCCESS, payload: user });
    onSuccess?.();
  } catch (error) {
    errorHandler(error);
    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};
export const signOut: () => IThunkAction = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const refreshToken = await getRefreshToken();

    await AuthServices.signout()(refreshToken);
    window.localStorage.removeItem('id');

    dispatch({ type: AuthTypes.USER_LOGGED_OUT });
  } catch (error) {
    errorHandler(error);
    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};
export const checkLoggingStatus = (): IThunkAction => async (dispatch): Promise<void> => {
  dispatch(startLoading());
  try {
    const id = window.localStorage.getItem('id');

    if (id) {
      await refreshUserToken();
      const response = await AuthServices.userByToken()();

      dispatch({
        type: AuthTypes.SIGNIN_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({ type: AuthTypes.USER_LOGGED_OUT });
    errorHandler(error);
  }
  dispatch(stopLoading());
  dispatch({ type: LoadingTypes.INITIATE });
};
export const updateProfile: (values: IUserFormUpdate) => IThunkAction = (values) => async (
  dispatch,
) => {
  dispatch(startLoading());

  try {
    const { data } = await UserServices.updateProfile(values);

    dispatch({ type: AuthTypes.UPDATE_USER, payload: data });
    values.password && toast('Password update', { type: 'success', autoClose: 10 * 1000 });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updateAvatar: (values: { avatar: File }) => IThunkAction = (values) => async (
  dispatch,
) => {
  dispatch(startLoading());

  try {
    const { data } = await UserServices.updateAvatar(values);

    dispatch({ type: AuthTypes.UPDATE_AVATAR, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const refreshUserToken = async () => {
  const refreshToken = await getRefreshToken();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `bearer ${refreshToken}`,
    },
  };
  const response = await AuthServices.refreshToken()(config);

  resetAuthToken(response.data.accessToken);
};

const getRefreshToken = async () => {
  const id = window.localStorage.getItem('id');
  let token = 'no token';

  if (id) {
    const { refreshToken } = await db.table('user').get({ id });

    token = refreshToken;
  }

  return token;
};

const saveUserRefreshToken = async ({
  user: { _id },
  token: { refreshToken, accessToken },
}: ISignInResponse) => {
  localStorage.setItem('id', _id);
  resetAuthToken(accessToken);
  await db.table('user').put({ id: _id, refreshToken });
};

export const resetUserPassword = (
  resetPasswordData: {
    password: string;
    resetPasswordToken: string;
  },
  onSuccess?: () => void,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data: resetPasswordResponse } = await AuthServices.resetPassword()(
      resetPasswordData,
    );

    toast(resetPasswordResponse, { type: 'success' });
    onSuccess?.();
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const registerNotificationToken: (fcmToken: string) => IThunkAction = (
  fcmToken,
) => async () => {
  try {
    await AuthServices.notificationToken()(fcmToken);
  } catch (error) {
    errorHandler(error);
  }
};
export const forgetPassword: (email: string, onSuccess: () => void) => IThunkAction = (
  email,
  onSuccess?: () => void,
) => async (dispatch) => {
  dispatch(startLoading());

  try {
    const sendEmail = await AuthServices.forgetPassword()({ email });
    const { data } = sendEmail;

    dispatch({ type: AuthTypes.SEND_EMAIL_SUCCESS, payload: data });
    onSuccess?.();
  } catch (error) {
    errorHandler(error);

    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};
