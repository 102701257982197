import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { sendNotification } from 'dashboard/_redux/actions/users';
export type INotificationForm = {
  title: string;
  body: string;
  to: string;
};
export const useNotificationPage = () => {
  const formMethods = useForm<INotificationForm>({
    shouldFocusError: true,
  });
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<INotificationForm> = useCallback(
    async (data) => {
      dispatch(sendNotification(data));
    },
    [dispatch],
  );

  return {
    onSubmit,
    handleSubmit,
    formMethods,
    t,
  };
};
