import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { RootState } from 'client/_redux/reducers';
import { useEffect } from 'react';
import { getPostDetailsById } from 'client/_redux/actions/post';
import { useHistory, useParams } from 'react-router';

export const usePostDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { postDetails } = useSelector((state: RootState) => state.post);

  const { id } = useParams<{ id: string }>();
  const formattedAddress = [postDetails?.post.city, postDetails?.post.country]
    .filter((el) => el)
    .join(', ');
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getPostDetailsById(id));
  }, [dispatch, id]);
  const handleClick = (id: string) => history.push(`${id}`);

  return {
    postDetails,
    formattedAddress,
    t,
    handleClick,
  };
};
