import * as React from 'react';
import Select from '../Form/Select';
import { useLanguageSelector } from './useLanguageSelector';

export const LanguageSelector = () => {
  const { options, language, setLanguage } = useLanguageSelector();

  return (
    <div style={{ width: '90px', marginLeft: '10px' }}>
      <Select
        options={options}
        value={language}
        onChange={(option) => option && setLanguage(option.value)}
      />
    </div>
  );
};
