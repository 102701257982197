import React from 'react';
import { TFunction } from 'react-i18next';

type UpdatePasswordProps = {
  username?: string;
  setPassword: (target: string) => void;
  error: string;
  handlePasswordChange: () => void;
  t: TFunction<'translation'>;
};
const UpdatePassword = ({
  username,
  setPassword,
  error,
  handlePasswordChange,
  t,
}: UpdatePasswordProps) => (
  <div className="d-flex flex-column mb-1">
    <div className="d-flex flex-row no-gutters mb-3 font-text-info-condition">
      <div className="col-4 text-primary-lighter  ">{t('videoModal.pseudo')}</div>
      <div className="col-8 ">{username}</div>
    </div>
    <div className="d-flex flex-row no-gutters align-items-center">
      <div className="col-4 text-primary-lighter font-text-info-condition ">
        {t('LoginForm.PassWord')}:
      </div>

      <div className="col-6">
        <input
          style={{ border: 0 }}
          className="w-100 text-muted muted-placeholder"
          type="password"
          placeholder="••••••••"
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && (
          <div
            data-testid="inputError"
            className="invalid-feedback"
            style={{ display: 'block' }}
          >
            {error}
          </div>
        )}
      </div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={handlePasswordChange}
        className="col-2 text-primary-lighter d-flex align-items-center"
      >
        <i className="fa fa-chevron-circle-right font-size-2 p-0 mr-0 mr-sm-2 " />
        <div className="font-text-info-condition">{t('LoginForm.Edit')}</div>
      </div>
    </div>
  </div>
);

export default UpdatePassword;
