/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavBarElement from '../NavBarElement';

interface IProps {
  isConnected: boolean;
  handleDisconnect: () => void;
}

const NavBarBurger: React.FC<IProps> = ({ isConnected, handleDisconnect }) => {
  const [t] = useTranslation();
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);

  return (
    <>
      <button
        id="headerToggler"
        type="button"
        aria-pressed={isNavBarOpen ? 'true' : 'false'}
        onClick={() => setIsNavBarOpen(!isNavBarOpen)}
        className={`navbar-toggler fullscreen-toggler btn btn-icon btn-sm btn-soft-white rounded-circle ${
          isNavBarOpen ? 'focus active' : ''
        }`}
      >
        <span className="navbar-toggler-default">
          <svg width={14} height={14} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="currentColor"
              d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z"
            />
          </svg>
        </span>
        <span className="navbar-toggler-toggled position-fixed">
          <svg width={14} height={14} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="currentColor"
              d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
            />
          </svg>
        </span>
      </button>

      <div
        className={`fullscreen ${isNavBarOpen ? 'fullscreen-showed' : ''}`}
        onClick={() => {
          setIsNavBarOpen(false);
        }}
      >
        <div className="fullscreen-container">
          <div className="container ml-6">
            <div className="fullscreen-body">
              <div className="row justify-content-md-between align-items-center">
                <div id="fullscreenWrapper" className="col-md-6">
                  <ul id="fullscreenNav" className="fullscreen-nav">
                    <li>
                      <NavBarElement text={t('navBar.commentCaMarche')} page="/how-to" />
                    </li>
                    <li>
                      <NavBarElement text={t('navBar.devenirVideaste')} page="/video-maker" />
                    </li>
                    <li>
                      <NavBarElement text={t('navBar.galerie')} page="/gallery" />
                    </li>
                    {isConnected && (
                      <li>
                        <NavBarElement text={t('navBar.myLibrary')} page="/video-library" />
                      </li>
                    )}
                  </ul>

                  <ul className="pl-3 pr-4 pr-md-6 mt-5">
                    <li className="border-top border-primary mb-3">
                      <span className="text-white" style={{ fontSize: '12px' }}>
                        {t('navBar.compteProfessionelPourVideaste')}
                      </span>
                    </li>
                    <li className="pl-0 pl-md-0">
                      {!isConnected ? (
                        <NavBarElement
                          text={t('navBar.seConnecter')}
                          className="section-button alt-tertiary pl-0"
                          page="/login"
                        />
                      ) : (
                        <button
                          type="button"
                          className="section-button alt-tertiary pl-0"
                          onClick={handleDisconnect}
                        >
                          {t('navBar.seDeconnecter')}
                        </button>
                      )}
                    </li>
                  </ul>

                  <ul className="ml-1 ml-md-0 pl-md-3 mb-0 mt-5 pl-lg-7">
                    <li className="list-inline-item">
                      <a
                        className="btn btn-xs btn-icon btn-soft-light rounded-circle"
                        target="_blank"
                        href="https://www.facebook.com/Pulp-Ground-101923445163572"
                      >
                        <i className="fab fa-facebook-square mx-2 fa-lg " />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="btn btn-xs btn-icon btn-soft-light rounded-circle"
                        target="_blank"
                        href="https://twitter.com/pulp_ground"
                      >
                        <i className="fab fa-twitter-square mx-2 fa-lg " />
                      </a>
                    </li>

                    <li className="list-inline-item">
                      <a
                        className="btn btn-xs btn-icon btn-soft-light rounded-circle"
                        target="_blank"
                        href="https://www.youtube.com/channel/UCKvf9DSQ4dcVXPZ_xQ7gfyQ"
                      >
                        <span className="fab fa-youtube mx-2 fa-lg " />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="btn btn-xs btn-icon btn-soft-light rounded-circle"
                        target="_blank"
                        href="https://www.instagram.com/pulpground/"
                      >
                        <i className="fab fa-instagram mx-2 fa-lg " />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fullscreen-overlay w-100 h-100" />
      </div>
    </>
  );
};

export default NavBarBurger;
