import { TableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import React, { useRef, useCallback } from 'react';
import { getImageUrl } from 'client/helpers/getImageUrl';
import { CRUDDataTableHandle } from 'dashboard/containers/CRUDDataTable';
import { useTableColumnsWithActions } from 'dashboard/containers/CRUDDataTable/useTableColumnsWithActions';
import { useHistory } from 'react-router';
import { IUser } from 'server/models/User';

export const useVideoGrapherList = () => {
  const [t] = useTranslation();
  const dataTableRef = useRef<CRUDDataTableHandle>(null);

  const tableCols: TableColumn<IUser>[] = [
    {
      name: t('dashboard.videoGrapherPage.avatar'),
      selector: (e) => (
        <img
          alt="avatar"
          id={e._id}
          className="avatar m-2"
          width="100"
          height="100"
          src={getImageUrl(e.avatar)}
        />
      ),
      center: true,
    },
    {
      name: t('dashboard.videoGrapherPage.email'),
      selector: (e) => e.email,
      sortable: true,
      center: true,
      sortfield: 'email',
    },
    {
      name: t('dashboard.videoGrapherPage.username'),
      selector: (e) => e.username,
      sortable: true,
      center: true,
      sortfield: 'username',
    },
    {
      name: t('dashboard.videoGrapherPage.firstName'),
      selector: (e) => e.firstName,
      sortable: true,
      center: true,
      sortfield: 'firstName',
    },
    {
      name: t('dashboard.videoGrapherPage.lastName'),
      selector: (e) => e.lastName,
      sortable: true,
      center: true,
      sortfield: 'lastName',
    },

    {
      name: t('dashboard.videoGrapherPage.status'),
      selector: (e) => e.isVideoGrapher || '',
      sortable: true,
      center: true,
      sortfield: 'isVideoGrapher',
    },
    {
      name: t('dashboard.videoGrapherPage.phoneNumber'),
      selector: (e) => e.phoneNumber,
      sortable: true,
      center: true,
      sortfield: 'phoneNumber',
    },
    {
      sortfield: 'createdAt',
      name: t('dashboard.videoGrapherPage.createdAt'),
      selector: (e) => (e.createdAt ? format(new Date(e.createdAt), 'DD-MM-YYYY') : ''),
      sortable: true,
    },
  ];
  const navigate = useHistory();

  const { videoGraphers, loaded } = useSelector(
    (state: RootState) => state.dashboardVideoGraphers,
  );
  const onPressShow = useCallback(
    (item: IUser) => {
      navigate.push(`/dashboard/videographer/${item._id}`);
    },
    [navigate],
  );

  const columns = useTableColumnsWithActions({
    columns: tableCols,
    buttons: [
      {
        fn: onPressShow,
        title: t('dataTable.show'),
        className: 'btn btn-dark btn-xs m-1',
      },
    ],
  });

  return {
    videoGraphers,
    loaded,
    tableCols,
    columns,
    dataTableRef,
  };
};
