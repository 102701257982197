/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

export const useLoadingDispatch = (): {
  isLoading: boolean;
  dispatch: (callback: any) => Promise<void>;
  startLoading: () => void;
  stopLoading: () => void;
  classicDispatch: Dispatch<any>;
} => {
  const classicDispatch = useDispatch();

  const [loadingProcesses, setIsLoading] = useState(0);

  const startLoading = useCallback(() => {
    setIsLoading((prev) => (prev += 1));
  }, []);
  const stopLoading = useCallback(() => {
    setIsLoading((prev) => Math.min(prev - 1, 0));
  }, []);

  const dispatch = useCallback(
    async (callback) => {
      startLoading();
      const response = await classicDispatch(callback);

      stopLoading();

      return response;
    },
    [classicDispatch, startLoading, stopLoading],
  );

  return {
    isLoading: loadingProcesses > 0,
    dispatch,
    startLoading,
    stopLoading,
    classicDispatch,
  };
};
