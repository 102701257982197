import SliderAlphabet from 'client/components/Form/RangeInput';
import ScrollDirectionIndicator from 'client/components/ScrollDirectionIndicator';
import TabSwitcher from 'client/components/TabSwitcher/TabSwitcher';
import React from 'react';
import { useGalleryMidSectionContainer } from './useGalleryMidSectionContainer';

type GalleryMidSectionContainerProps = {
  scrollRef: React.MutableRefObject<null>;
};
const GalleryMidSectionContainer = ({ scrollRef }: GalleryMidSectionContainerProps) => {
  const { OnLetterChange, OnSwitchChange, category, t } = useGalleryMidSectionContainer(
    scrollRef,
  );

  return (
    <div className="d-flex justify-content-center align-items-center mid-section-container">
      <div className="gallery-search-section">
        <TabSwitcher
          options={[
            { label: t('categories.new'), value: 'RECENT' },
            { label: t('categories.popular'), value: 'POPULAR' },
          ]}
          title={t('misc.categories')}
          onSwitch={(value) => OnSwitchChange(value)}
          className="mb-md-3"
          typeId={category}
        />
        <SliderAlphabet onChangeLetters={(letter) => OnLetterChange(letter)} />
      </div>
      <ScrollDirectionIndicator
        isHorizontal
        text={t('misc.scrollToSeeMore')}
        textColor="text-dark"
        isGallery
      />
    </div>
  );
};

export default GalleryMidSectionContainer;
