import { LoadingTypes } from 'client/_redux/types/loadingTypes';
import { IReduxAction } from 'types/IReduxAction';
const initialState = {
  loadingProcesses: 0,
  loading: false,
};

export interface LoadingState {
  loadingProcesses: number;
  loading: boolean;
}

export default (state: LoadingState = initialState, action: IReduxAction): LoadingState => {
  let { loadingProcesses, loading } = state;
  const { type } = action;

  switch (type) {
    case LoadingTypes.LOADING_START:
      loadingProcesses += 1;
      loading = true;

      return { loadingProcesses, loading };
    case LoadingTypes.LOADING_FINISHED:
      loadingProcesses -= 1;
      if (loadingProcesses === 0 || loadingProcesses <= 0) {
        loading = false;
        loadingProcesses = 0;
      }

      return { loadingProcesses, loading };
    default:
      return state;
  }
};
