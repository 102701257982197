import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
interface IProps {
  date?: string;
  downloads: number;
}
const VideoCardTopSide: React.FC<IProps> = ({ date, downloads }) => {
  const [t] = useTranslation();

  return (
    <div className="ml-3 px-1 px-md-0">
      <div className="w-70">
        <div className="fSize pr-11 pr-md-0 " style={{ paddingTop: '0.7rem' }}>
          <Trans components={[<span className="text-primary dataSize" />]}>
            {t('editVideoCard.createdAt', { date })}
          </Trans>
        </div>
        <div className="fSize pr-10 pr-md-0">
          <Trans components={[<span className="text-primary dataSize" />]}>
            {t('editVideoCard.downloads', { downloads })}
          </Trans>
        </div>
      </div>

      <div className="mt-1 mt-md-3 tSize">Modifier les informations de la vidéo</div>
      <hr className="lwidth ml-0" />
    </div>
  );
};

export default VideoCardTopSide;
