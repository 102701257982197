import { useLoadingDispatch } from 'client/hooks/useLoadingDispatch';
import { forgetPassword } from 'client/_redux/actions/auth';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

export const useForgetPassword = () => {
  const [t] = useTranslation();
  const { push } = useHistory();
  const formMethods = useForm();
  const { isLoading, dispatch } = useLoadingDispatch();
  const onSubmit = useCallback(
    async ({ email }) => {
      await dispatch(forgetPassword(email, () => push('/')));
    },
    [dispatch, push],
  );

  return { t, isLoading, onSubmit, formMethods };
};
