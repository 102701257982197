import { errorHandler } from 'client/helpers/errorHandler';
import { IThunkAction } from 'types/IThunkAction';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import * as videoServices from 'dashboard/_redux/services/videos';
import { IPost } from 'server/models/Post';
import { VideosDashboardTypes } from 'dashboard/_redux/types/videosDashboardTypes';
import { IVideoFormValues } from 'dashboard/_redux/reducers/videos';
import { toast } from 'react-toastify';
import { SearchQuery } from '../users';

export const getAllVideosDashboard = (searchQuery: SearchQuery<IPost>): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  dispatch({ type: VideosDashboardTypes.FETCH_VIDEOS_START });
  try {
    const user = await videoServices.getAllVideos()(searchQuery);

    dispatch({ type: VideosDashboardTypes.FETCH_VIDEOS_SUCCESS, payload: user });
  } catch (error) {
    dispatch({ type: VideosDashboardTypes.FETCH_VIDEOS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getVideoDashboardById = (userId: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  dispatch({ type: VideosDashboardTypes.UPDATE_VIDEO_START });
  try {
    const user = await videoServices.getVideoById()(userId);

    dispatch({ type: VideosDashboardTypes.UPDATE_VIDEO_SUCCESS, payload: user });
  } catch (error) {
    dispatch({ type: VideosDashboardTypes.UPDATE_VIDEO_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updatePostDashboard = (
  postId: string,
  values: Partial<IVideoFormValues>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  dispatch({ type: VideosDashboardTypes.UPDATE_VIDEO_START });
  try {
    const user = await videoServices.updatePost()(values, postId);

    toast('Vidéo modifiée avec succès!', { type: 'success' });

    dispatch({ type: VideosDashboardTypes.UPDATE_VIDEO_SUCCESS, payload: user });
  } catch (error) {
    dispatch({ type: VideosDashboardTypes.UPDATE_VIDEO_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const deleteVideoDashboard = (
  postId: string,
  onSuccess?: () => void,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());

  try {
    await videoServices.deletePostById()(postId);
    onSuccess?.();
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
