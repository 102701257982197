import React from 'react';
import AppStore from 'client/components/SVG/AppSore';
import GooglePlay from 'client/components/SVG/GooglePlay';
import Pattern from 'client/components/SVG/Pattern';
import SectionContainer from 'client/containers/SectionContainer';
import { useTranslation } from 'react-i18next';
import { openShareUrl } from 'client/helpers/openShareUrl';
const SharePage: React.FC = () => {
  const [t] = useTranslation();

  return (
    <>
      <main
        id="content"
        role="main"
        style={{ overflow: 'hidden' }}
        className="position-relative"
      >
        <SectionContainer
          title={t('SharePage')}
          backgroundStyle="dark-image-background"
          miscComponent={
            <img
              className="smartphone-mockup"
              src="/assets/svg/custom/homePage/Smartphone-white-mockup.png"
              alt="video maker first"
            />
          }
          titleStyle="text-white"
          descriptionStyle="text-white-70 h4"
        >
          <Pattern
            className="red-pattern"
            width="300px"
            style={{ position: 'absolute', top: '-30%', right: '75%', opacity: '0.45' }}
          />
          <div className="d-flex row media-container">
            <div className="col-sm col-md-4">
              <GooglePlay
                onClick={() => openShareUrl(process.env.RAZZLE_GOOGLE_PLAY_LINK)}
                height="65px"
              />
            </div>
            <div className="col-sm col-md-4">
              <AppStore
                onClick={() => openShareUrl(process.env.RAZZLE_APPLE_STORE_LINK)}
                height="65px"
              />
            </div>
          </div>
        </SectionContainer>
      </main>
    </>
  );
};

export default SharePage;
