import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import VideoGrapherLevel from 'client/components/SVG/VideoGrapherLevel';
import { starColors } from 'client/constants/VideoGrapherLevelColors';
interface IVideoMarkerLevel {
  level: 'BRONZE' | 'SILVER' | 'GOLD';
  category: 'Downloads' | 'Gains';
  className?: string;
  isLight?: boolean;
  description: string;
}

const VideoMakerLevelCard: React.FC<IVideoMarkerLevel> = ({
  className,
  isLight = true,
  category,
  level,
  description,
}) => {
  const [t] = useTranslation();

  const { levelTranslate, starColor } = useMemo(() => {
    switch (level) {
      case 'SILVER':
        return {
          levelTranslate: t('videoMarkerCard.level.levelTwo'),
          starColor: starColors.blueStar,
        };
      case 'GOLD':
        return {
          levelTranslate: t('videoMarkerCard.level.levelThree'),
          starColor: starColors.redStar,
        };
      case 'BRONZE':
      default:
        return {
          levelTranslate: t('videoMarkerCard.level.levelOne'),
          starColor: starColors.goldStar,
        };
    }
  }, [level, t]);

  return (
    <div
      className={`row p-2 mb-2 video-maker-card-container text-oxygen border-top border-primary bg-${
        isLight ? 'white' : 'secondary-lighter'
      } ${className || ''}`}
    >
      <div className="col-5 d-flex flex-column align-items-center justify-content-center">
        <VideoGrapherLevel
          className="video-maker-card-level-indicator"
          starColor={starColor}
          isLight={isLight}
        />
        <span
          className={`text-center text-${
            isLight ? 'primary' : 'white'
          } font-weight-bold mt-2 video-maker-card-text text-center`}
        >
          {levelTranslate}
        </span>
      </div>
      <div className="col-7 d-flex flex-column font-weight- justify-content-center">
        <span
          className={`m-0 font-weight-light ${
            isLight ? '' : 'text-white'
          } video-maker-card-text`}
        >
          {category === 'Gains' ? (
            <>
              {t('videoMarkerCard.descriptionGain.descriptionDebut')}
              <span className="text-primary  font-weight-bold video-maker-card-text">{` ${description}% `}</span>
              {t('videoMarkerCard.descriptionGain.descriptionFin')}
            </>
          ) : (
            description
          )}
        </span>
      </div>
    </div>
  );
};

export default VideoMakerLevelCard;
