import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useSignUp } from '../../useSignUp';
export const useSlideOne = (nextSlide: () => void) => {
  const { formMethods, t, handleSubmit, _onSubmit } = useSignUp(nextSlide);
  const { signUpData } = useSelector((state: RootState) => state.auth);
  const [password, setPassword] = useState(signUpData?.password || '');
  const [error, setError] = useState('');
  const handlePasswordChange = () => {
    if (password.length < 8) setError('Le champ doit au moins être 8.');
    else if (signUpData) {
      signUpData.password = password;
      setError('');
    } else if (!password) setError('');
  };

  return {
    formMethods,
    t,
    handleSubmit,
    _onSubmit,
    setPassword,
    error,
    handlePasswordChange,
    signUpData,
  };
};
