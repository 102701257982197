import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
type IProps<T extends unknown> = {
  columns: TableColumn<T>[];
  buttons: { fn?: (item: T) => void; title?: string; className?: string }[];
};

export const ActionButtons = <T extends unknown>({
  buttons,
  item,
}: Omit<IProps<T>, 'columns'> & { item: T }) => (
  <div className="d-flex">
    {buttons.map(({ className, fn, title }) => (
      <button
        className={className || 'btn btn-primary btn-xs mr-1'}
        type="button"
        key={title}
        onClick={() => fn && fn(item)}
      >
        {title}
      </button>
    ))}
  </div>
);

export const useTableColumnsWithActions = <T extends unknown>({
  columns,
  buttons,
}: IProps<T>) => {
  const [t] = useTranslation();
  const TableColumns: TableColumn<T>[] = [
    ...columns,
    buttons.length
      ? {
          name: t('dataTable.actions'),
          sortable: false,
          center: true,
          cell: (row) => <ActionButtons item={row} buttons={buttons} />,
          ignoreRowClick: true,
          allowOverflow: true,
          minWidth: '25vw',
        }
      : {},
  ];

  return TableColumns;
};
