export enum VideoGraphersTypes {
  FETCH_USERS_START = 'FETCH_USERS_START',
  FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE',
  UPDATE_VIDEOGRAPHER_START = 'UPDATE_VIDEOGRAPHER_START',
  UPDATE_VIDEOGRAPHER_SUCCESS = 'UPDATE_VIDEOGRAPHER_SUCCESS',
  UPDATE_VIDEOGRAPHER_FAILURE = 'UPDATE_VIDEOGRAPHER_FAILURE',
  GET_VIDEOGRAPHER_BYID_START = 'GET_VIDEOGRAPHER_BYID_START',
  GET_VIDEOGRAPHER_BYID_SUCCESS = 'GET_VIDEOGRAPHER_BYID_SUCCESS',
  GET_VIDEOGRAPHER_BYID_FAILURE = 'GET_VIDEOGRAPHER_BYID_FAILURE',
  UPDATE_VIDEOGRAPHER_STATUS_SUCCESS = 'UPDATE_VIDEOGRAPHER_STATUS_SUCCESS',
}
