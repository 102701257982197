import React from 'react';
import User from 'client/components/SVG/User';
import Setting from 'client/components/SVG/Setting';
import Wallet from 'client/components/SVG/Wallet';

interface IProps {
  handleIndex: (n: number) => void;
  indexPage: number;
}
const NavBarProfile: React.FC<IProps> = ({ handleIndex, indexPage }) => (
  <div className="nav d-flex justify-content-around text-dark pt-0 pb-0">
    <button
      type="button"
      className={`btn rounded-0  ${indexPage === 0 ? 'active' : ''}`}
      onClick={() => handleIndex(0)}
    >
      <User width="25px" stroke={`${indexPage === 0 ? '#EA384D' : '#D9D8D7 '}`} />
    </button>
    <button
      type="button"
      className={`btn rounded-0  ${indexPage === 1 ? 'active' : ''}`}
      onClick={() => handleIndex(1)}
    >
      <Wallet width="30px" stroke={`${indexPage === 1 ? '#EA384D' : '#D9D8D7 '}`} />
    </button>
    <button
      type="button"
      className={`btn rounded-0  ${indexPage === 2 ? 'active' : ''}`}
      onClick={() => handleIndex(2)}
    >
      <Setting width="25px" stroke={`${indexPage === 2 ? '#EA384D' : '#D9D8D7 '}`} />
    </button>
    <div className="animation" />
  </div>
);

export default NavBarProfile;
