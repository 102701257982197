import { RootState } from 'client/_redux/reducers';
import { useSelector } from 'react-redux';

export const useIsVideoGrapherApproved = () => {
  const isVideoGrapher = useSelector(
    (state: RootState) => state.auth.currentUser?.isVideoGrapher,
  );

  return isVideoGrapher === 'APPROVED';
};
