import { clientApi } from 'api';
import { INotificationForm } from 'dashboard/pages/NotificationPage/useNotificationPage';
import { SearchQuery } from 'dashboard/_redux/actions/users';
import { IUser } from 'server/models/User';
import { IWithdrawRequest } from 'server/models/WithdrawRequest';

const baseUrl = '/videographer/dashboard';

export const getAllVideoGraphers = (isServer = false) => async (
  searchQuery: SearchQuery<IUser>,
) => {
  const response = await clientApi.get(`${baseUrl}`, { params: { ...searchQuery } });

  return response.data;
};
export const getVideoGrapherById = (isServer = false) => async (id: string) => {
  const { data } = await clientApi.get<IUser>(`${baseUrl}/${id}`);

  return data;
};
export const updateVideoGrapherStatus = (isServer = false) => async (
  userId: string,
  status: string,
) => {
  const { data } = await clientApi.patch<IUser>(`${baseUrl}`, { userId, status });

  return data;
};
export const getWithdrawRequests = (isServer = false) => async (
  searchQuery: SearchQuery<IWithdrawRequest>,
) => {
  const response = await clientApi.get(`videographer/fetch-withdraw-requests`, {
    params: { ...searchQuery },
  });

  return response.data;
};
export const updateWithdrawRequest = (isServer = false) => async (
  userId: string,
  withdrawReqId: string,
) => {
  const response = await clientApi.patch('videographer/dashboard/withdraw', {
    userId,
    withdrawReqId,
  });

  return response;
};

export const sendNotification = (isServer = false) => async (
  notification: INotificationForm,
) => {
  const { data } = await clientApi.post(`notification`, { ...notification });

  return data;
};
