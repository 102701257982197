import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useLanguageSelector = () => {
  const [, i18n] = useTranslation();
  const options = useMemo(
    () =>
      i18n.languages.map((language) => ({
        label: language.toUpperCase(),
        value: language,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.isInitialized],
  );

  const setLanguage = async (lang: string) => {
    i18n.changeLanguage(lang.toLowerCase());
  };

  return {
    options,
    language: {
      label: i18n.language.toUpperCase().slice(0, 2),
      value: i18n.language,
    },
    setLanguage,
  };
};
