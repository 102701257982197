import React from 'react';
import { CustomArrowProps } from 'react-slick';

export const LeftArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
  <span
    onClick={onClick}
    className="fas fa-arrow-left slick-arrow slick-arrow-white 
      slick-arrow-left slick-arrow-centered-y rounded-circle ml-sm-2 ml-xl-4"
  />
);
export const RightArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
  <span
    onClick={onClick}
    className="fas fa-arrow-right slick-arrow slick-arrow-white 
  slick-arrow-right slick-arrow-centered-y rounded-circle mr-sm-2 mr-xl-4"
  />
);
