import { RootState } from 'client/_redux/reducers';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const useModalReport = () => {
  const [t] = useTranslation();
  const alertMsg = t('ProfileContainer.WalletTab.reportModel.alertMsg');
  const ModalTitle = t('ProfileContainer.WalletTab.reportModel.title');
  const alertMsgReport = t('ProfileContainer.WalletTab.reportModel.alertMsgReport');
  const reportext = t('ProfileContainer.WalletTab.reportModel.reportext');
  const textTranslations = { alertMsg, ModalTitle, alertMsgReport, reportext };
  const { userReports } = useSelector(({ auth: { userReports } }: RootState) => ({
    userReports,
  }));

  return { textTranslations, userReports };
};
