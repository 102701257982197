/* eslint-disable @typescript-eslint/no-non-null-assertion */
import VideoCard from 'client/components/VideoCard';
import VideoCardForm from 'client/containers/VideoCardForm';
import React from 'react';
import { IPost } from 'client/models/IPost';
import { format } from 'date-fns';
import { useVideoCardInfo } from './useVideoCardInfo';

type VideoCardInfoProps = {
  post: IPost;
};
const VideoCardInfo = ({ post }: VideoCardInfoProps) => {
  const {
    videoCard,
    changeButton,
    handleDelete,
    musicTypeOptions,
    videoTypeOptions,
    cinematicStyleOptions,
  } = useVideoCardInfo(post);

  return (
    <div
      className={`text-white ${
        videoCard ? 'bg-secondary' : 'bg-white'
      } position-relative mx-3 rounded shadow video-card-mw`}
    >
      <button
        type="button"
        className={`btn ${
          videoCard ? 'btn-info-card' : 'bg-dark'
        } btn-xs position-absolute top-0 right-0 mt-1 mt-md-3 mr-7 mr-md-10 card-button`}
        onClick={(e) => {
          e.stopPropagation();
          changeButton();
        }}
      >
        {videoCard ? (
          <i className="fas fa-minus fa-xs text-primary" />
        ) : (
          <i className="fas fa-plus fa-xs text-primary" />
        )}
      </button>
      <button
        type="button"
        className={`btn ${
          videoCard ? 'btn-info-card' : 'bg-dark'
        } btn-xs position-absolute top-0 right-0 m-1 m-md-3 card-button`}
        onClick={(e) => {
          e.stopPropagation();
          handleDelete();
        }}
      >
        <i className="far fa-trash-alt fa-xs text-primary" />
      </button>
      {videoCard ? (
        <VideoCardForm
          downloads={post?.downloads || 0}
          postId={post?._id}
          date={post?.createdAt && format(post?.createdAt, 'DD-MM-YYYY')}
          description={post?.description}
          countryCity={{
            location: `${post?.country},${post?.city}`,
            country: post?.country,
            state: post?.city,
            lat: post?.location?.coordinates[1],
            lng: post?.location?.coordinates[0],
          }}
          name={post?.name}
          tags={post?.tags}
          musicType={musicTypeOptions.find((el) => el.value === post.musicType)!}
          cinematicStyle={
            cinematicStyleOptions.find((el) => el.value === post.cinematicStyle)!
          }
          videoType={videoTypeOptions.find((el) => el.value === post.videoType)!}
        />
      ) : (
        <VideoCard
          name={post?.name}
          thumbnailUrl={post?.mainVideo?.thumbnailUrl}
          tags={post?.tags}
          addedBy={post?.addedBy?.username}
          date={post?.createdAt && format(post?.createdAt, 'DD-MM-YYYY')}
          country={post?.country}
          city={post?.city}
          videoUrl={post?.mainVideo?.originalUrl}
        />
      )}
    </div>
  );
};

export default VideoCardInfo;
