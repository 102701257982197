import { DBDocument } from 'client/models/utils';
import { VideosDashboardTypes } from 'dashboard/_redux/types/videosDashboardTypes';
import { emptyPaginationPayload } from 'server/constants/emptyPaginationPayload';
import { VideoUploadResponse } from 'server/middlewares/upload/uploadVideo/types';
import { IPost } from 'server/models/Post';
import { IFileWithPreview } from 'types/IFileWithPreview';
import { IReduxAction } from 'types/IReduxAction';
import { PaginatedResponse } from 'types/Posts';
export interface IVideoFormValues {
  _id?: string;
  postId?: string;
  name: string;
  video?: IFileWithPreview[];
  downloads?: number;
  date?: string;
  tags: string | string[];
  description: string;
  cinematicStyle: string;
  musicType: string;
  videoType: string;
  countryCity: { state: string; country: string; lat: number; lng: number };
  profilePicture?: IFileWithPreview;
  city?: string;
  country?: string;
  addedBy: {
    _id: string;
    username: string;
  };
  mainVideo: DBDocument & VideoUploadResponse<DBDocument>;
  shortVideo?: DBDocument & VideoUploadResponse<DBDocument>;
}

export interface IVideosDashboardState {
  loading: boolean;
  loaded: boolean;
  videos: PaginatedResponse<IPost>;
  selectedVideo?: IVideoFormValues;
}

const initialState: IVideosDashboardState = {
  loading: false,
  loaded: false,
  videos: emptyPaginationPayload,
};

export default (
  state = initialState,
  { type, payload }: IReduxAction,
): IVideosDashboardState => {
  switch (type) {
    case VideosDashboardTypes.FETCH_VIDEOS_SUCCESS:
      return { ...state, loaded: true, videos: payload };
    case VideosDashboardTypes.UPDATE_VIDEO_SUCCESS:
      return { ...state, loaded: true, selectedVideo: payload };

    default:
      return state;
  }
};
