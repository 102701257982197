/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { IValidator } from 'client/helpers/errorMessages';
import moment, { Moment } from 'moment';
import { ValidationRules } from 'react-hook-form';
import i18n from 'locales';
import { splitTagsString } from './splitTagsString';

const testRegex = (value: string, regex: RegExp) =>
  value === '' || (value && value.toString().match(regex) !== null);

export const toSentence = (arr: string[]) => {
  const lastWord = arr[arr.length - 1];

  arr.pop();
  const res = arr.join(', ');

  return `${res} ${i18n.t('misc.or')} ${lastWord}`;
};

export interface IVlidatorParams {
  validation: IValidator;
  date?: Moment;
  betweenParams?: number[];
  inStringArray?: string[];
  maxParams?: number;
  minParams?: number;
  passwordConfirm?: string;
}
const Validators = (params: IVlidatorParams[]): ValidationRules => {
  let validators: ValidationRules['validate'] = {};

  params.forEach((o) => {
    const { validation } = o;

    switch (validation) {
      case 'after':
      case 'after_or_equal':
      case 'before':
      case 'before_or_equal':
      case 'after_day':
      case 'before_day':
        if (o.date)
          validators = {
            ...validators,
            [validation]: _Validators[validation](o.date),
          };
        break;
      case 'between':
        if (o.betweenParams)
          validators = {
            ...validators,
            [validation]: _Validators[validation](o.betweenParams),
          };
        break;
      case 'in':
        if (o.inStringArray)
          validators = {
            ...validators,
            [validation]: _Validators[validation](o.inStringArray),
          };
        break;
      case 'max':
      case 'maxLength':
        if (o.maxParams)
          validators = {
            ...validators,
            [validation]: _Validators[validation](o.maxParams),
          };
        break;
      case 'min':
      case 'minLength':
        if (o.minParams)
          validators = {
            ...validators,
            [validation]: _Validators[validation](o.minParams),
          };
        break;
      case 'passwordConfirm':
        if (o.passwordConfirm)
          validators = {
            ...validators,
            [validation]: _Validators[validation](o.passwordConfirm),
          };
        break;
      default:
        validators = {
          ...validators,
          [validation]: _Validators[validation],
        };
        break;
    }
  });

  return { validate: validators };
};

const _Validators = {
  accepted: (val: boolean) => (val === true ? undefined : i18n.t('formErrors.accepted')),
  after: (date: Moment) => (val: Moment) =>
    val.isAfter(date)
      ? undefined
      : i18n.t('formErrors.after').replace(':date', date.format('LLL')),
  after_or_equal: (date: Moment) => (val: Moment) =>
    val.isSameOrAfter(date, 'day')
      ? undefined
      : i18n.t('formErrors.after_or_equal').replace(':date', date.format('DD/MM/YYYY')),
  alpha: (val: string) =>
    testRegex(val, /^[A-Z]*$/i) ? undefined : i18n.t('formErrors.alpha'),
  alpha_space: (val: string) =>
    testRegex(val, /^[A-Z\s]*$/i) ? undefined : i18n.t('formErrors.alpha_space'),
  alpha_num: (val: string) =>
    testRegex(val, /^[A-Z0-9]*$/i) ? undefined : i18n.t('formErrors.alpha_num'),
  alpha_num_space: (val: string) =>
    testRegex(val, /^[A-Z0-9\s]*$/i) ? undefined : i18n.t('formErrors.alpha_num_space'),
  alpha_num_dash: (val: string) =>
    testRegex(val, /^[A-Z0-9_-]*$/i) ? undefined : i18n.t('formErrors.alpha_num_dash'),
  alpha_num_dash_space: (val: string) =>
    testRegex(val, /^[A-Z0-9_-\s]*$/i) ? undefined : i18n.t('formErrors.alpha_num_dash_space'),
  before: (date: Moment) => (val: Moment) =>
    val.isBefore(date)
      ? undefined
      : i18n.t('formErrors.before').replace(':date', date.format('LLL')),
  before_day: (date: Moment) => (val: Moment) =>
    val.isBefore(date, 'day')
      ? undefined
      : i18n.t('formErrors.before_day').replace(':date', date.format('DD/MM/YYYY')),
  after_day: (date: Moment) => (val: Moment) =>
    val.isAfter(date, 'day')
      ? undefined
      : i18n.t('formErrors.after_day').replace(':date', date.format('DD/MM/YYYY')),
  before_or_equal: (date: Moment) => (val: Moment) =>
    val.isSameOrBefore(date, 'day')
      ? undefined
      : i18n.t('formErrors.before_or_equal').replace(':date', date.format('DD/MM/YYYY')),
  between: (betweenParams: number[]) => (val: string | number) =>
    parseFloat(val.toString()) >= betweenParams[0] &&
    parseFloat(val.toString()) <= betweenParams[1]
      ? undefined
      : i18n
          .t('formErrors.between')
          .replace(':min', betweenParams[0].toString())
          .replace(':max', betweenParams[1].toString()),
  boolean: (val: boolean) =>
    val === false || val === true ? undefined : i18n.t('formErrors.boolean'),
  date: (val: any) =>
    moment.isMoment(val) && val.isValid() ? undefined : i18n.t('formErrors.date'),
  email: (val: string) =>
    testRegex(val, /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
      ? undefined
      : i18n.t('formErrors.email'),
  in: (inStringArray: string[]) => (val: any) =>
    inStringArray.indexOf(val) > -1
      ? undefined
      : i18n.t('formErrors.in').replace(':values', toSentence(inStringArray)),
  integer: (val: string) =>
    testRegex(val, /^\d*$/) ? undefined : i18n.t('formErrors.integer'),
  max: (maxParams: number) => (val: string | number) =>
    parseFloat(val.toString()) <= maxParams && parseFloat(val.toString()) >= 0
      ? undefined
      : i18n.t('formErrors.max').replace(':max', maxParams.toString()),
  min: (minParams: number) => (val: string | number) =>
    val >= parseFloat(minParams.toString()) && parseFloat(val.toString()) >= 0
      ? undefined
      : i18n.t('formErrors.min').replace(':min', minParams.toString()),
  maxLength: (maxParams: number) => (val: string) =>
    val.length <= maxParams
      ? undefined
      : i18n.t('formErrors.max').replace(':max', maxParams.toString()),
  minLength: (minParams: number) => (val: string) =>
    val.length >= minParams
      ? undefined
      : i18n.t('formErrors.min').replace(':min', minParams.toString()),
  numeric: (val: string) =>
    !val || testRegex(val, /^(\d+.?\d*)?$/) ? undefined : i18n.t('formErrors.numeric'),
  phone: (val: string) =>
    testRegex(
      val,
      /^(((\+216){0,1}(\+ 216){0,1}(216){0,1}(00216){0,1}[.\- ]{0,1})([2345978]{1}[0-9]{1}[.\- ]{0,1})([0-9]{3}[.\- ]{0,1})([0-9]{3}))/g,
    )
      ? undefined
      : i18n.t('formErrors.phone'),
  required: (val: string | any[]) => {
    if (val instanceof Array)
      return val.length !== 0 ? undefined : i18n.t('formErrors.required');
    if (typeof val === 'string') return val.trim() ? undefined : i18n.t('formErrors.required');

    return val ? undefined : i18n.t('formErrors.required');
  },
  tags: (val: string) =>
    splitTagsString(val).length !== 0 ? undefined : i18n.t('formErrors.required'),
  string: (val: string) =>
    typeof val === typeof 'string' ? undefined : i18n.t('formErrors.string'),
  passwordConfirm: (passwordConfirm: string) => (val: string) =>
    val === passwordConfirm ? undefined : i18n.t('formErrors.passwordConfirm'),
  url: (val: string) =>
    testRegex(val, /^(https?|ftp):\/\/(-\.)?([^\s/?\.#-]+\.?)+(\/[^\s]*)?$/i)
      ? undefined
      : i18n.t('formErrors.url'),
  validLocation: (location: string | any[]) =>
    location instanceof Object ? undefined : i18n.t('formErrors.invalidLocation'),
};

export default Validators;
