import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const HomeRedirect = () => <Redirect to="/" />;

const UnhandledDynamicLinkRoute: React.FC<{
  path: string;
  exact: boolean;
}> = ({ path, exact }) => <Route path={path} exact={exact} component={HomeRedirect} />;

export default UnhandledDynamicLinkRoute;
