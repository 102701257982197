import React from 'react';
import { FormProvider } from 'react-hook-form';
import ModifyBtn from 'client/components/UserProfile/ModifyBtn';
import get from 'lodash/get';
import useInputRow from './useInputRow';

export interface IPorps {
  label?: string;
  name: string;

  defaultvalue?: string | boolean;
}

const InputRow: React.FC<IPorps> = ({ label, defaultvalue, name, children }) => {
  const {
    isModifing,
    formMethods,
    startModifing,
    inputValue,
    handleSubmit,
    _onSubmit,
  } = useInputRow({
    defaultvalue,
    name,
  });

  const fieldError = get(formMethods.errors, name);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)} className="w-100">
        <div className="border-top pb-2 pt-2 m-0 InputRow row">
          <div className="info-name col-4 pr-0 ">
            <h1>{label}</h1>
          </div>
          <div className="value col-5">
            <div className={`input-group input-group-sm   ${!isModifing ? 'd-none' : ''}`}>
              {children}
            </div>
            <div className={`${isModifing ? 'd-none' : ''}`}>
              {!(label === 'Mot de passe') ? (
                <h1 className="ellipsis-text">{inputValue}</h1>
              ) : (
                <h1>********</h1>
              )}
            </div>
          </div>
          <div className="col-3 p-0  d-flex">
            <ModifyBtn isModifing={isModifing} startModifing={startModifing} />
          </div>
        </div>
        {fieldError && (
          <div
            data-testid="inputError"
            className="invalid-entry-text"
            style={{ display: 'block' }}
          >
            {fieldError.message}
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default InputRow;
