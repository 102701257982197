/* eslint-disable max-lines */
// import ScrollDirectionIndicator from 'client/components/ScrollDirectionIndicator';
import InfoCardContainer from 'client/containers/InfoCardContainer';
import NavBarContainer from 'client/containers/NavBarContainer';
import SectionContainer from 'client/containers/SectionContainer';
import SliderIndicator from 'client/components/SliderIndicator';
import VideoMakerLevelContainer from 'client/containers/VideoMakerLevelContainer';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pattern from 'client/components/SVG/Pattern';
import { Link } from 'react-router-dom';
import Footer from 'client/containers/Footer';
import { fullpageApi } from '@fullpage/react-fullpage';

const VideoMakerPage: React.FC = () => {
  const [t] = useTranslation();
  const [fullPageApi, setFullPageApi] = useState<fullpageApi | null>(null);

  return (
    <>
      <SliderIndicator
        anchors={['1', '2', '3', '4', '5', '6', 'last']}
        buttonText="En savoir plus"
        textColor="text-primary"
        setFullPageApi={setFullPageApi}
      >
        <>
          <NavBarContainer />
          <SectionContainer
            title={t('VideoMakerPage.section1.title')}
            backgroundStyle="dark-image-background"
            miscComponent={
              <div className="w-75 mt-sm-2 mb-sm-3">
                <img
                  src="/assets/svg/custom/video-maker/first-section.svg"
                  alt="video maker first"
                />
              </div>
            }
            firstDescription={t('VideoMakerPage.section1.subtitle')}
            secondDescription={t('VideoMakerPage.section1.description')}
            titleStyle="text-white"
            subtitleStyle="text-primary-lighter subtitle-text-semiBold"
            descriptionStyle="text-white-70"
            mobileTopMargin="80"
          >
            <Pattern
              className="red-pattern"
              width="900px"
              style={{ position: 'absolute', top: '-30%', right: '75%', opacity: '0.45' }}
            />
            <div className="d-flex flex-wrap justify-content-md-start justify-content-center">
              <Link className="section-button alt-primary mr-md-5" to="/">
                {t('navigationButtons.telechargerReam')}
              </Link>
              <Link className="section-button alt-tertiary" to="/login">
                {t('navigationButtons.RejoinsNous')}
              </Link>
            </div>
          </SectionContainer>
        </>
        <SectionContainer
          title={t('VideoMakerPage.section2.title')}
          backgroundStyle="white-background"
          miscComponent={
            <div className="w-80">
              <img
                src="/assets/svg/custom/video-maker/second-section.svg"
                alt="video maker second"
              />
            </div>
          }
          number={1}
          numberStyle="section-number-alt"
          firstDescription={t('VideoMakerPage.section2.subtitle')}
          secondDescription={t('VideoMakerPage.section2.description')}
          titleStyle="text-dark"
          subtitleStyle="text-primary-lighter subtitle-text-medium"
          descriptionStyle="text-dark"
          isReversed
        >
          <div className="d-flex flex-wrap justify-content-md-start justify-content-center mt-5">
            <Link className="section-button alt-secondary" to="/login">
              {t('navigationButtons.RejoinsNous')}
            </Link>
          </div>
        </SectionContainer>
        <SectionContainer
          title={t('VideoMakerPage.section3.title')}
          backgroundStyle="dark-background"
          miscComponent={
            <div className="w-75">
              <img
                src="/assets/svg/custom/video-maker/third-section.svg"
                alt="video maker third"
              />
            </div>
          }
          number={2}
          numberStyle="section-number"
          firstDescription={t('VideoMakerPage.section3.subtitle')}
          secondDescription={t('VideoMakerPage.section3.description')}
          titleStyle="text-white"
          subtitleStyle="text-primary-lighter subtitle-text-medium"
          descriptionStyle="text-white-70"
        >
          <div className="d-flex flex-wrap justify-content-md-start justify-content-center mt-5">
            <Link className="section-button alt-tertiary" to="/login">
              {t('navigationButtons.RejoinsNous')}
            </Link>
          </div>
          <Pattern className="red-pattern red-pattern-position" width="900px" />
        </SectionContainer>
        <SectionContainer
          title={t('VideoMakerPage.section4.title')}
          backgroundStyle="white-background"
          miscComponent={
            <div className="w-60">
              <img
                src="/assets/svg/custom/video-maker/fourth-section.svg"
                alt="video maker third"
              />
            </div>
          }
          number={3}
          numberStyle="section-number-alt"
          firstDescription={t('VideoMakerPage.section4.subtitle')}
          secondDescription={t('VideoMakerPage.section4.description')}
          titleStyle="text-dark"
          subtitleStyle="text-primary-lighter subtitle-text-medium"
          descriptionStyle="text-dark"
          isReversed
        >
          <Pattern
            className="red-pattern"
            width="900px"
            style={{
              position: 'absolute',
              top: '55%',
              right: '-72%',
              opacity: '0.45',
              pointerEvents: 'none',
            }}
          />
          <div className="d-flex flex-wrap justify-content-md-start justify-content-center mt-5">
            <Link className="section-button alt-secondary mr-md-5" to="/login">
              {t('navigationButtons.RejoinsNous')}
            </Link>
            <button
              type="button"
              className="section-button alt-secondary p-0"
              onClick={fullPageApi?.moveSectionDown}
            >
              {t('navigationButtons.VoirLesGains')}
            </button>
          </div>
        </SectionContainer>
        <SectionContainer
          title={t('VideoMakerPage.section5.title')}
          backgroundStyle="dark-background"
          miscComponent={<VideoMakerLevelContainer />}
          firstDescription={t('VideoMakerPage.section5.subtitle')}
          secondDescription={t('VideoMakerPage.section5.description')}
          titleStyle="text-white"
          subtitleStyle="text-primary-lighter subtitle-text-medium"
          descriptionStyle="text-white-70"
          contentClassName="force-justify-start"
        >
          <div className="mb-3">
            <span className="font-size-lg text-primary-lighter subtitle-text-medium subtitle-section">
              {t('VideoMakerPage.section5.secondSubtitle')}
            </span>
          </div>
          <div className="mb-5">
            <span
              className="text-white-70 description-section"
              style={{ font: 'normal normal normal 24px/34px Inter' }}
            >
              {t('VideoMakerPage.section5.secondDescription')}
            </span>
          </div>
        </SectionContainer>
        <SectionContainer
          title={<div className="">{t('VideoMakerPage.section6.title')}</div>}
          backgroundStyle="white-background"
          miscComponent={<InfoCardContainer />}
          secondDescription={t('VideoMakerPage.section6.description')}
          titleStyle="text-dark"
          subtitleStyle="text-secondary"
          isReversed
          descriptionStyle="text-dark"
        >
          <div className="d-flex flex-wrap justify-content-md-start justify-content-center mt-5">
            <Link className="section-button alt-primary mr-md-5 " to="/">
              {t('navigationButtons.telechargerReam')}
            </Link>
            <Link className="section-button alt-secondary" to="/login">
              {t('navigationButtons.RejoinsNous')}
            </Link>
          </div>
          <Pattern
            className="red-pattern"
            width="900px"
            style={{ position: 'absolute', top: '55%', right: '-72%', opacity: '0.45' }}
          />
        </SectionContainer>
        <Footer />
      </SliderIndicator>
    </>
  );
};

export default VideoMakerPage;
