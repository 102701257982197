import React from 'react';

import { useCompleteSignUpInformation } from './useCompleteSignUpInformation';

const CompleteSignupInformation: React.FC = () => {
  const { selectedIndex, slidersArray, handleClick, slide } = useCompleteSignUpInformation();

  return (
    <div className="w-100 flex-column align-content-center justify-content-center m-auto">
      <div className="rounded shadow pb-5 pt-3">{slide}</div>
      <div className="d-flex justify-content-center  mt-5">
        {slidersArray.map((slider) => (
          <div
            key={slider}
            onClick={() => handleClick(slider)}
            className={`check-case mr-5 ${selectedIndex === slider ? 'active' : ''}`}
          />
        ))}
      </div>
    </div>
  );
};

export default CompleteSignupInformation;
