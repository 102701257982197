import Avatar from 'client/components/UserProfile/Avatar';
import React, { useMemo } from 'react';
import VideoGrapherLevel from 'client/components/SVG/VideoGrapherLevel';
import { starColors } from 'client/constants/VideoGrapherLevelColors';
import { useAvatar } from './useAvatar';
// import { IFileWithPreview } from 'types/IFileWithPreview';
interface IProps {
  profileName: string;
  lvlProfile: string;
  userProfilePicture?: string;
}

const CardHeader: React.FC<IProps> = ({ profileName, lvlProfile }) => {
  const { handleUploadAvatar, currentUser } = useAvatar();

  const { starColor } = useMemo(() => {
    switch (lvlProfile.split(' ')[1].toUpperCase()) {
      case 'SILVER':
        return {
          starColor: starColors.blueStar,
        };
      case 'GOLD':
        return {
          starColor: starColors.redStar,
        };
      case 'BRONZE':
      default:
        return {
          starColor: starColors.goldStar,
        };
    }
  }, [lvlProfile]);

  return (
    <div className="container p-3 d-flex flex-wrap rounded-top profil">
      <Avatar
        imageUrl={currentUser?.avatar || 'assets/img/unknown-user-image.jpg'}
        handleUploadAvatar={handleUploadAvatar}
      />
      <div className="ml-4 column  mt-md-0 mt-lg-0 mt-2">
        <div className=" text-white text-size-profileName mb-1">{profileName}</div>
        <div className="border mb-1 rounded w-100" />
        <span className="  text-size-profileName d-inline-flex align-items-center mt-1">
          <VideoGrapherLevel
            className="video-maker-card-level-indicator mr-2"
            starColor={starColor}
            style={{ height: '26px' }}
          />
          {lvlProfile}
        </span>
      </div>
    </div>
  );
};

export default CardHeader;
