/* eslint-disable max-lines */
import GalleryContainer from 'client/containers/GalleryContainer';
import GalleryMidSectionContainer from 'client/containers/GalleryMidSectionContainer';
import VideoCard from 'client/components/VideoCard';
import VideoCarousel from 'client/containers/VideoCarousel';
import Modal from 'client/components/Modal';
import VideoModalContent from 'client/components/VideoModalContent';
import NavBarContainer from 'client/containers/NavBarContainer';
import Footer from 'client/containers/Footer';
import Pattern from 'client/components/SVG/Pattern';
import { Link } from 'react-router-dom';
import React, { useRef } from 'react';
import { format } from 'date-fns';
import { useGallery } from './useGallery';

const Gallery: React.FC = () => {
  const {
    currentPosts,
    handleClick,
    isModalOpen,
    cancelModal,
    modalData,
    t,
    setIsSwiping,
  } = useGallery();
  const ref = useRef(null);

  return (
    <>
      <NavBarContainer />
      <GalleryContainer
        title={t('Gallery.section1.title')}
        backgroundStyle="dark-image-background"
        firstDescription={t('Gallery.section1.subtitle')}
        secondDescription={t('Gallery.section1.description')}
        titleStyle="text-white"
        subtitleStyle="text-primary-lighter subtitle-text-semiBold"
        descriptionStyle="text-white-70"
        mobileTopMargin="80"
      >
        <Link className="section-button alt-primary mr-3 " to="/">
          {t('navigationButtons.telechargerReam')}
        </Link>
      </GalleryContainer>
      <Pattern
        className=""
        width="900px"
        style={{ opacity: '0.5', position: 'absolute', top: '10%', right: '77%' }}
      />
      <GalleryMidSectionContainer scrollRef={ref} />
      <div className="video-gallery-container">
        <VideoCarousel
          beforeChange={() => setIsSwiping(true)}
          afterChange={() => setIsSwiping(false)}
        >
          {currentPosts &&
            currentPosts.byCategory?.map((post) => (
              <div key={post._id} onClick={() => handleClick(post)}>
                <VideoCard
                  className="bg-white video-card-shadow rounded m-3 video-card-mw"
                  name={post.name}
                  tags={post.tags}
                  country={post.country}
                  city={post.city}
                  thumbnailUrl={post.mainVideo?.thumbnailUrl}
                  date={post.createdAt && format(post.createdAt, 'DD-MM-YYYY')}
                  addedBy={post.addedBy?.username}
                />
              </div>
            ))}
        </VideoCarousel>
        <div ref={ref}>
          {currentPosts &&
            currentPosts?.byCountry?.map((post) => (
              <VideoCarousel>
                {post?.posts &&
                  post.posts?.map((post) => (
                    <div key={post._id} onClick={() => handleClick(post)}>
                      <VideoCard
                        className="bg-white video-card-shadow rounded m-3 video-card-mw"
                        name={post.name}
                        tags={post.tags}
                        country={post.country}
                        city={post.city}
                        thumbnailUrl={post.mainVideo.thumbnailUrl}
                        videoUrl={post.mainVideo.previewUrl}
                        date={post.createdAt && format(post.createdAt, 'DD-MM-YYYY')}
                        addedBy={post.addedBy?.username}
                      />
                    </div>
                  ))}
              </VideoCarousel>
            ))}
        </div>
        <div className="d-flex justify-content-center ml-5 mb-7 mt-7">
          <Link className="section-button alt-primary mr-3 " to="/">
            {t('navigationButtons.telechargerReam')}
          </Link>
        </div>
        <Modal isOpen={isModalOpen} closeModal={cancelModal}>
          <VideoModalContent
            imageUrl={modalData?.imageUrl}
            videoUrl={modalData?.previewUrl}
            nickName={modalData.nickName}
            datePlace={modalData.datePlace}
            firstDescription={modalData.description}
          />
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
