/* eslint-disable max-len */
/* eslint-disable max-lines */
import * as React from 'react';

const ReamPattern = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 776.23 768.11"
    {...props}
  >
    <defs>
      <linearGradient
        id="prefix__a"
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-59.92 504.101 -56.821) scale(.17)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ed213a" />
        <stop offset={0.5} stopColor="#8c031c" />
        <stop offset={1} stopColor="#ea384d" />
      </linearGradient>
      <linearGradient
        id="prefix__b"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="matrix(.12 -.13 .13 .12 292.81 389.42)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__c"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-35.93 713.004 -260.993) scale(.18)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__d"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-23.93 962.26 -509.111) scale(.19)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__e"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="matrix(.2 -.04 .04 .2 294.5 323.79)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__f"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(.07 -246644.992 249722.96) scale(.21)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__g"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(12.07 -1168.094 1656.204) scale(.23)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__h"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(24.06 -446.582 931.754) scale(.24)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__i"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="matrix(.2 .15 -.15 .2 366.17 249.14)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__j"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(48.06 -72.006 563.183) scale(.26)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__k"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(60.06 7.835 487.873) scale(.28)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__l"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(72.05 63.764 437.04) scale(.29)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__m"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(84.05 106.11 400.205) scale(.31)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__n"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(96.05 139.982 372.248) scale(.32)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__o"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(108.05 168.228 350.307) scale(.34)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__p"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(120.05 192.573 332.658) scale(.36)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__q"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(132.04 214.097 318.244) scale(.38)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__r"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="matrix(-.32 .23 -.23 -.32 602.48 417.01)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__s"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(156.04 251.487 296.274) scale(.42)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__t"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(167.8 268.754 287.761) scale(.44)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__u"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="matrix(-.46 0 0 -.46 567.82 562.09)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__v"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="matrix(-.48 -.1 .1 -.48 534.19 606.69)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__w"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-155.97 313.564 270.503) scale(.51)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__x"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-143.97 327.627 266.78) scale(.54)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__y"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-131.98 341.31 263.986) scale(.57)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__z"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-120 354.68 261.9) scale(.6)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__A"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-107.98 367.967 260.905) scale(.63)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__B"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-95.98 381.02 260.594) scale(.66)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__C"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-83.98 393.955 261.089) scale(.7)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__D"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-71.99 406.717 262.415) scale(.74)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__E"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-59.99 419.461 264.565) scale(.77)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__F"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="matrix(.55 -.61 .61 .55 -55.88 409.59)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__G"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-35.99 444.793 271.478) scale(.86)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__H"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-24 457.19 276.263) scale(.9)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__I"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        gradientTransform="rotate(-12 469.7 282.062) scale(.95)"
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__J"
        x1={0}
        y1={384.06}
        x2={776.23}
        y2={384.06}
        xlinkHref="#prefix__a"
      />
    </defs>
    <g data-name="Layer 2">
      <g data-name="Calque 1">
        <path
          d="M333.64 352.5a19.26 19.26 0 014.31-5.12l23.63-19.77a19.29 19.29 0 0112.42-4.49h30.81a19.29 19.29 0 0112.38 4.53l23.58 19.84a19.27 19.27 0 016.54 11.51l5.31 30.35a19.29 19.29 0 01-2.31 13l-15.44 26.66a19.34 19.34 0 01-10.11 8.46l-29 10.5a19.32 19.32 0 01-13.18 0l-28.91-10.67a19.25 19.25 0 01-10.08-8.49l-15.37-26.7a19.28 19.28 0 01-2.27-13l5.39-30.34a19.13 19.13 0 012.3-6.29m-.72-.41a20.16 20.16 0 00-2.4 6.55L325.13 389a20.05 20.05 0 002.37 13.54l15.37 26.71a20.08 20.08 0 0010.52 8.85l28.94 10.58a20.09 20.09 0 0013.74 0l29-10.5a20.08 20.08 0 0010.55-8.82L451 402.69a20.1 20.1 0 002.41-13.53l-5.31-30.36a20.1 20.1 0 00-6.86-11.91l-23.58-19.84a20.1 20.1 0 00-12.91-4.72h-30.81A20.1 20.1 0 00361 327l-23.63 19.78a19.8 19.8 0 00-4.49 5.34z"
          fill="url(#prefix__a)"
        />
        <path
          d="M338.93 339.65a20.3 20.3 0 015.55-4.33l28.66-15.19a20.29 20.29 0 0113.74-1.91L418.6 325a20.35 20.35 0 0111.75 7.37L450.29 358a20.29 20.29 0 014.27 13.2l-1.18 32.41a20.31 20.31 0 01-5.21 12.86l-21.74 24.03a20.35 20.35 0 01-12.25 6.5l-32.13 4.47a20.36 20.36 0 01-13.57-2.9L341 431.34a20.36 20.36 0 01-8.53-10.94l-10-30.86a20.35 20.35 0 01.51-13.87l12.19-30.05a20.16 20.16 0 013.74-6m-.65-.59a21.06 21.06 0 00-3.9 6.23l-12.19 30a21.18 21.18 0 00-.53 14.47l10 30.86a21.13 21.13 0 008.9 11.41L368 449.31a21.12 21.12 0 0014.15 3l32.13-4.47a21.12 21.12 0 0012.78-6.77L448.82 417a21.17 21.17 0 005.44-13.41l1.17-32.41a21.13 21.13 0 00-4.43-13.76l-20-25.59a21.12 21.12 0 00-12.26-7.68l-31.72-6.79a21.15 21.15 0 00-14.33 2l-28.66 15.19a21.07 21.07 0 00-5.79 4.51z"
          fill="url(#prefix__b)"
        />
        <path
          d="M347.19 327.57a21 21 0 016.66-3.24l32.84-9.33a21.3 21.3 0 0114.56 1l31.18 13.92a21.45 21.45 0 0110.49 10.16l14.92 30.71a21.37 21.37 0 011.51 14.52l-8.3 33.12a21.43 21.43 0 01-8.18 12.1l-27.65 20a21.43 21.43 0 01-14 4l-34.06-2.43a21.37 21.37 0 01-13.33-6l-24.53-23.75a21.39 21.39 0 01-6.39-13.13l-3.52-34a21.34 21.34 0 013.55-14.16L342 332.89a21.21 21.21 0 015.16-5.32m-.54-.75a22.22 22.22 0 00-5.38 5.56l-19.13 28.28a22.24 22.24 0 00-3.71 14.77l3.53 34a22.26 22.26 0 006.66 13.69l24.53 23.75a22.2 22.2 0 0013.9 6.22l34.06 2.42a22.19 22.19 0 0014.65-4.18l27.65-20a22.25 22.25 0 008.53-12.61l8.3-33.12a22.21 22.21 0 00-1.57-15.15l-14.93-30.71a22.29 22.29 0 00-10.94-10.6l-31.17-13.92a22.3 22.3 0 00-15.2-1.09l-32.83 9.37a22.22 22.22 0 00-7 3.38z"
          fill="url(#prefix__c)"
        />
        <path
          d="M358.33 316.94a22.35 22.35 0 017.58-1.88l35.85-2.46a22.52 22.52 0 0114.77 4.25L445.58 338a22.45 22.45 0 018.58 12.75l8.65 34.88a22.51 22.51 0 01-1.62 15.29l-15.8 32.28a22.46 22.46 0 01-11.07 10.66l-32.85 14.58a22.51 22.51 0 01-15.34 1.05l-34.53-9.95a22.46 22.46 0 01-12.42-9.05l-20.06-29.82a22.45 22.45 0 01-3.7-14.92l3.8-35.74a22.5 22.5 0 016.75-13.8l25.89-24.94a22.51 22.51 0 016.47-4.35m-.39-.89a23.4 23.4 0 00-6.75 4.55l-25.89 24.95a23.46 23.46 0 00-7.05 14.4l-3.8 35.74a23.48 23.48 0 003.86 15.56l20.06 29.82a23.41 23.41 0 0013 9.44l34.54 9.95a23.43 23.43 0 0016-1.09l32.85-14.58a23.44 23.44 0 0011.55-11.12l15.8-32.29a23.42 23.42 0 001.69-15.94l-8.65-34.88a23.41 23.41 0 00-9-13.31l-29-21.16a23.42 23.42 0 00-15.4-4.44l-35.86 2.46a23.47 23.47 0 00-7.9 2z"
          fill="url(#prefix__d)"
        />
        <path
          d="M372.14 308.43a23.59 23.59 0 018.21-.27l37.45 5.31a23.69 23.69 0 0114.28 7.61l25.28 28.15a23.65 23.65 0 016 15l1.28 37.81a23.76 23.76 0 01-5 15.38l-23.33 29.78a23.7 23.7 0 01-13.73 8.56l-37 7.82a23.69 23.69 0 01-16-2.27l-33.38-17.8a23.7 23.7 0 01-10.81-12l-14.13-35.1a23.72 23.72 0 01-.54-16.17l11.73-36a23.71 23.71 0 0110-12.74l32.11-20a23.84 23.84 0 017.62-3.07m-.21-1a24.47 24.47 0 00-7.95 3.2l-32.11 20a24.73 24.73 0 00-10.41 13.29l-11.73 36a24.63 24.63 0 00.57 16.86l14.13 35.1a24.58 24.58 0 0011.27 12.55l33.38 17.81a24.67 24.67 0 0016.71 2.37l37-7.82a24.65 24.65 0 0014.32-8.93l23.33-29.78A24.7 24.7 0 00465.7 402l-1.28-37.81a24.59 24.59 0 00-6.3-15.65l-25.28-28.14a24.64 24.64 0 00-14.84-7.94l-37.46-5.31a24.59 24.59 0 00-8.56.29z"
          fill="url(#prefix__e)"
        />
        <path
          d="M388.21 302.7a24.7 24.7 0 018.51 1.51l37.41 13.66a24.91 24.91 0 0113 11L467 363.35a24.91 24.91 0 013 16.77l-7 39.21a24.91 24.91 0 01-8.53 14.74l-30.54 25.56a24.93 24.93 0 01-16 5.81l-39.82-.05a24.89 24.89 0 01-16-5.84l-30.48-25.64a25 25 0 01-8.5-14.76l-6.86-39.22a25 25 0 013-16.77l20-34.46a24.92 24.92 0 0113.06-10.94l37.43-13.57a24.54 24.54 0 018.52-1.49m0-1.08a26 26 0 00-8.88 1.56l-37.44 13.57a26 26 0 00-13.62 11.41l-20 34.46a26 26 0 00-3.1 17.49l6.87 39.23a26 26 0 008.86 15.4l30.48 25.63a26 26 0 0016.68 6.1h39.83a26 26 0 0016.7-6.05l30.54-25.57a26 26 0 008.9-15.37l7-39.21a25.94 25.94 0 00-3.07-17.5l-19.94-34.47a26 26 0 00-13.59-11.44l-37.41-13.66a26 26 0 00-8.88-1.58z"
          fill="url(#prefix__f)"
        />
        <path
          d="M406 300.31a26.33 26.33 0 018.43 3.41L450 326a26.31 26.31 0 0111 14.14L473.9 380a26.23 26.23 0 01-.64 17.92l-15.75 38.85a26.3 26.3 0 01-12 13.31l-37 19.63a26.28 26.28 0 01-17.75 2.48l-41-8.77a26.22 26.22 0 01-15.2-9.51l-25.77-33.07a26.24 26.24 0 01-5.52-17l1.51-41.89a26.32 26.32 0 016.74-16.62l28.08-31.12a26.3 26.3 0 0115.84-8.4l41.48-5.81a25.78 25.78 0 019.1.33m.23-1.11a27.46 27.46 0 00-9.49-.34l-41.51 5.79a27.28 27.28 0 00-16.53 8.76l-28.08 31.12a27.35 27.35 0 00-7 17.33l-1.51 41.89a27.36 27.36 0 005.76 17.79l25.77 33.06a27.31 27.31 0 0015.85 9.93l41 8.76a27.29 27.29 0 0018.49-2.6l37-19.64a27.33 27.33 0 0012.53-13.88l15.75-38.85a27.36 27.36 0 00.67-18.69l-12.91-39.88A27.37 27.37 0 00450.58 325l-35.53-22.25a27.15 27.15 0 00-8.8-3.57z"
          fill="url(#prefix__g)"
        />
        <path
          d="M424.87 301.74a27.48 27.48 0 017.94 5.37l31.71 30.68a27.66 27.66 0 018.25 17l4.56 43.89a27.63 27.63 0 01-4.58 18.32L448 453.51a27.59 27.59 0 01-15.28 11.08l-42.4 12.11a27.61 27.61 0 01-18.82-1.33l-40.29-18a27.7 27.7 0 01-13.56-13.13l-19.3-39.68a27.66 27.66 0 01-2-18.77L307.12 343a27.65 27.65 0 0110.57-15.64l35.73-25.89a27.62 27.62 0 0118.14-5.18l44 3.12a27.38 27.38 0 019.29 2.33m.49-1.09a29 29 0 00-9.7-2.42l-44-3.13a28.78 28.78 0 00-18.93 5.4L317 326.4a28.8 28.8 0 00-11 16.31l-10.72 42.8a28.74 28.74 0 002 19.58l19.3 39.68a28.76 28.76 0 0014.15 13.69l40.29 18a28.74 28.74 0 0019.63 1.4l42.43-12.12A28.8 28.8 0 00449 454.18l24.71-36.55a28.8 28.8 0 004.79-19.1l-4.5-43.89a28.71 28.71 0 00-8.61-17.7l-31.7-30.69a28.89 28.89 0 00-8.28-5.6z"
          fill="url(#prefix__h)"
        />
        <path
          d="M444 307.34a29 29 0 017 7.27l25.93 38.53a29.07 29.07 0 014.79 19.28l-4.91 46.19a29.05 29.05 0 01-8.72 17.84l-33.45 32.23a29 29 0 01-18.15 8.06l-46.34 3.19a29.09 29.09 0 01-19.15-5.49l-37.52-27.34a29.12 29.12 0 01-11.09-16.48l-11.19-45.08a29.08 29.08 0 012.1-19.76l20.41-41.72A29.1 29.1 0 01328 310.27l42.45-18.84a29.1 29.1 0 0119.82-1.37l44.63 12.85a28.83 28.83 0 019.06 4.43m.74-1a30.45 30.45 0 00-9.45-4.62l-44.63-12.85a30.31 30.31 0 00-20.62 1.41l-42.5 18.85a30.29 30.29 0 00-14.92 14.38l-20.41 41.72a30.33 30.33 0 00-2.17 20.61l11.19 45.08a30.28 30.28 0 0011.56 17.19l37.55 27.34a30.3 30.3 0 0019.91 5.73l46.33-3.18a30.25 30.25 0 0018.94-8.41l33.45-32.23a30.31 30.31 0 009.1-18.61l4.9-46.19a30.23 30.23 0 00-5-20.11L452 313.91a30.25 30.25 0 00-7.3-7.58z"
          fill="url(#prefix__i)"
        />
        <path
          d="M462.41 317.29a30.34 30.34 0 015.62 9l18.27 45.35a30.65 30.65 0 01.71 20.9L471.85 439A30.6 30.6 0 01459 455.5l-41.49 25.87a30.62 30.62 0 01-20.51 4.32l-48.41-6.85a30.64 30.64 0 01-18.44-9.84l-32.68-36.37a30.6 30.6 0 01-7.81-19.39L288 364.37a30.54 30.54 0 016.48-19.88L324.63 306a30.57 30.57 0 0117.74-11.06l47.84-10.12a30.66 30.66 0 0120.7 2.93l43.15 23a30.47 30.47 0 018.35 6.54m1-.88a31.88 31.88 0 00-8.71-6.82l-43.15-23a31.88 31.88 0 00-21.6-3.06l-47.85 10.12a31.86 31.86 0 00-18.51 11.54l-30.14 38.49a31.88 31.88 0 00-6.76 20.74l1.65 48.86a31.89 31.89 0 008.15 20.23l32.68 36.37a31.87 31.87 0 0019.25 10.26l48.41 6.86a31.88 31.88 0 0021.34-4.52l41.49-25.86a31.88 31.88 0 0013.44-17.18L488.26 393a31.92 31.92 0 00-.74-21.8l-18.27-45.35a31.84 31.84 0 00-5.86-9.4z"
          fill="url(#prefix__j)"
        />
        <path
          d="M479.23 331.57a32.28 32.28 0 013.77 10.51l8.88 50.69a32.19 32.19 0 01-3.84 21.67L462.3 459a32.25 32.25 0 01-16.87 14.13l-48.38 17.55a32.2 32.2 0 01-22 0L326.69 473a32.24 32.24 0 01-16.84-14.16l-25.69-44.6a32.21 32.21 0 01-3.8-21.68l9-50.67a32.2 32.2 0 0111-19.05l39.46-33a32.18 32.18 0 0120.69-7.5h51.46a32.19 32.19 0 0120.68 7.54l39.4 33.12a32.1 32.1 0 017.18 8.56m1.2-.69a33.57 33.57 0 00-7.49-8.94l-39.39-33.12A33.55 33.55 0 00412 281l-51.46-.05a33.53 33.53 0 00-21.58 7.83l-39.46 33a33.58 33.58 0 00-11.5 19.86l-9 50.68a33.53 33.53 0 004 22.61l25.69 44.6a33.49 33.49 0 0017.57 14.77l48.3 17.7a33.55 33.55 0 0023 0l48.38-17.56a33.53 33.53 0 0017.6-14.74l25.78-44.54a33.6 33.6 0 004-22.61l-8.89-50.69a33.52 33.52 0 00-4-11z"
          fill="url(#prefix__k)"
        />
        <path
          d="M493.41 350a33.85 33.85 0 011.59 11.6l-2 54.14a33.89 33.89 0 01-8.7 21.48l-36.28 40.22a34 34 0 01-20.47 10.86L374 495.79a33.89 33.89 0 01-22.67-4.79l-45.92-28.8a33.93 33.93 0 01-14.25-18.27l-16.69-51.54a34 34 0 01.83-23.16L295.64 319a34 34 0 0115.52-17.2L359 276.43a34 34 0 0123-3.2l53 11.31a33.9 33.9 0 0119.63 12.3l33.32 42.72a33.94 33.94 0 015.46 10.44m1.39-.45a35.48 35.48 0 00-5.75-10.84l-33.31-42.72a35.3 35.3 0 00-20.49-12.82l-53-11.32a35.36 35.36 0 00-23.93 3.34l-47.86 25.39a35.32 35.32 0 00-16.19 18L274 368.69a35.32 35.32 0 00-.87 24.15l16.7 51.54a35.35 35.35 0 0014.86 19.06l45.91 28.75a35.32 35.32 0 0023.64 5l53.65-7.48a35.31 35.31 0 0021.35-11.33l36.29-40.23a35.32 35.32 0 009.07-22.39l1.94-54.14a35.38 35.38 0 00-1.69-12.16z"
          fill="url(#prefix__l)"
        />
        <path
          d="M504 372a35.65 35.65 0 01-.87 12.35l-13.85 55.32a35.71 35.71 0 01-13.65 20.21l-46.17 33.48A35.72 35.72 0 01406 500l-56.88-4a35.68 35.68 0 01-22.27-9.94l-41-39.65a35.76 35.76 0 01-10.67-21.93l-5.91-56.72a35.73 35.73 0 015.92-23.66l31.93-47.25a35.73 35.73 0 0119.75-14.31l54.83-15.67a35.72 35.72 0 0124.3 1.73l52.07 23.24a35.74 35.74 0 0117.53 17l24.95 51.28A35.34 35.34 0 01504 372m1.53-.16A37.43 37.43 0 00502 359.4l-25-51.27a37.21 37.21 0 00-18.28-17.69l-52.06-23.24a37.15 37.15 0 00-25.37-1.8l-54.83 15.67a37.21 37.21 0 00-20.6 14.93l-31.93 47.25a37.2 37.2 0 00-6.18 24.68l5.91 56.72a37.24 37.24 0 0011.13 22.87l41 39.65A37.19 37.19 0 00349 497.54l56.88 4a37.2 37.2 0 0024.46-7l46.17-33.48A37.14 37.14 0 00490.76 440l13.85-55.32a37.15 37.15 0 00.91-12.89z"
          fill="url(#prefix__m)"
        />
        <path
          d="M510.07 397a37.36 37.36 0 01-3.61 12.53l-26.36 53.91a37.53 37.53 0 01-18.48 17.81l-54.86 24.37a37.59 37.59 0 01-25.61 1.77l-57.69-16.6a37.59 37.59 0 01-20.75-15.1l-33.52-49.8A37.57 37.57 0 01263 401l6.32-59.7a37.59 37.59 0 0111.28-23.06l43.21-41.66a37.63 37.63 0 0123.46-10.42l59.89-4.16a37.64 37.64 0 0124.67 7.1l48.53 35.33a37.56 37.56 0 0114.34 21.29L509.17 384a37.43 37.43 0 01.9 13m1.61.17a39.28 39.28 0 00-.94-13.56l-14.47-58.26a39.1 39.1 0 00-15-22.21l-48.53-35.33a39.19 39.19 0 00-25.73-7.4l-59.89 4.13a39.18 39.18 0 00-24.47 10.88l-43.22 41.66a39.15 39.15 0 00-11.76 24.05l-6.32 59.7a39.16 39.16 0 006.45 26l33.53 49.79A39.18 39.18 0 00323 492.35l57.68 16.59a39.16 39.16 0 0026.72-1.84l54.86-24.37a39.15 39.15 0 0019.28-18.58l26.36-53.93a39.26 39.26 0 003.76-13.07z"
          fill="url(#prefix__n)"
        />
        <path
          d="M510.85 424.05a39.38 39.38 0 01-6.45 12.11l-38.94 49.76a39.57 39.57 0 01-22.93 14.3l-61.81 13.08a39.57 39.57 0 01-26.72-3.78l-55.77-29.71a39.55 39.55 0 01-18.06-20.1l-23.62-58.6a39.51 39.51 0 01-.92-27L275.16 314a39.58 39.58 0 0116.66-21.28l53.61-33.44a39.6 39.6 0 0126.44-5.59l62.56 8.84a39.57 39.57 0 0123.85 12.71l42.24 47a39.54 39.54 0 0110.1 25.06l2.16 63.15a39.43 39.43 0 01-1.93 13.59m1.62.53a41.3 41.3 0 002-14.17l-2.16-63.15a41.25 41.25 0 00-10.53-26.15l-42.24-47a41.22 41.22 0 00-24.88-13.25L372.1 252a41.21 41.21 0 00-27.57 5.84l-53.62 33.46a41.18 41.18 0 00-17.37 22.2L254 373.57a41.25 41.25 0 001 28.18l23.62 58.6a41.15 41.15 0 0018.84 21l55.7 29.65a41.16 41.16 0 0027.91 4l61.81-13.09A41.16 41.16 0 00466.8 487l38.94-49.76a41.06 41.06 0 006.73-12.63z"
          fill="url(#prefix__o)"
        />
        <path
          d="M505.74 452.09a41.46 41.46 0 01-9.29 11.06l-51 42.71a41.6 41.6 0 01-26.73 9.71l-66.51-.05a41.66 41.66 0 01-26.73-9.75L274.58 463a41.63 41.63 0 01-14.21-24.64l-11.5-65.51a41.67 41.67 0 015-28l33.3-57.58A41.61 41.61 0 01308.94 269l62.52-22.7a41.64 41.64 0 0128.45 0l62.48 22.8a41.64 41.64 0 0121.78 18.3l33.21 57.6a41.6 41.6 0 014.91 28l-11.6 65.49a41.33 41.33 0 01-4.95 13.57m1.55.9a43.29 43.29 0 005.17-14.15l11.6-65.49a43.39 43.39 0 00-5.13-29.23l-33.21-57.63A43.43 43.43 0 00463 267.4l-62.48-22.8a43.41 43.41 0 00-29.67 0l-62.52 22.7a43.39 43.39 0 00-22.74 19.05l-33.31 57.58a43.43 43.43 0 00-5.17 29.22l11.5 65.51a43.38 43.38 0 0014.81 25.7l50.92 42.8a43.41 43.41 0 0027.87 10.17l66.52.05a43.39 43.39 0 0027.89-10.13l51-42.71a43.13 43.13 0 009.67-11.54z"
          fill="url(#prefix__p)"
        />
        <path
          d="M494.34 479.85a43.62 43.62 0 01-12 9.35L420.51 522a43.77 43.77 0 01-29.65 4.14l-68.47-14.6A43.85 43.85 0 01297 495.67l-43.07-55.2a43.88 43.88 0 01-9.22-28.47l2.5-70a43.81 43.81 0 0111.23-27.76l46.89-52a43.82 43.82 0 0126.45-14l69.34-9.69a43.86 43.86 0 0129.28 6.25l59.35 37.14a43.8 43.8 0 0118.41 23.61l21.59 66.6a43.82 43.82 0 01-1.07 29.93L502.41 467a43.88 43.88 0 01-8.07 12.89m1.4 1.26a45.67 45.67 0 008.41-13.44l26.28-64.9a45.64 45.64 0 001.11-31.21L510 305a45.69 45.69 0 00-19.21-24.63l-59.39-37.18a45.6 45.6 0 00-30.54-6.52l-69.34 9.69A45.7 45.7 0 00303.93 261L257 313a45.63 45.63 0 00-11.68 29l-2.49 70a45.65 45.65 0 009.62 29.71l43.07 55.2A45.61 45.61 0 00322 513.4l68.46 14.6a45.7 45.7 0 0030.94-4.32l61.84-32.83a45.44 45.44 0 0012.5-9.75z"
          fill="url(#prefix__q)"
        />
        <path
          d="M476.53 505.93a45.9 45.9 0 01-14.39 7l-70.86 20.27a46.21 46.21 0 01-31.44-2.2l-67.3-30a46.11 46.11 0 01-22.66-22l-32.26-66.26a46.2 46.2 0 01-3.27-31.35l17.88-71.49a46.15 46.15 0 0117.64-26.12l59.66-43.28a46.14 46.14 0 0130.3-8.66l73.51 5.19a46.15 46.15 0 0128.79 12.84l53 51.23a46.17 46.17 0 0113.79 28.34l7.65 73.3a46.13 46.13 0 01-7.64 30.58l-41.26 61.07a46.21 46.21 0 01-11.12 11.5m1.16 1.61a48 48 0 0011.61-12l41.25-61.07a48.06 48.06 0 008-31.9l-7.65-73.29a48.1 48.1 0 00-14.39-29.56l-53-51.24a48.09 48.09 0 00-30-13.39L360 229.9a48 48 0 00-31.61 9l-59.65 43.27a48.05 48.05 0 00-18.41 27.25L232.42 381a48.05 48.05 0 003.42 32.7l32.26 66.26a48.06 48.06 0 0023.63 22.86l67.3 30a48.14 48.14 0 0032.8 2.32l70.86-20.27a48.07 48.07 0 0015-7.3z"
          fill="url(#prefix__r)"
        />
        <path
          d="M452.48 528.89a48.35 48.35 0 01-16.35 4.11l-77.39 5.36a48.53 48.53 0 01-31.88-9.17l-62.73-45.69a48.56 48.56 0 01-18.54-27.5l-18.71-75.3a48.54 48.54 0 013.49-33l34.05-69.7a48.58 48.58 0 0123.88-23l70.89-31.51a48.58 48.58 0 0133.1-2.29l74.56 21.43a48.56 48.56 0 0126.82 19.52L537 326.47a48.64 48.64 0 018 32.2l-8.16 77.15a48.59 48.59 0 01-14.57 29.81l-55.84 53.85a48.33 48.33 0 01-14 9.41m.85 1.91a50.6 50.6 0 0014.62-9.8l55.84-53.85A50.61 50.61 0 00538.93 436l8.17-77.15a50.6 50.6 0 00-8.35-33.58L495.41 261a50.64 50.64 0 00-28-20.36l-74.55-21.43a50.59 50.59 0 00-34.53 2.39l-70.89 31.51a50.56 50.56 0 00-24.91 24l-34.05 69.7a50.61 50.61 0 00-3.64 34.42l18.71 75.28a50.63 50.63 0 0019.35 28.69l62.73 45.64a50.64 50.64 0 0033.26 9.56l77.39-5.4a50.53 50.53 0 0017.05-4.24z"
          fill="url(#prefix__s)"
        />
        <path
          d="M422.7 547.26a50.75 50.75 0 01-17.72.61l-80.86-11.41A51.19 51.19 0 01293.29 520l-54.6-60.72a51.14 51.14 0 01-13.06-32.39l-2.8-81.61a51.13 51.13 0 0110.81-33.2L284 247.8a51.11 51.11 0 0129.63-18.49l79.88-16.93a51.1 51.1 0 0134.58 4.89l72.08 38.38a51.08 51.08 0 0123.35 26l30.48 75.7a51.08 51.08 0 011.2 34.9l-25.26 77.65a51.11 51.11 0 01-21.52 27.5l-69.28 43.23a50.58 50.58 0 01-16.44 6.63m.45 2.16a53.34 53.34 0 0017.16-6.92l69.27-43.23A53.26 53.26 0 00532 470.58l25.28-77.65a53.26 53.26 0 00-1.24-36.41l-30.55-75.73a53.18 53.18 0 00-24.36-27.08l-72.07-38.39a53.31 53.31 0 00-36.06-5.09l-79.89 16.93a53.2 53.2 0 00-30.9 19.28l-50.32 64.32a53.26 53.26 0 00-11.28 34.64l2.8 81.61a53.22 53.22 0 0013.63 33.78l54.6 60.72a53.29 53.29 0 0032.15 17.13l80.86 11.41a53.06 53.06 0 0018.48-.63z"
          fill="url(#prefix__t)"
        />
        <path
          d="M388 559.67a53.6 53.6 0 01-18.38-3.25L288.88 527a53.82 53.82 0 01-28.15-23.65l-42.94-74.46a53.83 53.83 0 01-6.36-36.21l15-84.64a53.86 53.86 0 0118.4-31.83L310.69 221a53.83 53.83 0 0134.55-12.55l86 .05a53.83 53.83 0 0134.53 12.59l65.82 55.29a53.83 53.83 0 0118.36 31.85l14.88 84.66a53.87 53.87 0 01-6.41 36.2l-43 74.41a53.87 53.87 0 01-28.18 23.62l-80.79 29.35a53.58 53.58 0 01-18.45 3.2m0 2.32a56.11 56.11 0 0019.17-3.37L488 529.26a56 56 0 0029.39-24.63l43-74.41a56.1 56.1 0 006.68-37.76l-14.88-84.66A56.06 56.06 0 00533 274.58l-65.82-55.29a56 56 0 00-36-13.14l-86-.05a56.07 56.07 0 00-36 13.09l-65.88 55.22a56 56 0 00-19.19 33.19l-15 84.65a56 56 0 006.68 37.75l42.93 74.47a56.07 56.07 0 0029.36 24.67l80.76 29.44A56.14 56.14 0 00388 562z"
          fill="url(#prefix__u)"
        />
        <path
          d="M349.58 564.85a56.43 56.43 0 01-18.21-7.37l-76.71-48a56.65 56.65 0 01-23.8-30.48l-27.92-86.06a56.66 56.66 0 011.38-38.68l33.94-83.87a56.67 56.67 0 0125.9-28.74l79.92-42.44a56.68 56.68 0 0138.32-5.36l88.49 18.86a56.67 56.67 0 0132.81 20.53l55.67 71.33a56.68 56.68 0 0111.93 36.8l-3.2 90.43a56.71 56.71 0 01-14.52 35.87L493 534.86A56.66 56.66 0 01458.82 553l-89.61 12.55a56.31 56.31 0 01-19.63-.7m-.51 2.39a59.13 59.13 0 0020.48.72l89.61-12.54a59 59 0 0035.65-18.93l60.58-67.21a59 59 0 0015.14-37.41l3.21-90.43a59 59 0 00-12.45-38.39l-55.67-71.34a59 59 0 00-34.22-21.4l-88.49-18.86a59 59 0 00-40 5.59L263 239.47a59 59 0 00-27 30l-33.95 83.87a59 59 0 00-1.43 40.34l27.92 86.07a59 59 0 0024.83 31.82l76.71 48a59.09 59.09 0 0019 7.69z"
          fill="url(#prefix__v)"
        />
        <path
          d="M308.88 561.78a59.22 59.22 0 01-17.14-11.58L223.26 484a59.69 59.69 0 01-17.84-36.62l-9.9-94.73a59.63 59.63 0 019.87-39.52l53.3-78.93a59.61 59.61 0 0133-23.92l91.56-26.21a59.64 59.64 0 0140.64 2.86l87 38.79A59.6 59.6 0 01540.13 254l41.71 85.62a59.69 59.69 0 014.24 40.51L563 472.58a59.65 59.65 0 01-22.8 33.75l-77.08 55.95a59.59 59.59 0 01-39.12 11.2l-95-6.69a59.5 59.5 0 01-20.06-5m-1.05 2.34a62 62 0 0020.93 5.23l95 6.69a62.08 62.08 0 0040.85-11.69l77.08-55.94a62.11 62.11 0 0023.78-35.21l23.09-92.4a62.15 62.15 0 00-4.42-42.26l-41.71-85.63a62.14 62.14 0 00-30.55-29.53l-87-38.78a62.07 62.07 0 00-42.38-3L291 207.82a62.16 62.16 0 00-34.39 25l-53.3 78.94A62.11 62.11 0 00193 352.93l9.91 94.73a62.11 62.11 0 0018.61 38.19l68.48 66.2a62.22 62.22 0 0017.88 12.07z"
          fill="url(#prefix__w)"
        />
        <path
          d="M267.64 549.71A62.69 62.69 0 01252.53 534l-56-83.14a62.82 62.82 0 01-10.35-41.61l10.53-99.7a62.73 62.73 0 0118.81-38.53l72.15-69.61A62.81 62.81 0 01326.82 184l100-6.95a62.81 62.81 0 0141.21 11.84l81.08 59a62.79 62.79 0 0124 35.56l24.2 97.29a62.71 62.71 0 01-4.5 42.64l-44 90.08a62.74 62.74 0 01-30.86 29.77L426.34 584a62.8 62.8 0 01-42.78 3l-96.36-27.74a62.65 62.65 0 01-19.56-9.55m-1.59 2.18a65.59 65.59 0 0020.41 10l96.36 27.68a65.42 65.42 0 0044.61-3.1L519 545.7a65.37 65.37 0 0032.19-31.05l44-90.09a65.37 65.37 0 004.7-44.48l-24.21-97.29a65.4 65.4 0 00-25-37.09l-81.08-59a65.34 65.34 0 00-43-12.35l-100 6.94a65.37 65.37 0 00-40.86 18.18l-72.15 69.61A65.32 65.32 0 00194 309.3L183.46 409a65.41 65.41 0 0010.8 43.4l56 83.14a65.32 65.32 0 0015.76 16.34z"
          fill="url(#prefix__x)"
        />
        <path
          d="M227.82 528.26a65.87 65.87 0 01-12.13-19.44L176.2 411a66 66 0 01-1.56-45.1l32.66-100.41a66.07 66.07 0 0127.8-35.55l89.52-55.89a66.1 66.1 0 0144.15-9.36l104.5 14.73a66 66 0 0139.84 21.21l70.58 78.45a66.07 66.07 0 0116.89 41.86l3.64 105.47a66.15 66.15 0 01-14 42.92l-65 83.13a66.07 66.07 0 01-38.22 23.9l-103.24 21.9A66.14 66.14 0 01339 592l-93.15-49.59a66.06 66.06 0 01-18.06-14.11m-2.11 1.9a68.89 68.89 0 0018.83 14.72l93.16 49.59a68.81 68.81 0 0046.64 6.49l103.24-21.9a68.83 68.83 0 0039.94-24.93l65-83.13a68.86 68.86 0 0014.56-44.77l-3.63-105.47a68.86 68.86 0 00-17.62-43.66l-70.58-78.45a68.78 68.78 0 00-41.56-22.12l-104.5-14.74a68.8 68.8 0 00-46.05 9.77l-89.53 55.89a68.84 68.84 0 00-29 37.08L171.94 365a68.83 68.83 0 001.62 47l39.49 97.86a69 69 0 0012.66 20.28z"
          fill="url(#prefix__y)"
        />
        <path
          d="M191.52 497.46a69.34 69.34 0 01-8.24-22.67L164 365.38a69.6 69.6 0 018.27-46.78l55.58-96.19a69.6 69.6 0 0136.41-30.52l104.4-37.95a69.51 69.51 0 0147.51 0l104.37 38a69.52 69.52 0 0136.46 30.6l55.51 96.23a69.52 69.52 0 018.23 46.79L601.36 475a69.63 69.63 0 01-23.77 41.14l-85.13 71.37a69.56 69.56 0 01-44.65 16.23l-111.09-.05a69.51 69.51 0 01-44.63-16.26L207 515.94a69.5 69.5 0 01-15.5-18.48m-2.57 1.54a72.26 72.26 0 0016.16 19.28l85.07 71.44a72.5 72.5 0 0046.56 17h111.09a72.48 72.48 0 0046.58-16.93l85.12-71.37a72.5 72.5 0 0024.8-42.91l19.33-109.39a72.49 72.49 0 00-8.59-48.81l-55.5-96.22a72.42 72.42 0 00-37.95-31.87l-104.37-38a72.46 72.46 0 00-49.56 0l-104.4 38a72.39 72.39 0 00-38 31.84l-55.56 96.04a72.45 72.45 0 00-8.63 48.8l19.25 109.4a72.52 72.52 0 008.6 23.65z"
          fill="url(#prefix__z)"
        />
        <path
          d="M160.88 457.8a72.74 72.74 0 01-3.52-25.15l4.12-116.86a73.18 73.18 0 0118.75-46.36l78.27-86.87a73.2 73.2 0 0144.17-23.46l115.8-16.23a73.16 73.16 0 0148.91 10.41l99.15 62a73.28 73.28 0 0130.77 39.42l36.09 111.22a73.17 73.17 0 01-1.76 50l-43.84 108.39a73.21 73.21 0 01-33.48 37.15l-103.26 54.86a73.26 73.26 0 01-49.53 6.94l-114.37-24.35a73.25 73.25 0 01-42.4-26.52l-72-92.17a72.88 72.88 0 01-11.91-22.42m-3 1a76.32 76.32 0 0012.43 23.39l72 92.17A76.23 76.23 0 00286.5 602l114.37 24.35a76.24 76.24 0 0051.66-7.24l103.26-54.86a76.26 76.26 0 0034.92-38.75l43.84-108.41a76.17 76.17 0 001.84-52.09L600.3 253.73a76.28 76.28 0 00-32.1-41.12l-99.15-62a76.22 76.22 0 00-51-10.86L302.23 156a76.25 76.25 0 00-46.07 24.47l-78.27 86.87a76.26 76.26 0 00-19.56 48.36l-4.12 116.86a76.29 76.29 0 003.67 26.23z"
          fill="url(#prefix__A)"
        />
        <path
          d="M138 410.26a76.72 76.72 0 011.87-26.66l29.83-119.42a77 77 0 0129.45-43.63l99.6-72.32a77.07 77.07 0 0150.61-14.49l122.78 8.62a77.13 77.13 0 0148.09 21.43l88.51 85.53a77.06 77.06 0 0123.06 47.32l12.83 122.42a77.06 77.06 0 01-12.75 51.07L563 572.15a77 77 0 01-42.6 30.93L402.09 637a77 77 0 01-52.51-3.69l-112.43-50.1a77 77 0 01-37.86-36.58L145.37 436a76.68 76.68 0 01-7.36-25.7m-3.3.35a80.16 80.16 0 007.68 26.8l53.92 110.65a80.28 80.28 0 0039.49 38.15l112.43 50.1a80.19 80.19 0 0054.77 3.81l118.33-33.89A80.25 80.25 0 00565.77 574l68.86-102a80.27 80.27 0 0013.3-53.28L635.1 296.29a80.25 80.25 0 00-24.05-49.36l-88.52-85.53a80.28 80.28 0 00-50.15-22.35l-122.79-8.62a80.27 80.27 0 00-52.79 15.11l-99.6 72.32a80.3 80.3 0 00-30.72 45.52L136.66 382.8a80.36 80.36 0 00-1.95 27.81z"
          fill="url(#prefix__B)"
        />
        <path
          d="M124.86 356.31a80.92 80.92 0 017.77-27l56.83-116.44a81.13 81.13 0 0139.87-38.48l118.38-52.66a81.08 81.08 0 0155.29-3.89l124.54 35.74a81.14 81.14 0 0144.82 32.59l72.4 107.43a81.08 81.08 0 0113.39 53.77l-13.57 128.85A81.18 81.18 0 01620.27 526L527 616a81.14 81.14 0 01-50.62 22.52l-129.26 9a81.15 81.15 0 01-53.26-15.29L189.1 556.05a81.12 81.12 0 01-31-45.94l-31.28-125.73a80.88 80.88 0 01-2-28.07m-3.47-.36a84.6 84.6 0 002 29.27L154.74 511a84.48 84.48 0 0032.31 47.93l104.8 76.18a84.52 84.52 0 0055.56 16l129.25-9a84.49 84.49 0 0052.81-23.5l93.23-90a84.47 84.47 0 0025.35-51.94l13.58-128.85a84.53 84.53 0 00-14-56.09l-72.39-107.52a84.51 84.51 0 00-46.76-34L404 114.48a84.47 84.47 0 00-57.66 4l-118.43 52.68a84.52 84.52 0 00-41.59 40.14l-56.83 116.44a84.56 84.56 0 00-8.1 28.21z"
          fill="url(#prefix__C)"
        />
        <path
          d="M123.13 297.89A85.21 85.21 0 01137 271.74l84-107.45a85.44 85.44 0 0149.48-30.9l133.41-28.32a85.39 85.39 0 0157.76 8.13l120.4 64.06a85.37 85.37 0 0139 43.35l51.06 126.47a85.41 85.41 0 012 58.29L632 535.07a85.41 85.41 0 01-35.92 46l-115.67 72.2a85.37 85.37 0 01-57.06 12.12l-135-19A85.39 85.39 0 01236.8 619l-91.23-101.4a85.35 85.35 0 01-21.84-54.08L119 327.21a85.27 85.27 0 014.13-29.32m-3.49-1.14a88.86 88.86 0 00-4.31 30.59l4.73 136.3a88.91 88.91 0 0022.78 56.42l91.23 101.38A88.94 88.94 0 00287.79 650l135 19a89 89 0 0059.52-12.64L598 584.15a88.91 88.91 0 0037.47-47.94l42.18-129.7a88.93 88.93 0 00-2.11-60.81l-51.03-126.46a89 89 0 00-40.7-45.24L463.4 110a89 89 0 00-60.25-8.48L269.74 129.8a88.92 88.92 0 00-51.6 32.2l-84 107.45a89.06 89.06 0 00-14.5 27.27z"
          fill="url(#prefix__D)"
        />
        <path
          d="M134.13 237.35a89.79 89.79 0 0120-23.87l110-92.26a89.91 89.91 0 0157.7-21h143.56a89.83 89.83 0 0157.69 21l110 92.3a89.87 89.87 0 0130.69 53.18l24.9 141.39A89.83 89.83 0 01678 468.6l-71.81 124.32a89.85 89.85 0 01-47 39.45l-134.95 49.08a89.91 89.91 0 01-61.4 0l-134.9-49.13a89.93 89.93 0 01-47-39.48l-71.78-124.35A89.92 89.92 0 0198.51 408l25-141.38a89.48 89.48 0 0110.66-29.29m-3.35-1.93a93.48 93.48 0 00-11.16 30.6l-25 141.38a93.54 93.54 0 0011.11 63.07l71.75 124.35a93.64 93.64 0 0049.06 41.17l134.89 49.13a93.63 93.63 0 0064.05 0L560.47 636a93.6 93.6 0 0049.07-41.16l71.81-124.31a93.61 93.61 0 0011.13-63.07l-24.9-141.39a93.62 93.62 0 00-32-55.47l-110-92.3a93.56 93.56 0 00-60.18-21.92H321.84a93.7 93.7 0 00-60.19 21.89l-110 92.26a93.72 93.72 0 00-20.91 24.91z"
          fill="url(#prefix__E)"
        />
        <path
          d="M158.71 177.43a94.07 94.07 0 0125.86-20.2L318 86.31a94.64 94.64 0 0164-9l147.81 31.45A94.65 94.65 0 01584.63 143l93 119.1a94.63 94.63 0 0120 61.47l-5.3 151a94.66 94.66 0 01-24.22 59.92L567 646.83a94.62 94.62 0 01-57.07 30.33l-149.65 21A94.65 94.65 0 01297 684.72l-128.15-80.1a94.69 94.69 0 01-39.78-50.94L82.41 410a94.69 94.69 0 012.27-64.59l56.63-140.11a94.38 94.38 0 0117.4-27.82m-3-2.73a98.49 98.49 0 00-18.14 29L80.9 343.84a98.54 98.54 0 00-2.36 67.37l46.68 143.73a98.53 98.53 0 0041.49 53.13l128.15 80.1a98.57 98.57 0 0065.94 14l149.65-21A98.53 98.53 0 00570 649.55l101.12-112.29a98.55 98.55 0 0025.26-62.5l5.3-151a98.61 98.61 0 00-20.82-64.12l-93-119.09a98.56 98.56 0 00-57.17-35.74L382.86 73.34a98.58 98.58 0 00-66.76 9.37l-133.45 70.93a98.56 98.56 0 00-27 21.06z"
          fill="url(#prefix__F)"
        />
        <path
          d="M197.12 121.11a99.15 99.15 0 0131-15.14l152.96-43.83A99.62 99.62 0 01449 66.9l145.31 64.71a99.65 99.65 0 0148.93 47.27l69.72 143a99.61 99.61 0 017.1 67.66l-38.5 154.34a99.61 99.61 0 01-38 56.4l-128.7 93.49a99.68 99.68 0 01-65.4 18.74l-158.79-11.13a99.7 99.7 0 01-62.14-27.68L114.11 563.18A99.56 99.56 0 0184.3 502L67.69 343.83a99.62 99.62 0 0116.46-66l89-131.87a99.23 99.23 0 0124-24.84m-2.52-3.47a103.67 103.67 0 00-25 25.91l-89 131.87a103.8 103.8 0 00-17.18 68.86L80 502.48a103.79 103.79 0 0031.1 63.79l114.45 110.51a103.67 103.67 0 0064.82 28.87l158.69 11.12a103.76 103.76 0 0068.22-19.56L646 603.73a103.72 103.72 0 0039.69-58.83l38.5-154.34a103.77 103.77 0 00-7.43-70.56l-69.71-143a103.76 103.76 0 00-51-49.3L450.69 63a103.76 103.76 0 00-70.79-5L227 101.85a103.73 103.73 0 00-32.38 15.79z"
          fill="url(#prefix__G)"
        />
        <path
          d="M249 71.52a104.27 104.27 0 0135.27-8.79l167-11.67a104.86 104.86 0 0168.84 19.75l135.46 98.43a104.82 104.82 0 0140 59.37l40.49 162.48a104.78 104.78 0 01-7.49 71.22L655.24 612.8a104.88 104.88 0 01-51.52 49.74l-153 68.1a104.9 104.9 0 01-71.44 5l-161-46.17a104.89 104.89 0 01-57.93-42.1L66.8 508.54a104.87 104.87 0 01-17.32-69.49L67 272.52a104.83 104.83 0 0131.4-64.36L218.85 91.85A104.2 104.2 0 01249 71.52m-1.84-4.12a109 109 0 00-31.44 21.21L95.26 204.91a109.2 109.2 0 00-32.75 67.14L45 438.58a109.16 109.16 0 0018.07 72.48l93.62 138.82a109.18 109.18 0 0060.43 43.92l161 46.16a109.2 109.2 0 0074.52-5.2l153-68.1a109.19 109.19 0 0053.73-51.88l73.42-150.5a109.15 109.15 0 007.73-74.28L700 227.52a109.2 109.2 0 00-41.77-61.93L522.8 67.16a109.15 109.15 0 00-71.8-20.6L284 58.23a109.09 109.09 0 00-36.8 9.17z"
          fill="url(#prefix__H)"
        />
        <path
          d="M313.26 31.82a110 110 0 0138.25-1.34L526.05 55a110.41 110.41 0 0166.56 35.39l117.93 131a110.44 110.44 0 0128.24 69.9l6.14 176.15a110.35 110.35 0 01-23.3 71.69L613.1 678a110.33 110.33 0 01-63.93 40l-172.4 36.64a110.38 110.38 0 01-74.65-10.49l-155.63-82.79a110.39 110.39 0 01-50.43-56L30 441.92a110.34 110.34 0 01-2.63-75.34L81.88 199a110.44 110.44 0 0146.41-59.4l149.48-93.4a110 110 0 0135.49-14.33m-1-4.65a115 115 0 00-37 15l-149.48 93.3a115 115 0 00-48.42 62L22.89 365.11a115 115 0 002.74 78.59l66 163.42a115 115 0 0052.61 58.44l155.63 82.75a114.92 114.92 0 0077.86 11l172.41-36.64A115 115 0 00616.85 681l108.52-138.94a114.94 114.94 0 0024.3-74.78l-6.15-176.15a114.92 114.92 0 00-29.45-72.9l-117.93-131a115 115 0 00-69.43-36.92L352.17 25.78a115.14 115.14 0 00-39.9 1.39z"
          fill="url(#prefix__I)"
        />
        <path
          d="M388.11 5a115.5 115.5 0 0139.68 7l174.34 63.45a116.16 116.16 0 0160.79 51l92.77 160.68a116.23 116.23 0 0113.78 78.14L737.25 548a116.17 116.17 0 01-39.68 68.71L555.45 736a116.2 116.2 0 01-74.57 27.14H295.35A116.2 116.2 0 01220.78 736L78.65 616.71A116.21 116.21 0 0139 548L6.76 365.28a116.17 116.17 0 0113.78-78.14l92.77-160.68a116.17 116.17 0 0160.78-51L348.44 12a115.44 115.44 0 0139.67-7m0-5a120.85 120.85 0 00-41.38 7.3L172.38 70.75A121 121 0 00109 124L16.21 284.64a121 121 0 00-14.37 81.51l32.22 182.71a121 121 0 0041.38 71.68L217.57 739.8a121 121 0 0077.78 28.31h185.53a121 121 0 0077.78-28.31l142.13-119.26a121 121 0 0041.38-71.68l32.22-182.71A121 121 0 00760 284.64L667.25 124a121 121 0 00-63.41-53.21L429.5 7.3A120.91 120.91 0 00388.11 0z"
          fill="url(#prefix__J)"
        />
      </g>
    </g>
  </svg>
);

export default ReamPattern;
