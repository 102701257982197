import { TableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import React, { useRef, useCallback } from 'react';
import { getImageUrl } from 'client/helpers/getImageUrl';
import { CRUDDataTableHandle } from 'dashboard/containers/CRUDDataTable';
import { useTableColumnsWithActions } from 'dashboard/containers/CRUDDataTable/useTableColumnsWithActions';
import { useHistory } from 'react-router';
import { IPost } from 'server/models/Post';
import { IUser } from 'server/models/User';

export const usePostList = () => {
  const [t] = useTranslation();
  const dataTableRef = useRef<CRUDDataTableHandle>(null);
  const navigate = useHistory();

  const { videos, loaded } = useSelector((state: RootState) => state.dashboardVideos);
  const onPressShow = useCallback(
    (item: IPost) => {
      navigate.push(`/dashboard/video/${item._id}`);
    },
    [navigate],
  );
  const tableCols: TableColumn<IPost>[] = [
    {
      selector: (e) => (
        <img
          id={e._id}
          alt="post-thumbnail"
          className="p-2"
          width="100"
          height="100"
          src={getImageUrl(e.mainVideo.thumbnailUrl)}
        />
      ),
      center: true,
    },
    {
      name: t('addVideoDashboard.name'),
      selector: (e) => e.name,
      sortable: true,
      center: true,
      sortfield: 'name',
    },
    {
      name: t('dashboard.videoGrapherPage.videoGrapher'),
      selector: (e) => (e.addedBy as IUser).username,
      sortable: true,
      center: true,
      sortfield: 'addedBy',
    },
    {
      name: t('addVideoDashboard.countryAndCity'),
      selector: (e) => `${e.city ? `${e.city}, ` : ''}${e.country}`,
      sortable: true,
      center: true,
      sortfield: 'country',
    },
    {
      name: t('dashboard.videoGrapherPage.status'),
      selector: (e) => e.postStatus,
      sortable: true,
      center: true,
      sortfield: 'postStatus',
    },
    {
      name: t('addVideoDashboard.cinematographyStyle'),
      selector: (e) => e.cinematicStyle,
      sortable: true,
      center: true,
      sortfield: 'cinematicStyle',
    },
    {
      sortfield: 'createdAt',
      name: t('dashboard.videoGrapherPage.createdAt'),
      selector: (e) => (e.createdAt ? format(new Date(e.createdAt), 'DD-MM-YYYY') : ''),
      sortable: true,
    },
  ];

  const columns = useTableColumnsWithActions({
    columns: tableCols,
    buttons: [
      {
        fn: onPressShow,
        title: t('dataTable.show'),
        className: 'btn btn-dark btn-xs m-1',
      },
    ],
  });

  return {
    videos,
    loaded,
    tableCols,
    columns,
    dataTableRef,
  };
};
