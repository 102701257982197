import { queryStringToObject } from 'client/helpers/queryStringToObject';
import { useLoadingDispatch } from 'client/hooks/useLoadingDispatch';
import { ResetPasswordFormValues } from 'client/models/ResetPassword';
import { resetUserPassword } from 'client/_redux/actions/auth';
import { useCallback } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

export const useResetPassword = () => {
  const [t] = useTranslation();
  const { search } = useLocation();
  const { push } = useHistory();
  const formMethods = useForm<ResetPasswordFormValues>();
  const { isLoading, dispatch } = useLoadingDispatch();
  const onSubmit = useCallback<SubmitHandler<ResetPasswordFormValues>>(
    async ({ password }) => {
      const { token } = queryStringToObject<{ token: string }>(search);

      await dispatch(
        resetUserPassword({ password, resetPasswordToken: token }, () => push('/')),
      );
    },
    [search, dispatch, push],
  );

  return { t, isLoading, onSubmit, formMethods };
};
