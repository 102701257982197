import VideoMakerLevelCard from 'client/components/VideoMakerLevelCard/VideoMakerLevelCard';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const VideoMakerLevelContainer: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div className="video-card-container">
      <VideoMakerLevelCard
        level="BRONZE"
        category="Downloads"
        description={`${t('videoMarkerCard.level.until')} 699 ${t(
          'videoMarkerCard.descriptionsTelechargement',
        )}`}
      />
      <VideoMakerLevelCard
        level="SILVER"
        category="Downloads"
        description={`${t('videoMarkerCard.level.between')} 700 ${t(
          'videoMarkerCard.level.and',
        )} 2499 ${t('videoMarkerCard.descriptionsTelechargement')}`}
      />
      <VideoMakerLevelCard
        level="GOLD"
        category="Downloads"
        description={`${t('videoMarkerCard.level.moreThen')} 2500 ${t(
          'videoMarkerCard.descriptionsTelechargement',
        )}`}
      />

      <VideoMakerLevelCard level="BRONZE" category="Gains" description="25" isLight={false} />
      <VideoMakerLevelCard level="SILVER" category="Gains" description="30" isLight={false} />
      <VideoMakerLevelCard level="GOLD" category="Gains" description="35" isLight={false} />
    </div>
  );
};

export default VideoMakerLevelContainer;
