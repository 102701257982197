import * as WithdrawServices from 'client/_redux/services/user';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { errorHandler } from 'client/helpers/errorHandler';
import { IThunkAction } from 'types/IThunkAction';

import { WithdrawTypes } from 'client/_redux/types/withdrawTypes';

export const withdrawSalesBalance: () => IThunkAction = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    await WithdrawServices.createWithdrawRequest()();
    dispatch({ type: WithdrawTypes.DISABLE_WITHDRAW });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: WithdrawTypes.ADD_WITHDRAW_FAILURE });
  }
  dispatch(stopLoading());
};
