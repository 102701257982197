import React from 'react';
import SEO from 'client/components/SEO';
import LoginFormSignUp from 'client/containers/LoginForm/SignUp';
import LoginFormSignIn from 'client/containers/LoginForm/SignIn';
import ReamPattern from 'client/components/SVG/ReamPattern';
import Footer from 'client/containers/Footer';
import NavBarContainer from 'client/containers/NavBarContainer';
import CompleteSignupInformation from 'client/containers/LoginForm/SignUp/CompleteSignupInformation';
import { useLogin } from './useLogin';

const Login: React.FC = () => {
  const { onSubmitSignIn, signupForm, setSignupForm, signUpData, t } = useLogin();

  return (
    <>
      <SEO title="Connexion" description="Connexion à Ream Movie" />
      <div className="">
        <NavBarContainer isDarkBackground />
      </div>

      <main
        id="content"
        role="main"
        style={{ overflow: 'hidden' }}
        className="position-relative"
      >
        <ReamPattern className="background-image-page" />

        <div className="pt-10 pb-5 d-flex  flex-column  mt-11 align-items-center">
          {signupForm ? (
            <div
              className="container"
              style={{
                maxWidth: 900,
              }}
            >
              <div className="px-2 px-md-0">
                <div className="text-primary-lighter font-size-2 font-weight-bold title-section">
                  {t('authPage.Welcome')}
                  <span className="text-input-placeholder"> {signUpData?.username}</span>
                </div>
                <div className=" h4 font-weight-normal text-secondary-lighter title-section">
                  {t('authPage.InformationToCompletSignUp')}
                </div>
                <div className="h6 font-weight-normal text-secondary-lighter title-section">
                  {t('authPage.The')} <span className="text-primary-lighter">*</span>{' '}
                  {t('authPage.RequiredFields')}
                </div>
              </div>
              <div className="d-flex justify-content-center mt-8 flex-wrap flex-lg-row">
                <CompleteSignupInformation />
              </div>
            </div>
          ) : (
            <>
              <div className="px-2 px-md-0 w-lg-40 w-90 ">
                <div className="text-primary-lighter font-size-2 font-weight-bold title-section">
                  {t('authPage.WelcomeToAuthPage')}
                </div>
                <div className=" h4 font-weight-normal text-secondary-lighter title-section mt-4">
                  {t('authPage.DescriptionToAuthPage')}
                </div>
              </div>
              <div className="d-flex w-lg-75 w-100 justify-content-center mt-8 flex-wrap flex-lg-row">
                <div className="col-lg-6 col-12 ">
                  <LoginFormSignIn onSubmit={onSubmitSignIn} />
                </div>
                <div className="col-lg-6 col-12">
                  <LoginFormSignUp
                    nextSlide={() => {
                      setSignupForm(true);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Login;
