import { components, OptionProps } from 'react-select';
import React from 'react';

import { ISelectorOption } from 'types/ISelectorOption';

const DropdownIndicator: React.FC<OptionProps<ISelectorOption, false>> = ({
  children,
  ...props
}) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <div className=" arrowSize">▼</div>
    </components.DropdownIndicator>
  );

export default DropdownIndicator;
export const styledSelect = {
  components: {
    DropdownIndicator,
  },
};
