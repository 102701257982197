import { checkLoggingStatus } from 'client/_redux/actions/auth';
import { RootState } from 'client/_redux/reducers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
export const useAppRouter = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const initiated = useSelector(({ init: { initiated } }: RootState) => initiated);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkLoggingStatus());
  }, [dispatch]);

  return {
    isLoggedIn,
    initiated,
  };
};
