import ReactSelect, { OptionTypeBase, ValueType } from 'react-select';
import * as React from 'react';
import { FieldError } from 'react-hook-form';
import { ISelectorOption } from 'types/ISelectorOption';
import { SelectComponents } from 'react-select/src/components';
import languageStyles, { errorStyles } from './languageStyle';
import CountryCodeStyle from './CountryCodeStyle';
import formStyle from './formStyle';
import cardStyle from './cardStyle';

type Variant = 'language' | 'countryCode' | 'form' | 'card';
export interface Props<T extends unknown> {
  id?: string;
  options: ISelectorOption<T>[];
  multi?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  label?: string;
  onChange: (value?: ISelectorOption<T>) => void;
  errors?: FieldError;
  value: ISelectorOption<T> | ISelectorOption<T>[];
  variant?: Variant;
  components?: Partial<SelectComponents<ISelectorOption, boolean>>;
}

const Select = <T extends unknown = unknown>({
  components,
  variant = 'language',
  id,
  options,
  multi = false,
  isClearable = false,
  isSearchable = false,
  label,
  onChange,
  errors,
  value,
}: Props<T>) => {
  const style = React.useMemo(() => {
    switch (variant) {
      case 'language':
        return languageStyles;

      case 'countryCode':
        return CountryCodeStyle;

      case 'form':
        return formStyle;

      case 'card':
        return cardStyle;

      default:
        return undefined;
    }
  }, [variant]);

  React.useEffect(() => {
    if (!multi && value instanceof Array) onChange(undefined);

    // eslint-disable-next-line
  }, [multi, value]);

  const handleChange = (event: ValueType<OptionTypeBase, boolean>): void => {
    onChange(event as ISelectorOption<T>);
  };

  return (
    <div className={`${errors ? 'u-has-error' : ''}`}>
      <ReactSelect
        components={components}
        valueKey="value"
        isMulti={multi}
        options={options}
        isClearable={isClearable}
        isSearchable={isSearchable}
        styles={errors ? errorStyles : style}
        value={value}
        onChange={handleChange}
      />
      {/* {errors && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {errors.message}
        </div>
      )} */}
    </div>
  );
};

export default Select;
