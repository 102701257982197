/* eslint-disable max-len */
import React from 'react';

const HandMoney = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 170.74 133.64" {...props}>
    <defs>
      <style>{'.cls-1{fill:#ffffff;}.cls-2{fill:#e9394d;}'}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Calque_1" data-name="Calque 1">
        <path
          className="cls-1"
          d="M97.92,29a24,24,0,0,0-8-3.55c-2-.55-3.87-1-5.56-1.42a30,30,0,0,1-3.35-1,6.68,6.68,0,0,1-2.64-1.58,2.14,2.14,0,0,1-.43-.73,4,4,0,0,1-.2-1.4,4.62,4.62,0,0,1,2.12-3.84,9.33,9.33,0,0,1,5.19-1.23c3.81,0,6.13.91,7.27,1.81a3.83,3.83,0,0,1,1.17,1.41,4.3,4.3,0,0,1,.39,1.85h9.48a13.64,13.64,0,0,0-1.31-5.94A14,14,0,0,0,95.44,6.8a20,20,0,0,0-4.85-1.6V0H81.11V5a18.61,18.61,0,0,0-3.4.92,14.62,14.62,0,0,0-7.26,5.83,14.23,14.23,0,0,0-2.16,7.48A12.8,12.8,0,0,0,70.09,26,12.65,12.65,0,0,0,74,30a26.26,26.26,0,0,0,7.81,3.15c1.92.51,3.78.92,5.43,1.38a26.09,26.09,0,0,1,3.25,1.1,6.41,6.41,0,0,1,2.64,1.8,3.46,3.46,0,0,1,.55,1.05,6,6,0,0,1,.25,1.85,4.81,4.81,0,0,1-.5,2.38,2.88,2.88,0,0,1-.82,1,7.08,7.08,0,0,1-2.76,1.23,20.3,20.3,0,0,1-4.75.5c-2.9,0-4.75-.8-5.78-1.69a4.19,4.19,0,0,1-1.14-1.46,4.7,4.7,0,0,1-.39-1.93H68.29a13.87,13.87,0,0,0,7.4,12.36,18.25,18.25,0,0,0,5.42,1.83v5.16h9.48V54.38c.26-.05.53-.06.78-.12a19.1,19.1,0,0,0,4.45-1.49,13.42,13.42,0,0,0,5.51-4.83,14,14,0,0,0,2.07-7.64,14.37,14.37,0,0,0-1.69-7A13.2,13.2,0,0,0,97.92,29Z"
        />
        <path
          className="cls-2"
          d="M159.32,65a11.33,11.33,0,0,0-5.12,1.26l-4.15,2.53a11.31,11.31,0,0,0-15.58-5.58l-6.54,3.7a11.27,11.27,0,0,0-15.3-5L94.16,73.18l1.74.55a30.9,30.9,0,0,1,8.32,3.84,18.25,18.25,0,0,1,6.35,7.15,15.8,15.8,0,0,1,1.51,6.8,17.57,17.57,0,0,1-17.56,17.56h-.23l-9.24-.91-29.57-3.79h0L56.68,95l29.44,3.78,8.52.83a8,8,0,0,0,8-8.06,6.07,6.07,0,0,0-.59-2.73,7.65,7.65,0,0,0-1.75-2.29,18.41,18.41,0,0,0-7.11-3.69c-4.11-1.25-9.77-3.18-15.81-5.25s-12.42-4.26-17.85-6c-3.62-1.17-6.82-2.15-9.12-2.74a33.8,33.8,0,0,0-7.17-.09c-2.37.34-5.41.83-8.67,1.37l-2.91.48c-2.21-7.42-8.26-12.8-15.35-12.8H0v75.88H16.26c6.47,0,12.07-4.48,14.68-10.91,18.54,4,50.53,10.6,56.67,10.6,10.94,0,63.55-37.43,78.56-47.77a11,11,0,0,0,4.57-9.14A11.42,11.42,0,0,0,159.32,65Z"
        />
      </g>
    </g>
  </svg>
);

export default HandMoney;
