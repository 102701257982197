import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useForgetPassword } from './useForgetPassword';

const ForgetPassword = () => {
  const { onSubmit, formMethods, isLoading, t } = useForgetPassword();

  return (
    <div className=" container mx-auto mt-5">
      <span className="h1 text-center d-flex justify-content-center">
        {t('forgetPassword.title')}
      </span>
      <FormProvider {...formMethods}>
        <form
          className="d-flex flex-column mt-5"
          onSubmit={formMethods.handleSubmit(onSubmit)}
        >
          <div className="w-xl-50 w-100 mx-auto ">
            <Input
              className="bg-light-grey text-center"
              name="email"
              placeholder={t('LoginForm.User')}
              validate={Validators([{ validation: 'required' }])}
            />
            <div className="d-flex justify-content-end ">
              <button
                disabled={isLoading}
                type="submit"
                className="btn btn-sm btn-primary  mt-5"
              >
                {t('misc.submit')}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ForgetPassword;
