/* eslint-disable no-nested-ternary */
import React, { ReactNode, useEffect, useState } from 'react';

export interface IModalProps {
  isOpen?: boolean;
  modalHead?: string;
  modalContent?: string;
  footer?: ReactNode;
  closeModal: () => void;
  isVideoCard?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  isOpen = false,
  closeModal,
  modalHead,
  footer,
  children,
  isVideoCard,
}) => {
  const [isDelayedChange, setIsDelayedChange] = useState<boolean>(false);
  const [isOpening, setIsOpening] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen === true) setIsOpening(true);
    else setIsOpening(false);
    setTimeout(() => {
      setIsDelayedChange(isOpen);
    }, 150);
  }, [isOpen]);

  return (
    <div className={`${isOpening ? 'modal-open' : ''}`}>
      <div
        data-testid="modalDisplay"
        style={{ display: isOpening ? 'block' : isDelayedChange ? 'block' : 'none' }}
        className={
          isVideoCard && !isOpening
            ? 'fade '
            : isVideoCard && isDelayedChange
            ? 'fade show'
            : !isOpening
            ? 'modal fade'
            : isDelayedChange
            ? 'modal fade show'
            : 'modal fade'
        }
      >
        <div
          data-testid="modalBg"
          role="button"
          onClick={closeModal}
          onKeyDown={closeModal}
          tabIndex={0}
          className="dark-overlay"
        >
          {' '}
        </div>
        <div className={`${isVideoCard ? 'modal-container-input' : 'modal-container'}`}>
          <div className="modal-header">
            <h5>{modalHead}</h5>
            <button
              type="button"
              className="modal-close-button"
              aria-label="Close"
              onClick={(e) => {
                e.stopPropagation();
                closeModal();
              }}
            >
              <span className="font-size-3 font-weight-bold" aria-hidden="true">
                ×
              </span>
            </button>
          </div>
          {children}
          {footer}
        </div>
      </div>
    </div>
  );
};

export default Modal;
