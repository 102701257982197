/* eslint-disable max-len */
import React from 'react';

const Mouse = (props: React.SVGProps<SVGSVGElement>) => (
  <svg id="Calque_1" x={0} y={0} viewBox="0 0 100 100" xmlSpace="preserve" {...props}>
    <defs>
      <style>{'.cls-1{fill:#ea384d;}'}</style>
    </defs>

    <style />
    <switch>
      <g>
        <path
          className="cls-1"
          style={{ stroke: '#ea384d' }}
          d="M58.46 24.51c4.96 0 9 4.04 9 9v32.98c0 4.96-4.04 9-9 9H43.8c-4.96 0-9-4.04-9-9V33.51c0-4.96 4.04-9 9-9h14.66m0-2H43.8c-6.08 0-11 4.92-11 11v32.98c0 6.08 4.92 11 11 11h14.65c6.08 0 11-4.92 11-11V33.51c.01-6.07-4.92-11-10.99-11z"
        />
        <path
          style={{
            stroke: '#ea384d',
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeMiterlimit: 10,
          }}
          d="M51.16 24.1v18.23"
        />
        <path
          className="cls-1"
          d="M53.33 50a2.2 2.2 0 0 1-4.4 0c0-1.22.98-2.2 2.2-2.2 1.21 0 2.2.99 2.2 2.2z"
          style={{
            stroke: '#ea384d',
          }}
        />
      </g>
    </switch>
  </svg>
);

export default Mouse;
