/* eslint-disable max-len */
import React from 'react';

const Search = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    style={{ paddingTop: '4px', paddingLeft: '5px', paddingRight: '5px' }}
    width="3rem"
    height="3.2rem"
    viewBox="-100 -100 600 600"
    fill="white"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M509.007 448.319 353.309 292.62l-.152-.152-32.153-32.154c-3.99-3.99-10.459-3.99-14.448 0L290.66 276.21l-19.103-19.103c53.349-60.825 51.03-153.761-6.979-211.78l-.001-.001c-60.448-60.436-158.802-60.434-219.25.002-60.436 60.447-60.436 158.801.001 219.249 30.224 30.217 69.923 45.327 109.624 45.327 36.511 0 73.016-12.79 102.156-38.348l19.102 19.102-15.896 15.896c-3.99 3.99-3.99 10.457-.001 14.447l32.223 32.233c.093.093.188.184.283.274l155.5 155.5a10.217 10.217 0 0 0 14.448 0l46.24-46.24a10.217 10.217 0 0 0 0-14.449zM250.133 250.123l-.004.004-.004.004c-52.481 52.467-137.87 52.465-190.349-.003-52.47-52.48-52.47-137.873-.001-190.353 26.24-26.236 60.709-39.354 95.177-39.354s68.936 13.118 95.175 39.352c52.471 52.48 52.472 137.87.006 190.35zm31.853 63.655 31.793-31.793 17.785 17.785-31.796 31.796-17.782-17.788zm173.557 173.557L314.22 346.011l31.792-31.792 141.323 141.323-31.792 31.793z" />
    <path d="M232.364 77.53c-42.683-42.683-112.14-42.684-154.835 0-42.683 42.693-42.682 112.15 0 154.833 20.681 20.682 48.176 32.072 77.421 32.072s56.738-11.389 77.412-32.07c20.682-20.677 32.073-48.17 32.073-77.415 0-29.245-11.389-56.74-32.071-77.42zm-14.449 140.385c-16.817 16.823-39.178 26.087-62.965 26.087-23.786 0-46.15-9.264-62.973-26.087-34.717-34.716-34.716-91.211 0-125.936 17.365-17.361 40.165-26.038 62.973-26.038 22.801 0 45.609 8.681 62.965 26.037 16.823 16.822 26.087 39.186 26.087 62.972 0 23.785-9.263 46.145-26.087 62.965z" />
    <path d="M219.78 146.023c-.766-5.591-5.92-9.504-11.51-8.734-5.591.766-9.5 5.919-8.734 11.51 1.932 14.091-2.721 27.931-12.764 37.972-3.991 3.99-3.991 10.459-.002 14.449a10.186 10.186 0 0 0 7.225 2.993c2.614 0 5.229-.997 7.223-2.991 14.605-14.601 21.37-34.719 18.562-55.199zM198.423 109.538c-13.148 0-13.169 20.433 0 20.433 13.148.001 13.169-20.433 0-20.433z" />
  </svg>
);

export default Search;
