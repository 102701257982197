/* eslint-disable max-len */
import React from 'react';

const HandScroller = (props: React.SVGProps<SVGSVGElement>) => (
  <svg id="Calque_1" x={0} y={0} viewBox="0 0 100 100" xmlSpace="preserve" {...props}>
    <defs>
      <style>{'.cls-1{fill:#ea384d;}'}</style>
    </defs>

    <style />
    <switch>
      <g>
        <path
          className="cls-1"
          d="M69.09 50.15c-.74 0-1.43.22-2.03.57-.08-2.14-1.83-3.85-3.98-3.85h-2c-.87 0-1.67.29-2.33.76a4.006 4.006 0 0 0-3.69-2.45h-2c-.73 0-1.4.21-1.99.55V31.06c2.7-1.64 4.4-4.47 4.56-7.64h7.36l-3.07 2.86.68.73 4.4-4.09-4.4-4.09-.68.73 3.07 2.86h-7.35c-.26-5.03-4.43-9.05-9.53-9.05-5.09 0-9.25 4-9.52 9.02h-7.36l3.08-2.86-.68-.73-4.4 4.09 4.4 4.09.68-.73-3.07-2.86h7.36a9.445 9.445 0 0 0 4.47 7.62v24.47l-3.13-1.88c-.9-.62-1.88-.9-2.82-.9-3 0-5.7 2.8-4.87 6.2l3.95 12.59c.42 1.33.97 2.61 1.65 3.82l3.26 5.79c1.69 3.4 6.16 5.54 9.95 5.54h12.17c7.66 0 13.86-6.21 13.86-13.86V56.14a6.003 6.003 0 0 0-6-5.99zM37.56 22.92c0-4.72 3.84-8.55 8.55-8.55s8.55 3.84 8.55 8.55c0 2.81-1.35 5.38-3.6 6.97v-3.66c0-2.76-2.24-5-5-5s-5 2.24-5 5v3.58a8.498 8.498 0 0 1-3.5-6.89zm35.52 39.77v10.08c0 6.54-5.32 11.86-11.86 11.86H49.06c-3.34 0-6.92-1.95-8.16-4.43-.01-.03-.03-.06-.05-.09l-3.26-5.79c-.61-1.09-1.11-2.25-1.49-3.44l-3.93-12.52c-.26-1.15.15-1.98.54-2.48.59-.75 1.49-1.19 2.4-1.19.59 0 1.16.18 1.69.55.03.02.07.05.11.07l3.13 1.88c.32.19.67.29 1.03.29.34 0 .68-.09.99-.26.63-.35 1.01-1.02 1.01-1.74V26.23c0-1.65 1.35-3 3-3s3 1.35 3 3v19.5c0 .71.38 1.38 1 1.73.31.18.65.27 1 .27s.69-.09 1-.27c.32-.19.65-.28.99-.28h2c.81 0 1.53.48 1.84 1.22.24.56.72.98 1.3 1.15a2.016 2.016 0 0 0 1.71-.31c.24-.17.64-.38 1.16-.38h2c1.07 0 1.94.85 1.99 1.92.03.7.42 1.34 1.04 1.68.3.16.63.24.96.24.35 0 .71-.09 1.02-.28.33-.19.66-.29 1.01-.29 2.21 0 4 1.79 4 4v6.56z"
        />
      </g>
    </switch>
  </svg>
);

export default HandScroller;
