import * as React from 'react';
import { useTranslation } from 'react-i18next';

import CRUDDataTable from 'dashboard/containers/CRUDDataTable';
import { getAllVideosDashboard } from 'dashboard/_redux/actions/videos';
import { usePostList } from './usePostList';

const PostList: React.FC = () => {
  const { videos, loaded, dataTableRef, columns } = usePostList();

  const [t] = useTranslation();

  return (
    <div className="card card-body pt-10">
      <CRUDDataTable
        myRef={dataTableRef}
        loading={!loaded}
        data={videos}
        title={t('dashboard.videosPage.title')}
        columns={columns}
        fetchDataFn={getAllVideosDashboard}
      />
    </div>
  );
};

export default PostList;
