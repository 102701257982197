import FormSelect from 'client/components/Form/FormSelect';
import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { ISelectorOption } from 'types/ISelectorOption';
import { useNotificationPage } from './useNotificationPage';
export const USER_ROLE_OPTIONS: ISelectorOption<number>[] = [
  { value: -1, label: 'Tous les utilisateurs' },
  { value: 1, label: 'VideoGrapher' },
  { value: 0, label: 'Utilisateur' },
];

const NotificationPage = () => {
  const { onSubmit, formMethods, handleSubmit, t } = useNotificationPage();

  return (
    <div className="col-8 card justify-content-center align-items-center">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className="col-md-6">
          <div className="card-header align-content-center">
            <h3 className="text-bold">Notification</h3>
          </div>
          <div className="card-body">
            <div className="form-group bg-secondary mb-4">
              <FormSelect
                options={USER_ROLE_OPTIONS}
                name="to"
                validate={Validators([{ validation: 'required' }])}
                defaultValue={USER_ROLE_OPTIONS[0]}
              />
            </div>
            <div className="form-group">
              <label>{t('dashboard.NotificationPage.notification.title')}</label>
              <Input name="title" validate={Validators([{ validation: 'required' }])} />
            </div>
            <div className="form-group">
              <label>{t('dashboard.NotificationPage.notification.body')}</label>
              <Input
                type="textarea"
                name="body"
                validate={Validators([{ validation: 'required' }])}
              />
            </div>
          </div>
          <button className="btn btn-dark btn-xs m-1 w-50 align-self-center m-5" type="submit">
            {t('dashboard.NotificationPage.send')}
          </button>
        </form>
      </FormProvider>
    </div>
  );
};

export default NotificationPage;
