import React from 'react';
import { FieldError } from 'react-hook-form';
import { useGoogleMapsAutoCompleteInput } from './useGoogleMapsAutoCompleteInput';

export interface IGoogleMapsInputProps {
  name: string;
  errors?: FieldError;

  inputForUpdate?: boolean;
  className?: string;
  onChange: (event: string | Record<string, unknown>) => void;

  value: { location: string; lat: number; lng: number };
}

const GoogleMapsAutoCompleteInput = ({
  name,
  onChange,
  inputForUpdate,
  errors,
  value,
  className,
  ...props
}: IGoogleMapsInputProps) => {
  const { handleChange, autoCompleteRef, t } = useGoogleMapsAutoCompleteInput(onChange);

  return (
    <>
      {inputForUpdate ? (
        <input
          name={name}
          {...props}
          className="form-group mb-0"
          ref={autoCompleteRef}
          onChange={(event) => handleChange(event.target.value)}
          placeholder={t('addVideoDashboard.countryAndCity')}
        />
      ) : (
        <>
          <div
            className="d-flex justify-content-between align-items-center"
            data-testid="inputFormGroup"
          >
            <input
              style={{ font: 'normal normal 300 14px/17px Inter' }}
              name={name}
              {...props}
              className={`${className} is-video-input font-weight-bold`}
              ref={autoCompleteRef}
              onChange={(event) => handleChange(event.target.value)}
              placeholder={t('addVideoDashboard.countryAndCity')}
              value={value?.location}
            />

            <i className="fas fa-chevron-right mt-1 chevron-i " />
          </div>
        </>
      )}
      {errors && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {errors.message}
        </div>
      )}
    </>
  );
};

export default GoogleMapsAutoCompleteInput;
