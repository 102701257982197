import { useForm, SubmitHandler } from 'react-hook-form';
import { appendData } from 'client/_redux/actions/auth';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ISelectorOption } from 'types/ISelectorOption';

export interface ISignUpFormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
  username: string;
}
export interface ISignUpSlideOne {
  firstName: string;
  lastName: string;
  companyName: string;

  registrationNumber: string;

  phoneCountryCode: {
    label: string;
    value: string;
  };
  emailCompany: string;
  phoneNumber: string;

  phone: ISelectorOption;
}
export interface ISignUpSlideTwo {
  contrat: boolean;
  condition: boolean;
  download: boolean;
}
export interface ISignUpSlideThree {
  videos: File[];
  idCard: File[];

  videoLinks: string[];
}

export type ISignUp = ISignUpFormValues &
  ISignUpSlideOne &
  ISignUpSlideTwo &
  ISignUpSlideThree;
export type ISignUpSection =
  | ISignUpFormValues
  | ISignUpSlideOne
  | ISignUpSlideTwo
  | ISignUpSlideThree;
export const useSignUp = <T extends ISignUpSection>(nextSlide?: () => void) => {
  const formMethods = useForm<T>({ shouldFocusError: true });
  const dispatch = useDispatch();
  const { handleSubmit, watch } = formMethods;
  const _onSubmit: SubmitHandler<T> = (data) => {
    dispatch(
      appendData(data as ISignUpFormValues, t, () => {
        window.scrollTo({ top: 0, behavior: 'auto' });
        nextSlide?.();
      }),
    );
  };

  const password = watch('password') || '';
  const { t } = useTranslation();

  return {
    formMethods,
    handleSubmit,
    _onSubmit,
    password,
    t,
  };
};
