import React from 'react';
import { format } from 'date-fns';
import { useModalReport } from './useModalReport';

interface IProps {
  openReport: () => void;
}

const ModalReport: React.FC<IProps> = ({ openReport }) => {
  const { textTranslations, userReports } = useModalReport();

  return (
    <div className="modal-body align-items-center text-dark ">
      <div onClick={openReport} className="d-flex flex-row justify-content-start ">
        <span className=" font-weight-bold h5 title-Report">
          <i className="fas fa-chevron-left mr-1" />
          {textTranslations.ModalTitle}
        </span>
      </div>
      <div className="mx-3 py-3 px-2 text-start report-text">{textTranslations.alertMsg}</div>

      <ul className="p-0 report-list">
        {userReports?.length ? (
          userReports.map((report) => (
            <li key={report.url} className="d-flex flex-row align-items-center">
              {textTranslations.reportext}
              {report.createdAt && format(report.createdAt, 'MMM YYYY')}
              <a target="_blank" href={report.url}>
                <i className="fas fa-long-arrow-alt-down m-2 h6 text-primary" />
              </a>
            </li>
          ))
        ) : (
          <li>
            <span className=" text-center text-primary p-3 m-auto ">
              {textTranslations.alertMsgReport}
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ModalReport;
