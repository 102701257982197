import { applyMiddleware, createStore, Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'js-cookie';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers, { RootState } from 'client/_redux/reducers';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  whitelist: ['auth', 'dashboardAuthReducer'],
  storage: new CookieStorage(Cookies, {
    expiration: {
      default: 365 * 86400, // Cookies expire after one year
    },
  }),
};

const rootReducer = persistReducer<RootState>(persistConfig, reducers);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createReduxPersistor = (store: Store, cb?: () => any) =>
  persistStore(store, {}, cb);

const configureStore = (preloadedState: RootState | Record<string, unknown> = {}) =>
  createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(thunk)));

export default configureStore;
