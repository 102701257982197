export const dataTableStyles = {
  tableWrapper: {
    style: {
      display: 'table',
    },
  },
  header: {
    style: {
      fontSize: '40px',
      fontWeight: 'bold',
      minHeight: '56px',
      paddingTop: '20px',
    },
  },
  subHeader: {
    style: {
      minHeight: '52px',
      paddingBottom: '15px',
      borderBottomWidth: '2px',
    },
  },
  pagination: {
    style: {
      fontSize: '20px',
      marginTop: '10px',
      minHeight: '56px',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
    },
  },
};
