import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

type AutoCompleteType = Window &
  typeof globalThis & {
    google: {
      maps: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        places: { Autocomplete: any };
      };
    };
    setFields: (fields: string[]) => void;
    addListener: (changes: string, handlePlaceSelect: () => void) => void;
    getPlace: () => {
      formatted_address: string;
      geometry: { location: { lat: () => void; lng: () => void } };
      address_components: { long_name: string; short_name: string; types: string[] }[];
    };
  };
export const useGoogleMapsAutoCompleteInput = (
  onChange: (event: string | Record<string, unknown>) => void,
) => {
  let autoComplete: AutoCompleteType;
  const autoCompleteRef = useRef(null);
  const [t] = useTranslation();
  const handleScriptLoad = (
    updateQuery: (event: string | Record<string, unknown>) => void,
    autoCompleteRef: React.MutableRefObject<null>,
  ) => {
    if (typeof window !== 'undefined' && (window as AutoCompleteType).google) {
      autoComplete = new (window as AutoCompleteType).google.maps.places.Autocomplete(
        autoCompleteRef.current,
      );
      autoComplete.setFields(['address_components', 'formatted_address', 'geometry']);
      autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery));
    }
  };

  const handlePlaceSelect = async (
    updateQuery: (event: string | Record<string, unknown>) => void,
  ) => {
    const addressObject = autoComplete.getPlace();

    if (!addressObject.geometry || !addressObject.geometry.location) return;
    const lat = addressObject.geometry.location.lat();
    const lng = addressObject.geometry.location.lng();
    const location = addressObject.formatted_address;
    const state = addressObject.address_components.find((address) =>
      address.types.includes('administrative_area_level_1'),
    )?.long_name;
    const country = addressObject.address_components.find((address) =>
      address.types.includes('country'),
    )?.long_name;

    updateQuery({ location, lng, lat, country, state });
  };

  const handleChange = (event: string) => {
    onChange(event);
  };

  useEffect(() => {
    handleScriptLoad(onChange, autoCompleteRef);
  });

  return { handleChange, autoCompleteRef, t };
};
