/* eslint-disable max-len */
import * as React from 'react';

const Setting = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41.08" {...props}>
    <defs>
      <style>
        {
          '.cls-1,.cls-2,.cls-3.cls-1,.cls-2{stroke-miterlimit:10;}.cls-1,.cls-3{fill-rule:evenodd;}.cls-2,.cls-3{fill:none;}.cls-2{stroke-width:2px;}.cls-3{stroke-linecap:round;stroke-linejoin:round;}'
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Calque_1" data-name="Calque 1">
        <path
          className="cls-1"
          d="M39.52,23.92l-2-1.76a1,1,0,0,1-.33-.74V19.49a1,1,0,0,1,.33-.73L39.48,17a3,3,0,0,0,.74-3.34l-1-2.37a3,3,0,0,0-2.89-1.81l-2.71.15a1,1,0,0,1-.8-.33L31.54,7.82a1,1,0,0,1-.25-.7l.12-2.55a3,3,0,0,0-1.83-2.88L27.2.72a3,3,0,0,0-3.32.77l-1.77,2a1,1,0,0,1-.73.33H19.45a1,1,0,0,1-.73-.32L17,1.52A3,3,0,0,0,13.62.78l-2.37,1a3,3,0,0,0-1.8,2.89l.14,2.65a1,1,0,0,1-.29.75L7.94,9.44a1,1,0,0,1-.74.29L4.56,9.6a3,3,0,0,0-2.87,1.84l-1,2.38a3,3,0,0,0,.76,3.33l2,1.77a1,1,0,0,1,.33.74v1.93a1,1,0,0,1-.33.73L1.52,24.1a3,3,0,0,0-.74,3.33l1,2.37a3,3,0,0,0,2.89,1.82l2.65-.15a1,1,0,0,1,.75.29l1.36,1.36a1,1,0,0,1,.29.75L9.59,36.5a3,3,0,0,0,1.83,2.88l2.38,1a2.89,2.89,0,0,0,1.11.22,3,3,0,0,0,2.21-1l1.77-2a1,1,0,0,1,.73-.32h1.93a1,1,0,0,1,.73.32l1.77,2a3,3,0,0,0,3.33.74l2.37-1a3,3,0,0,0,1.81-2.9l-.15-2.64A1,1,0,0,1,31.7,33l1.36-1.36a1,1,0,0,1,.74-.29l2.63.12a3,3,0,0,0,2.88-1.84l1-2.38A3,3,0,0,0,39.52,23.92Zm-1.06,2.59-1,2.38a1,1,0,0,1-1,.61l-2.64-.12a2.93,2.93,0,0,0-2.22.86L30.3,31.61a3,3,0,0,0-.86,2.26l.15,2.64a1,1,0,0,1-.6,1l-2.37,1a1,1,0,0,1-1.11-.24l-1.78-1.95a2.92,2.92,0,0,0-2.18-1H19.62a2.94,2.94,0,0,0-2.2,1l-1.77,2a1,1,0,0,1-1.11.26l-2.37-1a1,1,0,0,1-.61-1L11.68,34a3,3,0,0,0-.86-2.23L9.45,30.36a2.94,2.94,0,0,0-2.09-.87H7.2l-2.64.14a1,1,0,0,1-1-.6l-1-2.37a1,1,0,0,1,.24-1.11l2-1.78a3,3,0,0,0,1-2.19V19.66a3,3,0,0,0-1-2.21l-2-1.77a1,1,0,0,1-.26-1.11l1-2.38a1,1,0,0,1,.95-.61l2.64.12a3,3,0,0,0,2.22-.86L10.7,9.47a3,3,0,0,0,.86-2.26l-.15-2.65a1,1,0,0,1,.6-1l2.37-1a1,1,0,0,1,1.11.25L17.27,4.8a3,3,0,0,0,2.18,1h1.93a2.94,2.94,0,0,0,2.2-1l1.77-2a1,1,0,0,1,1.11-.25l2.37,1a1,1,0,0,1,.61,1L29.33,7a2.93,2.93,0,0,0,.74,2.11l1.29,1.45a3,3,0,0,0,2.38,1l2.71-.15a1,1,0,0,1,1,.61l1,2.37a1,1,0,0,1-.24,1.11L36.21,17.3a3,3,0,0,0-1,2.19v1.93a3,3,0,0,0,1,2.21l2,1.77A1,1,0,0,1,38.46,26.51Z"
        />
        <circle className="cls-2" cx={20.5} cy={20.54} r={7.5} />
        <path className="cls-3" d="M30.5,20.54a10,10,0,1,1-12.12-9.78" />
        <path className="cls-3" d="M20.5,10.54a10,10,0,0,1,9,5.68" />
      </g>
    </g>
  </svg>
);

export default Setting;
