import { GoogleMapsInput } from 'client/components/Form/GoogleMapsInput';
import FormSelect from 'client/components/Form/FormSelect';
import Input from 'client/components/Form/Input';
import { styledSelect } from 'client/components/Form/Select/MenuListArrow';
import VideoCardFormFragment from 'client/components/VideoCardFormFragment';
import Validators from 'client/helpers/formValidator';

import React from 'react';
import { SelectComponents } from 'react-select/src/components';
import { ISelectorOption } from 'types/ISelectorOption';
import { useVideoDetails } from './useVideoDetails';

const component = styledSelect.components as Partial<
  SelectComponents<ISelectorOption, boolean>
>;

const VideoDetails = () => {
  const { t, cinematicStyleOptions, videoTypeOptions, musicTypeOptions } = useVideoDetails();

  return (
    <div className="container-fluid col-lg-6 col-sm-12 pl-md-6 pr-sm-0">
      <VideoCardFormFragment name="tags" title={t('addVideoDashboard.tags')} isText isAddCard>
        <Input
          name="tags"
          placeholder={t('addVideoDashboard.tags')}
          isVideo
          validate={Validators([{ validation: 'tags' }])}
        />
      </VideoCardFormFragment>
      <VideoCardFormFragment
        name="name"
        title={t('addVideoDashboard.videoName')}
        isText
        isAddCard
      >
        <Input
          name="name"
          placeholder={t('addVideoDashboard.name')}
          isVideo
          validate={Validators([{ validation: 'required' }])}
        />
      </VideoCardFormFragment>

      <VideoCardFormFragment
        name="countryCity"
        title={t('addVideoDashboard.countryAndCity')}
        isText
        isAddCard
      >
        <GoogleMapsInput
          validate={Validators([{ validation: 'required' }, { validation: 'validLocation' }])}
          name="countryCity"
        />
      </VideoCardFormFragment>

      <VideoCardFormFragment
        name="description"
        title={t('addVideoDashboard.description')}
        isText
        isAddCard
      >
        <Input
          name="description"
          placeholder={t('misc.description')}
          isVideo
          validate={Validators([{ validation: 'required' }])}
        />
      </VideoCardFormFragment>
      <VideoCardFormFragment
        name="cinematicStyle"
        title={t('addVideoDashboard.cinematographyStyle')}
        isAddCard
      >
        <FormSelect
          defaultValue={{ label: 'Documentary', value: 'DOCUMENTARY' }}
          name="cinematicStyle"
          components={component}
          variant="form"
          options={cinematicStyleOptions}
          validate={Validators([{ validation: 'required' }])}
        />
      </VideoCardFormFragment>
      <VideoCardFormFragment
        name="musicType"
        title={t('addVideoDashboard.musicType')}
        isAddCard
      >
        <FormSelect
          defaultValue={{ label: 'Soul', value: 'SOUL' }}
          name="musicType"
          components={component}
          variant="form"
          options={musicTypeOptions}
          validate={Validators([{ validation: 'required' }])}
        />
      </VideoCardFormFragment>
      <VideoCardFormFragment
        name="videoType"
        title={t('addVideoDashboard.videoType')}
        isAddCard
      >
        <FormSelect
          defaultValue={videoTypeOptions[0]}
          name="videoType"
          components={component}
          variant="form"
          options={videoTypeOptions}
          validate={Validators([{ validation: 'required' }])}
        />
      </VideoCardFormFragment>
      <div className="row justify-content-center">
        <button
          type="submit"
          className="section-button alt-primary mt-2 pt-0 video-publish-button text-oxygen "
          style={{ zIndex: 0 }}
        >
          {t('addVideoDashboard.publish')}
        </button>
      </div>
    </div>
  );
};

export default VideoDetails;
