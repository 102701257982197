export enum AuthTypes {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',
  SIGNIN_SUCCESS = 'SIGNIN_SUCCESS',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  APPEND_SIGN_UP_DATA = 'APPEND_SIGN_UP_DATA',
  SET_USER = 'SET_USER',
  RESET_USER = 'RESET_USER',
  USER_LOGGED_IN = 'USER_LOGGED_IN',
  USER_LOGGED_OUT = 'USER_LOGGED_OUT',
  LOGOUT = 'LOGOUT',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_AVATAR = 'UPDATE_AVATAR',
  SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS',
}
