import React from 'react';
import { NavLink } from 'react-router-dom';
interface IProps {
  text: string;
  className?: string;

  page: string;
}

const NavBarElement: React.FC<IProps> = ({ text, className, page }) => (
  <NavLink
    isActive={(_match, location) => location.pathname === page}
    activeClassName="text-primary"
    className={`text-oxygen font-weight-bold font-size-1 nav-bar-link-container mb-1 nav-bar-link ${
      className || ''
    }`}
    to={page}
  >
    {text}
  </NavLink>
);

export default NavBarElement;
