export enum starColors {
  goldStar = '#CD7F32',
  blueStar = '#778899',
  redStar = '#e93a4e',
}

export enum trophyColors {
  white = '#fff',
  black = '#101010',
}
