import { updateProfile } from 'client/_redux/actions/auth';
import { useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { IUserFormUpdate } from 'types/IUserFormUpdate';

interface IRowMainBodyFormValues {
  onSubmit: SubmitHandler<IRowMainBodyFormValues>;
  email: string;
  password: string;
}

type UseInputRowProps = {
  defaultvalue?: string | boolean;
  name: string;
};

const useInputRow = ({ defaultvalue = '', name }: UseInputRowProps) => {
  const myInputRef = useRef<HTMLInputElement>(null);
  const [isModifing, setIsModifing] = useState(false);
  const formMethods = useForm<IRowMainBodyFormValues>({
    shouldFocusError: true,
    defaultValues: { [name]: defaultvalue },
  });
  const startModifing = () => {
    setIsModifing(true);
  };
  const stopModifing = () => {
    setIsModifing(false);
  };
  const { watch } = formMethods;
  const inputValue: string | { label: string; value: string } = watch(name);
  const { handleSubmit } = formMethods;
  const dispatch = useDispatch();

  const _onSubmit: SubmitHandler<IUserFormUpdate> = (data) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!data?.[name]?.label) dispatch(updateProfile(data as IUserFormUpdate));
    stopModifing();
  };

  return {
    defaultvalue,
    handleSubmit,
    _onSubmit,
    myInputRef,
    isModifing,
    formMethods,
    startModifing,
    stopModifing,
    inputValue: (inputValue as { label: string; value: string })?.label
      ? (inputValue as { label: string; value: string }).label
      : inputValue,
    watch,
  };
};

export default useInputRow;
