import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerNotificationToken } from 'client/_redux/actions/auth';
import { RootState } from 'client/_redux/reducers';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { toast } from 'react-toastify';
import NotificationToast from 'client/components/NotificationToast';
const firebaseConfig = {
  apiKey: 'AIzaSyALjqdGOmlqw3ChTkfBaAUGZn4wZT3JlEE',
  authDomain: 'ream-2f65f.firebaseapp.com',
  projectId: 'ream-2f65f',
  storageBucket: 'ream-2f65f.appspot.com',
  messagingSenderId: '732598157878',
  appId: '1:732598157878:web:3c00b356dfb4e7e9910e97',
  measurementId: 'G-E20ZTS6GBS',
};
const { FIREBASE_VAPID_KEY } = process.env;
const publicKey = FIREBASE_VAPID_KEY;

const windowExist = typeof window !== 'undefined' && 'serviceWorker' in navigator;

const useNotification = () => {
  const { currentUser } = useSelector(({ auth: { currentUser } }: RootState) => ({
    currentUser,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!windowExist || !currentUser || Notification.permission === 'denied') return;
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    getToken(messaging, { vapidKey: publicKey }).then((el) =>
      dispatch(registerNotificationToken(el)),
    );
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (windowExist) {
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);

      onMessage(messaging, (payload) => {
        payload.notification &&
          toast(
            <NotificationToast
              body={payload.notification.body}
              title={payload.notification.title}
            />,
            {
              type: 'dark',
              pauseOnHover: true,
              progressStyle: { color: 'red' },
              autoClose: 10 * 1000,
            },
          );
      });
    }
  }, []);
};

export default useNotification;
