import * as React from 'react';
import Search from '../SVG/Search';
type InputOrAreaProps = React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>;

export interface ISearchInputProps extends InputOrAreaProps {
  name: string;
  placeHolder: string;
}
const SearchInput: React.FC<ISearchInputProps> = ({ name, placeHolder, onChange }) => (
  <div className="bg-primary row rounded no-gutters search-input-margin-top">
    <div className="search-icon px-0">
      <Search />
    </div>
    <input
      placeholder={placeHolder}
      name={name}
      className="is-search-input py-3 change"
      onChange={onChange}
    />
  </div>
);

export default SearchInput;
