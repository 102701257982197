import { RootState } from 'client/_redux/reducers';

import { getVideoDashboardById, updatePostDashboard } from 'dashboard/_redux/actions/videos';
import { IVideoFormValues } from 'dashboard/_redux/reducers/videos';
import { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const usePostDetailsDashboard = () => {
  const { id } = useParams<{ id: string }>();
  const [t] = useTranslation();
  const selectedVideo = useSelector((state: RootState) => state.dashboardVideos.selectedVideo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id || typeof id !== 'string') return;

    dispatch(getVideoDashboardById(id));
  }, [dispatch, id]);

  const onSubmit: SubmitHandler<IVideoFormValues> = (values) => {
    dispatch(updatePostDashboard(id, values));
  };

  return {
    selectedVideo,
    onSubmit,
    t,
  };
};
