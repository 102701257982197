import FormImagePicker from 'client/components/Form/FormImagePicker';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { useTranslation } from 'react-i18next';

const VideoPicker: React.FC = () => {
  const [t] = useTranslation();

  return (
    <>
      <div className="video-picker align-items-center">
        <FormImagePicker
          name="video"
          label={`${t('imagePicker.dragPrompt')}`}
          dropzoneClassName="u-dropzone justify-content-center align-items-center"
          labelClassName="text-primary-lighter"
          placeholderClassName="fSize"
          className="video-picker-button bg-secondary border-primary"
          acceptedExtensions="video/mp4 , video/quicktime , video/avi"
          validate={Validators([{ validation: 'required' }])}
        />

        <FormImagePicker
          name="video"
          label={t('addVideoDashboard.addVideo')}
          dropzoneClassName="d-flex justify-content-center align-items-center u-dropzone-button"
          labelClassName="text-oxygen font-weight-regular font-size-xxs"
          className="video-picker-button bg-secondary-lighter ml-lg-3"
          acceptedExtensions="video/mp4 , video/quicktime , video/avi"
          placeholderClassName="pl-2 pr-2 text-oxygen font-weight-regular font-size-xxs"
          rightIcon
        >
          <div className=" row  align-items-center">
            <div className="fSize ">{t('addVideoDashboard.addVideo')}</div>
          </div>
        </FormImagePicker>
      </div>
      <hr className="mx-3" />
    </>
  );
};

export default VideoPicker;
