import { Route, Switch } from 'react-router-dom';
import * as React from 'react';
import LoginDashboard from 'dashboard/pages/LoginDashboard';
import { SidebarSafeAreaView } from '@edonec/sidebar';
import Sidebar from 'dashboard/containers/Sidebar';
import VideoGrapherList from 'dashboard/pages/VideoGrapherList';
import VideoGrapherDetails from 'dashboard/pages/VideoGrapherDetails';
import PostList from 'dashboard/pages/PostList';
import PostDetailsDashboard from 'dashboard/pages/PostDetailsDashboard';
import WithdrawRequestList from 'dashboard/pages/WithdrawRequestList';
import NotificationPage from 'dashboard/pages/NotificationPage';
import NotFound from 'client/pages/NotFound';
import { useDashboardRouter } from './useDashboardRouter';
import LoggedOutRoute from './LoggedOutRoute';
import PrivateRoute from './PrivateRoute';
const DashboardRoot = () => {
  const { initiated, isLoggedIn } = useDashboardRouter();

  return initiated ? (
    <SidebarSafeAreaView>
      <div className="appSideBar">{isLoggedIn && <Sidebar />}</div>
      <div className="container container-xl conatiner-md">
        <Switch>
          <LoggedOutRoute exact path="/dashboard" component={LoginDashboard} />
          <LoggedOutRoute exact path="/dashboard/login" component={LoginDashboard} />
          <PrivateRoute exact path="/dashboard/videographers" component={VideoGrapherList} />
          <PrivateRoute
            exact
            path="/dashboard/videographer/:id"
            component={VideoGrapherDetails}
          />
          <PrivateRoute exact path="/dashboard/videos" component={PostList} />
          <PrivateRoute exact path="/dashboard/video/:id" component={PostDetailsDashboard} />
          <PrivateRoute exact path="/dashboard/withdraw" component={WithdrawRequestList} />
          <PrivateRoute exact path="/dashboard/notification" component={NotificationPage} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </SidebarSafeAreaView>
  ) : (
    <div className="p-3 d-flex justify-content-center align-items-center vh-100">
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  );
};

export default DashboardRoot;
