import { errorHandler } from 'client/helpers/errorHandler';
import { IThunkAction } from 'types/IThunkAction';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { VideoGraphersTypes } from 'dashboard/_redux/types/videoGraphersTypes';
import * as usersServices from 'dashboard/_redux/services/users';
import { IUser } from 'server/models/User';
import { WithdrawDashboardTypes } from 'dashboard/_redux/types/withdrawDashboardTypes';
import { IWithdrawRequest } from 'server/models/WithdrawRequest';
import { INotificationForm } from 'dashboard/pages/NotificationPage/useNotificationPage';
import { toast } from 'react-toastify';
export type SearchQuery<T extends unknown> = Partial<
  T & {
    keyword: string;
    page: number;
    field: string;
    direction: 1 | -1;
    limit: number;
    status?: string;
  }
>;
export const getVideoGrapherById = (userId: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  dispatch({ type: VideoGraphersTypes.GET_VIDEOGRAPHER_BYID_START });
  try {
    const user = await usersServices.getVideoGrapherById()(userId);

    dispatch({ type: VideoGraphersTypes.GET_VIDEOGRAPHER_BYID_SUCCESS, payload: user });
  } catch (error) {
    dispatch({ type: VideoGraphersTypes.GET_VIDEOGRAPHER_BYID_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getAllVideoGraphers = (searchQuery: SearchQuery<IUser>): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  dispatch({ type: VideoGraphersTypes.FETCH_USERS_START });
  try {
    const user = await usersServices.getAllVideoGraphers()(searchQuery);

    dispatch({ type: VideoGraphersTypes.FETCH_USERS_SUCCESS, payload: user });
  } catch (error) {
    dispatch({ type: VideoGraphersTypes.FETCH_USERS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updateVideoGrapherStatus = (
  userId: string,
  status: string,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  dispatch({ type: VideoGraphersTypes.UPDATE_VIDEOGRAPHER_START });
  try {
    const user = await usersServices.updateVideoGrapherStatus()(userId, status);

    dispatch({ type: VideoGraphersTypes.UPDATE_VIDEOGRAPHER_STATUS_SUCCESS, payload: user });
  } catch (error) {
    dispatch({ type: VideoGraphersTypes.UPDATE_VIDEOGRAPHER_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const withdrawVideoGrapherBalance = (
  userId: string,
  withdrawReqId: string,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  dispatch({ type: VideoGraphersTypes.UPDATE_VIDEOGRAPHER_START });
  try {
    const user = await usersServices.updateWithdrawRequest()(userId, withdrawReqId);

    dispatch({ type: VideoGraphersTypes.UPDATE_VIDEOGRAPHER_SUCCESS, payload: user.data });
    toast('Demande effectuée avec succès.', { type: 'success' });
  } catch (error) {
    dispatch({ type: VideoGraphersTypes.UPDATE_VIDEOGRAPHER_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getWithdrawRequests = (
  searchQuery: SearchQuery<IWithdrawRequest>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const requests = await usersServices.getWithdrawRequests()(searchQuery);

    dispatch({ type: WithdrawDashboardTypes.FETCH_WITHDRAW_SUCCESS, payload: requests });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const sendNotification = (data: INotificationForm): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  dispatch({ type: VideoGraphersTypes.UPDATE_VIDEOGRAPHER_START });
  try {
    const user = await usersServices.sendNotification()(data);

    dispatch({ type: VideoGraphersTypes.UPDATE_VIDEOGRAPHER_SUCCESS, payload: user.data });
  } catch (error) {
    dispatch({ type: VideoGraphersTypes.UPDATE_VIDEOGRAPHER_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
