import * as React from 'react';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import get from 'lodash/get';
import GoogleMapsAutoCompleteInput, {
  IGoogleMapsInputProps,
} from '../GoogleMapsAutocompleteInput';

interface IProps extends Omit<IGoogleMapsInputProps, 'onChange' | 'value'> {
  name: string;
  validate?: ValidationRules;
  className?: string;
  defaultValue?: string;
}
export const GoogleMapsInput = ({
  name,
  validate,
  className,
  defaultValue,
  ...autoCompleteInputProps
}: IProps) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      render={({ onChange, value }) => (
        <GoogleMapsAutoCompleteInput
          onChange={onChange}
          value={value || defaultValue}
          name={name}
          errors={get(errors, name)}
          {...autoCompleteInputProps}
          className={`${className}`}
        />
      )}
    />
  );
};
