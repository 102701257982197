import React from 'react';
import { ISwitcherOption } from 'types/ISwitcherOption';
interface IProps {
  options: ISwitcherOption[];
  title?: string;
  onSwitch: (id: string) => void;
  className?: string;

  typeId?: string;
}

const TabSwitcher: React.FC<IProps> = ({ options, title, onSwitch, className, typeId }) => (
  <div className={`${className}`}>
    {title && (
      <span
        className="text-secondary text-oxygen"
        style={{ fontSize: '2vh', fontWeight: 'bold' }}
      >
        {title}
      </span>
    )}
    <div
      style={{ height: '3.75rem', zIndex: 1 }}
      className="p-2 position-relative  bg-secondary rounded-lg w-100"
    >
      <div className="d-flex h-100 ">
        {options.map(({ value, label }) => (
          <button
            key={value}
            style={{
              font: ' normal normal bold 18px/30px Oxygen',
              zIndex: 3,
              boxShadow: '0px 2px 14px #0D0D0D29',
              borderRadius: '5px',
            }}
            className={`col-6 mb-0 position-relative border-0 text-center fontSizebtn w-100 bg-transparent pl-1 pl-auto ${
              typeId === value ? ' text-primary' : ' text-white'
            }`}
            onClick={() => {
              onSwitch(value);
            }}
            type="button"
          >
            {label}
          </button>
        ))}
      </div>
      <div
        className={`slider position-absolute bg-white h-80 rounded ${
          typeId === options[0].value ? ' sliderLeft' : ' sliderRight'
        } `}
      />
    </div>
  </div>
);

export default TabSwitcher;
