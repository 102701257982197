import React from 'react';
import ReamPattern from 'client/components/SVG/ReamPattern';
import Footer from 'client/containers/Footer';
import NavBarContainer from 'client/containers/NavBarContainer';
import VideoModalContent from 'client/components/VideoModalContent';
import VideoCarousel from 'client/containers/VideoCarousel';
import { format } from 'date-fns';
import VideoCard from 'client/components/VideoCard';
import { usePostDetails } from './usePostDetails';
const PostDetails: React.FC = () => {
  const { postDetails, handleClick, formattedAddress, t } = usePostDetails();

  return (
    <>
      <div>
        <NavBarContainer isDarkBackground />
      </div>

      <main
        id="content"
        role="main"
        style={{ overflow: 'hidden' }}
        className="position-relative"
      >
        <ReamPattern className="background-image-page" />
        <div className="pt-10 pb-5 d-flex border flex-column  mt-11 align-items-center w-100 ">
          {postDetails && (
            <VideoModalContent
              imageUrl={postDetails.post.mainVideo.thumbnailUrl}
              nickName={postDetails.post.addedBy.username}
              datePlace={formattedAddress}
              firstDescription={postDetails.post.description}
              videoUrl={postDetails.post.mainVideo.previewUrl}
            />
          )}
        </div>
        <div className="pt-5">
          <h1 className="sections-title">{t('postDetails.similarPosts')}</h1>
        </div>
        <VideoCarousel>
          {postDetails?.similarPosts.map((post) => (
            <div onClick={() => handleClick(post._id)}>
              <VideoCard
                name={post.name}
                tags={post.tags}
                country={post.country}
                city={post.city}
                thumbnailUrl={post.mainVideo.thumbnailUrl}
                date={post.createdAt && format(post.createdAt, 'dd-MM-yyyy')}
                addedBy={post.addedBy?.username}
                videoUrl={post.mainVideo.previewUrl}
              />
            </div>
          ))}
        </VideoCarousel>
        <div>
          <h1 className="sections-title">
            {t('postDetails.userPost', { user: postDetails?.post.addedBy.username })}
          </h1>
        </div>

        <VideoCarousel>
          {postDetails?.userPosts.map((post) => (
            <div onClick={() => handleClick(post._id)}>
              <VideoCard
                name={post.name}
                tags={post.tags}
                country={post.country}
                city={post.city}
                thumbnailUrl={post.mainVideo.thumbnailUrl}
                date={post.createdAt && format(post.createdAt, 'dd-MM-yyyy')}
                addedBy={post.addedBy?.username}
                videoUrl={post.mainVideo.previewUrl}
              />
            </div>
          ))}
        </VideoCarousel>
      </main>
      <Footer />
    </>
  );
};

export default PostDetails;
