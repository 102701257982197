import { SearchQuery } from 'dashboard/_redux/actions/users';
import React, { ForwardedRef } from 'react';
import DataTable, { TableProps } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { IThunkAction } from 'types/IThunkAction';
import { PaginatedResponse } from 'types/Posts';
import { dataTableStyles } from './dataTableStyles';
import { useDataTable } from './useDataTable';

export type CRUDDataTableHandle = {
  getData: () => void;
};

type CRUDDataTableProps<T extends unknown> = Omit<TableProps<T>, 'data'> & {
  title?: string;
  fetchDataFn: (searchQuery: SearchQuery<T>) => IThunkAction;
  data: PaginatedResponse<T>;
  loading?: boolean;
  onAddButtonClick?: () => void;
  onPressChangeStatus?: () => void;
  myRef?: ForwardedRef<CRUDDataTableHandle>;
};

const CRUDDataTable = <T extends unknown>({
  title,
  fetchDataFn,
  columns,
  data,
  loading,
  onAddButtonClick,
  myRef,
  ...dataTableProps
}: CRUDDataTableProps<T>) => {
  const {
    handlePageChange,
    handleSort,
    subHeaderComponent,
    handlePerRowsChange,
    itemsPerPage,
  } = useDataTable({
    getData: fetchDataFn,
    onAddButtonClick,
    myRef,
  });
  const [t] = useTranslation();

  return (
    <DataTable
      title={title}
      columns={columns}
      data={data.items}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={data.totalItems}
      paginationPerPage={itemsPerPage}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerRowsChange}
      sortServer
      subHeader
      subHeaderComponent={subHeaderComponent}
      responsive
      customStyles={dataTableStyles}
      onSort={handleSort}
      paginationComponentOptions={{
        rowsPerPageText: `${t('dataTable.rowsPerPage')}`,
        rangeSeparatorText: `${t('dataTable.of')}`,
        selectAllRowsItem: true,
        selectAllRowsItemText: `${t('dataTable.all')}`,
      }}
      {...dataTableProps}
    />
  );
};

export default CRUDDataTable;
