import { clientApi } from 'api';

export const signout = (isServer = false) => async (refreshToken: string) => {
  const response = await clientApi.post(
    'users/sign-out',
    {},
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    },
  );

  return response;
};
