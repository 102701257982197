import FormImagePicker from 'client/components/Form/FormImagePicker';
import ProgressBar from 'client/components/ProgressBar';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { TFunction } from 'react-i18next';
type IdCardUploaderProps = {
  identityApprovementFiles: File[];
  uploadingProgress: number;
  t: TFunction<'translation'>;
};
const IdCardUploader = ({
  identityApprovementFiles,
  uploadingProgress,
  t,
}: IdCardUploaderProps) => (
  <div className="d-lg-flex justify-content-lg-between justify-content-center align-content-center">
    <div className=" d-flex align-items-center px-3 justify-content-center">
      {identityApprovementFiles?.length || 0}
      <span className="text-primary-lighter">/2</span>
    </div>
    <div className=" w-100 font-text-info-condition ">
      <div className="d-flex justify-content-between">
        <div className="col-lg-7">
          {t('LoginForm.FrontBackIdCard')}
          <span className=" text-primary-lighter"> *</span>
        </div>

        <div className="d-flex ml-3 align-items-center mt-2 mt-lg-0">
          <label htmlFor="idCard" style={{ cursor: 'pointer' }}>
            <i className=" fas fa-plus-circle fa-lg text-primary-lighter" />
            <span className="ml-1 text-primary-lighter">{t('LoginForm.Add')}</span>
          </label>
        </div>
      </div>
      <div className="d-flex row no-gutters mt-3">
        <div className="col-md-7 row no-gutters d-flex justify-content-center">
          {(identityApprovementFiles as File[])?.map((file) => (
            <ProgressBar
              key={file?.name}
              checkProgressComplet
              progress={uploadingProgress}
              className="mx-3"
              widthBar={identityApprovementFiles?.length > 1 ? '60px' : '100px'}
            />
          ))}
        </div>
        <div className="mb-lg-0 mb-2 col-md-5">
          <FormImagePicker
            name="idCard"
            label={`${t('imagePicker.dragPrompt')}`}
            maxFiles={2}
            dropzoneClassName="u-dropzone"
            labelClassName="dataSize text-primary-lighter"
            className="bg-transparent p-2 border-primary"
            validate={Validators([{ validation: 'required' }])}
          />
        </div>
      </div>
    </div>
  </div>
);

export default IdCardUploader;
