import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { signOut } from 'client/_redux/actions/auth';
export const useProfileContainer = () => {
  const [indexPage, setIndexPage] = useState(0);
  const [t] = useTranslation();

  const handleIndex = (n: number) => {
    setIndexPage(n);
  };
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(signOut());
  };

  return {
    indexPage,
    t,
    handleIndex,
    handleLogout,
  };
};
