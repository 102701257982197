import FormImagePicker from 'client/components/Form/FormImagePicker';
import ImagePickerPreview from 'client/components/Form/ImagePicker/ImagePickerPreview';
import ProgressBar from 'client/components/ProgressBar';
import IPostForm from 'client/models/IPostForm';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFileWithPreview } from 'types/IFileWithPreview';

export interface IProps {
  file?: IFileWithPreview;
  uploadingProgress: number;
}
const VideoPreview: React.FC<IProps> = ({ file, uploadingProgress }) => {
  const [t] = useTranslation();
  const { watch, setValue } = useFormContext<IPostForm>();

  return (
    <div className="container-fluid col-lg-6 col-sm-12 pl-2 pb-1">
      <label className="text-primary text-oxygen font-weight-regular font-size-xxs">
        {t('addVideoDashboard.downloads')}
      </label>
      <div className="row justify-content-center align-items-center">
        <label className="col-lg-8 col-sm-4">
          <ProgressBar progress={uploadingProgress || 0} widthBar="100%" heightBar="4px" />
        </label>
        <label className="col-lg-4 col-sm-2 fSize align-items-center">
          <label className="dataSize text-primary font-weight-light">
            {uploadingProgress || 0}%
          </label>
          <label className="dataSize font-weight-light"> /100%</label>
        </label>
      </div>
      <div className="card mb-2 video-preview-shadow">
        {file && (
          <ImagePickerPreview
            className="video-preview-max-height"
            isAddVideo
            file={file}
            index={0}
          />
        )}
      </div>
      {file && (
        <>
          <FormImagePicker
            name="shortVideo"
            label={t('addVideoDashboard.addShortVersion')}
            dropzoneClassName="d-flex justify-content-center align-items-center u-dropzone-button"
            labelClassName="text-oxygen font-weight-regular font-size-xxs"
            className="video-picker-button bg-secondary-lighter mt-2 w-100"
            acceptedExtensions="video/mp4 , video/quicktime , video/avi"
            placeholderClassName="pl-2 pr-2 text-oxygen font-weight-regular font-size-xxs"
            rightIcon
          />

          {watch('shortVideo')?.[0] && (
            <div className="card my-2 video-preview-shadow position-relative">
              <ImagePickerPreview
                className="video-preview-max-height"
                isAddVideo
                //  eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                file={watch('shortVideo')![0]}
                index={0}
              />
              <button
                type="button"
                className="btn bg-dark btn-xs position-absolute top-0 right-0 m-1 m-md-3 card-button"
                onClick={() => {
                  setValue('shortVideo', undefined);
                }}
              >
                <i className="far fa-trash-alt fa-xs text-primary" />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VideoPreview;
