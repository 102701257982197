import { signOut } from 'client/_redux/actions/auth';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const useSideBar = () => {
  const dispatch = useDispatch();
  const [T] = useTranslation();

  const logout = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  return {
    logout,
    T,
  };
};

export default useSideBar;
