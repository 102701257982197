import React from 'react';
import { useCollapsible } from './useCollapsible';
interface IProps {
  open?: boolean;
  title: string;

  scrollable?: boolean;

  className?: string;
  callback?: ({ isOpen }: { isOpen: boolean }) => void;
}

const Collapsible: React.FC<IProps> = ({
  open,
  children,
  title,
  scrollable: isOverFlow,
  className,
  callback,
}) => {
  const { handleFilterOpening, isOpen, height, ref } = useCollapsible({
    open,
    callback,
    title,
  });

  return (
    <>
      <div className={`card ${className || ''} mt-3 px-3 py-3 shadow-collapsible`}>
        <div onClick={handleFilterOpening} style={{ cursor: 'pointer' }}>
          <div className="p-1 px-4 ">
            <span className="text-oxygen text-black font-weight-bold font-size-1 title-section text-left pt-2">
              {title}
            </span>
            <button
              type="button"
              data-testid="collapse-button-test"
              className="btn position-absolute top-0 right-0 pr-5"
            >
              <i
                style={{ color: '#D9D8D7' }}
                className={`fas fa-angle-right${isOpen ? 'fas fa-angle-down' : ''}`}
              />
            </button>
          </div>
        </div>

        <div
          data-testid="collapse-test"
          className={`my-collapse ${isOpen ? 'border-top' : ''}`}
          style={{ height, overflow: isOverFlow ? 'auto' : '' }}
        >
          <div ref={ref}>
            <div className="p-3">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;
