/* eslint-disable max-lines */
import HomePageLandingSection from 'client/containers/HomePageLandingSection';
import NavBarContainer from 'client/containers/NavBarContainer';
import SectionContainer from 'client/containers/SectionContainer';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoCardContainer from 'client/containers/InfoCardContainer';
import SliderIndicator from 'client/components/SliderIndicator';
import Partners from 'client/components/Partners';
import Testimonials from 'client/components/Testimonials';
import Pattern from 'client/components/SVG/Pattern';
import { Link } from 'react-router-dom';
import Footer from 'client/containers/Footer';
import { fullpageApi } from '@fullpage/react-fullpage';
import SEO from 'client/components/SEO';

const LandingPage: React.FC = () => {
  const [t] = useTranslation();

  const [fullPageApi, setFullPageApi] = useState<fullpageApi | null>(null);

  const scrollToTop = () => {
    fullPageApi?.moveTo('1');
    // Desperate times call for desperate measures
    document.querySelectorAll(
      '.fp-scroller',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    )[0].style.transform = 'translate(0px, 0px) translateZ(0px)';
    document.querySelectorAll(
      '.iScrollIndicator',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    )[0].style.transform = 'translate(0px, 0px) translateZ(0px)';
  };

  return (
    <>
      <SEO />
      <NavBarContainer
        className={fullPageApi?.getActiveSection?.()?.anchor === '1' ? '' : 'd-none'}
      />
      <SliderIndicator
        anchors={['1', '2', '3', '4', '5', '6', 'last']}
        buttonText={t('videoModal.découvrirReamButton')}
        setFullPageApi={setFullPageApi}
      >
        <HomePageLandingSection />
        <>
          <SectionContainer
            title={t('HomePage.section2.title')}
            backgroundStyle="white-background"
            miscComponent={
              <div className="mt-0 mt-md-8">
                <img
                  className="mr-0 mr-md-7"
                  src="/assets/svg/custom/homePage/Smartphone_Ream_Mockup_2.png"
                  alt="video maker first"
                  style={{ width: '100%' }}
                />
              </div>
            }
            firstDescription={t('HomePage.section2.subtitle')}
            secondDescription={t('HomePage.section2.description')}
            checkList={[
              t('HomePage.section2.checkListElement1'),
              t('HomePage.section2.checkListElement2'),
            ]}
            titleStyle="text-dark"
            subtitleStyle="text-primary"
            descriptionStyle="text-dark"
            isReversed
          >
            <div
              className="row"
              style={{
                gap: 6,
              }}
            >
              <Link
                className="section-button alt-secondary mx-auto mr-md-3 ml-4 ml-md-0"
                style={{ boxShadow: '1px 1px 20px #808080ad' }}
                to="/how-to"
              >
                {t('navigationButtons.voirLesEtapes')}
              </Link>
              <Link
                className="section-button alt-primary mx-auto mr-md-3 ml-4 ml-md-0"
                style={{ boxShadow: '1px 1px 20px #808080ad' }}
                to={{ hash: '#1' }}
                onClick={scrollToTop}
              >
                {t('navigationButtons.telechargerReam')}
              </Link>
            </div>
            <Pattern className="red-pattern red-pattern-position-section-3" width="900px" />
          </SectionContainer>
          <Partners />
        </>
        <>
          <SectionContainer
            title={t('HomePage.section3.title')}
            backgroundStyle="dark-image-background-alt"
            miscComponent={
              <div className="section-image">
                <img
                  src="/assets/svg/custom/homePage/third-section.svg"
                  alt="home third"
                  className="w-100"
                />
              </div>
            }
            firstDescription={t('HomePage.section3.subtitle')}
            secondDescription={t('HomePage.section3.description')}
            titleStyle="text-white"
            subtitleStyle="text-primary"
            descriptionStyle="text-white-70"
            isReversed
            mr-0
            mr-md-3
            ml-4
            ml-md-0
          >
            <div className="row">
              <Link
                className="section-button alt-primary mr-md-3 mx-auto "
                style={{ boxShadow: '1px 1px 20px #808080ad' }}
                to={{ hash: '#1' }}
                onClick={scrollToTop}
              >
                {t('navigationButtons.telechargerReam')}
              </Link>
            </div>
          </SectionContainer>
          <Testimonials />
        </>
        <SectionContainer
          title={t('HomePage.section4.title')}
          backgroundStyle="white-background"
          miscComponent={
            <div className="section-image">
              <img
                src="/assets/svg/custom/homePage/fourth-section.svg"
                alt="home fourth"
                className="w-100"
              />
            </div>
          }
          firstDescription={t('HomePage.section4.subtitle')}
          secondDescription={t('HomePage.section4.description')}
          checkList={[
            t('HomePage.section4.checkListElement1'),
            t('HomePage.section4.checkListElement2'),
            t('HomePage.section4.checkListElement3'),
          ]}
          titleStyle="text-dark"
          subtitleStyle="text-primary"
          descriptionStyle="text-dark"
          isReversed
        >
          <div className="row">
            <Link
              className="section-button alt-primary mr-md-3 mx-auto"
              style={{ boxShadow: '1px 1px 20px #808080ad' }}
              to={{ hash: '#1' }}
              onClick={scrollToTop}
            >
              {t('navigationButtons.telechargerReam')}
            </Link>
          </div>
        </SectionContainer>
        <SectionContainer
          title={t('HomePage.section5.title')}
          backgroundStyle="dark-background"
          miscComponent={
            <div className="section-image">
              <img
                src="/assets/svg/custom/homePage/fifth-section.svg"
                alt="home fifth"
                className="w-100"
              />
            </div>
          }
          firstDescription={t('HomePage.section5.subtitle')}
          secondDescription={t('HomePage.section5.description')}
          checkList={[
            t('HomePage.section5.checkListElement1'),
            t('HomePage.section5.checkListElement2'),
          ]}
          titleStyle="text-white"
          subtitleStyle="text-primary"
          descriptionStyle="text-white-70"
        >
          <div
            className="row"
            style={{
              gap: 6,
            }}
          >
            <Link
              className="section-button alt-tertiary mr-0 mr-md-3 mx-auto ml-4 ml-md-0"
              style={{ boxShadow: '1px 1px 20px rgb(74 71 71 / 71%)' }}
              to="/video-maker"
            >
              {t('navigationButtons.DevenirVideaste')}
            </Link>
            <Link
              className="section-button alt-primary mr-0 mr-md-3 mx-auto ml-4 ml-md-0"
              style={{ boxShadow: '1px 1px 20px rgb(74 71 71 / 71%)' }}
              to={{ hash: '#1' }}
              onClick={scrollToTop}
            >
              {t('navigationButtons.telechargerReam')}
            </Link>
          </div>
        </SectionContainer>
        <SectionContainer
          title={t('HomePage.section6.title')}
          backgroundStyle="white-background"
          miscComponent={<InfoCardContainer isHomePage />}
          secondDescription={t('HomePage.section6.description')}
          titleStyle="text-dark"
          subtitleStyle="text-primary"
          descriptionStyle="text-dark"
          isReversed
        >
          <div className="row">
            <Link
              className="section-button alt-primary  mr-md-3 mx-auto"
              style={{ boxShadow: '1px 1px 20px #808080ad' }}
              to={{ hash: '#1' }}
              onClick={scrollToTop}
            >
              {t('navigationButtons.telechargerReam')}
            </Link>
          </div>
          <Pattern className="red-pattern red-pattern-position-section-3" width="900px" />
        </SectionContainer>
        <Footer />
      </SliderIndicator>
    </>
  );
};

export default LandingPage;
