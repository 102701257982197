/* eslint-disable max-len */
import * as React from 'react';
import { trophyColors } from 'client/constants/VideoGrapherLevelColors';

interface IProps extends React.SVGProps<SVGSVGElement> {
  starColor: string;
  isLight?: boolean;
}

const VideoGrapherLevel: React.FC<IProps> = ({ starColor, isLight, ...svgProps }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.76 146.54" {...svgProps}>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g
        id="prefix__Calque_1"
        data-name="Calque 1"
        fill={isLight ? trophyColors.black : trophyColors.white}
      >
        <path d="M145 9c3.91 5.2 12.18 13.21 13 12.66 1-.55-2.94-9.92-5.34-12.79a25.33 25.33 0 00-11-7.46L137.93 0l1.91 2.26C140.8 3.49 143.2 6.5 145 9zM21.41 23.06c.55.54 9.72-8.56 12.12-12 1.5-2.12 3.62-5 4.85-6.43 1.78-2.12 1.92-2.53.82-2.53a17.27 17.27 0 00-4.65 1.8c-4.1 2.12-8.21 5.88-9.92 9.17-1.23 2.46-3.56 9.64-3.22 9.99z" />
        <path d="M163.17 28.32l.41 2-1.77-1.78a66 66 0 00-5.69-4.65c-2.11-1.58-5.26-4.11-6.9-5.61-2.67-2.53-2.95-2.67-2.47-1.1 1.17 4.52 3.56 7.8 8.49 11.84 2.87 2.32 6.63 5.68 8.35 7.45a22.08 22.08 0 003.62 3.29c.21 0 .41-3.83.41-8.55 0-7.53-.2-9-1.64-12.25-1.78-4-6.16-8.83-8.55-9.51-1.44-.34-1.51-.27-.55.75 1.44 1.63 4.72 11.01 6.29 18.12zM13 41.19l4.93-4.66C28.46 26.55 30.51 24.22 32 21c.89-1.77 1.37-3.49 1.16-3.69s-1.37.55-2.6 1.78-3.9 3.56-6 5.27a49.17 49.17 0 00-6.43 5.95c-1.44 1.64-2.33 2.33-2.06 1.57s1.1-4 1.85-7.18a79.61 79.61 0 013-9.78q3.09-7.5-3.28-.62c-4.58 4.93-5.27 7.8-4.79 21.55z" />
        <path d="M167.48 45.5c-.75-1.71-3.62-4.93-7.59-8.48-3.42-3.15-5.88-5.27-5.4-4.79a5.37 5.37 0 01.82 2.73c0 2 4 9 8.61 15.12a53.6 53.6 0 015 7.94c2 4 2 4 2.46 1.91.28-1.16 1.09-3.89 1.78-6.15 2.46-7.39 2.8-9.51 2.53-15.74a26.59 26.59 0 00-1.84-9.72c-2.94-6.7-3.22-6.15-3.77 7.26a134.87 134.87 0 00-.08 14.23l.34 1.91-.74-1.72c-.48-.94-1.37-2.93-2.12-4.5zM7.46 54.73l2.67 7.87 1.57-3.42a55.05 55.05 0 014.86-8c4.24-5.89 7.8-12.46 8.41-15.47l.48-2.32L21.28 37c-4.86 4.3-8 8.48-9.72 12.92l-1.16 3.17-.07-7.53c-.07-4.17-.34-9.37-.68-11.69a24.12 24.12 0 01-.07-7c.68-4-.28-3.56-2.4.89a30.36 30.36 0 00-2.8 15.39 84.94 84.94 0 003.08 11.58z" />
        <path d="M171 70.47l-.89 3-.85-4.47a34.16 34.16 0 00-2-6.77c-1.92-3.63-6.64-11.56-7.12-11.91-1.37-.82 2.12 16.49 4.51 22.58a40 40 0 011.51 7.87l.42 5.13 5.19-7.05c6.91-9.37 8.42-13.68 8.42-23.4 0-4.17-.34-7.79-.82-8.55-.76-1.29-.76-1.29-.82.35 0 2.25-1.51 7.18-4.38 14.36-1.29 3.25-2.72 7.22-3.17 8.86zM10.61 82.1L14 86.41l.47-4a117.64 117.64 0 012.81-12.94 100.59 100.59 0 002.87-14l.41-5.13-2.12 3.77c-5.67 10.19-6.91 13.41-7 18.06-.07 2.46-.35 4.31-.69 4.1a2.53 2.53 0 01-.55-1.71c0-1.5-2.6-10.12-3.42-11.22C5.89 62 2.6 53 2.12 50.35 1.64 48 1.51 47.82 1 49c-1 2.25-.55 14.64.68 18.88C2.8 71.7 4.17 74 10.61 82.1z" />
        <path d="M27.16 107.28c.21-.21-.13-1.37-.75-2.53-1-2-1.51-5.27-3.15-19.16-.89-7.32-2.12-13.61-2.67-14-.27-.14-1 3.41-1.71 7.86-1.3 8.49-1.09 13.34.76 17.79 1.09 2.67.61 2.33-2.4-1.92a117.2 117.2 0 00-7.8-9.1 80.69 80.69 0 01-7.53-9.37C.07 73.69 0 73.62 0 75.67c0 5.47 2.8 12.45 7.11 17.44 3.56 4.25 19.02 15.12 20.05 14.17zM179 75.26a51 51 0 01-6.43 8.55c-2.61 2.67-5.75 6.22-7.05 7.93s-2.53 3.36-2.88 3.77-1 1.3-1.57 2c-.89 1.23-.89 1.09 0-1 2.4-6 2.53-8.55 1.1-17-.83-4.37-1.58-8.07-1.72-8.21s-.54.35-.88 1c-.82 1.43-1.92 9-2.87 19.08-.69 7.53-1 9.38-2.13 12A6.32 6.32 0 00154 106c.47 1.24 12.45-7 17.17-11.76 5.88-6 8.75-11.63 9.23-18.07l.35-4.65z" />
        <path d="M159.69 107.42c-3.36 1.71-7.74 4-9.65 5.33a18.65 18.65 0 01-3.63 2 51.93 51.93 0 012.33-5 35.39 35.39 0 003.15-9.78c.41-2.8 1.09-6.5 1.43-8.14a8.89 8.89 0 00.41-3.29c-.2-.14-1.71 1.85-3.35 4.38a45.4 45.4 0 00-4.58 9.1c-.89 2.46-1.85 5.06-2.13 5.82s-1.09 2.87-1.77 4.65a27.22 27.22 0 01-3.15 6.09c-1.09 1.5-1.84 2.87-1.71 3.08.82.82 15.46-2.81 19.29-4.79a42.48 42.48 0 0012.87-10.74c2.33-3.15 4.58-7.32 4.58-8.62 0-.21-1.78 1.23-4 3.21a48.19 48.19 0 01-10.09 6.7zM43.72 122.13a7.52 7.52 0 00-1.37-2.81c-1.85-2.87-3.49-6.57-6.84-15.26a48.13 48.13 0 00-6.91-13l-1.37-1.71.48 4.11c1 9 3 15.67 5.88 19.7 1.51 2.12 3.08 3.9 3.49 3.9s.62.14.41.34-2.46-1-5.06-2.73a107.72 107.72 0 00-11.63-6.5c-5-2.4-7.87-4.31-10.54-7-2.87-2.87-3.49-3.29-3.08-1.92 1 3.49 6.78 11.16 10.47 14a38.35 38.35 0 0010.74 6.16c2.19.8 15.06 3.06 15.33 2.72z" />
        <path d="M155.92 120.75c-4.1 2.26-7.11 3-16.35 3.77-4.31.41-9.1.89-10.6 1.16l-2.74.41 3.21-2a24.41 24.41 0 008.55-10.4c.48-1.3 1.3-3.08 1.72-3.9 1.64-3.35-.35-2-7.32 4.72-4 3.9-7.8 7.32-8.42 7.66a11.48 11.48 0 00-2 1.44 16.68 16.68 0 01-4.66 2c-2.12.69-3.69 1.37-3.55 1.51a17.79 17.79 0 003.28.89c1.64.34 5.75 1.3 9.1 2.05 11 2.53 18.06 1.58 25.72-3.49 4-2.67 8-6.43 7.4-7-.19-.32-1.69.3-3.34 1.18zM44.47 131.71a50.62 50.62 0 0014-1.92 61.14 61.14 0 008.07-2.46c.34-.28-.75-.83-2.4-1.23-4-1-7.25-3.43-15.53-11.09-6.77-6.3-8.27-7.53-8.27-6.43a52.52 52.52 0 002.87 6c3.08 6 6.57 9.65 10.67 11.09 3.08 1.09 2.67 1.09-4 .27-2.8-.41-8.48-1-12.52-1.37-5.47-.48-8.41-1.1-11.22-2.4a16 16 0 00-4.1-1.5c-.75.82 6.36 6.5 10.74 8.62 4.85 2.28 5.67 2.48 11.69 2.42zM141.9 134.51c-5.06 2.12-14.51 1.23-21.83-2-10-4.52-15.19-5.61-22.51-4.79-5.88.62-7.32.62-14.77.14a29.17 29.17 0 00-9.24.47c-1.85.48-7 2.33-11.29 4.1-10.4 4.32-17.72 4.93-25 2.13-2.81-1.1 2.73 3.14 6.5 4.92 3.21 1.58 4.65 1.78 10.26 1.78 7.53 0 9.92-.82 18.2-6.36 6-4.11 9-5.48 11.9-5.48s2.53 1-1.64 3.15c-5.34 2.81-14.78 10.47-14.78 12a2.62 2.62 0 001.17 1.92c.95.48 2.19-.28 6.36-4.24 5.95-5.75 13.14-10.74 15.39-10.74s9.38 4.92 15.13 10.4c2.66 2.6 5.13 4.65 5.47 4.65a4.16 4.16 0 001.84-1.37c1.1-1.3 1-1.43-.75-3.14A91.79 91.79 0 0098 132c-2.46-1.23-2.8-1.58-1.85-2.12 2.06-1.17 5.82.13 12.12 4.1 3.48 2.19 7 4.39 8 4.79 7.73 3.63 17.78 2.74 25.24-2.19 3.49-2.34 3.67-3.44.39-2.07z" />
        <path
          d="M72.81 70.58a1.74 1.74 0 01.63 1.94l-8.2 25.24a1.73 1.73 0 002.67 1.94l21.47-15.6a1.74 1.74 0 012 0l21.46 15.6a1.73 1.73 0 002.67-1.94l-8.2-25.24a1.75 1.75 0 01.63-1.94L129.45 55a1.74 1.74 0 00-1-3.14H101.9a1.72 1.72 0 01-1.65-1.2l-8.2-25.23a1.73 1.73 0 00-3.3 0l-8.2 25.23a1.74 1.74 0 01-1.65 1.2H52.36a1.74 1.74 0 00-1 3.14z"
          fill={starColor}
        />
      </g>
    </g>
  </svg>
);

export default VideoGrapherLevel;
