import { initializeImagePicker } from 'client/helpers/initializeImagePicker';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { IFileWithPreview } from 'types/IFileWithPreview';

type useImagePickerProps = {
  value: string[] | string;
  maxFiles: number;
  onChange?: (files: File[]) => void;
};
export const useImagePicker = ({ value, maxFiles, onChange }: useImagePickerProps) => {
  const [t] = useTranslation();
  const [files, setFiles] = useState<IFileWithPreview[]>([]);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles((prev) => [
        ...prev,
        ...acceptedFiles
          .filter((_file, index) => prev.length + index + 1 <= maxFiles)
          .map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
      ]);
    },
    [maxFiles],
  );

  // const previousFiles = usePrevious(files);

  useEffect(() => {
    if (isEqual(files, value)) return;
    const initImagePicker = async () => {
      const _value = typeof value === 'string' ? [value] : value;

      if (!_value) return;

      const initialFiles = await initializeImagePicker(
        _value.filter((el) => typeof el === 'string'),
      );

      setFiles(initialFiles);
    };

    initImagePicker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (isEqual(files, value)) return;

    onChange?.(files);
  }, [files, onChange, value]);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    accept: 'image/jpeg, image/png',
  });

  const deleteFile = (index: number) => {
    setFiles((prev) => prev.filter((_file, i) => index !== i));
  };

  return { getRootProps, getInputProps, t, isDragActive, deleteFile, files };
};
