import * as React from 'react';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import ImagePicker, {
  IProps as IComponentProps,
} from 'dashboard/components/Forms/ImagePicker';
import { get } from 'lodash';

interface IProps extends Omit<IComponentProps, 'errors' | 'onChange'> {
  name: string;
  validate?: ValidationRules;
  defaultValue?: string[];
}

const FormImagePicker: React.FC<IProps> = ({
  name,
  validate,
  defaultValue = [],
  ...imagePickerProps
}) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <ImagePicker
          {...imagePickerProps}
          name={name}
          value={value}
          onChange={onChange}
          errors={get(errors, name)}
        />
      )}
    />
  );
};

export default FormImagePicker;
