import { caluclateFileSize } from 'client/helpers/calculateFileSize';
import React from 'react';
import { IFileWithPreview } from 'types/IFileWithPreview';

interface IImagePickerPreviewProps {
  index: number;
  file: IFileWithPreview;
  deleteFile?: (index: number) => void;
  isAddVideo?: boolean;
  className?: string;
}

const ImagePickerPreview: React.FC<IImagePickerPreviewProps> = ({
  file,
  deleteFile,
  index,
  isAddVideo = false,
  className,
}) => {
  const handleDeleteFile = (
    event:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | React.KeyboardEvent<HTMLSpanElement>,
  ) => {
    event.stopPropagation();
    deleteFile && deleteFile(index);
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video className="m-2 rounded" controls>
      <source src={URL.createObjectURL(file)} />
    </video>;
  };

  return isAddVideo ? (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video className={`m-2 img-fluid rounded ${className || ''}`} controls>
      <source src={URL.createObjectURL(file)} />
    </video>
  ) : (
    <div className="col h-100 mb-5" data-testid="imagePickerPreviewTestId">
      <div className="dz-preview dz-file-preview">
        <span
          tabIndex={index}
          role="button"
          onClick={handleDeleteFile}
          onKeyDown={handleDeleteFile}
          className="d-flex justify-content-end dz-close-icon"
        >
          <small className="fa fa-times" />
        </span>
        <div className="dz-details media">
          <div className="dz-img">
            <img className="img-fluid" alt="upload" src={file.preview} />
          </div>
          <div className="media-body dz-file-wrapper">
            <h6 className="dz-filename">
              <span className="dz-title">{file.name}</span>
            </h6>
            <div className="dz-size">{caluclateFileSize(file.size)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePickerPreview;
