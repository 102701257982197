import React from 'react';
import Validators from 'client/helpers/formValidator';
import LevelInformation from 'client/components/UserProfile/LevelInformation';
import InputUserProfile from 'client/components/Form/InputProfileContainer';
import { IUser } from 'server/models/User';
import { useTranslation } from 'react-i18next';
import { useFormatVideoGrapherGrade } from 'client/hooks/useFormatVideoGrapherGrade';
import InputRow from '../InputRow';
type ProfileTabProps = {
  currentUser: IUser;
};
const ProfileTab = ({ currentUser }: ProfileTabProps) => {
  const [t] = useTranslation();
  const grade = useFormatVideoGrapherGrade(currentUser.grade);

  return (
    <>
      <div className="body-title">
        <span>{t('ProfileContainer.ProfileTab.title')}</span>
      </div>

      <InputRow
        name="firstName"
        label={t('ProfileContainer.ProfileTab.lastName')}
        defaultvalue={currentUser?.firstName}
      >
        <InputUserProfile
          name="firstName"
          type="text"
          className="info-name col-4 pr-0 "
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
          validate={Validators([
            { validation: 'required' },
            { validation: 'minLength', minParams: 2 },
          ])}
        />
      </InputRow>
      <InputRow
        name="lastName"
        label={t('ProfileContainer.ProfileTab.firstName')}
        defaultvalue={currentUser?.lastName}
      >
        <InputUserProfile
          name="lastName"
          type="text"
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
          validate={Validators([
            { validation: 'required' },
            { validation: 'minLength', minParams: 2 },
          ])}
        />
      </InputRow>

      <InputRow
        name="phoneNumber"
        label={t('ProfileContainer.ProfileTab.phone')}
        defaultvalue={currentUser?.phoneNumber?.toString()}
      >
        <InputUserProfile
          name="phoneNumber"
          type="number"
          className="form-control"
          validate={Validators([
            { validation: 'required' },
            { validation: 'maxLength', maxParams: 10 },
            { validation: 'minLength', minParams: 10 },
          ])}
        />
      </InputRow>

      <InputRow
        name="email"
        label={t('ProfileContainer.ProfileTab.email')}
        defaultvalue={currentUser?.email}
      >
        <InputUserProfile
          name="email"
          type="text"
          className="form-control"
          validate={Validators([
            { validation: 'email' },
            { validation: 'minLength', minParams: 2 },
          ])}
        />
      </InputRow>
      {/* <InputRow
        name="companyName"
        label={t('ProfileContainer.ProfileTab.companyName')}
        defaultvalue={currentUser?.companyName}
      >
        <InputUserProfile
          name="companyName"
          type="text"
          placeholder={t('ProfileContainer.ProfileTab.companyName')}
          className="form-control"
          validate={Validators([
            { validation: 'required' },
            { validation: 'maxLength', maxParams: 18 },
            { validation: 'minLength', minParams: 2 },
          ])}
        />
      </InputRow> */}
      <InputRow
        name="country"
        label={t('ProfileContainer.ProfileTab.country')}
        defaultvalue={currentUser?.country}
      >
        <InputUserProfile
          name="country"
          type="text"
          placeholder={t('ProfileContainer.ProfileTab.country')}
          className="form-control"
          validate={Validators([{ validation: 'required' }])}
        />
      </InputRow>
      <InputRow
        name="city"
        label={t('ProfileContainer.ProfileTab.city')}
        defaultvalue={currentUser?.city}
      >
        <InputUserProfile
          name="city"
          type="text"
          placeholder={t('ProfileContainer.ProfileTab.city')}
          className="form-control"
          validate={Validators([{ validation: 'required' }])}
        />
      </InputRow>
      <InputRow
        name="description"
        label={t('ProfileContainer.ProfileTab.description')}
        defaultvalue={currentUser?.description}
      >
        <InputUserProfile
          name="description"
          type="textarea"
          placeholder={t('ProfileContainer.ProfileTab.description')}
          className="form-control"
        />
      </InputRow>
      {/* <InputRow
        name="immatriculation"
        label={t('ProfileContainer.ProfileTab.Immatriculation')}
        defaultvalue={currentUser?.immatriculation}
      >
        <InputUserProfile
          name="immatriculation"
          type="number"
          className="form-control"
          validate={Validators([
            { validation: 'required' },
            { validation: 'maxLength', maxParams: 14 },
            { validation: 'minLength', minParams: 14 },
          ])}
        />
      </InputRow> */}

      <LevelInformation level={currentUser?.downloadedVidoGrapherPosts} grade={grade} />
    </>
  );
};

export default ProfileTab;
