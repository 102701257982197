import React from 'react';
import Setting from '../SVG/Setting';
interface IProps {
  imageUrl?: string;
  handleUploadAvatar: (url?: File) => void;
}

const Avatar: React.FC<IProps> = ({ imageUrl, handleUploadAvatar }) => (
  <div className="d-flex">
    <div className="image-border">
      <img
        src={imageUrl}
        alt="profile-pic"
        className="profile-round-circle align-self-center m-1"
      />
    </div>
    <label
      htmlFor="avatar"
      className="text-primary font-size-1"
      style={{ marginLeft: '-0.7rem' }}
    >
      <Setting width="13.5px" stroke="#EA384D" />
      <input
        id="avatar"
        type="file"
        onChange={(e) => handleUploadAvatar(e.target.files?.[0])}
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </label>
  </div>
);

export default Avatar;
