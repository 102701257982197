import AuthSidebar from 'client/components/AuthSidebar';
import LoginForm, { ILoginFormValues } from 'dashboard/containers/LoginForm';
import { loginDashboard } from 'dashboard/_redux/actions/auth';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';

const LoginDashboard: React.FC = () => {
  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<ILoginFormValues> = (values) => {
    dispatch(loginDashboard(values));
  };

  return (
    <>
      <main id="content" role="main">
        <div className="d-flex align-items-center position-relative height-lg-100vh">
          <AuthSidebar />
          <div className="container">
            <div className="row no-gutters">
              <LoginForm onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default LoginDashboard;
