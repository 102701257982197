import React from 'react';
import { FormProvider } from 'react-hook-form';

import { useAddVideoDashboard } from './useAddVideoDashboard';
import VideoDetails from './VideoDetails';
import VideoPicker from './VideoPicker';
import VideoPreview from './VideoPreview';
const AddVideoDashboard: React.FC = () => {
  const {
    formMethods,
    handleSubmit,
    onSubmit,
    file,
    uploadingProgress,
  } = useAddVideoDashboard();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card video-card-dashboard-container col-lg-12 col-md-12 col-sm-12 text-white bg-secondary font-size-xs">
          <VideoPicker />
          <div className="row mx-2">
            <VideoPreview file={file?.[0]} uploadingProgress={uploadingProgress} />
            <VideoDetails />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddVideoDashboard;
