import axios from 'axios';
import { refreshUserToken } from 'client/_redux/actions/auth';
import Dexie from 'dexie';

export const CLIENT_API =
  process.env.NODE_ENV === 'production' ? process.env.RAZZLE_HOST : `http://localhost:4991`;
export const clientApi = axios.create({
  baseURL: `${CLIENT_API}${process.env.RAZZLE_API_PREFIX}`,
});

export const db = new Dexie('Auth');
db.version(1).stores({
  user: 'id',
});

export const resetAuthToken = async (token: string | null) => {
  if (token) {
    clientApi.defaults.headers.Authorization = `bearer ${token}`;
  } else {
    clientApi.defaults.headers.Authorization = null;
  }
};

clientApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const request = error.config;

    if (
      error.response?.status === 403 &&
      error.response.data === 'Token expired' &&
      !request._retry
    ) {
      request._retry = true;
      await refreshUserToken();
      request.headers.Authorization = clientApi.defaults.headers.Authorization;

      return clientApi(request);
    }

    return Promise.reject(error);
  },
);
