import * as React from 'react';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import SwitchBox, { IProps as IComponentProps } from 'client/components/Form/SwitchBox';

interface IProps extends Omit<IComponentProps, 'errors' | 'onChange' | 'defaultValue'> {
  name: string;

  checked?: boolean;
  validate?: ValidationRules;

  defaultValue?: boolean;
}

const FormSwitchBox: React.FC<IProps> = ({
  defaultValue,
  checked,
  name,
  validate,
  ...switchProps
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange }) => (
        <SwitchBox {...switchProps} onChange={onChange} checked={checked} id={name} />
      )}
    />
  );
};

export default FormSwitchBox;
