import React, { useState, useCallback } from 'react';
interface IDataProps {
  title: string;
  body: JSX.Element;
  icon?: JSX.Element;
  cardTitle: string;
}
interface IProps {
  data: IDataProps[];

  isHomePage: boolean;
}

const InfoCard: React.FC<IProps> = ({ data }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);

  return (
    <div className="mt-md-11">
      <div className="d-flex flex-column size-card justify-content-center mx-auto px-lg-8 py-lg-5 px-md-0 py-md-0">
        <div>
          <div className="content-title">{data[selectedIndex].title}</div>
          <div className="content-body">{data[selectedIndex].body}</div>
          <div className="mt-5">{data[selectedIndex].icon}</div>
        </div>
        <div className="d-flex justify-content-around px-8 pb-2">
          {data.map((e, index) => (
            <div
              key={e.title}
              onClick={() => handleClick(index)}
              className={` check-case ${selectedIndex === index ? 'active' : ''}`}
            />
          ))}
        </div>
      </div>
      <div className="text-center card-title subtitle-text-semiBold mt-2 mt-md-3">
        {data[selectedIndex].cardTitle}
      </div>
    </div>
  );
};

export default InfoCard;
