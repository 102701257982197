import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RootState } from 'client/_redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { signUp } from 'client/_redux/actions/auth';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUploadingProgress } from 'client/hooks/useUpladingProgress';
import { ISignUp } from '../../useSignUp';
export const useSlideThree = () => {
  const signUpData = useSelector((state: RootState) => state.auth.signUpData);
  const loading = useSelector(({ loading }: RootState) => loading.loading);
  const formMethods = useForm<ISignUp>({ shouldFocusError: true });
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit } = formMethods;
  const [t] = useTranslation();
  const identityApprovementFiles = formMethods.watch('idCard');
  const accountApprovementVideos = formMethods.watch('videos');
  const [videoLinks, setVideoLinks] = useState<string[]>([]);
  const [videoLinkInputs, setVideoLinkInputs] = useState(0);
  const { calculatingProgress, uploadingProgress } = useUploadingProgress();
  const handleVideoLinksChange = (index: number, link: string) => {
    const videoLink = videoLinks.find((link, _index) => _index === index);

    videoLink ? (videoLinks[index] = link) : setVideoLinks([...videoLinks, link]);
    videoLinks[index] === '' && setVideoLinks(videoLinks.filter((link) => link));
  };
  const _onSubmit: SubmitHandler<ISignUp> = (data) => {
    const cleanSignUpData: ISignUp = {
      ...signUpData,
      ...data,
      videoLinks,
    };

    if (
      videoLinks.length + accountApprovementVideos.length >= 3 &&
      identityApprovementFiles.length === 2
    )
      dispatch(
        signUp(cleanSignUpData, calculatingProgress, () => history.push('/video-library')),
      );
    else toast(t('authPage.error'), { type: 'error' });
  };

  const handleVideoLinkAdd = () => {
    videoLinkInputs < 3 && setVideoLinkInputs((prev) => prev + 1);
  };

  return {
    formMethods,
    _onSubmit,
    handleSubmit,
    identityApprovementFiles,
    accountApprovementVideos,
    videoLinkInputs,
    handleVideoLinksChange,
    handleVideoLinkAdd,
    videoLinks,
    uploadingProgress,
    loading,
    t,
  };
};
