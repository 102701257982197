import Input from 'dashboard/components/Forms/Input';
import Validators from 'client/helpers/formValidator';
import * as React from 'react';
import FormImagePicker from 'dashboard/components/Forms/FormImagePicker';
import { UseFieldArrayMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const IconsVideoForm = ({ append, remove, fields }: UseFieldArrayMethods) => {
  const [t] = useTranslation();

  return (
    <>
      <h5 className="text-bold mt-3">{t('dashboard.videosPage.icons')}</h5>
      <div className="justify-content-center align-content-center">
        <button
          className="btn btn-dark btn-xs m-1 align-self-center"
          type="button"
          onClick={() => {
            const o = { link: 'https://', backgroundColor: '#ffffff' };

            append(o);
          }}
        >
          {t('dashboard.videosPage.addIcon')}
        </button>
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="list-group-item d-flex justify-content-between align-items-center mt-3"
          >
            <FormImagePicker
              name={`icons[${index}].url`}
              label="image"
              maxFiles={1}
              defaultValue={field.url}
            />
            <Input
              label="couleur"
              type="color"
              className="p-1 m-2"
              defaultValue={field.backgroundColor}
              name={`icons[${index}].backgroundColor`}
            />
            <Input
              label="lien"
              className="w-50"
              name={`icons[${index}].link`}
              defaultValue={field.link}
              validate={Validators([{ validation: 'url' }])}
            />
            <button
              onClick={() => remove(index)}
              className="flex-end  bg-transparent border-0 p-0 m-0"
              type="button"
              style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 10 }}
            >
              <i className="h3 text-danger  fas fa-trash" />
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default IconsVideoForm;
