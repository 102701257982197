import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterLvl from './FilterLvl';

interface IProps {
  level: number;
  grade: string;
}

const LevelInformation: React.FC<IProps> = ({ level, grade }) => {
  const [t] = useTranslation();

  return (
    <>
      <div className="border-top pb-2 pt-2 m-0 InputRow row w-100">
        <div className="info-name col-4 pr-0 ">
          <h1>{t('ProfileContainer.ProfileTab.userGrade')}</h1>
        </div>
        <div className="value col-8 m-auto ">
          <div>
            <h1>{grade}</h1>
          </div>
        </div>
      </div>
      <FilterLvl level={level} />
    </>
  );
};

export default LevelInformation;
