import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useFAQContent = () => {
  const [t] = useTranslation();
  const [isOpenIndex, setisOpenIndex] = useState(-1);

  const FAQcontent = [
    {
      question: t('FAQSection.Question1'),
      answer: t('FAQSection.Answer1'),
    },
    {
      question: t('FAQSection.Question2'),
      answer: t('FAQSection.Answer2'),
    },
    {
      question: t('FAQSection.Question3'),
      answer: t('FAQSection.Answer3'),
    },
    {
      question: t('FAQSection.Question4'),
      answer: t('FAQSection.Answer4'),
    },
    {
      question: t('FAQSection.Question5'),
      answer: t('FAQSection.Answer5'),
    },
    {
      question: t('FAQSection.Question6'),
      answer: t('FAQSection.Answer6'),
    },
  ];

  return { FAQcontent, t, isOpenIndex, setisOpenIndex };
};
