import Input from 'dashboard/components/Forms/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import { useLoginForm } from './useLoginForm';

export interface ILoginFormValues {
  login: string;
  password: string;
}

interface IPorps {
  onSubmit: SubmitHandler<ILoginFormValues>;
}

const LoginForm: React.FC<IPorps> = ({ onSubmit }) => {
  const { _onSubmit, handleSubmit, formMethods, t } = useLoginForm(onSubmit);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div>
          <h1 className="h3 text-primary font-weight-normal mb-0">
            {t('dashboard.authTitle')}
          </h1>
        </div>
        <Input
          type="login"
          name="login"
          label={t('LoginForm.User')}
          validate={Validators([{ validation: 'required' }])}
        />

        <Input
          type="password"
          name="password"
          label={t('LoginForm.PassWord')}
          placeholder="********"
          forgotPasswordLink="/"
          forgotPasswordMessage={t('LoginForm.PassWordForget')}
          validate={Validators([
            { validation: 'required' },
            { minParams: 8, validation: 'minLength' },
          ])}
        />

        <div className="row justify-content-center m-5">
          <button
            name="Commencer"
            data-testid="submitButton"
            className="btn btn-primary mb-1"
            type="submit"
          >
            {t('misc.submit')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
