/* eslint-disable @typescript-eslint/no-explicit-any */

import { isVideo } from '../../types/typesGuard/isVideo';

export const getFromData = (
  formData: FormData,
  data: Record<string, unknown>,
  parentKey?: string,
): void => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !isVideo(data)) {
    Object.keys(data).forEach((key) => {
      getFromData(
        formData,
        data[key] as Record<string, unknown>,
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else if (isVideo(data)) {
    formData.append((parentKey as string).split('[')[0], data);
  } else {
    let value;

    if (data == null) value = '';
    else if (data instanceof Date) value = data.toString();
    else value = data;

    formData.append(parentKey as string, value as any);
  }
};
