import { LoadingTypes } from 'client/_redux/types/loadingTypes';
import { IThunkAction } from 'types/IThunkAction';

export const startLoading = (): IThunkAction => (dispatch) => {
  dispatch({ type: LoadingTypes.LOADING_START });
};

export const stopLoading = (): IThunkAction => (dispatch) => {
  dispatch({ type: LoadingTypes.LOADING_FINISHED });
};
