/* eslint-disable no-underscore-dangle */
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as React from 'react';
import Root from 'client/containers/AppRouter';
import configureStore, { createReduxPersistor } from 'client/_redux/store/configureStore';
import { hydrate } from 'react-dom';
import { RootState } from 'client/_redux/reducers';
import i18n from 'locales/';
import { Suspense } from 'react';
import { useSSR } from 'react-i18next';
import { Resource } from 'i18next';
import { ToastContainer } from 'react-toastify';
import DashboardRoot from 'dashboard/containers/DashboardRouter';
import NotFound from 'client/pages/NotFound';

// import combinedReducers from 'reducers';
declare global {
  interface Window {
    __PRELOADED_STATE__?: RootState;
    initialLanguage: string;
    initialI18nStore: Resource;
  }
}
if (!i18n.isInitialized) {
  i18n.init();
}
const preloadedState = window.__PRELOADED_STATE__;

delete window.__PRELOADED_STATE__;
const store = configureStore(preloadedState);

export const reduxPersistor = createReduxPersistor(store);
const BaseApp = () => {
  useSSR(window.initialI18nStore, window.initialLanguage);

  return (
    <Suspense fallback={<div>Still loading i18n...</div>}>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />
        <BrowserRouter>
          <Switch>
            <Route path="/dashboard">
              <DashboardRoot />
            </Route>
            <Route path="/">
              <Root />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
};

hydrate(<BaseApp />, document.getElementById('root'));
if (module.hot) {
  module.hot.accept();
}
