import { TFunction } from 'react-i18next';
import { ISelectorOption } from 'types/ISelectorOption';
import { cinematicStyle, musicType, videoType } from 'types/Posts';

export const videoSpecOptions = (translate: TFunction<'translation'>) => {
  const cinematicStyleOptions: ISelectorOption<cinematicStyle>[] = [
    {
      label: translate('addVideoDashboard.videoSpecs.cinematicStyle.classic'),
      value: 'CLASSIC',
    },
    {
      label: translate('addVideoDashboard.videoSpecs.cinematicStyle.blackAndWhite'),
      value: 'BLACKANDWHITE',
    },
    { label: translate('addVideoDashboard.videoSpecs.cinematicStyle.fun'), value: 'FUN' },
    {
      label: translate('addVideoDashboard.videoSpecs.cinematicStyle.documentary'),
      value: 'DOCUMENTARY',
    },
    {
      label: translate('addVideoDashboard.videoSpecs.cinematicStyle.vintage'),
      value: 'VINTAGE',
    },
    {
      label: translate('addVideoDashboard.videoSpecs.cinematicStyle.artistic'),
      value: 'ARTISTIC',
    },
  ];
  const musicTypeOptions: ISelectorOption<musicType>[] = [
    { label: translate('addVideoDashboard.videoSpecs.musicType.afro'), value: 'AFRO' },
    { label: translate('addVideoDashboard.videoSpecs.musicType.rock'), value: 'ROCK' },
    {
      label: translate('addVideoDashboard.videoSpecs.musicType.soundTrack'),
      value: 'SOUNDTRACK',
    },
    { label: translate('addVideoDashboard.videoSpecs.musicType.soul'), value: 'SOUL' },
    { label: translate('addVideoDashboard.videoSpecs.musicType.pop'), value: 'POP' },
    { label: translate('addVideoDashboard.videoSpecs.musicType.others'), value: 'OTHERS' },
  ];
  const videoTypeOptions: ISelectorOption<videoType>[] = [
    {
      label: translate('addVideoDashboard.videoSpecs.videoType.withDrone'),
      value: 'WITHDRONE',
    },
    { label: translate('addVideoDashboard.videoSpecs.videoType.withPpov'), value: 'WITHPOV' },
    {
      label: translate('addVideoDashboard.videoSpecs.videoType.droneRacing'),
      value: 'DRONERACING',
    },
    {
      label: translate('addVideoDashboard.videoSpecs.videoType.stabilizer'),
      value: 'STABILIZER',
    },
    { label: translate('addVideoDashboard.videoSpecs.videoType.macro'), value: 'MACRO' },
  ];

  return { cinematicStyleOptions, musicTypeOptions, videoTypeOptions };
};
