import { clientApi } from 'api';

export const resetPassword = (isServer = false) => async (resetPasswordData: {
  resetPasswordToken: string;
  password: string;
}) => {
  const response = await clientApi.post<string>('/users/reset-password', resetPasswordData);

  return response;
};
