import * as DashboardAuthServices from 'dashboard/_redux/services/auth';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { errorHandler } from 'client/helpers/errorHandler';
import { IThunkAction } from 'types/IThunkAction';
import { AuthTypes } from 'dashboard/_redux/types/authTypes';
import { toast } from 'react-toastify';
import { LoadingTypes } from 'client/_redux/types/loadingTypes';
import { refreshUserToken } from 'client/_redux/actions/auth';
import { ISignInResponse } from 'client/models/IUser';
import { db, resetAuthToken } from 'api';
import { UserTypes } from 'dashboard/_redux/types/userTypes';
import { ILoginFormValues } from 'dashboard/containers/LoginForm';

const saveUserRefreshToken = async ({
  user: { _id },
  token: { refreshToken, accessToken },
}: ISignInResponse) => {
  localStorage.setItem('id', _id);
  resetAuthToken(accessToken);
  await db.table('user').put({ id: _id, refreshToken });
};

export const loginDashboard: (values: ILoginFormValues) => IThunkAction = ({
  login,
  password,
}) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const signInData = await DashboardAuthServices.loginDashboard()({ login, password });

    const { token, ...userData } = signInData.data;

    saveUserRefreshToken(signInData.data);

    delete userData.emailActivationToken;
    delete userData.password;
    dispatch({
      type: AuthTypes.SIGNIN_ADMIN_SUCCESS,
      payload: { token, currentUser: userData.user },
    });
  } catch (error) {
    errorHandler(error);
    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};

export const checkLoggingStatus = (): IThunkAction => async (dispatch): Promise<void> => {
  dispatch(startLoading());
  try {
    const id = window.localStorage.getItem('id');

    if (id) {
      await refreshUserToken();
      const response = await DashboardAuthServices.userByToken()();

      dispatch({
        type: AuthTypes.SIGNIN_ADMIN,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({ type: AuthTypes.LOGOUT_ADMIN });
    dispatch({ type: UserTypes.USER_LOGGED_OUT });
    errorHandler(error);
  }
  dispatch(stopLoading());
  dispatch({ type: LoadingTypes.INITIATE });
};
