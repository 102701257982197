/* eslint-disable max-len */
import * as React from 'react';

const Wallet = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.12 41.18" {...props}>
    <defs>
      <style>
        {
          '.cls-1,.cls-2,.cls-3{fill:none;}.cls-1{stroke-miterlimit:10;}.cls-2,.cls-3{stroke-linecap:round;stroke-linejoin:round;}.cls-2{stroke-width:2px;}'
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Calque_1" data-name="Calque 1">
        <circle className="cls-1" cx={41.61} cy={23.59} r={1.5} />
        <path
          className="cls-2"
          d="M1,7H47.12a2,2,0,0,1,2,2V38.18a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V7A0,0,0,0,1,1,7Z"
        />
        <path
          className="cls-2"
          d="M3,1H44.89a2,2,0,0,1,2,2V7a0,0,0,0,1,0,0H1A0,0,0,0,1,1,7V3A2,2,0,0,1,3,1Z"
        />
        <rect className="cls-2" x={34.11} y={19.07} width={15.01} height={9.05} />
        <rect className="cls-3" x={3.44} y={4} width={41.01} height={3} />
      </g>
    </g>
  </svg>
);

export default Wallet;
