import Input from 'client/components/Form/Input';
import React from 'react';
import Validators from 'client/helpers/formValidator';
import { Link } from 'react-router-dom';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import SubmitButton from 'client/components/Form/SubmitButton';
import FormCheckBox from 'client/components/Form/FormCheckbox';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { ILoginFormValues, useSignIn } from './useSignIn';

interface IPorps {
  onSubmit: SubmitHandler<ILoginFormValues>;
}

const LoginFormSignIn: React.FC<IPorps> = ({ onSubmit }) => {
  const {
    formMethods,
    handleSubmit,
    _onSubmit,
    t,
    googleLoaded,
    googleSignIn,
    onFacebookLoginSuccess,
  } = useSignIn(onSubmit);

  return (
    <div className=" shadow rounded py-5 h-100">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(_onSubmit)}>
          {/* eslint-disable-next-line max-len  */}
          <div className="flex-column d-flex align-items-center align-items-lg-start mb-3  mx-lg-11 font-size-2 font-weight-bold text-primary-lighter">
            {t('LoginForm.DejaInscrit')}
          </div>
          <div className="body-login-form row flex-column justify-content-center align-items-center no-gutters mb-3">
            <div className="col-lg-6 col-10 ">
              <Input
                className="bg-light-grey text-center"
                data-testid="emailInput"
                name="login"
                placeholder={t('LoginForm.User')}
                validate={Validators([{ validation: 'required' }])}
              />

              <Input
                className="bg-light-grey text-center"
                data-testid="passwordInput"
                type="password"
                name="password"
                placeholder={t('LoginForm.PassWord')}
                validate={Validators([
                  { validation: 'required' },
                  { minParams: 8, validation: 'minLength' },
                ])}
              />
              <div className="d-flex row">
                <div className="col-6 d-flex justify-content-start align-items-center pr-0">
                  <div
                    className="d-inline-flex  justify-content-center align-items-center"
                    style={{ height: '3.6rem' }}
                  >
                    <FormCheckBox name="rememberMe" />
                  </div>
                  <div className="d-flex font-size-checkbox-label text-secondary">
                    <p className="mb-0 pt-2">{t('LoginForm.RememberMe')}</p>
                  </div>
                </div>

                <div className="col-6 align-self-center" style={{ textAlignLast: 'right' }}>
                  <Link
                    className="font-size-checkbox-label text-primary-lighter"
                    to="/forgetpassword"
                  >
                    {t('LoginForm.PassWordForget')}
                  </Link>
                </div>
              </div>
              <FacebookLogin
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                appId={process.env.RAZZLE_FACEBOOK_APP_ID!}
                scope="email"
                onSuccess={onFacebookLoginSuccess}
                dialogParams={{
                  response_type: 'token',
                  state: 'facebookdirect',
                  redirect_uri:
                    typeof window !== 'undefined'
                      ? window.location.origin + window.location.pathname
                      : '/',
                }}
                render={({ onClick }) => (
                  <button
                    onClick={onClick}
                    type="button"
                    className=" d-flex btn justify-content-center mt-2 mb-3 w-100 bg-secondary font-size-xs text-white"
                  >
                    {t('LoginForm.FaceBookConnexion')}
                  </button>
                )}
              />

              <button
                disabled={!googleLoaded}
                type="button"
                className=" d-flex btn justify-content-center mb-3 w-100 bg-secondary font-size-xs text-white"
                onClick={googleSignIn}
              >
                {t('LoginForm.GoogleConnexion')}
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <SubmitButton name={t('LoginForm.Connexion')} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default LoginFormSignIn;
