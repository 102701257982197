import { ISelectorOption } from 'types/ISelectorOption';
import { styledSelect } from 'client/components/Form/Select/MenuListArrow';
import { SelectComponents } from 'react-select/src/components';
import { useDispatch } from 'react-redux';
import { updatePost } from 'client/_redux/actions/post';
import IPostForm from 'client/models/IPostForm';
import { videoSpecOptions } from 'client/constants/videoSpecOptions';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';

export const useVideoCardForm = (post: IPostForm) => {
  const component = styledSelect.components as Partial<
    SelectComponents<ISelectorOption, boolean>
  >;

  const formMethods = useForm<IPostForm>({ defaultValues: post });

  const { handleSubmit } = formMethods;

  const dispatch = useDispatch();

  const [t] = useTranslation();
  const { cinematicStyleOptions, musicTypeOptions, videoTypeOptions } = videoSpecOptions(t);

  const onSubmit: SubmitHandler<IPostForm> = (data) => {
    if (!post.postId) return;
    dispatch(updatePost(data, post.postId));
  };

  return {
    component,
    cinematicStyleOptions,
    musicTypeOptions,
    videoTypeOptions,
    t,
    formMethods,
    handleSubmit,
    onSubmit,
  };
};
