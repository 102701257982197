import { AxiosError } from 'axios';
import { IPost } from 'client/models/IPost';
import {
  AddPostTypes,
  deletePostTypes,
  GetPostTypes,
  getAllPostsTypes,
  fetchVideoGrapherPosts,
  GetPostByIdTypes,
} from 'client/_redux/types/postTypes';
import { IReduxAction } from 'types/IReduxAction';

export interface CurrentPostsResponse {
  byCategory?: IPost[];
  byCountry?: [{ country: string; posts: IPost[] }];
}
type postDetails = {
  post: IPost;
  similarPosts: IPost[];
  userPosts: IPost[];
};
export interface EstabState {
  error?: AxiosError | Error;

  currentPost?: IPost;
  currentPosts?: CurrentPostsResponse;

  videoGrapherPosts?: { posts: IPost[]; totalPosts: number };
  postDetails?: postDetails;
}

const initialState: EstabState = {};

export default (state: EstabState = initialState, action: IReduxAction): EstabState => {
  const { type, payload, error } = action;

  switch (type) {
    case AddPostTypes.ADD_POST_SUCCESS:
      return {
        ...state,
        currentPost: payload,
      };
    case GetPostTypes.POST_SUCCESS:
      return {
        ...state,
        currentPost: payload,
      };
    case AddPostTypes.UPDATE_POST_SUCCESS:
      return {
        ...state,
        currentPost: payload,
      };
    case AddPostTypes.UPDATE_POST_FAILURE:
      return {
        ...state,
        error,
      };
    case deletePostTypes.POST_DELETE_SUCCESS:
      return {
        ...state,
        currentPost: payload,
      };
    case deletePostTypes.POST_DELETE_FAILURE:
      return {
        ...state,
        error,
      };
    case AddPostTypes.ADD_POST_FAILURE:
      return {
        ...state,
        error,
      };
    case GetPostTypes.RESET_POST:
      return initialState;
    case GetPostTypes.POST_FAILURE:
      return {
        ...state,
        error,
      };
    case getAllPostsTypes.POSTS_SUCCESS:
      return {
        ...state,
        currentPosts: payload,
      };
    case getAllPostsTypes.POSTS_FAILURE:
      return {
        ...state,
        error,
      };
    case fetchVideoGrapherPosts.FETCH_VIDEOGRAPHER_POSTS_SUCCESS:
      return {
        ...state,
        videoGrapherPosts: payload,
      };
    case fetchVideoGrapherPosts.FETCH_VIDEOGRAPHER_POSTS_FAILURE:
      return {
        ...state,
        error,
      };
    case GetPostByIdTypes.POST_BYID_SUCCESS:
      return {
        ...state,
        postDetails: payload,
      };
    case GetPostByIdTypes.POST_BYID_FAILURE:
      return {
        ...state,
        error,
      };
    default:
      return state;
  }
};
