import { ISignUp } from 'client/containers/LoginForm/SignUp/useSignUp';
import { AuthTypes } from 'client/_redux/types/authTypes';
import { UserReportTypes } from 'client/_redux/types/userReportTypes';
import { WithdrawTypes } from 'client/_redux/types/withdrawTypes';
import { IUser } from 'server/models/User';
import { IUserInvoice } from 'server/models/UserInvoice';
import { IReduxAction } from 'types/IReduxAction';

export interface IAuthState {
  isLoggedIn: boolean;
  currentUser?: IUser;
  signUpData?: ISignUp;
  token?: { accessToken: string };
  loading: boolean;
  loaded: boolean;
  error: boolean;
  userReports: IUserInvoice[];
  isAbleToWithdraw: boolean;
}

const initialState: IAuthState = {
  isLoggedIn: false,
  loading: false,
  loaded: false,
  error: false,
  userReports: [],
  isAbleToWithdraw: false,
};

export default (state = initialState, { type, payload }: IReduxAction): IAuthState => {
  switch (type) {
    case AuthTypes.APPEND_SIGN_UP_DATA:
      return { ...state, signUpData: payload };
    case AuthTypes.USER_LOGGED_IN:
      return { ...state, isLoggedIn: true };
    case AuthTypes.USER_LOGGED_OUT:
      return initialState;
    case AuthTypes.SIGNIN_SUCCESS:
    case AuthTypes.SIGN_UP_SUCCESS:
    case AuthTypes.SET_CURRENT_USER:
      return { ...state, currentUser: payload, isLoggedIn: true };
    case AuthTypes.UPDATE_USER:
    case AuthTypes.UPDATE_AVATAR:
    case AuthTypes.SET_USER:
      return { ...state, isLoggedIn: true, loaded: true, currentUser: payload };
    case AuthTypes.RESET_USER:
      return { ...state, isLoggedIn: false, loaded: true };
    case UserReportTypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        loaded: true,
        userReports: payload.userReports,
        isAbleToWithdraw: payload.isAbleToWithdraw,
      };
    case WithdrawTypes.DISABLE_WITHDRAW:
      return {
        ...state,
        isAbleToWithdraw: false,
      };
    default:
      return state;
  }
};
