import { useTranslation } from 'react-i18next';
import ProgressBar from 'client/components/ProgressBar';
import React, { useMemo } from 'react';

interface IProps {
  level: number;
}

const FilterLvl: React.FC<IProps> = ({ level }) => {
  const [t] = useTranslation();
  const { input1, input2, input3, upperBound } = useMemo(
    () => ({
      input1: Math.max(Math.min(level, 700), 0) / 7,
      input2: (Math.max(Math.min(level, 2500), 700) - 700) / ((2500 - 700) / 100),
      input3: (Math.max(Math.min(level, 5000), 2500) - 2500) / ((5000 - 2500) / 100),
      // eslint-disable-next-line no-nested-ternary
      upperBound: level < 700 ? 699 : level < 2500 ? 2499 : undefined,
    }),
    [level],
  );

  return (
    <div className="w-100 mt-2">
      <div className="d-flex">
        <div className="col-4" />
        <p
          className="text-dark text-center pt-2 m-0 mb-3 level-span"
          style={{ paddingLeft: '20px' }}
        >
          <span className="text-primary">{level} </span>
          {upperBound && (
            <>
              /<span className="mx-1 font-weight-bolder">{upperBound}</span>{' '}
            </>
          )}
          {t('ProfileContainer.ProfileTab.downloads')}
        </p>
      </div>
      <div className="d-flex justify-content-center m-auto">
        <div className="col-4" style={{ textAlignLast: 'center' }}>
          <div className="d-flex flex-column">
            <ProgressBar progress={input1} widthBar="100%" heightBar="8px" className="" />
            <p
              className={`${
                level > 0 && level < 700 ? `text-primary` : `text-black-50`
              } level-span mt-1 mb-1`}
            >
              Grade Bronze
            </p>
          </div>
        </div>
        <div className="col-4" style={{ textAlignLast: 'center' }}>
          <div className="d-flex flex-column">
            <ProgressBar progress={input2} widthBar="100%" heightBar="8px" className="" />
            <p
              className={`${
                level > 699 && level < 2500 ? `text-primary` : `text-black-50`
              } level-span mt-1 mb-1`}
            >
              Grade Argent
            </p>
          </div>
        </div>
        <div className="col-4" style={{ textAlignLast: 'center' }}>
          <div className="d-flex flex-column">
            <ProgressBar progress={input3} widthBar="100%" heightBar="8px" className="" />
            <p
              className={`${
                level > 2499 ? `text-primary` : `text-black-50`
              } level-span mt-1 mb-1`}
            >
              Grade Or
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterLvl;
