import { useTranslation } from 'react-i18next';
import { RootState } from 'client/_redux/reducers';
import { useSelector } from 'react-redux';
export const useSettingsTab = () => {
  const [t] = useTranslation();
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const { currentUser } = useSelector(({ auth: { currentUser } }: RootState) => ({
    currentUser,
  }));

  return { t, currentUser, currentLanguage };
};
