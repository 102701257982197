import { useDispatch, useSelector } from 'react-redux';
import { login } from 'client/_redux/actions/auth';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ILoginFormValues } from 'client/containers/LoginForm/SignIn/useSignIn';
import { useHistory } from 'react-router-dom';

import { RootState } from 'client/_redux/reducers';
import { useState } from 'react';

export const useLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmitSignIn: SubmitHandler<ILoginFormValues> = (values) => {
    dispatch(login(values, () => history.push('/video-library')));
  };
  const [signupForm, setSignupForm] = useState(false);
  const { signUpData } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();

  return {
    onSubmitSignIn,
    signupForm,
    setSignupForm,
    signUpData,
    t,
  };
};
