/* eslint-disable max-len */
import React from 'react';

const Paper = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 141.69 184.25" {...props}>
    <defs>
      <style>{'.cls-1{fill:#e9394d;}.cls-2{fill:#ffffff;}'}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Calque_1" data-name="Calque 1">
        <path
          className="cls-1"
          d="M29.19,35.29h-23L39.53,2V25A10.36,10.36,0,0,1,29.19,35.29ZM123.91,0H45.08V25A15.91,15.91,0,0,1,29.19,40.84H4.25V152.72a13.52,13.52,0,0,0,13.52,13.5H123.91a13.52,13.52,0,0,0,13.52-13.5V13.5A13.52,13.52,0,0,0,123.91,0Z"
        />
        <path
          className="cls-2"
          d="M137.43,184.25H4.25a4.25,4.25,0,0,1,0-8.5H137.43a4.25,4.25,0,1,1,0,8.5Z"
        />
      </g>
    </g>
  </svg>
);

export default Paper;
