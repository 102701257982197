import { useDispatch } from 'react-redux';
import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  ForwardedRef,
  useImperativeHandle,
} from 'react';
import { TableColumn } from 'react-data-table-component';
import { IThunkAction } from 'types/IThunkAction';

import { SearchQuery } from 'dashboard/_redux/actions/users';
import FilterInput from './FilterInput';
import { CRUDDataTableHandle } from '.';

type IProps<T extends unknown> = {
  getData: (searchQuery: SearchQuery<T>) => IThunkAction;
  onAddButtonClick?: () => void;
  myRef?: ForwardedRef<CRUDDataTableHandle>;
};
export const useDataTable = <T extends unknown>({
  getData,
  onAddButtonClick,
  myRef,
}: IProps<T>) => {
  const dispatch = useDispatch();

  const [query, setQuery] = useState<SearchQuery<T>>({});
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getData({ ...query, limit: itemsPerPage }));
  }, [dispatch, query, getData, itemsPerPage]);

  useImperativeHandle(
    myRef,
    () => ({
      getData: () => {
        dispatch(getData({ ...query, limit: itemsPerPage }));
      },
    }),
    [dispatch, getData, query, itemsPerPage],
  );

  const handlePageChange = useCallback(
    (page: number, _totalRows: number) => {
      setQuery({ ...query, page });
    },
    [query],
  );

  const handleSort = useCallback(
    (column: TableColumn<T>, sortDirection: 'asc' | 'desc') =>
      setQuery((prev) => ({
        ...prev,
        field: column.sortfield || column.name,
        direction: sortDirection === 'asc' ? 1 : -1,
      })),
    [],
  );
  const handleSearch = useCallback(
    (keyword: string) =>
      setQuery((prev) => ({
        ...prev,
        keyword,
      })),
    [],
  );
  const onPressChangeStatus = useCallback(
    () =>
      setQuery((prev) => ({
        ...prev,
        status: query.status === 'PENDING' ? 'APPROVED' : 'PENDING',
      })),
    [query.status],
  );

  const subHeaderComponent = useMemo(
    () => (
      <FilterInput
        onFilter={(e) => handleSearch(e.target.value)}
        filterText={query.keyword}
        onAddButtonClick={onAddButtonClick}
        onPressChangeStatus={onPressChangeStatus}
        status={query.status}
      />
    ),
    [handleSearch, onAddButtonClick, onPressChangeStatus, query],
  );

  return {
    handlePageChange,
    handleSort,
    subHeaderComponent,
    handlePerRowsChange: setItemsPerPage,
    itemsPerPage: query.limit,
  };
};
