import FormSwitchBox from 'client/components/Form/FormSwitchBox';
import FormCheckBox from 'client/components/Form/FormCheckbox';
import { Link } from 'react-router-dom';
import React from 'react';

import SubmitButton from 'client/components/Form/SubmitButton';
import { FormProvider } from 'react-hook-form';
import { useSignUp } from '../../useSignUp';
interface IProps {
  nextSlide: () => void;
}
const SlideTwo: React.FC<IProps> = ({ nextSlide }) => {
  const { formMethods, t, handleSubmit, _onSubmit } = useSignUp(nextSlide);

  return (
    <div className="w-100 flex-column align-content-center justify-content-center">
      <div className="h4 text-secondary-lighter font-weight-normal  text-center ">
        <span className="text-primary-lighter">2.</span>
        {t('LoginForm.AcceptConditions')}
      </div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(_onSubmit)}>
          <div className="w-60 mx-auto my-5">
            <div className=" d-flex justify-content-between align-content-center">
              <div className="font-text-info-condition pr-5">
                {t('LoginForm.AcceptAgreement')}
                <Link to="/licence">
                  <span className="text-primary-lighter">{t('LoginForm.Agreement')}</span>
                </Link>
              </div>
              <div className="d-flex my-auto ">
                <span className="font-label-switchbox text-input-placeholder pr-3">
                  {t('LoginForm.Yes')}
                </span>
                <FormSwitchBox name="contrat" required />
              </div>
            </div>
            <hr className="my-4" />
            <div className=" d-flex justify-content-between align-content-center">
              <div className="font-text-info-condition pr-5">
                {t('LoginForm.AcceptAgreement')}
                <Link to="/legal-notice">
                  <span className="text-primary-lighter">{t('LoginForm.Charte')}</span>
                </Link>
                {t('LoginForm.UseCookie')}
                <Link to="/terms-and-conditions">
                  <span className="text-primary-lighter">{t('LoginForm.UseConditions')}</span>
                </Link>
              </div>
              <div className="d-flex my-auto ">
                <span className="font-label-switchbox text-input-placeholder pr-3">
                  {t('LoginForm.Yes')}
                </span>
                <FormSwitchBox name="condition" required />
              </div>
            </div>
            {/* <hr className="my-4" />
            <div className="d-flex justify-content-between align-content-center">
              <div className="font-text-info-condition pr-5">
                <span className="text-primary-lighter">{t('LoginForm.DownloadDocs')}</span>
                {t('LoginForm.InformationDoc')}
              </div>
              <div className="d-flex my-auto ">
                <FormCheckBox name="download" />
              </div>
            </div> */}
          </div>
          <div className="d-flex justify-content-center">
            <SubmitButton name={t('LoginForm.Next')} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default SlideTwo;
