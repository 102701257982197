import * as React from 'react';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import CheckBox, { IProps as IComponentProps } from '../CheckBox';

interface IProps extends Omit<IComponentProps, 'errors' | 'onChange'> {
  name: string;
  validate?: ValidationRules;
}

const FormCheckBox: React.FC<IProps> = ({ name, validate, ...checkProps }) => {
  const { control } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      defaultValue={[]}
      render={({ onChange }) => <CheckBox {...checkProps} id={name} onChange={onChange} />}
    />
  );
};

export default FormCheckBox;
