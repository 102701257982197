import { Link } from 'react-router-dom';
import * as React from 'react';
import { LogoReam } from '../SVG';

const AuthSidebar = () => (
  <div className="col-lg-5 col-xl-4 d-none d-lg-flex align-items-center bg-secondary vh-lg-100 px-0">
    <div className="w-100 p-5">
      <div className="mb-4">
        <div className="w-md-80 w-lg-60 text-center mx-auto">
          <Link to="/">
            <LogoReam width="174px" height="50px" />
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default AuthSidebar;
