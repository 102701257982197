/* eslint-disable jsx-a11y/media-has-caption */
import { getImageUrl } from 'client/helpers/getImageUrl';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IProps {
  imageUrl?: string;
  nickName: string;
  datePlace: string;
  firstDescription: string;
  videoUrl?: string;
}
const VideoModalContent: React.FC<IProps> = ({
  imageUrl,
  nickName,
  datePlace,
  firstDescription,
  videoUrl,
}) => {
  const [t] = useTranslation();

  return (
    <div className="m-2 mt-n3 d-flex flex-column">
      <div className="modal-video-section">
        <video
          poster={getImageUrl(imageUrl)}
          controls
          width="100%"
          height="100%"
          key={imageUrl}
        >
          <source src={getImageUrl(videoUrl)} />
        </video>
      </div>
      <div className="d-flex row">
        <div className="col-xl-7 col-lg-6 col-md-7 col-12 pt-2 pr-md-0">
          <div className="modal-information-container">
            <p className="font-weight-bold h4 mb-2">{nickName}</p>
            <p className="modal-text-light text-primary modal-text m-0 mb-2">{datePlace}</p>
            <p className="m-0 mb-2 modal-text-regular modal-text">
              <span className="modal-text-medium modal-text mb-3">
                {t('videoModal.firstMessage')}
              </span>
              {firstDescription}
            </p>
          </div>
        </div>
        <div className="col-xl-5 col-lg-6 col-md-5 sd-flex justify-content-center align-items-center">
          <div className="modal-download-container">
            <p className="modal-text-regular modal-text">
              {t('videoModal.secondMessageContent')}
              <Link to="/" className="modal-text-medium modal-text text-primary ">
                {t('videoModal.telechargeReam')}
              </Link>
            </p>
            <div className="d-flex justify-content-center justify-content-md-start">
              <Link
                className="modal-button border border-primary bg-white rounded font-weight-bold text-oxygen"
                style={{}}
                to="/how-to"
              >
                {t('videoModal.découvrirReamButton')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModalContent;
