/* eslint-disable max-lines */
/* eslint-disable max-len */
import React from 'react';

const Pattern = (props: React.SVGProps<SVGSVGElement>) => (
  <svg id="Calque_1" x={0} y={0} viewBox="0 0 1080 1080" xmlSpace="preserve" {...props}>
    <switch>
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1={51.496}
          y1={589.788}
          x2={827.726}
          y2={589.788}
          gradientTransform="matrix(.083 -.1438 .1438 .083 418.663 565.333)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M485.48 519.6a19.27 19.27 0 0 1 4.3-5.13l23.61-19.81c3.46-2.91 7.86-4.51 12.38-4.51h30.81c4.52 0 8.92 1.6 12.38 4.51l23.61 19.81c3.46 2.91 5.8 6.96 6.59 11.41l5.35 30.35c.79 4.45-.03 9.06-2.29 12.98l-15.41 26.69a19.295 19.295 0 0 1-10.1 8.47l-28.96 10.54a19.26 19.26 0 0 1-13.18 0l-28.96-10.54a19.333 19.333 0 0 1-10.1-8.47l-15.41-26.69a19.344 19.344 0 0 1-2.29-12.98l5.35-30.35c.42-2.21 1.19-4.33 2.32-6.28m-.72-.41a20.192 20.192 0 0 0-2.39 6.56l-5.35 30.35c-.82 4.65.02 9.45 2.39 13.54l15.41 26.69c2.36 4.09 6.09 7.22 10.53 8.84l28.96 10.54c4.44 1.62 9.31 1.62 13.75 0l28.96-10.54c4.44-1.62 8.17-4.74 10.53-8.84l15.41-26.69a20.11 20.11 0 0 0 2.39-13.54L600 525.75c-.82-4.65-3.25-8.87-6.87-11.9l-23.61-19.81c-3.62-3.04-8.19-4.7-12.92-4.7h-30.81c-4.72 0-9.3 1.66-12.92 4.7l-23.61 19.81a19.662 19.662 0 0 0-4.5 5.34z"
          style={{
            fill: 'url(#SVGID_1_)',
          }}
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1={70.023}
          y1={605.501}
          x2={846.25}
          y2={605.501}
          gradientTransform="rotate(-47.984 810.09 -188.22) scale(.1748)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M490.75 506.74c1.59-1.76 3.45-3.22 5.55-4.34l28.64-15.23c4.2-2.23 9.08-2.92 13.74-1.93l31.73 6.74c4.66.99 8.83 3.6 11.76 7.35l19.97 25.56c2.93 3.75 4.45 8.44 4.29 13.19l-1.13 32.42c-.17 4.76-2.01 9.32-5.2 12.86l-21.7 24.1a20.254 20.254 0 0 1-12.25 6.51l-32.12 4.51c-4.71.66-9.53-.36-13.57-2.88l-27.51-17.19c-4.04-2.52-7.07-6.4-8.54-10.93l-10.02-30.85c-1.47-4.53-1.3-9.45.48-13.86l12.15-30.07c.89-2.19 2.15-4.2 3.73-5.96m-.65-.59a21.027 21.027 0 0 0-3.89 6.23l-12.15 30.07a21.208 21.208 0 0 0-.51 14.46l10.02 30.85c1.54 4.73 4.69 8.77 8.91 11.4l27.51 17.19a21.14 21.14 0 0 0 14.15 3.01l32.12-4.51c4.92-.69 9.45-3.1 12.78-6.79l21.7-24.1c3.33-3.7 5.25-8.45 5.42-13.42l1.13-32.42a21.18 21.18 0 0 0-4.47-13.76l-19.97-25.56a21.187 21.187 0 0 0-12.27-7.67l-31.73-6.74c-4.86-1.03-9.94-.32-14.33 2.01l-28.64 15.23c-2.18 1.17-4.14 2.7-5.78 4.52z"
          style={{
            fill: 'url(#SVGID_2_)',
          }}
        />
        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1={90.356}
          y1={616.495}
          x2={866.583}
          y2={616.495}
          gradientTransform="matrix(.1489 -.1082 .1082 .1489 402.08 511.051)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M499 494.65c2.02-1.47 4.26-2.56 6.66-3.25l32.82-9.41c4.82-1.38 9.99-1.02 14.57 1.02l31.19 13.89a21.35 21.35 0 0 1 10.5 10.14l14.97 30.69c2.2 4.5 2.74 9.66 1.53 14.52l-8.26 33.13a21.377 21.377 0 0 1-8.17 12.11l-27.62 20.07a21.347 21.347 0 0 1-14.04 4.02l-34.06-2.38c-5-.35-9.74-2.46-13.34-5.94l-24.56-23.72c-3.6-3.48-5.88-8.14-6.4-13.12l-3.57-33.96c-.52-4.98.73-10.01 3.53-14.17l19.09-28.31a21.43 21.43 0 0 1 5.16-5.33m-.54-.75a22.184 22.184 0 0 0-5.37 5.56L474 527.77a22.23 22.23 0 0 0-3.68 14.78l3.57 33.96c.55 5.21 2.91 10.05 6.68 13.69l24.56 23.72c3.77 3.64 8.69 5.83 13.91 6.2l34.06 2.38c5.22.37 10.41-1.12 14.64-4.2l27.62-20.07a22.28 22.28 0 0 0 8.52-12.63l8.26-33.13c1.27-5.08.7-10.44-1.59-15.15l-14.97-30.69a22.205 22.205 0 0 0-10.96-10.58l-31.19-13.89a22.209 22.209 0 0 0-15.19-1.06l-32.82 9.41c-2.53.72-4.88 1.88-6.96 3.39z"
          style={{
            fill: 'url(#SVGID_3_)',
          }}
        />
        <linearGradient
          id="SVGID_4_"
          gradientUnits="userSpaceOnUse"
          x1={111.428}
          y1={622.669}
          x2={887.657}
          y2={622.669}
          gradientTransform="matrix(.177 -.0788 .0788 .177 402.539 480.25)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M510.13 484c2.4-1.07 4.95-1.7 7.57-1.89l35.85-2.51c5.26-.37 10.51 1.14 14.78 4.24l29.08 21.13c4.27 3.1 7.32 7.63 8.6 12.74l8.69 34.87c1.28 5.12.71 10.55-1.61 15.29l-15.76 32.3a22.523 22.523 0 0 1-11.06 10.68l-32.83 14.62a22.432 22.432 0 0 1-15.33 1.07l-34.55-9.91a22.505 22.505 0 0 1-12.44-9.03l-20.1-29.8a22.502 22.502 0 0 1-3.72-14.91l3.76-35.74c.55-5.25 2.94-10.15 6.74-13.82l25.85-24.97c1.91-1.82 4.08-3.29 6.48-4.36m-.39-.88c-2.48 1.1-4.77 2.64-6.75 4.55l-25.85 24.97a23.488 23.488 0 0 0-7.03 14.41l-3.76 35.74c-.58 5.48.8 10.99 3.88 15.56l20.1 29.8c3.08 4.57 7.67 7.91 12.97 9.42l34.55 9.91c5.3 1.52 10.96 1.12 15.99-1.12l32.83-14.62c5.03-2.24 9.12-6.18 11.53-11.14l15.76-32.3c2.42-4.95 3.01-10.6 1.68-15.95l-8.69-34.87a23.41 23.41 0 0 0-8.97-13.29l-29.08-21.13c-4.46-3.24-9.91-4.8-15.41-4.42l-35.85 2.51c-2.75.19-5.42.86-7.9 1.97z"
          style={{
            fill: 'url(#SVGID_4_)',
          }}
        />
        <linearGradient
          id="SVGID_5_"
          gradientUnits="userSpaceOnUse"
          x1={132.229}
          y1={624.217}
          x2={908.457}
          y2={624.217}
          gradientTransform="matrix(.1995 -.0424 .0424 .1995 409.753 448.64)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M523.93 475.48c2.71-.58 5.47-.67 8.21-.29l37.46 5.27c5.5.77 10.57 3.47 14.29 7.6l25.31 28.11c3.71 4.13 5.87 9.45 6.06 15l1.32 37.81c.19 5.55-1.58 11.01-5 15.39l-23.29 29.81a23.691 23.691 0 0 1-13.72 8.57l-37.01 7.87c-5.43 1.15-11.12.35-16.02-2.25l-33.4-17.76c-4.9-2.61-8.75-6.88-10.83-12.02l-14.17-35.08a23.663 23.663 0 0 1-.56-16.17l11.69-35.98c1.72-5.28 5.25-9.81 9.96-12.75l32.08-20.05c2.35-1.47 4.91-2.51 7.62-3.08m-.21-1a24.44 24.44 0 0 0-7.94 3.21l-32.08 20.05c-4.92 3.07-8.6 7.78-10.39 13.3l-11.69 35.98a24.713 24.713 0 0 0 .59 16.87l14.17 35.08c2.17 5.38 6.17 9.82 11.29 12.54l33.4 17.76a24.72 24.72 0 0 0 16.71 2.35l37.01-7.87a24.694 24.694 0 0 0 14.31-8.94L612.39 585a24.687 24.687 0 0 0 5.22-16.05l-1.32-37.81c-.2-5.8-2.44-11.34-6.32-15.65l-25.31-28.11a24.638 24.638 0 0 0-14.9-7.92l-37.46-5.27c-2.89-.41-5.79-.3-8.58.29z"
          style={{
            fill: 'url(#SVGID_5_)',
          }}
        />
        <linearGradient
          id="SVGID_6_"
          gradientUnits="userSpaceOnUse"
          x1={151.877}
          y1={621.539}
          x2={928.105}
          y2={621.539}
          gradientTransform="translate(424.097 417.674) scale(.2146)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M540 469.72c2.91 0 5.78.51 8.52 1.5l37.42 13.62c5.49 2 10.12 5.89 13.05 10.95l19.91 34.49a24.974 24.974 0 0 1 2.96 16.77l-6.92 39.22a24.9 24.9 0 0 1-8.52 14.75l-30.51 25.6a24.925 24.925 0 0 1-16 5.83h-39.82c-5.84 0-11.53-2.07-16-5.83l-30.51-25.6a24.95 24.95 0 0 1-8.52-14.75l-6.92-39.22c-1.01-5.76.04-11.71 2.96-16.77l19.91-34.49c2.92-5.06 7.56-8.95 13.05-10.95l37.42-13.62c2.74-.99 5.61-1.5 8.52-1.5m0-1.07c-3.01 0-6.01.52-8.88 1.57l-37.42 13.62a25.953 25.953 0 0 0-13.61 11.42l-19.91 34.49a25.938 25.938 0 0 0-3.08 17.5l6.92 39.22a26.04 26.04 0 0 0 8.88 15.39l30.51 25.6a26.008 26.008 0 0 0 16.69 6.08h39.82c6.11 0 12.02-2.15 16.69-6.08l30.51-25.6a25.93 25.93 0 0 0 8.88-15.39l6.92-39.22c1.06-6.01-.03-12.21-3.08-17.5l-19.91-34.49a25.953 25.953 0 0 0-13.61-11.42l-37.42-13.62a26.006 26.006 0 0 0-8.9-1.57z"
          style={{
            fill: 'url(#SVGID_6_)',
          }}
        />
        <linearGradient
          id="SVGID_7_"
          gradientUnits="userSpaceOnUse"
          x1={169.631}
          y1={615.193}
          x2={945.86}
          y2={615.193}
          gradientTransform="rotate(12.07 -1623.803 2315.6) scale(.226)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M557.81 467.31c3 .64 5.84 1.78 8.44 3.41l35.55 22.21c5.22 3.26 9.14 8.28 11.04 14.13l12.95 39.87c1.9 5.85 1.68 12.21-.63 17.92l-15.7 38.87c-2.3 5.7-6.56 10.43-12 13.32l-37.01 19.68a26.225 26.225 0 0 1-17.75 2.49l-41-8.72a26.201 26.201 0 0 1-15.2-9.5l-25.81-33.03a26.26 26.26 0 0 1-5.54-17.05l1.46-41.89c.21-6.15 2.6-12.05 6.72-16.62l28.05-31.15c4.12-4.57 9.74-7.56 15.83-8.42l41.51-5.83c3.03-.43 6.09-.33 9.09.31m.23-1.11c-3.1-.66-6.31-.78-9.49-.33l-41.51 5.83c-6.36.89-12.21 4-16.51 8.78l-28.05 31.15a27.396 27.396 0 0 0-7.01 17.34l-1.46 41.89a27.375 27.375 0 0 0 5.78 17.79l25.81 33.03c3.96 5.06 9.57 8.57 15.86 9.91l41 8.72c6.29 1.34 12.84.41 18.52-2.6l37.01-19.68a27.334 27.334 0 0 0 12.51-13.9l15.7-38.87c2.41-5.96 2.64-12.58.65-18.69L613.9 506.7a27.387 27.387 0 0 0-11.51-14.74l-35.55-22.21c-2.72-1.69-5.7-2.89-8.8-3.55z"
          style={{
            fill: 'url(#SVGID_7_)',
          }}
        />
        <linearGradient
          id="SVGID_8_"
          gradientUnits="userSpaceOnUse"
          x1={184.81}
          y1={605.789}
          x2={961.037}
          y2={605.789}
          gradientTransform="matrix(.2173 .0967 -.0967 .2173 474.123 364.042)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M576.67 468.72c2.95 1.31 5.62 3.12 7.94 5.36l31.74 30.65c4.66 4.5 7.6 10.52 8.27 16.96l4.61 43.88c.68 6.44-.94 12.94-4.57 18.31L600 620.47a27.58 27.58 0 0 1-15.27 11.09l-42.42 12.16a27.64 27.64 0 0 1-18.83-1.32l-40.31-17.95a27.554 27.554 0 0 1-13.57-13.11l-19.34-39.66a27.603 27.603 0 0 1-1.97-18.77l10.67-42.81a27.615 27.615 0 0 1 10.55-15.64l35.7-25.94a27.618 27.618 0 0 1 18.14-5.2l44.02 3.08c3.22.23 6.35 1.01 9.3 2.32m.48-1.08a28.718 28.718 0 0 0-9.7-2.42l-44.02-3.08a28.76 28.76 0 0 0-18.92 5.43l-35.7 25.94a28.808 28.808 0 0 0-11.01 16.32l-10.67 42.81c-1.64 6.56-.91 13.5 2.06 19.58l19.34 39.66a28.785 28.785 0 0 0 14.16 13.67L523 643.5a28.763 28.763 0 0 0 19.64 1.37l42.42-12.16c6.5-1.86 12.14-5.96 15.93-11.57l24.67-36.58a28.778 28.778 0 0 0 4.76-19.1l-4.61-43.88a28.766 28.766 0 0 0-8.63-17.69l-31.74-30.65a28.665 28.665 0 0 0-8.29-5.6z"
          style={{
            fill: 'url(#SVGID_8_)',
          }}
        />
        <linearGradient
          id="SVGID_9_"
          gradientUnits="userSpaceOnUse"
          x1={197.091}
          y1={594.036}
          x2={973.317}
          y2={594.036}
          gradientTransform="rotate(35.999 -275.942 955.573) scale(.2503)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M595.78 474.31c2.75 2 5.11 4.44 7.01 7.25l25.97 38.51a29.11 29.11 0 0 1 4.81 19.27l-4.86 46.19c-.71 6.78-3.8 13.12-8.71 17.85l-33.41 32.27c-4.9 4.73-11.35 7.6-18.15 8.08l-46.33 3.24c-6.8.48-13.58-1.47-19.09-5.48l-37.58-27.3a29.109 29.109 0 0 1-11.11-16.47l-11.24-45.07a29.085 29.085 0 0 1 2.08-19.76l20.36-41.75a29.058 29.058 0 0 1 14.29-13.8l42.43-18.89a29.16 29.16 0 0 1 19.82-1.39l44.65 12.8c3.26.96 6.31 2.45 9.06 4.45m.73-1.01a30.259 30.259 0 0 0-9.46-4.61l-44.65-12.8a30.29 30.29 0 0 0-20.67 1.45l-42.43 18.89a30.328 30.328 0 0 0-14.91 14.39l-20.36 41.75c-3.12 6.4-3.89 13.7-2.17 20.61l11.24 45.07a30.308 30.308 0 0 0 11.59 17.18l37.58 27.3a30.244 30.244 0 0 0 19.92 5.71l46.33-3.24c7.1-.5 13.81-3.48 18.93-8.43l33.41-32.27a30.284 30.284 0 0 0 9.08-18.62l4.86-46.19c.74-7.08-1.03-14.2-5.01-20.11l-25.97-38.51a30.082 30.082 0 0 0-7.31-7.57z"
          style={{
            fill: 'url(#SVGID_9_)',
          }}
        />
        <linearGradient
          id="SVGID_10_"
          gradientUnits="userSpaceOnUse"
          x1={206.212}
          y1={580.705}
          x2={982.44}
          y2={580.705}
          gradientTransform="rotate(48.005 -98.636 782.65) scale(.2635)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M614.23 484.24c2.39 2.66 4.29 5.69 5.63 9l18.32 45.33c2.69 6.65 2.95 14.07.73 20.9l-15.11 46.5a30.656 30.656 0 0 1-12.87 16.48l-41.46 25.91a30.613 30.613 0 0 1-20.45 4.35l-48.42-6.8c-7.1-1-13.66-4.48-18.46-9.82l-32.72-36.33a30.619 30.619 0 0 1-7.83-19.39l-1.71-48.86a30.623 30.623 0 0 1 6.46-19.89l30.1-38.53a30.621 30.621 0 0 1 17.73-11.08l47.82-10.17c7.02-1.49 14.37-.46 20.71 2.91l43.17 22.95c3.15 1.68 5.97 3.88 8.36 6.54m.98-.88a31.968 31.968 0 0 0-8.73-6.82l-43.17-22.95a31.94 31.94 0 0 0-21.6-3.04l-47.82 10.17a31.912 31.912 0 0 0-18.5 11.56l-30.1 38.53c-4.62 5.91-7 13.25-6.74 20.74l1.71 48.86a31.85 31.85 0 0 0 8.17 20.22l32.72 36.33c5.02 5.57 11.83 9.2 19.26 10.24l48.42 6.8c7.42 1.04 14.98-.56 21.33-4.53l41.46-25.91a31.888 31.888 0 0 0 13.43-17.19l15.11-46.5a31.86 31.86 0 0 0-.76-21.8l-18.32-45.33c-1.4-3.46-3.4-6.64-5.87-9.38z"
          style={{
            fill: 'url(#SVGID_10_)',
          }}
        />
        <linearGradient
          id="SVGID_11_"
          gradientUnits="userSpaceOnUse"
          x1={212.009}
          y1={566.555}
          x2={988.238}
          y2={566.555}
          gradientTransform="matrix(.1387 .2402 -.2402 .1387 592.867 328.337)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M631.06 498.51c1.88 3.26 3.17 6.79 3.82 10.5l8.94 50.68c1.31 7.44-.05 15.14-3.82 21.68l-25.73 44.57a32.22 32.22 0 0 1-16.86 14.15l-48.36 17.6a32.262 32.262 0 0 1-22.01 0l-48.36-17.6a32.22 32.22 0 0 1-16.86-14.15l-25.73-44.57a32.22 32.22 0 0 1-3.82-21.68l8.94-50.68a32.18 32.18 0 0 1 11.01-19.06l39.43-33.08a32.26 32.26 0 0 1 20.68-7.53h51.47c7.55 0 14.9 2.67 20.68 7.53l39.42 33.08c2.86 2.42 5.28 5.3 7.16 8.56m1.2-.7a33.53 33.53 0 0 0-7.49-8.93l-39.42-33.08a33.554 33.554 0 0 0-21.57-7.85h-51.47c-7.89 0-15.53 2.78-21.57 7.85l-39.42 33.08a33.577 33.577 0 0 0-11.48 19.88l-8.94 50.68c-1.37 7.77.04 15.78 3.99 22.61l25.73 44.57a33.593 33.593 0 0 0 17.59 14.76l48.36 17.6c7.42 2.7 15.54 2.7 22.96 0l48.36-17.6c7.42-2.7 13.64-7.92 17.59-14.76l25.73-44.57a33.537 33.537 0 0 0 3.99-22.61l-8.94-50.68c-.7-3.88-2.06-7.58-4-10.95z"
          style={{
            fill: 'url(#SVGID_11_)',
          }}
        />
        <linearGradient
          id="SVGID_12_"
          gradientUnits="userSpaceOnUse"
          x1={214.601}
          y1={552.213}
          x2={990.829}
          y2={552.213}
          gradientTransform="rotate(72.007 89.761 606.64) scale(.292)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M645.26 516.88c1.22 3.77 1.78 7.69 1.64 11.65l-1.89 54.14a33.946 33.946 0 0 1-8.68 21.48l-36.25 40.26a33.924 33.924 0 0 1-20.46 10.88l-53.65 7.54c-7.87 1.11-15.92-.6-22.66-4.82l-45.94-28.71a33.893 33.893 0 0 1-14.26-18.26l-16.74-51.52a33.88 33.88 0 0 1 .81-23.15l20.29-50.23a33.923 33.923 0 0 1 15.5-17.22l47.83-25.43a33.89 33.89 0 0 1 22.94-3.22l52.99 11.26a33.895 33.895 0 0 1 19.65 12.28l33.35 42.69c2.45 3.11 4.31 6.61 5.53 10.38m1.39-.45c-1.26-3.89-3.2-7.56-5.76-10.83l-33.35-42.69a35.311 35.311 0 0 0-20.5-12.81l-52.99-11.26c-8.12-1.73-16.6-.54-23.93 3.36l-47.83 25.43a35.346 35.346 0 0 0-16.17 17.96l-20.29 50.23a35.313 35.313 0 0 0-.84 24.15l16.74 51.52c2.57 7.9 7.83 14.64 14.88 19.04l45.94 28.71c7.04 4.4 15.41 6.18 23.64 5.02l53.65-7.54a35.316 35.316 0 0 0 21.34-11.35l36.25-40.26a35.32 35.32 0 0 0 9.05-22.41l1.89-54.14c.13-4.13-.45-8.24-1.72-12.13z"
          style={{
            fill: 'url(#SVGID_12_)',
          }}
        />
        <linearGradient
          id="SVGID_13_"
          gradientUnits="userSpaceOnUse"
          x1={214.192}
          y1={538.386}
          x2={990.421}
          y2={538.386}
          gradientTransform="rotate(84.006 148.476 555.318) scale(.3074)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M655.87 538.9c.44 4.15.15 8.3-.86 12.35l-13.8 55.33a35.697 35.697 0 0 1-13.64 20.22l-46.13 33.52a35.668 35.668 0 0 1-23.44 6.72l-56.89-3.98a35.702 35.702 0 0 1-22.28-9.92l-41.02-39.61a35.679 35.679 0 0 1-10.69-21.92l-5.96-56.71a35.715 35.715 0 0 1 5.9-23.66l31.89-47.28a35.673 35.673 0 0 1 19.73-14.33l54.82-15.72c8.04-2.31 16.68-1.7 24.33 1.7l52.1 23.19c7.64 3.4 13.88 9.42 17.54 16.94l25 51.25c1.81 3.76 2.96 7.76 3.4 11.91m1.53-.16c-.45-4.28-1.64-8.49-3.56-12.42l-25-51.25a37.133 37.133 0 0 0-18.3-17.67l-52.1-23.19a37.174 37.174 0 0 0-25.38-1.77l-54.82 15.72c-8.4 2.41-15.69 7.7-20.58 14.95l-31.89 47.28a37.148 37.148 0 0 0-6.15 24.68l5.96 56.71c.91 8.7 4.86 16.79 11.15 22.86l41.02 39.61a37.22 37.22 0 0 0 23.24 10.35l56.89 3.98a37.17 37.17 0 0 0 24.45-7.01l46.13-33.52a37.252 37.252 0 0 0 14.23-21.09l13.8-55.33c1.06-4.25 1.36-8.61.91-12.89z"
          style={{
            fill: 'url(#SVGID_13_)',
          }}
        />
        <linearGradient
          id="SVGID_14_"
          gradientUnits="userSpaceOnUse"
          x1={211.081}
          y1={525.616}
          x2={987.31}
          y2={525.616}
          gradientTransform="matrix(-.0338 .3218 -.3218 -.0338 729.386 376.058)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M661.97 563.9a37.56 37.56 0 0 1-3.59 12.53l-26.31 53.95a37.547 37.547 0 0 1-18.47 17.83l-54.84 24.42a37.595 37.595 0 0 1-25.61 1.79l-57.7-16.55a37.62 37.62 0 0 1-20.77-15.09l-33.57-49.76a37.567 37.567 0 0 1-6.21-24.91l6.27-59.7c.92-8.76 4.92-16.95 11.25-23.07l43.18-41.7a37.577 37.577 0 0 1 23.45-10.44l59.88-4.19c8.79-.61 17.55 1.9 24.68 7.08l48.56 35.28a37.646 37.646 0 0 1 14.36 21.28l14.52 58.24a37.51 37.51 0 0 1 .92 13.01m1.6.17c.47-4.51.16-9.1-.95-13.56l-14.52-58.24a39.187 39.187 0 0 0-14.97-22.2l-48.56-35.28a39.162 39.162 0 0 0-25.74-7.38l-59.88 4.19c-9.18.64-17.84 4.5-24.46 10.89l-43.18 41.7a39.145 39.145 0 0 0-11.74 24.07l-6.27 59.7a39.137 39.137 0 0 0 6.48 25.98l33.57 49.76a39.19 39.19 0 0 0 21.66 15.74l57.7 16.55a39.078 39.078 0 0 0 26.71-1.87l54.84-24.42a39.106 39.106 0 0 0 19.26-18.6l26.31-53.95a39.35 39.35 0 0 0 3.74-13.08z"
          style={{
            fill: 'url(#SVGID_14_)',
          }}
        />
        <linearGradient
          id="SVGID_15_"
          gradientUnits="userSpaceOnUse"
          x1={205.674}
          y1={514.362}
          x2={981.903}
          y2={514.362}
          gradientTransform="matrix(-.1052 .3239 -.3239 -.1052 769.088 412.887)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M662.77 590.97a39.45 39.45 0 0 1-6.44 12.11l-38.9 49.79a39.572 39.572 0 0 1-22.92 14.32l-61.81 13.14c-9.07 1.93-18.57.59-26.76-3.76l-55.79-29.66a39.546 39.546 0 0 1-18.08-20.08l-23.67-58.59a39.57 39.57 0 0 1-.94-27.01l19.53-60.09a39.535 39.535 0 0 1 16.64-21.29l53.59-33.48a39.582 39.582 0 0 1 26.43-5.62l62.57 8.79c9.18 1.29 17.66 5.8 23.86 12.69l42.28 46.96c6.2 6.89 9.8 15.79 10.12 25.06l2.21 63.15c.15 4.6-.49 9.18-1.92 13.57m1.62.53c1.47-4.54 2.16-9.33 1.99-14.17l-2.21-63.15a41.25 41.25 0 0 0-10.56-26.14l-42.28-46.96a41.139 41.139 0 0 0-24.89-13.23l-62.57-8.79c-9.59-1.35-19.36.73-27.57 5.86l-53.58 33.48a41.16 41.16 0 0 0-17.35 22.21l-19.53 60.09a41.223 41.223 0 0 0 .98 28.17l23.67 58.59a41.248 41.248 0 0 0 18.86 20.95l55.79 29.66a41.156 41.156 0 0 0 27.91 3.92l61.81-13.14c9.48-2.01 17.94-7.3 23.9-14.94l38.9-49.79c3-3.8 5.26-8.08 6.73-12.62z"
          style={{
            fill: 'url(#SVGID_15_)',
          }}
        />
        <linearGradient
          id="SVGID_16_"
          gradientUnits="userSpaceOnUse"
          x1={198.409}
          y1={504.958}
          x2={974.637}
          y2={504.958}
          gradientTransform="rotate(119.991 268.278 461.281) scale(.3585)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M657.68 619.02a41.458 41.458 0 0 1-9.28 11.06l-50.95 42.75a41.679 41.679 0 0 1-26.73 9.73H504.2c-9.76 0-19.25-3.46-26.73-9.73l-50.95-42.75a41.589 41.589 0 0 1-14.22-24.63l-11.55-65.5a41.67 41.67 0 0 1 4.94-28.01l33.26-57.6a41.622 41.622 0 0 1 21.79-18.28l62.5-22.75a41.642 41.642 0 0 1 28.45 0l62.5 22.75a41.667 41.667 0 0 1 21.79 18.28l33.26 57.6a41.67 41.67 0 0 1 4.94 28.01l-11.55 65.5c-.85 4.79-2.52 9.36-4.95 13.57m1.55.9c2.51-4.35 4.27-9.13 5.15-14.16l11.55-65.5a43.374 43.374 0 0 0-5.15-29.22l-33.26-57.6a43.405 43.405 0 0 0-22.73-19.07l-62.5-22.75a43.348 43.348 0 0 0-29.67 0l-62.5 22.75a43.405 43.405 0 0 0-22.73 19.07l-33.26 57.6a43.374 43.374 0 0 0-5.15 29.22l11.55 65.5a43.37 43.37 0 0 0 14.84 25.7l50.95 42.75a43.338 43.338 0 0 0 27.88 10.15h66.51c10.2 0 20.07-3.59 27.88-10.15l50.95-42.75c3.92-3.28 7.18-7.19 9.69-11.54z"
          style={{
            fill: 'url(#SVGID_16_)',
          }}
        />
        <linearGradient
          id="SVGID_17_"
          gradientUnits="userSpaceOnUse"
          x1={189.825}
          y1={497.667}
          x2={966.054}
          y2={497.667}
          gradientTransform="matrix(-.2525 .2804 -.2804 -.2525 825.489 514.67)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M646.3 646.79a43.755 43.755 0 0 1-11.98 9.36l-61.82 32.87c-9.07 4.82-19.6 6.3-29.65 4.17l-68.48-14.56a43.821 43.821 0 0 1-25.39-15.87l-43.1-55.17a43.816 43.816 0 0 1-9.25-28.48l2.44-69.97a43.802 43.802 0 0 1 11.22-27.76l46.85-52.03a43.797 43.797 0 0 1 26.44-14.06l69.33-9.74c10.17-1.43 20.58.78 29.29 6.23l59.37 37.1A43.802 43.802 0 0 1 660 472.47l21.63 66.59c3.17 9.77 2.8 20.4-1.04 29.92l-26.23 64.91c-1.92 4.76-4.64 9.1-8.06 12.9m1.4 1.26a45.56 45.56 0 0 0 8.4-13.45l26.23-64.91c4.02-9.95 4.41-21 1.09-31.21l-21.64-66.59a45.69 45.69 0 0 0-19.23-24.61l-59.37-37.1a45.61 45.61 0 0 0-30.55-6.49l-69.33 9.74a45.7 45.7 0 0 0-27.58 14.66l-46.85 52.03a45.655 45.655 0 0 0-11.7 28.96l-2.44 69.97a45.647 45.647 0 0 0 9.65 29.7l43.1 55.17a45.655 45.655 0 0 0 26.49 16.55l68.48 14.56c10.5 2.23 21.45.69 30.93-4.35l61.82-32.87c4.74-2.51 8.96-5.83 12.5-9.76z"
          style={{
            fill: 'url(#SVGID_17_)',
          }}
        />
        <linearGradient
          id="SVGID_18_"
          gradientUnits="userSpaceOnUse"
          x1={180.377}
          y1={492.601}
          x2={956.605}
          y2={492.601}
          gradientTransform="matrix(-.3214 .2335 -.2335 -.3214 837.697 576.65)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ed213a',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#8c031c',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ea384d',
            }}
          />
        </linearGradient>
        <path
          d="M628.5 672.89c-4.36 3.17-9.2 5.53-14.38 7.01l-70.84 20.31a46.141 46.141 0 0 1-31.44-2.2l-67.33-29.98a46.137 46.137 0 0 1-22.67-21.89l-32.31-66.24a46.14 46.14 0 0 1-3.29-31.35l17.83-71.51a46.227 46.227 0 0 1 17.62-26.13l59.62-43.32a46.163 46.163 0 0 1 30.3-8.69l73.52 5.14c10.79.75 21.01 5.31 28.79 12.82l53.01 51.19a46.134 46.134 0 0 1 13.82 28.33l7.7 73.29a46.17 46.17 0 0 1-7.62 30.58l-41.21 61.1c-3.02 4.5-6.76 8.37-11.12 11.54m1.17 1.61c4.5-3.27 8.44-7.32 11.6-12.01l41.21-61.1a48.058 48.058 0 0 0 7.95-31.9l-7.7-73.29a48.042 48.042 0 0 0-14.41-29.55l-53.01-51.19a48.064 48.064 0 0 0-30.03-13.37l-73.52-5.14a48.053 48.053 0 0 0-31.6 9.06l-59.62 43.32a48.03 48.03 0 0 0-18.38 27.26l-17.83 71.51a48.06 48.06 0 0 0 3.44 32.7l32.31 66.24a48.065 48.065 0 0 0 23.65 22.84l67.33 29.98a48.012 48.012 0 0 0 32.8 2.29l70.84-20.31c5.4-1.58 10.47-4.07 14.97-7.34z"
          style={{
            fill: 'url(#SVGID_18_)',
          }}
        />
        <g>
          <linearGradient
            id="SVGID_19_"
            gradientUnits="userSpaceOnUse"
            x1={170.616}
            y1={489.743}
            x2={946.844}
            y2={489.743}
            gradientTransform="matrix(-.382 .1701 -.1701 -.382 836.707 643.128)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M604.46 695.87a48.378 48.378 0 0 1-16.34 4.08l-77.39 5.41a48.593 48.593 0 0 1-31.89-9.14l-62.76-45.6a48.547 48.547 0 0 1-18.55-27.51l-18.77-75.27a48.62 48.62 0 0 1 3.47-33l34.01-69.73a48.597 48.597 0 0 1 23.87-23.05l70.87-31.55a48.573 48.573 0 0 1 33.1-2.31l74.57 21.38a48.563 48.563 0 0 1 26.84 19.5l43.38 64.31a48.557 48.557 0 0 1 8.03 32.19l-8.11 77.15a48.63 48.63 0 0 1-14.54 29.82l-55.8 53.89c-4.1 3.95-8.8 7.12-13.99 9.43m.85 1.91a50.433 50.433 0 0 0 14.57-9.83l55.8-53.89a50.559 50.559 0 0 0 15.17-31.1l8.11-77.15a50.616 50.616 0 0 0-8.37-33.58l-43.38-64.31a50.58 50.58 0 0 0-28-20.34l-74.57-21.38a50.62 50.62 0 0 0-34.52 2.41l-70.87 31.55a50.562 50.562 0 0 0-24.89 24.04l-34.01 69.73a50.668 50.668 0 0 0-3.62 34.42l18.77 75.27a50.612 50.612 0 0 0 19.35 28.69l62.76 45.6a50.597 50.597 0 0 0 33.27 9.54l77.39-5.41c5.93-.43 11.69-1.88 17.04-4.26z"
            style={{
              fill: 'url(#SVGID_19_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_20_"
            gradientUnits="userSpaceOnUse"
            x1={160.992}
            y1={489.01}
            x2={937.22}
            y2={489.01}
            gradientTransform="rotate(168.011 373.183 398.822) scale(.4401)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M574.69 714.27a50.976 50.976 0 0 1-17.72.62l-80.86-11.36a51.134 51.134 0 0 1-30.84-16.4l-54.64-60.68a51.132 51.132 0 0 1-13.08-32.38l-2.85-81.61a51.085 51.085 0 0 1 10.79-33.21l50.27-64.35a51.166 51.166 0 0 1 29.62-18.51l79.87-16.98c11.72-2.49 24-.77 34.58 4.86l72.1 38.34a51.157 51.157 0 0 1 23.37 25.95l30.59 75.71a51.141 51.141 0 0 1 1.22 34.9l-25.23 77.66a51.117 51.117 0 0 1-21.5 27.52l-69.25 43.27a50.84 50.84 0 0 1-16.44 6.65m.45 2.15a53.176 53.176 0 0 0 17.15-6.93l69.25-43.27a53.237 53.237 0 0 0 22.43-28.71l25.23-77.66a53.26 53.26 0 0 0-1.27-36.41l-30.59-75.71a53.24 53.24 0 0 0-24.38-27.07l-72.1-38.34a53.252 53.252 0 0 0-36.07-5.07l-79.87 16.98a53.252 53.252 0 0 0-30.89 19.3l-50.27 64.35a53.293 53.293 0 0 0-11.26 34.65l2.85 81.61A53.292 53.292 0 0 0 389 627.92l54.64 60.68a53.28 53.28 0 0 0 32.16 17.1l80.86 11.36c6.2.88 12.45.64 18.48-.64z"
            style={{
              fill: 'url(#SVGID_20_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_21_"
            gradientUnits="userSpaceOnUse"
            x1={151.89}
            y1={490.256}
            x2={928.119}
            y2={490.256}
            gradientTransform="rotate(180 395.09 389.106) scale(.4633)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M540 726.69c-6.29 0-12.47-1.09-18.38-3.24l-80.77-29.4a53.846 53.846 0 0 1-28.16-23.63l-42.98-74.44a53.798 53.798 0 0 1-6.38-36.2l14.93-84.65a53.858 53.858 0 0 1 18.38-31.84l65.85-55.25a53.802 53.802 0 0 1 34.54-12.57h85.96c12.61 0 24.88 4.47 34.54 12.57l65.85 55.25a53.829 53.829 0 0 1 18.38 31.84l14.93 84.65a53.856 53.856 0 0 1-6.38 36.2l-42.98 74.44a53.846 53.846 0 0 1-28.16 23.63l-80.77 29.4a53.785 53.785 0 0 1-18.4 3.24m0 2.32c6.49 0 12.98-1.13 19.17-3.38l80.77-29.4a56.052 56.052 0 0 0 29.37-24.65l42.98-74.44a56.035 56.035 0 0 0 6.66-37.76l-14.93-84.65a56.088 56.088 0 0 0-19.17-33.21L619 386.27a56.069 56.069 0 0 0-36.03-13.12h-85.96c-13.18 0-25.94 4.64-36.03 13.12l-65.85 55.25a56.037 56.037 0 0 0-19.17 33.21l-14.93 84.65a56.035 56.035 0 0 0 6.66 37.76l42.98 74.44a56.096 56.096 0 0 0 29.37 24.65l80.77 29.4c6.21 2.25 12.7 3.38 19.19 3.38z"
            style={{
              fill: 'url(#SVGID_21_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_22_"
            gradientUnits="userSpaceOnUse"
            x1={143.673}
            y1={493.201}
            x2={919.902}
            y2={493.201}
            gradientTransform="matrix(-.477 -.1014 .1014 -.477 743.665 840.265)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M501.57 731.9a56.555 56.555 0 0 1-18.22-7.36l-76.73-47.95a56.674 56.674 0 0 1-23.82-30.49l-27.96-86.05a56.691 56.691 0 0 1 1.35-38.67l33.89-83.89a56.687 56.687 0 0 1 25.89-28.76l79.89-42.48a56.68 56.68 0 0 1 38.32-5.39l88.5 18.81a56.645 56.645 0 0 1 32.82 20.51l55.71 71.3a56.693 56.693 0 0 1 11.96 36.8l-3.16 90.43a56.624 56.624 0 0 1-14.5 35.88l-60.54 67.24A56.651 56.651 0 0 1 610.8 720l-89.6 12.59c-6.55.91-13.16.68-19.63-.69m-.51 2.38a59.134 59.134 0 0 0 20.48.72l89.6-12.59a58.991 58.991 0 0 0 35.64-18.95l60.54-67.24a58.998 58.998 0 0 0 15.12-37.42l3.16-90.43a59.043 59.043 0 0 0-12.47-38.39l-55.71-71.3a58.992 58.992 0 0 0-34.23-21.39l-88.5-18.81a58.991 58.991 0 0 0-39.97 5.62l-79.89 42.48a58.988 58.988 0 0 0-27.01 30l-33.89 83.89a58.975 58.975 0 0 0-1.41 40.34l27.96 86.05a59.059 59.059 0 0 0 24.85 31.81l76.73 47.95a59.306 59.306 0 0 0 19 7.66z"
            style={{
              fill: 'url(#SVGID_22_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_23_"
            gradientUnits="userSpaceOnUse"
            x1={136.633}
            y1={497.551}
            x2={912.862}
            y2={497.551}
            gradientTransform="rotate(-155.996 436.112 374.499) scale(.5134)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M460.86 728.84a59.295 59.295 0 0 1-17.14-11.56l-68.51-66.16a59.662 59.662 0 0 1-17.86-36.61l-9.96-94.72a59.657 59.657 0 0 1 9.85-39.52l53.26-78.96a59.636 59.636 0 0 1 32.95-23.94L535 351.12a59.67 59.67 0 0 1 40.63 2.84l87.01 38.74a59.6 59.6 0 0 1 29.3 28.3l41.75 85.6a59.63 59.63 0 0 1 4.26 40.51l-23.04 92.41a59.647 59.647 0 0 1-22.78 33.77l-77.05 55.98a59.638 59.638 0 0 1-39.15 11.23l-95.01-6.64c-6.95-.5-13.7-2.18-20.06-5.02m-1.05 2.35a62.26 62.26 0 0 0 20.93 5.22l95.01 6.64a62.108 62.108 0 0 0 40.84-11.71l77.05-55.98a62.13 62.13 0 0 0 23.76-35.22l23.04-92.41a62.154 62.154 0 0 0-4.44-42.26l-41.75-85.6a62.094 62.094 0 0 0-30.56-29.52l-87.01-38.74a62.096 62.096 0 0 0-42.39-2.96l-91.55 26.25a62.123 62.123 0 0 0-34.37 24.97l-53.26 78.96a62.077 62.077 0 0 0-10.28 41.23l9.96 94.72a62.137 62.137 0 0 0 18.63 38.19l68.51 66.16a62.186 62.186 0 0 0 17.88 12.06z"
            style={{
              fill: 'url(#SVGID_23_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_24_"
            gradientUnits="userSpaceOnUse"
            x1={130.948}
            y1={502.983}
            x2={907.177}
            y2={502.983}
            gradientTransform="rotate(-144.001 455.601 369.294) scale(.5404)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M419.61 716.79a62.517 62.517 0 0 1-15.12-15.66l-56.06-83.12a62.746 62.746 0 0 1-10.37-41.6l10.48-99.71a62.77 62.77 0 0 1 18.8-38.54l72.12-69.64a62.784 62.784 0 0 1 39.17-17.44l100.01-6.99a62.764 62.764 0 0 1 41.22 11.82l81.11 58.93a62.801 62.801 0 0 1 23.98 35.55l24.25 97.28a62.767 62.767 0 0 1-4.48 42.64l-43.95 90.11a62.764 62.764 0 0 1-30.84 29.78l-91.59 40.78c-13.44 5.98-28.63 7.05-42.77 2.99l-96.37-27.63a62.484 62.484 0 0 1-19.59-9.55m-1.59 2.18a65.108 65.108 0 0 0 20.41 9.95l96.37 27.63a65.392 65.392 0 0 0 44.62-3.12L671 712.67a65.373 65.373 0 0 0 32.17-31.07l43.95-90.11a65.366 65.366 0 0 0 4.67-44.48l-24.25-97.28a65.373 65.373 0 0 0-25.01-37.08l-81.11-58.93a65.379 65.379 0 0 0-42.99-12.33l-100.01 6.99a65.38 65.38 0 0 0-40.86 18.19l-72.12 69.64a65.395 65.395 0 0 0-19.61 40.2l-10.48 99.71a65.357 65.357 0 0 0 10.82 43.4l56.06 83.12a65.57 65.57 0 0 0 15.79 16.33z"
            style={{
              fill: 'url(#SVGID_24_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_25_"
            gradientUnits="userSpaceOnUse"
            x1={126.715}
            y1={509.163}
            x2={902.943}
            y2={509.163}
            gradientTransform="rotate(-132 474.625 365.324) scale(.5688)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M379.77 695.35a65.771 65.771 0 0 1-12.14-19.43l-39.53-97.85a66.136 66.136 0 0 1-1.58-45.11l32.61-100.37a66.095 66.095 0 0 1 27.79-35.57l89.5-55.92a66.034 66.034 0 0 1 44.15-9.38l104.51 14.69a66.083 66.083 0 0 1 39.85 21.19l70.62 78.43a66.102 66.102 0 0 1 16.91 41.85l3.68 105.47a66.034 66.034 0 0 1-13.95 42.92l-64.97 83.16a66.098 66.098 0 0 1-38.28 23.92L535.7 765.28a66.068 66.068 0 0 1-44.69-6.28l-93.18-49.54a65.868 65.868 0 0 1-18.06-14.11m-2.11 1.9a68.79 68.79 0 0 0 18.84 14.72l93.18 49.54a68.768 68.768 0 0 0 46.62 6.55l103.23-21.94a68.839 68.839 0 0 0 39.93-24.95l64.97-83.16a68.851 68.851 0 0 0 14.55-44.77l-3.68-105.47a68.778 68.778 0 0 0-17.64-43.65l-70.62-78.43a68.83 68.83 0 0 0-41.57-22.1L520.96 328.9a68.807 68.807 0 0 0-46.05 9.79l-89.5 55.92a68.809 68.809 0 0 0-28.98 37.1l-32.61 100.37c-5 15.39-4.42 32.05 1.64 47.05l39.53 97.85c3.03 7.5 7.34 14.35 12.67 20.27z"
            style={{
              fill: 'url(#SVGID_25_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_26_"
            gradientUnits="userSpaceOnUse"
            x1={124.04}
            y1={515.744}
            x2={900.269}
            y2={515.744}
            gradientTransform="matrix(-.2994 -.5185 .5185 -.2994 425.899 971.04)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M343.45 664.56a69.275 69.275 0 0 1-8.25-22.67l-19.29-109.4c-2.83-16.05.1-32.67 8.25-46.79l55.54-96.2a69.595 69.595 0 0 1 36.39-30.54l104.39-37.99a69.512 69.512 0 0 1 47.51 0l104.39 37.99a69.557 69.557 0 0 1 36.39 30.54l55.54 96.2a69.575 69.575 0 0 1 8.25 46.79l-19.29 109.4a69.568 69.568 0 0 1-23.75 41.14l-85.1 71.41a69.552 69.552 0 0 1-44.64 16.25H488.7c-16.3 0-32.16-5.77-44.64-16.25l-85.1-71.41a69.149 69.149 0 0 1-15.51-18.47m-2.59 1.49c4.19 7.26 9.65 13.8 16.17 19.27l85.1 71.41a72.453 72.453 0 0 0 46.57 16.95h111.09c17.03 0 33.52-6 46.57-16.95l85.1-71.41a72.443 72.443 0 0 0 24.78-42.92L775.53 533a72.407 72.407 0 0 0-8.61-48.8L711.37 388a72.445 72.445 0 0 0-37.96-31.85l-104.39-37.99a72.403 72.403 0 0 0-49.56 0l-104.39 37.99A72.415 72.415 0 0 0 377.11 388l-55.54 96.2a72.464 72.464 0 0 0-8.61 48.8l19.29 109.4c1.48 8.4 4.41 16.39 8.61 23.65z"
            style={{
              fill: 'url(#SVGID_26_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_27_"
            gradientUnits="userSpaceOnUse"
            x1={122.819}
            y1={522.377}
            x2={899.047}
            y2={522.377}
            gradientTransform="matrix(-.1948 -.5994 .5994 -.1948 326.394 959.072)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M312.79 624.9a72.927 72.927 0 0 1-3.53-25.14l4.08-116.86c.6-17.15 7.25-33.62 18.73-46.37l78.24-86.9a73.23 73.23 0 0 1 44.16-23.48l115.8-16.27a73.197 73.197 0 0 1 48.92 10.4l99.17 61.97a73.215 73.215 0 0 1 30.79 39.41l36.13 111.21a73.23 73.23 0 0 1-1.75 49.98l-43.8 108.42a73.198 73.198 0 0 1-33.46 37.16l-103.25 54.9a73.181 73.181 0 0 1-49.52 6.96l-114.38-24.31c-16.78-3.57-31.85-12.98-42.41-26.5l-71.99-92.15a73.095 73.095 0 0 1-11.93-22.43m-2.99.98c2.73 8.4 6.91 16.32 12.43 23.38l71.99 92.15a76.21 76.21 0 0 0 44.24 27.64l114.38 24.31a76.235 76.235 0 0 0 51.66-7.26l103.25-54.9a76.28 76.28 0 0 0 34.91-38.77l43.8-108.42a76.231 76.231 0 0 0 1.82-52.13l-36.13-111.21a76.25 76.25 0 0 0-32.12-41.11l-99.17-61.97a76.283 76.283 0 0 0-51.02-10.85l-115.8 16.27a76.328 76.328 0 0 0-46.06 24.49l-78.24 86.9a76.207 76.207 0 0 0-19.54 48.37l-4.08 116.86c-.32 8.98.95 17.85 3.68 26.25z"
            style={{
              fill: 'url(#SVGID_27_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_28_"
            gradientUnits="userSpaceOnUse"
            x1={123.021}
            y1={528.785}
            x2={899.25}
            y2={528.785}
            gradientTransform="matrix(-.0693 -.6598 .6598 -.0693 226.56 924.99)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M289.9 577.37c-.94-8.95-.31-17.92 1.86-26.66l29.78-119.43a77.043 77.043 0 0 1 29.44-43.64l99.58-72.35a77.047 77.047 0 0 1 50.6-14.51l122.79 8.59a77.05 77.05 0 0 1 48.09 21.41l88.54 85.5a77.115 77.115 0 0 1 23.08 47.31L796.53 586a77.034 77.034 0 0 1-12.74 51.08l-68.83 102.04a77.08 77.08 0 0 1-42.59 30.94l-118.32 33.93c-17.36 4.98-36.01 3.67-52.51-3.67L389.1 750.26a77.09 77.09 0 0 1-37.87-36.57l-53.96-110.63a76.945 76.945 0 0 1-7.37-25.69m-3.29.34c.97 9.24 3.55 18.32 7.68 26.8l53.96 110.63a80.243 80.243 0 0 0 39.5 38.14l112.45 50.06a80.272 80.272 0 0 0 54.78 3.83l118.32-33.93a80.239 80.239 0 0 0 44.42-32.28l68.83-102.04a80.255 80.255 0 0 0 13.28-53.28l-12.87-122.41a80.278 80.278 0 0 0-24.07-49.35l-88.54-85.5a80.252 80.252 0 0 0-50.16-22.33l-122.79-8.59a80.19 80.19 0 0 0-52.78 15.14l-99.58 72.35a80.283 80.283 0 0 0-30.71 45.52L288.55 549.9a80.13 80.13 0 0 0-1.94 27.81z"
            style={{
              fill: 'url(#SVGID_28_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_29_"
            gradientUnits="userSpaceOnUse"
            x1={124.454}
            y1={534.703}
            x2={900.682}
            y2={534.703}
            gradientTransform="scale(.6983) rotate(-84 784.22 517.158)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M276.74 523.41a80.82 80.82 0 0 1 7.75-27.04l56.8-116.45a81.088 81.088 0 0 1 39.86-38.49l118.36-52.7c17.37-7.73 37-9.11 55.28-3.87l124.55 35.71a81.126 81.126 0 0 1 44.83 32.57l72.45 107.42c10.63 15.76 15.39 34.86 13.41 53.77l-13.54 128.86c-1.99 18.91-10.61 36.6-24.29 49.8l-93.2 90a81.14 81.14 0 0 1-50.62 22.54l-129.25 9.04c-18.97 1.33-37.88-4.1-53.27-15.27l-104.82-76.16c-15.38-11.18-26.39-27.49-30.99-45.94L278.7 551.48a80.883 80.883 0 0 1-1.96-28.07m-3.47-.36a84.343 84.343 0 0 0 2.05 29.27l31.34 125.72a84.524 84.524 0 0 0 32.32 47.92l104.82 76.16a84.445 84.445 0 0 0 55.56 15.93l129.25-9.04a84.506 84.506 0 0 0 52.8-23.51l93.2-90a84.511 84.511 0 0 0 25.34-51.95l13.54-128.86a84.457 84.457 0 0 0-13.98-56.08l-72.45-107.42a84.484 84.484 0 0 0-46.76-33.97L555.76 281.5a84.475 84.475 0 0 0-57.66 4.03l-118.36 52.7a84.52 84.52 0 0 0-41.58 40.15l-56.8 116.45a84.367 84.367 0 0 0-8.09 28.22z"
            style={{
              fill: 'url(#SVGID_29_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_30_"
            gradientUnits="userSpaceOnUse"
            x1={126.969}
            y1={539.915}
            x2={903.197}
            y2={539.915}
            gradientTransform="rotate(-71.997 565.467 362.937) scale(.7351)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M275 464.98a84.97 84.97 0 0 1 13.9-26.15l83.97-107.47a85.4 85.4 0 0 1 49.47-30.91l133.41-28.36a85.382 85.382 0 0 1 57.76 8.12l120.42 64.03a85.432 85.432 0 0 1 39.03 43.35l51.09 126.45a85.41 85.41 0 0 1 2.04 58.29l-42.15 129.71A85.382 85.382 0 0 1 748.03 748l-115.66 72.27c-16.97 10.61-37.23 14.91-57.05 12.13l-135.06-18.98c-19.82-2.79-38.11-12.51-51.5-27.38L297.5 684.69a85.416 85.416 0 0 1-21.85-54.08l-4.76-136.3a84.526 84.526 0 0 1 4.11-29.33m-3.5-1.14a89.092 89.092 0 0 0-4.3 30.59l4.76 136.3c.73 20.9 8.8 40.87 22.79 56.41l91.26 101.35a88.926 88.926 0 0 0 53.72 28.56l135.06 18.98a88.94 88.94 0 0 0 59.51-12.65l115.66-72.27a88.97 88.97 0 0 0 37.46-47.94l42.15-129.71a88.99 88.99 0 0 0-2.12-60.81L776.36 386.2a88.9 88.9 0 0 0-40.71-45.21l-120.42-64.03a88.917 88.917 0 0 0-60.25-8.47l-133.41 28.36a88.984 88.984 0 0 0-51.6 32.24L286 436.57a88.95 88.95 0 0 0-14.5 27.27z"
            style={{
              fill: 'url(#SVGID_30_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_31_"
            gradientUnits="userSpaceOnUse"
            x1={130.328}
            y1={544.273}
            x2={906.557}
            y2={544.273}
            gradientTransform="matrix(.3869 -.6701 .6701 .3869 -25.305 687.922)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M285.99 404.43a89.461 89.461 0 0 1 20.04-23.88L416 288.27a89.919 89.919 0 0 1 57.7-21h143.56c21.07 0 41.56 7.46 57.7 21l109.98 92.28c16.14 13.54 27.04 32.43 30.7 53.17l24.93 141.38c3.66 20.75-.13 42.22-10.66 60.47L758.12 759.9c-10.53 18.24-27.24 32.26-47.03 39.47l-134.91 49.1a89.85 89.85 0 0 1-61.4 0l-134.91-49.1a89.87 89.87 0 0 1-47.03-39.47l-71.78-124.33a89.914 89.914 0 0 1-10.66-60.47l24.93-141.38a89.393 89.393 0 0 1 10.66-29.29m-3.35-1.94a93.62 93.62 0 0 0-11.12 30.56l-24.93 141.38a93.664 93.664 0 0 0 11.12 63.07l71.78 124.33A93.682 93.682 0 0 0 378.55 803l134.91 49.1a93.597 93.597 0 0 0 64.04 0L712.41 803a93.6 93.6 0 0 0 49.06-41.17l71.78-124.33a93.607 93.607 0 0 0 11.12-63.07l-24.93-141.38a93.579 93.579 0 0 0-32.02-55.46L677.45 285.3a93.61 93.61 0 0 0-60.18-21.9H473.7c-22.01 0-43.32 7.76-60.18 21.9l-109.98 92.28a93.686 93.686 0 0 0-20.9 24.91z"
            style={{
              fill: 'url(#SVGID_31_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_32_"
            gradientUnits="userSpaceOnUse"
            x1={134.316}
            y1={547.644}
            x2={910.544}
            y2={547.644}
            gradientTransform="rotate(-48.001 600.69 370.006) scale(.8145)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M310.56 344.49c7.4-8.22 16.1-15.01 25.86-20.2l133.43-70.95c19.58-10.41 42.31-13.61 64-8.99l147.82 31.42c21.69 4.61 41.16 16.77 54.81 34.25l93.04 119.08c13.65 17.48 20.75 39.3 19.97 61.47l-5.27 151.03a94.597 94.597 0 0 1-24.21 59.92l-101.12 112.3c-14.84 16.48-35.1 27.26-57.06 30.34L512.17 865.2c-21.96 3.09-44.41-1.69-63.22-13.44l-128.16-80.08A94.6 94.6 0 0 1 281 720.75l-46.7-143.72a94.63 94.63 0 0 1 2.26-64.59l56.61-140.12a94.194 94.194 0 0 1 17.39-27.83m-3.03-2.72a98.491 98.491 0 0 0-18.14 29.03l-56.61 140.12a98.542 98.542 0 0 0-2.35 67.37l46.7 143.72a98.51 98.51 0 0 0 41.51 53.12l128.16 80.08a98.575 98.575 0 0 0 65.94 14.02l149.65-21.03a98.49 98.49 0 0 0 59.52-31.65l101.12-112.3a98.529 98.529 0 0 0 25.25-62.51l5.27-151.03a98.58 98.58 0 0 0-20.83-64.12l-93.04-119.08a98.528 98.528 0 0 0-57.17-35.72l-147.82-31.42a98.533 98.533 0 0 0-66.76 9.38L334.5 320.69a98.784 98.784 0 0 0-26.97 21.08z"
            style={{
              fill: 'url(#SVGID_32_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_33_"
            gradientUnits="userSpaceOnUse"
            x1={138.688}
            y1={549.992}
            x2={914.916}
            y2={549.992}
            gradientTransform="rotate(-36.006 618.136 375.395) scale(.8574)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M348.97 288.16a99.2 99.2 0 0 1 31.04-15.14l152.91-43.85c22.44-6.43 46.54-4.75 67.87 4.75l145.32 64.7c21.33 9.49 38.7 26.28 48.94 47.26l69.73 142.97c10.23 20.98 12.76 45.01 7.11 67.66l-38.48 154.35a99.64 99.64 0 0 1-38.04 56.4l-128.69 93.5a99.618 99.618 0 0 1-65.4 18.75l-158.69-11.1a99.637 99.637 0 0 1-62.15-27.67l-114.43-110.5a99.625 99.625 0 0 1-29.82-61.15l-16.63-158.2c-2.44-23.22 3.4-46.66 16.46-66.01L324.98 313a99.031 99.031 0 0 1 23.99-24.84m-2.52-3.47a103.713 103.713 0 0 0-25.03 25.92l-88.95 131.88a103.74 103.74 0 0 0-17.17 68.86l16.63 158.2a103.698 103.698 0 0 0 31.11 63.78l114.43 110.5a103.74 103.74 0 0 0 64.83 28.86l158.69 11.1c24.33 1.7 48.48-5.22 68.21-19.56l128.69-93.5a103.772 103.772 0 0 0 39.68-58.83l38.48-154.35c5.9-23.67 3.27-48.65-7.42-70.57L798.9 344.01a103.76 103.76 0 0 0-51.05-49.3L602.54 230a103.76 103.76 0 0 0-70.79-4.95L378.84 268.9a103.458 103.458 0 0 0-32.39 15.79z"
            style={{
              fill: 'url(#SVGID_33_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_34_"
            gradientUnits="userSpaceOnUse"
            x1={143.203}
            y1={551.314}
            x2={919.431}
            y2={551.314}
            gradientTransform="scale(.9025) rotate(-24.001 704.281 423.29)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M400.86 238.56a104.57 104.57 0 0 1 35.28-8.8l167.04-11.68c24.51-1.71 48.96 5.3 68.84 19.74l135.47 98.42c19.88 14.44 34.1 35.53 40.04 59.37l40.51 162.47c5.94 23.84 3.29 49.13-7.49 71.22l-73.4 150.5c-10.77 22.08-29.07 39.75-51.51 49.75l-152.97 68.11c-22.45 9.99-47.82 11.77-71.44 5L370.26 856.5c-23.62-6.77-44.19-21.72-57.94-42.09l-93.63-138.82a104.84 104.84 0 0 1-17.32-69.48l17.5-166.53c2.57-24.44 13.72-47.3 31.39-64.36L370.71 258.9c8.82-8.52 18.96-15.36 30.15-20.34m-1.84-4.12a109.03 109.03 0 0 0-31.44 21.21L247.13 371.96a109.23 109.23 0 0 0-32.75 67.14l-17.5 166.53c-2.68 25.53 3.71 51.2 18.07 72.48l93.63 138.82a109.248 109.248 0 0 0 60.43 43.91l160.96 46.15c24.68 7.08 51.06 5.23 74.52-5.21l152.97-68.11a109.163 109.163 0 0 0 53.73-51.89l73.4-150.5a109.24 109.24 0 0 0 7.81-74.29l-40.5-162.47c-6.21-24.91-21-46.84-41.77-61.93l-135.47-98.42a109.199 109.199 0 0 0-71.8-20.59l-167.04 11.68c-12.81.9-25.25 4.03-36.8 9.18z"
            style={{
              fill: 'url(#SVGID_34_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_35_"
            gradientUnits="userSpaceOnUse"
            x1={147.673}
            y1={551.652}
            x2={923.902}
            y2={551.652}
            gradientTransform="scale(.95) rotate(-12 687.42 410.655)"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M465.13 198.85c12.61-2.68 25.48-3.13 38.25-1.34l174.54 24.53c25.61 3.6 49.25 16.17 66.56 35.39l117.94 130.99a110.334 110.334 0 0 1 28.24 69.89l6.15 176.15c.9 25.85-7.37 51.31-23.29 71.69L765 845.05c-15.92 20.38-38.63 34.57-63.93 39.95l-172.41 36.65c-25.3 5.38-51.81 1.65-74.65-10.49L298.39 828.4c-22.84-12.14-40.75-32.04-50.44-56.02l-66.03-163.42c-9.69-23.98-10.62-50.74-2.63-75.33L233.76 366c7.99-24.6 24.47-45.69 46.41-59.4l149.48-93.4a109.447 109.447 0 0 1 35.48-14.35m-.99-4.65a114.875 114.875 0 0 0-37.01 14.95l-149.48 93.4a114.94 114.94 0 0 0-48.41 61.96l-54.47 167.63a114.97 114.97 0 0 0 2.74 78.58l66.03 163.42a114.885 114.885 0 0 0 52.61 58.43l155.63 82.75a114.935 114.935 0 0 0 77.87 10.94l172.41-36.65a114.953 114.953 0 0 0 66.68-41.67l108.52-138.89a114.959 114.959 0 0 0 24.3-74.78l-6.15-176.15a114.878 114.878 0 0 0-29.46-72.9L748.01 254.25a114.934 114.934 0 0 0-69.43-36.91l-174.54-24.53a114.895 114.895 0 0 0-39.9 1.39z"
            style={{
              fill: 'url(#SVGID_35_)',
            }}
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_36_"
            gradientUnits="userSpaceOnUse"
            x1={151.886}
            y1={551.08}
            x2={928.114}
            y2={551.08}
          >
            <stop
              offset={0}
              style={{
                stopColor: '#ed213a',
              }}
            />
            <stop
              offset={0.5}
              style={{
                stopColor: '#8c031c',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#ea384d',
              }}
            />
          </linearGradient>
          <path
            d="M540 172.02c13.57 0 26.92 2.35 39.67 7l174.35 63.46c25.58 9.31 47.17 27.43 60.78 51l92.77 160.68c13.61 23.58 18.51 51.33 13.78 78.14l-32.22 182.72c-4.73 26.81-18.82 51.22-39.67 68.72L707.33 903c-20.86 17.5-47.34 27.14-74.56 27.14H447.23c-27.23 0-53.71-9.64-74.56-27.14L230.54 783.74c-20.86-17.5-34.95-41.91-39.67-68.72L158.65 532.3c-4.73-26.81.17-54.56 13.78-78.14l92.77-160.68c13.61-23.58 35.2-41.69 60.78-51l174.35-63.46c12.75-4.64 26.1-7 39.67-7m0-5c-14.01 0-28.02 2.43-41.38 7.3l-174.35 63.46a121.007 121.007 0 0 0-63.4 53.2L168.1 451.66a121.024 121.024 0 0 0-14.37 81.51l32.22 182.72a121.003 121.003 0 0 0 41.38 71.68l142.13 119.26a120.976 120.976 0 0 0 77.78 28.31h185.54c28.45 0 55.99-10.02 77.78-28.31l142.13-119.26a121.003 121.003 0 0 0 41.38-71.68l32.22-182.72c4.94-28.02-.15-56.87-14.37-81.51l-92.77-160.68a120.963 120.963 0 0 0-63.4-53.2L581.4 174.32c-13.38-4.86-27.39-7.3-41.4-7.3z"
            style={{
              fill: 'url(#SVGID_36_)',
            }}
          />
        </g>
      </g>
    </switch>
  </svg>
);

export default Pattern;
