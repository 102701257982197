/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PostDashboardForm from 'dashboard/containers/PostDashboardForm';
import { usePostDetailsDashboard } from './usePostDetailsDashboard';
import './_PostDetails.scss';

const PostDetailsDashboard = () => {
  const { selectedVideo, onSubmit } = usePostDetailsDashboard();

  if (!selectedVideo) return <></>;

  return (
    <section className="content mt-3">
      <PostDashboardForm onSubmit={onSubmit} defaultValues={selectedVideo} />
    </section>
  );
};

export default PostDetailsDashboard;
