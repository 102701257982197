import * as React from 'react';

interface IProps {
  title: string | JSX.Element;
  backgroundStyle: 'dark-image-background' | 'dark-background' | 'white-background';
  firstDescription?: string | JSX.Element;
  secondDescription?: string;
  titleStyle: string;
  subtitleStyle?: string;
  descriptionStyle?: string;
  className?: string;
  styledTextSection?: React.Component;
  innerDivRef?: React.LegacyRef<HTMLDivElement>;
  isNotFullPage?: boolean;
  mobileTopMargin?: string;
}

const GalleryContainer: React.FC<IProps> = ({
  title,
  backgroundStyle,
  firstDescription,
  secondDescription,
  titleStyle,
  subtitleStyle,
  descriptionStyle,
  className,
  children,
  innerDivRef,
  isNotFullPage,
  mobileTopMargin,
}) => (
  <div
    className={`bg-cover ${backgroundStyle} ${
      isNotFullPage ? '' : 'min-vh-100'
    } d-flex justify-content-center  ${className || ''}`}
    ref={innerDivRef}
  >
    <div className="d-flex flex-column w-80 justify-content-center align-items-center">
      <div className={`col-lg-9 col-xl-7 mobile-top-${mobileTopMargin || '30'}`}>
        <div className="mb-1 mb-md-6 mt-sm-4">
          <span className={`text-oxygen ${titleStyle || ''} font-weight-bold title-section`}>
            {title}
          </span>
        </div>
        {firstDescription && (
          <div className="mb-3">
            <span className={`${subtitleStyle || ''} subtitle-section`}>
              {firstDescription}
            </span>
          </div>
        )}
        {secondDescription && (
          <div className="mb-5">
            <span
              className={`description-text-regular ${
                descriptionStyle || ''
              } description-section`}
            >
              {secondDescription}
            </span>
          </div>
        )}
        <div className="d-flex justify-content-center">{children}</div>
      </div>
    </div>
  </div>
);

export default GalleryContainer;
