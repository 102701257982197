import Collapsible from 'client/components/Collapsible';
import { useFAQContent } from 'client/containers/FAQSection/useFAQContents';
import React from 'react';

const FAQSection = () => {
  const { FAQcontent, t, isOpenIndex, setisOpenIndex } = useFAQContent();

  return (
    <div className="space-bottom-4">
      <div className="background container-fluid">
        <div className="container w-lg-50 w-md-60 w-sm-80 w-auto  p-4">
          <h2 className="text-white font-weight-bold">
            {t('FAQSection.QuestionsFréquentes')}
          </h2>
          <p>{t('FAQSection.Info')}</p>
        </div>
      </div>
      <div className="quest-container container ">
        {FAQcontent.map((el, i) => (
          <Collapsible
            open={i === isOpenIndex}
            callback={({ isOpen }) => (isOpen ? setisOpenIndex(i) : setisOpenIndex(-1))}
            key={el.question}
            title={el.question}
          >
            {el.answer}
          </Collapsible>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
