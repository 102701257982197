/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import Footer from 'client/containers/Footer';
import NavBarContainer from 'client/containers/NavBarContainer';
const LegalNotivePage: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  return (
    <>
      <div>
        <NavBarContainer isDarkBackground />
      </div>

      <main
        id="content"
        role="main"
        style={{ overflow: 'hidden' }}
        className="position-relative"
      >
        <div className="container col-12 col-sm-7" style={{ marginTop: '10rem' }}>
          <p
            style={{
              marginTop: '0pt',
              marginBottom: '8pt',
              textAlign: 'center',
              pageBreakBefore: 'always',
              lineHeight: '107%',
              fontSize: '20pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Calibri', fontWeight: 'bold' }}>
              POLITIQUE DE CONFIDENTIALITE
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              textAlign: 'center',
              lineHeight: '107%',
              fontSize: '20pt',
            }}
          >
            <span style={{ fontFamily: 'Calibri', fontWeight: 'bold' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-muted text-center" style={{ fontFamily: 'Oxygen' }}>
              Dernière mise à jour le 05 Novembre 2021.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Cette Déclaration de confidentialité explique comment Ream gère les données et
              informations personnelles et le respect de la vie privée. Cette politique peut
              être modifiée à l’occasion. Nous invitons les visiteurs et les utilisateurs de
              notre site à consulter régulièrement notre Politique de confidentialité.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>Toutes les «</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Données personnelles
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              » (toute information identifiante ou permettant d’identifier, de contacter ou de
              localiser une personne à laquelle cette information se réfère) que nous
              recueillons et conservons sont soumises à la présente politique de
              confidentialité, laquelle peut être modifiée ponctuellement.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>Toute utilisation des termes «</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>Ream</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>», «</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>nous</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>», «</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>notre</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>» ou «</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>nos</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              » dans cet avis de confidentialité fait référence à l’entité Ream qui fait office
              de responsable du traitement des données ou de leur sous-traitant.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Collecte de vos Données personnelles
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              En tant que visiteur du site et de l’application, vous pouvez participer à toutes
              sortes d’activités sans fournir aucune donnée personnelle.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '23pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Toutefois, lorsque vous vous inscrivez pour utiliser le site ou l’application
              Ream et commandez des produits ou des services, pour vous fournir les services en
              question, nous pouvons collecter les données personnelles suivantes
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>:</span>
          </p>
          <ul style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'disc' }}>
            <li
              style={{
                marginTop: '23pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Coordonnées telles que votre nom, vos numéros de téléphone et de fax, votre
                adresse postale et votre adresse e-mail.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Informations de facturation telles que le numéro, la date d’expiration et le
                type de carte bancaire que vous utilisez, ainsi que l’adresse associée à cette
                carte.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Informations démographiques telles que l’intitulé de votre poste, votre
                description de poste, le nom et le type d’entreprise pour laquelle vous
                travaillez.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '9pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Des données de profil, y compris votre nom d’utilisateur et votre mot de passe,
                des détails sur vos achats et le contenu que vous achetez sous licence chez
                nous.
              </span>
            </li>
          </ul>
          <p
            style={{
              marginTop: '9pt',
              marginBottom: '23pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Lorsque vous envoyez du contenu à Ream à utiliser sous licence, les informations
              suivantes peuvent vous être demandées
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>:</span>
          </p>
          <ul style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'disc' }}>
            <li
              style={{
                marginTop: '23pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Coordonnées telles que votre nom, votre numéro de téléphone, votre adresse
                postale et votre adresse e-mail.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '9pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Des informations relatives au contributeur et au paiement, y compris, mais sans
                s’y limiter, le moyen de paiement, l’e-mail associé au paiement, le nom
                d’affichage souhaité, l’objectif du contenu, l’équipement vidéo utilisé, ainsi
                que votre niveau de compétences et votre processus créatif.
              </span>
            </li>
          </ul>
          <p
            style={{
              marginTop: '9pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Selon l’activité, certaines des informations que nous vous demandons de nous
              fournir sont à caractère obligatoire et d’autres à caractère facultatif. Si vous
              ne communiquez pas les données à caractère obligatoire relatives à une activité
              particulière, vous ne pourrez pas participer à cette activité.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Comme sur la plupart des sites web ou applications, quand vous utilisez le site
              ou l’application Ream, Ream ou les sous-traitants tiers sous contrat avec Ream
              peuvent aussi recueillir certaines informations d’ordre technique ou de routage
              au sujet de votre ordinateur, afin d’améliorer votre utilisation du site et des
              services. Par exemple, nous pouvons consigner des renseignements sur votre
              environnement, comme le type de navigateur, le système d’exploitation, la vitesse
              du processeur et l’adresse IP de votre ordinateur. Nous utilisons ces variables
              pour faciliter et suivre votre utilisation du site ou de l’application et de ses
              services. Ream s’en sert également pour évaluer le trafic du ou de l’application.
              Pour mieux cerner les besoins de nos visiteurs sur le site ou l’application, nous
              établissons parfois un lien entre ces données et vos données personnelles.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Pour réduire le nombre d’erreurs dans notre base de données, authentifier les
              utilisateurs et éviter les emplois abusifs de notre système, nous pouvons parfois
              compléter les données personnelles que vous nous fournissez par des informations
              provenant de sources tierces. Nous pouvons compléter les informations que nous
              collectons directement auprès de vous par des informations stockées dans des
              bases de données tierces, par exemple des informations démographiques ou
              d’entreprise pour mieux cibler les contenus marketing que nous vous envoyons en
              fonction de vos centres d’intérêt.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Lorsque vous téléchargez et utilisez notre application mobile ou nos services,
              nous recueillons automatiquement des données sur le type d’appareil que vous
              utilisez et la version de votre système d’exploitation. Nous ne vous demandons
              pas de pouvoir accéder à tout moment à des informations géolocalisées, ni de
              pouvoir les suivre, lors du téléchargement ou de l’utilisation de notre
              application mobile ou de nos services.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Mise à jour de vos données personnelles et de vos préférences en matière de
              confidentialité
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Vous avez le droit d&apos;accéder à vos données personnelles et à vos préférences
              en matière de confidentialité et de les rectifier ou de les supprimer à tout
              moment. Pour ce faire, cliquez sur «
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>Mon profil</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>» dans l’application et sur «</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>Se connecter</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              » dans le site. Vous pouvez ensuite afficher et modifier immédiatement la plupart
              de vos données personnelles. Si vous souhaitez supprimer votre compte ou demander
              à ce que nous n’utilisions plus vos informations pour vous fournir nos services,
              contacter le support à «
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>contact@ream-movie.com</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              ». Pour des raisons de sécurité, certaines données personnelles ne peuvent être
              modifiées qu’en contactant le support. Nous répondrons à votre demande aussi vite
              que possible.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Utilisation de vos Données personnelles
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '23pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Ream collecte des informations vous concernant afin de vous fournir des services,
              de remplir ses obligations et d’améliorer ses produits et services. Nous ne
              vendons pas, ne louons pas et ne communiquons pas d’informations permettant de
              vous identifier à des tiers autrement que conformément à cette Politique de
              confidentialité. Ream peut utiliser ces informations :
            </span>
          </p>
          <ul style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'disc' }}>
            <li
              style={{
                marginTop: '23pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Authentifier et fournir l’accès aux sites
              </span>
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                web, application mobile et services de Ream.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Traiter vos transactions financières.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Vous envoyez des confirmations de commande/renouvellement.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Enregistrer vos droits, le cas échéant, auprès d’une assistance technique ou
                d’autres avantages proposés aux utilisateurs inscrits.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Répondre aux demandes, questions et préoccupations envoyées au service client.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Gérer votre compte.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Envoyer l’information que vous aviez demandée à propos d’un service ou d’un
                produit.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Vous tenir informé des offres spéciales et des services de Ream et des tiers
                sélectionnés.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Gérer les promotions et les tirages au sort auxquels vous participez et vous
                informer des résultats.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Enquêter sur, prévenir ou prendre des mesures à l’égard de toute activité
                illégale ou toute violation de nos Conditions d’utilisation du service.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Répondre à vos besoins en matière de recherche et développement de produits ou
                services et améliorer notre Site, nos services et nos offres.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '9pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Personnaliser votre expérience, notamment les services, les résultats de
                recherche et les offres que nous vous proposons.
              </span>
            </li>
          </ul>
          <p
            style={{
              marginTop: '9pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Nous stockons les informations que nous recueillons via les cookies, les fichiers
              journaux, les images transparentes ou les sources extérieures afin de créer un
              résumé de vos préférences. Nous associons vos données personnelles ou
              l’historique d’utilisation de vos préférences dans le but de vous proposer des
              promotions et des offres commerciales adaptées, d’améliorer le contenu du site et
              de l’application ou de déterminer vos préférences.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Dans certaines situations, conformément aux obligations légales, la société Ream
              peut être tenue de divulguer des données à caractère personnel à la demande des
              autorités publiques, y compris pour se conformer aux obligations en matière de
              sûreté nationale ou d’application de la loi.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>Lorsque la loi l’exige (p.</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              ex. dans le cadre d’une citation à comparaître, d’un mandat, d’une décision de
              justice ou d’une procédure judiciaire à l’encontre de Ream) et quand nous pensons
              qu’une telle divulgation est nécessaire pour protéger nos droits, éviter un
              litige, assurer votre sécurité et celle d’autrui, enquêter sur une fraude et/ou
              répondre à une demande administrative. Nous pouvons également divulguer des
              informations vous concernant afin de nous conformer à nos obligations légales si
              nous considérons qu’une telle divulgation devrait être réalisée pour des
              questions de sécurité nationale, d’application de la loi ou toute autre question
              d’intérêt public.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Bases juridiques relatives au traitement des données
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '23pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Pour les individus résidant dans l’Espace économique européen (EEE) et au
              Royaume-Uni, nous nous appuyons sur les bases juridiques suivantes pour le
              traitement de vos données à caractère personnel
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>:</span>
          </p>
          <ul style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'disc' }}>
            <li
              style={{
                marginTop: '23pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Le traitement des données est nécessaire pour exécuter notre contrat avec vous
              </span>
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>:</span>
              <ul
                style={{
                  marginRight: '0pt',
                  marginLeft: '0pt',
                  paddingLeft: '0pt',
                  listStyleType: 'circle',
                }}
              >
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour fournir l’accès aux sites web, application mobile et services de Ream
                    afin de respecter les conditions de votre contrat.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour personnaliser votre expérience, notamment les services, les résultats
                    de recherche et les offres que nous vous proposons.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour fournir des informations afin que vous puissiez prendre des décisions
                    concernant les services proposés par Ream.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour traiter vos transactions financières et vous envoyer les confirmations
                    de commande/renouvellement.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour enregistrer vos droits, le cas échéant, à une assistance technique ou
                    d’autres avantages proposés aux utilisateurs inscrits.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour répondre aux demandes, questions et préoccupations que vous envoyez au
                    service client.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour gérer votre compte.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <p
            style={{
              marginTop: '14pt',
              marginBottom: '14pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <ul style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'disc' }}>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Le traitement des données est nécessaire à la sauvegarde des intérêts vitaux de
                la personne concernée ou d’une autre personne physique
              </span>
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>:</span>
              <ul
                style={{
                  marginRight: '0pt',
                  marginLeft: '0pt',
                  paddingLeft: '0pt',
                  listStyleType: 'circle',
                }}
              >
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour examiner et empêcher toute violation des droits de propriété
                    intellectuelle.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour confirmer la validité des licences associées au contenu de Ream.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour examiner et empêcher toute violation de nos Conditions d’utilisation
                    du service, ou prendre les mesures adéquates.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <p
            style={{
              marginTop: '14pt',
              marginLeft: '72pt',
              marginBottom: '14pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <ul style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'disc' }}>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Le traitement des données est nécessaire à la sauvegarde des intérêts légitimes
                de Ream
              </span>
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>:</span>
              <ul
                style={{
                  marginRight: '0pt',
                  marginLeft: '0pt',
                  paddingLeft: '0pt',
                  listStyleType: 'circle',
                }}
              >
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour fournir, améliorer et personnaliser notre site
                  </span>
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>&nbsp;</span>
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    web, l’application et nos services.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour administrer nos opérations et respecter nos obligations contractuelles
                    envers nos clients et contributeurs.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour analyser et comprendre la manière dont nos plates-formes en ligne sont
                    utilisées.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour garantir la sécurité et l’intégrité du site
                  </span>
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>&nbsp;</span>
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    web, application mobile et autres services.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour vous envoyer les informations demandées relatives à un produit ou
                    service et vous tenir informé(e) des offres spéciales et services de Ream
                    et des tiers sélectionnés.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour gérer les promotions et les tirages au sort.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour la recherche et le développement de produits ou services afin
                    d’améliorer notre site, nos services et nos offres.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <p
            style={{
              marginTop: '14pt',
              marginLeft: '72pt',
              marginBottom: '14pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <ul style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'disc' }}>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Le traitement des données est nécessaire pour respecter les obligations
                juridiques de Ream, notamment
              </span>
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>:</span>
              <ul
                style={{
                  marginRight: '0pt',
                  marginLeft: '0pt',
                  paddingLeft: '0pt',
                  listStyleType: 'circle',
                }}
              >
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour examiner et empêcher toute violation des droits de propriété
                    intellectuelle, la fraude, l’envoi de courriers indésirables, le
                    harcèlement, les actes criminels et les risques pour la sécurité.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '14pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour respecter les lois et réglementations en vigueur imposant des
                    sanctions relatives au blanchiment d’argent et à la corruption.
                  </span>
                </li>
                <li
                  style={{
                    marginTop: '14pt',
                    marginLeft: '29pt',
                    marginBottom: '13pt',
                    paddingLeft: '7pt',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                    Pour répondre aux obligations de communication des rapports financiers et
                    d’autres obligations conformément aux lois sur les valeurs mobilières
                    applicables.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <p
            style={{
              marginTop: '13pt',
              marginLeft: '72pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Partage d’informations avec les fournisseurs de service ou les agents
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Ream a recours à une ou plusieurs entreprises de traitement des paiements par
              carte bancaire pour vous facturer nos produits et services. À notre connaissance,
              ces entreprises ne conservent pas, ne partagent pas, ne stockent pas et
              n’utilisent pas d’informations d’identification personnelle à d’autres fins que
              celle-ci.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Nous communiquons également des données personnelles à certaines entreprises qui
              fournissent des services pour notre compte. Nous ne fournissons que les données
              personnelles qui leur sont nécessaires pour fournir ces services. Nous exigeons
              des entreprises avec lesquelles nous partageons des données personnelles qu’elles
              protègent ces données conformément à la présente politique et qu’elles se
              limitent à utiliser ces données personnelles dans le cadre des services qu’elles
              fournissent pour le compte de Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Nous ne vendons pas et ne fournissons pas les données personnelles à d’autres
              entreprises qui commercialisent leurs propres produits et services.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Conservation et portabilité des données
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Nous conserverons vos informations aussi longtemps que votre compte sera actif.
              Nous en avons notamment besoin pour vous fournir des services, valider ou faire
              respecter les autorisations d’utilisation du contenu, le cas échéant, ou
              respecter nos obligations légales. Si vous souhaitez supprimer votre compte ou
              demander que nous n’utilisions plus vos informations pour vous fournir nos
              services, contacter le support au «
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>contact@ream-movie.com</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>»</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              sur le site. Si vous ne parvenez pas à accéder à votre compte, veuillez envoyer
              un courriel à l’adresse
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              contact@ream-movie.com. Nous traiterons votre demande sous 30
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              jours. Nous conserverons et utiliserons vos informations, s&apos;il y a lieu,
              pour nous conformer à nos obligations juridiques, résoudre des litiges et honorer
              nos contrats.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Vos choix (Se désinscrire)
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Ream ne vend pas vos données ou informations personnelles à des tiers. Nous
              utilisons des services publicitaires tiers pour gérer les publicités que nous
              effectuons sur d’autres sites. Notre partenaire tiers est susceptible d’utiliser
              des technologies, telles que des cookies et des outils de web tracking, pour
              recueillir des informations à propos de vos activités sur ce site et d’autres
              sites, afin d’afficher des publicités basées sur vos activités de navigation et
              vos centres d’intérêt.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Ream utilisera uniquement vos informations aux fins décrites dans la présente
              Déclaration de confidentialité et n’utilisera pas vos données personnelles à des
              fins sensiblement différentes sans votre autorisation expresse.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Vous pouvez demander à ne plus recevoir les actualités, les newsletters de Ream
              ou les e-mails de nos partenaires. Pour cela, contacter le support à «
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>contact@ream-movie.com</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>»</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              sur le site. Ream continuera à vous contacter en cas de modification des
              Conditions d’utilisation du service ou des Conditions d’utilisation du service
              pour les contributeurs. En outre, nous continuerons à vous envoyer les annonces
              liées aux services, y compris, mais sans s’y limiter, un e-mail concernant une
              inscription, des e-mails relatifs à des avantages proposés, des e-mails
              automatiquement envoyés lorsque vous effectuez certaines actions sur le site ou
              l’application et des avis relatifs aux factures. En règle générale, vous ne
              pouvez pas vous soustraire à ces communications qui n’ont pas de caractère
              promotionnel en soi. Si vous ne souhaitez pas les recevoir, vous pouvez faire
              pour désactiver votre compte.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Si vous utilisez l’application mobile Ream, vous pouvez recevoir
              occasionnellement des notifications push de notre part vous informant de tout
              événement ou promotion que nous pourrions mettre en place. Si vous ne souhaitez
              plus recevoir ce type de communications, vous pouvez les désactiver dans «
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>Mon profil</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>».</span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Pour demander la suppression de vos données à caractère personnel de nos systèmes
              et/ou l’interruption du traitement ultérieur de vos
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>
              données à caractère personnel, contacter le support à «
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>contact@ream-movie.com</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>». </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Protection de vos Données personnelles
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '23pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Les données personnelles que vous fournissez lorsque vous utilisez le site ou
              l’application sont protégées de différentes façons.
            </span>
          </p>
          <ul style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'disc' }}>
            <li
              style={{
                marginTop: '23pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Vous pouvez accéder à votre profil en utilisant un mot de passe et un
                identifiant client unique que vous choisissez. Le mot de passe est chiffré.
                Nous vous conseillons d’utiliser un mot de passe alphanumérique fiable et de ne
                le divulguer à personne.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Vos données personnelles sont stockées sur un serveur sécurisé, auquel seuls
                certains membres du personnel de Ream et certains fournisseurs de Ream ont
                accès via un mot de passe.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Vos données personnelles sont chiffrées chaque fois qu’elles sont transmises à
                Ream.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '9pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Quand vous saisissez des informations sensibles (comme le numéro de votre carte
                bancaire) dans nos formulaires de commande ou d’inscription, nous chiffrons ces
                données en utilisant la technologie de sécurité TLS.
              </span>
            </li>
          </ul>
          <p
            style={{
              marginTop: '9pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Nous nous efforçons de protéger les données personnelles qui nous sont envoyées,
              aussi bien pendant leur transmission qu’une fois que nous les avons reçues.
              Toutefois, aucune méthode de transmission sur Internet et aucune méthode de
              stockage électronique ne sont sûres à 100
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              %. Bien que nous tenions compte du caractère sensible des données personnelles
              que nous collectons, traitons et stockons, et de l’état actuel des technologies
              pour mettre en place ces mesures afin de protéger vos données personnelles, nous
              ne pouvons garantir leur sécurité à 100
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>%.</span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Si vous avez des questions sur la sécurité de no
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>tre</span>
            <span style={{ fontFamily: 'Oxygen' }}> </span>
            <span style={{ fontFamily: 'Oxygen' }}>s</span>
            <span style={{ fontFamily: 'Oxygen' }}>ite </span>
            <span style={{ fontFamily: 'Oxygen' }}>et de l’application</span>
            <span style={{ fontFamily: 'Oxygen' }}>, vous pouvez nous envoyer un e-mai</span>
            <span style={{ fontFamily: 'Oxygen' }}>l à </span>
            <a
              href="mailto:contact@ream-movie.com"
              target="_top"
              style={{ textDecoration: 'none' }}
            >
              <span
                style={{ fontFamily: 'Oxygen', textDecoration: 'underline', color: '#0000ff' }}
              >
                contact@ream-movie.com
              </span>
            </a>
            <span style={{ fontFamily: 'Oxygen' }}>.</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Cookie et technologies de suivi
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Le site peut recueillir automatiquement des informations pendant que vous
              parcourez son contenu, telles que le fournisseur d’accès à Internet, le type et
              la version du navigateur, le système d’exploitation et le type d’appareil, le
              temps moyen passé sur notre ou nos sites, les pages consultées, les informations
              consultées, l’adresse
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              IP utilisée pour connecter votre ordinateur à Internet et d’autres statistiques
              pertinentes. Ces informations sont des données de mesure d’audience web, parfois
              appelées flux de clics.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Il se peut que nous combinions ces informations recueillies automatiquement dans
              le fichier journal avec d’autres informations que nous recueillons à votre sujet.
              Nous procédons de la sorte pour améliorer les services offerts, les activités
              marketing et les analyses effectuées, ainsi que pour garantir le bon
              fonctionnement du site.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '23pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Des technologies, telles que les cookies, les balises et les scripts, sont
              utilisées par Ream, ses affiliés et les services d’analyse pour permettre le bon
              fonctionnement et la fonctionnalité du site et de l’application. Ces technologies
              sont utilisées pour analyser les tendances, administrer le site, suivre les
              mouvements des utilisateurs sur le site et l’application et recueillir les
              données démographiques de l’ensemble de notre base d’utilisateurs. Les rapports
              basés sur l’utilisation de ces technologies que nous envoient ces sociétés
              peuvent être détaillés par individu ou contenir des résultats agrégés.
            </span>
          </p>
          <ul style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'disc' }}>
            <li
              style={{
                marginTop: '23pt',
                marginLeft: '27.6pt',
                marginBottom: '14pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Un cookie est un petit fichier texte unique qu’un site web peut envoyer à votre
                ordinateur lorsque vous visitez un site. Nous pouvons utiliser des cookies de
                session qui disparaissent lorsque l’utilisateur ferme son navigateur, ou des
                cookies persistants qui restent sur l’ordinateur jusqu’à ce qu’ils soient
                supprimés manuellement. La plupart des navigateurs web peuvent vous alerter sur
                l’utilisation de cookies ou refuser systématiquement de les accepter. Si vous
                ne souhaitez pas que nous déployions de cookies sur votre navigateur, vous
                pouvez configurer ce dernier pour qu’il les rejette ou qu’il vous informe
                lorsqu’un site web essaie d’en placer un sur votre
              </span>
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                ordinateur. Le fait de rejeter les cookies peut vous empêcher d’utiliser
                correctement le site.
              </span>
            </li>
            <li
              style={{
                marginTop: '14pt',
                marginLeft: '27.6pt',
                marginBottom: '18pt',
                paddingLeft: '8.4pt',
                fontFamily: 'serif',
                fontSize: '10pt',
                backgroundColor: '#ffffff',
              }}
            >
              <span style={{ fontFamily: 'Oxygen', fontSize: '14.5pt' }}>
                Nous nous associons à un tiers soit pour afficher des publicités sur notre site
                web, soit pour gérer nos annonces sur d’autres sites. Notre partenaire tiers
                est susceptible d’utiliser des technologies, telles que des cookies, pour
                recueillir des informations à propos de vos activités sur ce site et d’autres
                sites, afin d’afficher des publicités basées sur vos activités de navigation et
                vos centres d’intérêt.
              </span>
            </li>
          </ul>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Veuillez noter que nous ne sommes pas en mesure de contrôler les actions des
              autres personnes avec lesquelles vous êtes susceptible de partager vos vidéos et
              informations. Il se peut que dans certains cas nous ne puissions pas supprimer
              vos données personnelles. Nous vous en informerons et vous en donnerons les
              raisons le cas échéant. Par ailleurs, si vous avez utilisé une application tierce
              pour publier de telles informations, vous pouvez les supprimer en vous connectant
              à ladite application ou en contactant son éditeur.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Nous utilisons un logiciel d’analyse mobile afin de mieux comprendre le
              fonctionnement de notre logiciel mobile sur votre téléphone. Il est possible que
              ce logiciel enregistre des données telles que votre fréquence d’utilisation de
              l’application, les événements qui se produisent dans l’application, l’usage
              agrégé, les données de performance et l’emplacement d’où l’application a été
              téléchargée. Nous ne relions pas les informations que nous stockons dans le
              logiciel d’analyse aux données personnelles que vous soumettez via l’application
              mobile.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Modifications apportées à la Politique de confidentialité
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Ream se réserve le droit de compléter régulièrement cette Politique de
              confidentialité, à sa seule discrétion. En cas de modifications importantes
              apportées à cette Politique de confidentialité, les utilisateurs seront informés
              par e-mail ou via la page d’accueil du site ou de l’application avant leur entrée
              en vigueur.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Non-Discrimination
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Ream ne discrimine aucun utilisateur faisant valoir ses droits en vertu de
              réglementations en matière de protection des données. Nous ne refuserons pas
              l’accès aux services et n’appliquons pas de tarifs différents aux utilisateurs
              faisant valoir leurs droits en vertu de réglementations en matière de vie privée.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Liens vers des sites tiers
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Notre site inclut des liens vers d’autres sites web dont les pratiques en matière
              de confidentialité peuvent différer de celles de Ream. Si vous transmettez vos
              données personnelles à l’un de ces sites, celles-ci seront régies par leurs
              politiques de confidentialité. Nous vous invitons à lire attentivement la
              politique de confidentialité des sites web que vous visitez.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Authentification unique
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Vous pouvez vous connecter à notre site via des services d’authentification
              unique, tels que Facebook ou Google. Ces services authentifieront votre identité
              et vous donneront la possibilité de partager certaines données personnelles avec
              nous, telles que votre nom et votre adresse e-mail, afin de préremplir notre
              formulaire d’inscription.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Enfants
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Nous n’avons pas l’intention de solliciter des personnes de moins de 18
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              ans ni de collecter des Données personnelles auprès de celles-ci. Si vous avez
              moins de 18
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              ans, ne saisissez pas d’informations sur ce site et n’utilisez pas nos services.
              Si vous pensez que l’un de vos enfants mineurs a fourni des Données personnelles
              sur notre site, contactez-nous à l’adresse
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <a
              href="mailto:support@ream-movie.com"
              target="_top"
              style={{ textDecoration: 'none' }}
            >
              <span style={{ fontFamily: 'Oxygen', color: '#0070f0' }}>
                support@ream-movie.com
              </span>
            </a>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              pour demander la suppression de ces données et fermer le compte de l’enfant.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '18pt',
              fontSize: '18pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              Réclamations liées à la confidentialité
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Les personnes se trouvant dans certains pays, dont l’espace économique européen,
              ont certains droits statutaires concernant leurs données à caractère personnel.
              Sous réserve des exemptions prévues par la loi, vous avez le droit de demander
              l’accès aux données à caractère personnel, leur mise à jour, suppression ou
              rectification, et la restriction de tout traitement ultérieur de vos données à
              caractère personnel.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Dans la mesure où le traitement de vos sonnées personnelles par Ream est soumis
              au règlement général de l’UE sur la protection des données, Ream s’appuie sur ses
              intérêts légitimes, décrits ci-dessus, pour le traitement de vos données. Lorsque
              Ream traite certaines données personnelles pour ses propres activités de
              marketing direct, vous avez le droit de vous opposer à tout moment à
              l’utilisation de vos données personnelles par Ream à cette fin. Pour cela,
              contacter le support «
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>contact@ream-movie.com</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>»</span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>
              et demandez à ne plus recevoir d’e-mails du tout.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '18pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Ream prend la vie privée de ses utilisateurs très au sérieux. Si vous avez des
              questions concernant la présente politique de confidentialité ou si vous pensez
              que Ream n’a pas respecté cette dernière eu égard à vos données personnelles,
              vous pouvez également contacter notre support.
            </span>
          </p>
          <p
            style={{
              marginTop: '18pt',
              marginBottom: '8pt',
              fontSize: '14.5pt',
              backgroundColor: '#ffffff',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              Sous réserve des lois applicables, vous avez également le droit de déposer une
              plainte auprès des autorités de protection des données de votre région. Si vous
              résidez dans l’espace économique européen et estimez que nous conservons vos
              données à caractère personnel conformément au règlement général sur la protection
              de données (RGPD), vous pouvez adresser vos questions ou réclamations aux
              autorités de protection des données de votre région.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '20pt',
            }}
          >
            <span style={{ fontFamily: 'Calibri', fontWeight: 'bold' }}>&nbsp;</span>
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default LegalNotivePage;
