import { updateAvatar } from 'client/_redux/actions/auth';
import { RootState } from 'client/_redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useAvatar = () => {
  const { currentUser } = useSelector(({ auth: { currentUser } }: RootState) => ({
    currentUser,
  }));

  const dispatch = useDispatch();

  const handleUploadAvatar = useCallback(
    (avatar) => {
      avatar && dispatch(updateAvatar({ avatar }));
    },
    [dispatch],
  );

  return { handleUploadAvatar, currentUser };
};
