import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';

export const login = (isServer = false) => async ({
  login,
  password,
}: {
  login: string;
  password: string;
}) => {
  const response = await clientApi.post('/users/sign-in', { login, password });

  return response;
};
export const googleFacebookLogin = (isServer = false) => async (
  idToken: string,
  loginProvider: 'google' | 'facebook',
) => {
  const response = await clientApi.post(
    `/users/web/${loginProvider}-sign-in`,
    {},
    {
      params: {
        idToken,
      },
    },
  );

  return response;
};

export const refreshToken = (isServer = false) => async (config: AxiosRequestConfig) => {
  const response = await clientApi.get<{ accessToken: string }>(
    '/users/refresh-token',
    config,
  );

  return response;
};
