import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAppSelector } from 'client/hooks/useAppSelector';

const LoggedOutRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = ({ component, path, exact }) => {
  const isLoggedIn = useAppSelector(({ auth: { isLoggedIn } }) => isLoggedIn);

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <Route path={path} exact={exact} component={component} />
  );
};

export default LoggedOutRoute;
