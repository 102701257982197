/* eslint-disable jsx-a11y/media-has-caption */
import Collapsible from 'client/components/Collapsible';
import Select from 'dashboard/components/Forms/Select';
import { getImageUrl } from 'client/helpers/getImageUrl';
import React from 'react';
import { format } from 'date-fns';
import { ISelectorOption } from 'types/ISelectorOption';
import { useVideoGrapherDetails } from './useVideoGrapherDetails';
import VideoGrapherDetailsSideBar from '../../components/videoGrapherDetails/VideoGrapherDetailsSideBar';
import './_videoGrapher.scss';
import VideoGrapherInformation from '../../components/videoGrapherDetails/VideoGrapherInformation';

const STATUS_OPTIONS: ISelectorOption<string>[] = [
  { value: 'PENDING', label: 'En Attente' },
  { value: 'APPROVED', label: 'Approuvé' },
  { value: 'REJECTED', label: 'Refusé' },
];

const VideoGrapherDetails = () => {
  const { videoGrapher, changeVideoGrapherStatus, t, reports } = useVideoGrapherDetails();

  if (!videoGrapher) return <></>;
  const { videoLinks, videos, idCard } = videoGrapher;

  return (
    <section className="content pt-3">
      <div className="container-fluid">
        <div className="row">
          <VideoGrapherDetailsSideBar videoGrapher={videoGrapher} />
          <div className="col-md-9">
            <div className="card">
              <div className="card-body">
                <h4>Status</h4>
                <div className="row-cols-6">
                  <Select
                    onChange={(e) => changeVideoGrapherStatus(e as string)}
                    options={STATUS_OPTIONS}
                    value={videoGrapher.isVideoGrapher as string}
                  />
                </div>
                <VideoGrapherInformation videoGrapher={videoGrapher} />
                <Collapsible title="Documents fournis">
                  <div>
                    <span className="text-oxygen font-weight-bold font-size-1 ">
                      {t('dashboard.videoGrapherPage.idCard')}
                    </span>
                    <div className="text mt-3">
                      {idCard &&
                        idCard.map((el) => (
                          <img
                            src={getImageUrl(el)}
                            alt="user-avatar"
                            height={200}
                            width={300}
                            key={el}
                          />
                        ))}
                    </div>
                  </div>
                  <div>
                    <span className="text-oxygen font-weight-bold font-size-1 ">
                      {t('dashboard.videoGrapherPage.videos')}
                    </span>
                    <div className="text mt-3">
                      {videos &&
                        videos.map((video) => (
                          <video controls height={200} width={200} key={video}>
                            <source src={getImageUrl(video)} />
                          </video>
                        ))}
                    </div>
                  </div>
                  <div>
                    <span className="text-oxygen font-weight-bold font-size-1 ">
                      {t('dashboard.videoGrapherPage.videoLinks')}
                    </span>
                    {videoLinks &&
                      videoLinks.map((el, index) => (
                        <div key={el} className="flex-row justify-content-between">
                          <a target="_blank" href={el}>
                            {t('dashboard.videoGrapherPage.video')}
                            {index + 1}
                          </a>
                        </div>
                      ))}
                  </div>
                </Collapsible>
                <Collapsible title="Rapports">
                  {reports &&
                    reports.map((el) => (
                      <div className="flex-row justify-content-between">
                        <a target="_blank" href={getImageUrl(el.url)}>
                          {el.createdAt && format(new Date(el.createdAt), 'DD-MM-YYYY')}
                        </a>
                      </div>
                    ))}
                </Collapsible>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoGrapherDetails;
