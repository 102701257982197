/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';

const Partners = () => {
  const [t] = useTranslation();

  return (
    <div
      className="background container-fluid pt-md-7 pt-4 "
      style={{ height: '100%', paddingBottom: '100px' }}
    >
      <div className="">
        <div className="container w-lg-50 w-md-60 w-sm-80 w-auto">
          <p className="text-white" style={{ font: 'normal normal bold 42px/55px Oxygen' }}>
            {t('HomePage.partners.title')}
          </p>
          <p className="text-white-70 font-size-2 font-weight-light subtitle-section">
            {t('HomePage.partners.text')}
          </p>
        </div>

        <div className="d-flex flex-wrap justify-content-center justify-content-sm-start justify-content-lg-between pt-5 w-80 w-md-70 mx-auto">
          <img
            alt="dvirtual"
            className="partners-logo mb-2"
            src="/assets/svg/custom/homePage/Divirtual-Logo.png"
          />
        </div>
      </div>
    </div>
  );
};

export default Partners;
