import * as React from 'react';

const Card = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.12 35.18" {...props}>
    <defs>
      <style>
        {
          '.cls-1,.cls-2{fill:none;;stroke-linecap:round;stroke-linejoin:round;}.cls-1{stroke-width:2px;}'
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Calque_1" data-name="Calque 1">
        <rect className="cls-1" x={1} y={1} width={48.12} height={33.18} rx={2} />
        <line className="cls-2" x1={1} y1={7.02} x2={49.12} y2={7.02} />
        <line className="cls-2" x1={1} y1={13.02} x2={49.12} y2={13.02} />
        <line className="cls-2" x1={25.06} y1={15.17} x2={37.11} y2={15.17} />
        <line className="cls-2" x1={40.23} y1={15.17} x2={45.24} y2={15.17} />
      </g>
    </g>
  </svg>
);

export default Card;
