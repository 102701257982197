import InputUserProfile from 'client/components/Form/InputProfileContainer';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { IUser } from 'server/models/User';
import { IUserInvoice } from 'server/models/UserInvoice';
import Card from 'client/components/SVG/Card';
import InputRow from '../InputRow';
import ModalReport from './ModalReport';
import useWalletTab from './useWalletTab';

type WalletTabProps = {
  currentUser: IUser;
  userReports: IUserInvoice[];
  isAbleToWithdraw: boolean;
};

const WalletTab = ({ currentUser, userReports, isAbleToWithdraw }: WalletTabProps) => {
  const {
    openReport,
    isOpen,
    t,
    onPressWithdraw,
    lastReport,
    isWithdrawLoading,
  } = useWalletTab(userReports);

  return (
    <>
      <div className="body-title">
        <span>{t('ProfileContainer.WalletTab.title')}</span>
      </div>
      {isOpen ? (
        <ModalReport openReport={openReport} />
      ) : (
        <>
          <div className="d-flex flex-column align-items-center stat position-relative">
            <div className="d-flex align-items-center position-relative justify-content-center ">
              <PieChart
                data={[{ value: currentUser?.salesBalance, color: '#EA384D' }]}
                paddingAngle={0}
                totalValue={20000}
                background="#3D3E4E"
                rounded
                animate
                segmentsShift={0}
                startAngle={-90}
                lineWidth={20}
                viewBoxSize={[100, 100]}
                labelStyle={{
                  fontSize: '16px',
                  fontFamily: 'Oxygen',
                  fontWeight: 'bold',
                  maxWidth: '.5rem',
                  fill: '#EA384D',
                }}
                labelPosition={0}
                style={{ width: '150px', height: '150px' }}
              />
              <div className="stat-ref text-center">
                <strong className="stat-balance"> {currentUser?.salesBalance}€</strong>
                /20000 €
              </div>
            </div>
            <button
              onClick={onPressWithdraw}
              type="button"
              disabled={!isAbleToWithdraw || isWithdrawLoading}
              style={{ width: '200px' }}
              className="btn py-2 px-2 text-white rounded mt-3  main-btn shadow-lg d-flex align-items-center justify-content-center"
            >
              <Card width="40px" stroke="white" className="pr-2 py-auto " />
              {t('ProfileContainer.WalletTab.mainButton')}
              {isWithdrawLoading && (
                <div
                  className="spinner-border text-white ml-1"
                  style={{ width: '1rem', height: '1rem' }}
                  role="status"
                />
              )}
            </button>
          </div>
          <div className="d-flex w-100">
            <InputRow
              name="RIB"
              label={t('ProfileContainer.WalletTab.paymentData')}
              defaultvalue="RIB"
            >
              <InputUserProfile
                name="RIB"
                type="text"
                className="form-control"
                placeholder="RIB"
                validate={Validators([
                  { validation: 'required' },
                  { validation: 'maxLength', maxParams: 18 },
                  { validation: 'minLength', minParams: 2 },
                ])}
              />
            </InputRow>
          </div>

          <div
            onClick={openReport}
            className="border-top pb-2 pt-2 InputRow row align-items-center"
          >
            <div className="info-name col-4 pr-0 m-0">
              <h1>{t('ProfileContainer.WalletTab.reports')}</h1>
            </div>
            <div className="value col-5">
              {lastReport && (
                <h1 style={{ cursor: 'pointer' }} className="ellipsis-text ">
                  {t('ProfileContainer.WalletTab.reportValue')}
                  {lastReport}
                </h1>
              )}
            </div>
            <div className="col-3 d-flex p-0 align-items-center">
              <i className="fas fa-angle-right mr-1" />
              <button type="button" className="btn  text-left p-0 m-0">
                {t('ProfileContainer.WalletTab.reportButton')}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WalletTab;
