import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';
import { getFormDataFile } from 'client/helpers/getFormDataFile';
import { SearchQuery } from 'dashboard/_redux/actions/users';
import { IVideoFormValues } from 'dashboard/_redux/reducers/videos';
import { IPost } from 'server/models/Post';
const baseUrl = '/dashboard/videos';

export const getAllVideos = (isServer = false) => async (searchQuery: SearchQuery<IPost>) => {
  const response = await clientApi.get(`${baseUrl}`, { params: searchQuery });

  return response.data;
};
export const getVideoById = (isServer = false) => async (id: string) => {
  const { data } = await clientApi.get<IPost>(`${baseUrl}/${id}`);

  return data;
};
export const updatePost = () => async (
  postData: Partial<IVideoFormValues>,
  postId?: string,
) => {
  const reqBody = new FormData();

  getFormDataFile(reqBody, postData);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await clientApi.patch(`${baseUrl}/${postId}/update-post`, reqBody, config);

  return response.data;
};

export const deletePostById = () => async (postId: string) => {
  await clientApi.delete(`posts/delete/${postId}`);
};
