import * as React from 'react';
import { ValidationRules, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

type InputOrAreaProps = React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>;

export interface IInputProps extends InputOrAreaProps {
  toggleShowPassword?: () => void;
  type?: string;
  isPasswordShown?: boolean;
  id?: string;
  label?: string;
  forgotPasswordMessage?: string;
  forgotPasswordLink?: string;
  rightIcon?: JSX.Element;
  leftIcon?: JSX.Element;
  name: string;
  validate?: ValidationRules;
}

const InputUserProfile: React.FC<IInputProps> = ({
  id,
  name,
  label,
  type,
  forgotPasswordLink,
  rightIcon,
  leftIcon,
  forgotPasswordMessage,
  validate,
  ...props
}) => {
  const isPassword = type === 'password';

  const passwordFieldType = 'password';

  const { errors, register } = useFormContext();

  const fieldError = get(errors, name);

  return (
    <div
      data-testid="inputFormGroup"
      className={`form-group m-auto ${fieldError ? 'u-has-error' : ''}`}
    >
      <div className="input-group">
        {leftIcon && (
          <div className="input-group-prepend m-0">
            <span
              className={`input-group-text 
            ${fieldError ? 'border-danger' : ''}
            `}
              id="basic-addon2"
            >
              {leftIcon}
            </span>
          </div>
        )}
        {type === 'textarea' ? (
          <textarea
            {...props}
            ref={validate ? register(validate) : register}
            name={name}
            className={`py-2 border-0 entry-width ${fieldError ? 'is-invalid' : ''}`}
          />
        ) : (
          <input
            name={name}
            {...props}
            ref={validate ? register(validate) : register}
            type={!isPassword ? type : passwordFieldType}
            className={`py-2 border-0 entry-width ${fieldError ? 'is-invalid' : ''}`}
          />
        )}
        {rightIcon && (
          <div className="input-group-append">
            <span
              className={`input-group-text 
            ${fieldError ? 'border-danger' : ''}
            `}
              id="basic-addon2"
            >
              {rightIcon}
            </span>
          </div>
        )}
      </div>
      {/* {fieldError && (
        <div
          data-testid="inputError"
          className="invalid-feedback"
          style={{ display: 'block' }}
        >
          {fieldError.message}
        </div>
      )} */}
    </div>
  );
};

export default InputUserProfile;
