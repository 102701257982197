// import 'styles/scss/index.scss'
import { Route, Switch } from 'react-router-dom';
import LandingPage from 'client/pages/LandingPage';
import VideoMakerPage from 'client/pages/VideoMakerPage';
import HowToPage from 'client/pages/HowToPage';
import Gallery from 'client/pages/Gallery';
import Login from 'client/pages/Login';
import LicencePage from 'client/pages/LicencePage';
import * as React from 'react';
import VideoLibrary from 'client/pages/VideoLibrary';
import PostDetails from 'client/pages/PostDetails';
import ResetPassword from 'client/pages/ResetPassword';
import ForgetPassword from 'client/pages/ForgetPassword';
import useNotification from 'client/hooks/useNotification';
import SharePage from 'client/pages/SharePage';
import NotFound from 'client/pages/NotFound';
import LegalNotivePage from 'client/pages/LegalNotivePage';
import TermsAndConditionsPage from 'client/pages/TermsAndConditionsPage';
import SEO from 'client/components/SEO';
import LoggedOutRoute from './LoggedOutRoute';
import PrivateRoute from './PrivateRoute';
import { useAppRouter } from './useAppRouter';
import UnhandledDynamicLinkRoute from './UnhandledDynamicLinkRoute';

const NotificationContainer = () => {
  useNotification();

  return <></>;
};

const Root = () => {
  const { initiated } = useAppRouter();

  return initiated ? (
    <div>
      <NotificationContainer />
      <Switch>
        <LoggedOutRoute exact path="/login" component={Login} />
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/video-maker" component={VideoMakerPage} />
        <Route exact path="/how-to" component={HowToPage} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/videos/:id" component={PostDetails} />
        <Route exact path="/share/:id" component={SharePage} />
        <PrivateRoute exact path="/video-library" component={VideoLibrary} />
        <LoggedOutRoute exact path="/resetpassword" component={ResetPassword} />
        <LoggedOutRoute exact path="/forgetpassword" component={ForgetPassword} />
        <Route exact path="/legal-notice" component={LegalNotivePage} />
        <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
        <Route exact path="/licence" component={LicencePage} />
        <UnhandledDynamicLinkRoute exact path="/downloads" />
        <UnhandledDynamicLinkRoute exact path="/welcome" />
        <Route component={NotFound} />
      </Switch>
    </div>
  ) : (
    <div className="p-3 d-flex justify-content-center align-items-center vh-100">
      <SEO />
      <div
        className="spinner-border text-primary"
        style={{ width: '4rem', height: '4rem' }}
        role="status"
      />
    </div>
  );
};

export default Root;
