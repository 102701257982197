import { useState } from 'react';
import { IPost } from 'client/models/IPost';
import { useDispatch } from 'react-redux';
import { deletePostById } from 'client/_redux/actions/post';
import { withConfirmation } from 'client/helpers/withConfirmation';
import { useTranslation } from 'react-i18next';
import { videoSpecOptions } from 'client/constants/videoSpecOptions';

export const useVideoCardInfo = (post: IPost) => {
  const [t] = useTranslation();

  const [videoCard, setvideoCard] = useState<boolean>(false);
  const { cinematicStyleOptions, musicTypeOptions, videoTypeOptions } = videoSpecOptions(t);

  const dispatch = useDispatch();
  const changeButton = () => {
    setvideoCard(!videoCard);
  };

  const handleDelete = () => {
    withConfirmation(() => {
      dispatch(post?._id && deletePostById(post?._id));
    });
  };

  return {
    videoCard,
    changeButton,
    handleDelete,
    cinematicStyleOptions,
    musicTypeOptions,
    videoTypeOptions,
  };
};
