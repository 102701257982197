import { clientApi } from 'api';
import { ILoginFormValues } from 'client/containers/LoginForm/SignIn/useSignIn';

export const loginDashboard = (isServer = false) => async ({
  login,
  password,
}: ILoginFormValues) => {
  const response = await clientApi.post('/users/sign-in-dashboard', { login, password });

  return response;
};
