import Collapsible from 'client/components/Collapsible';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IUser } from 'server/models/User';

type VideoGrapherInformationProps = { videoGrapher: IUser };

const VideoGrapherInformation: React.FC<VideoGrapherInformationProps> = ({ videoGrapher }) => {
  const [t] = useTranslation();

  return (
    <>
      <Collapsible title={t('dashboard.videoGrapherPage.videoGrapherInfo')}>
        <div className="row">
          <div className="col-12 col-sm-6">
            <span className="text-oxygen font-weight-bold">
              {`${t('dashboard.videoGrapherPage.username')} : `}
            </span>
            <span>{videoGrapher?.username}</span>

            <div>
              <span className="text-oxygen font-weight-bold ">{`${t(
                'dashboard.videoGrapherPage.firstName',
              )} : `}</span>
              <span>{videoGrapher?.firstName}</span>
            </div>
            <div>
              <span className="text-oxygen font-weight-bold ">{`${t(
                'dashboard.videoGrapherPage.lastName',
              )} : `}</span>
              <span>{videoGrapher?.lastName}</span>
            </div>
            <div>
              <span className="text-oxygen font-weight-bold ">{`${t(
                'dashboard.videoGrapherPage.email',
              )} : `}</span>
              <span>{videoGrapher?.email}</span>
            </div>
            <div>
              <span className="text-oxygen font-weight-bold ">
                {t('dashboard.videoGrapherPage.balance')} :
              </span>
              <span>{videoGrapher?.salesBalance}</span>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <span className="text-oxygen font-weight-bold ">
                {t('dashboard.videoGrapherPage.address')}
              </span>
              <span>
                {videoGrapher?.address} {videoGrapher?.city} {videoGrapher?.country}
              </span>
            </div>
            <div>
              <span className="text-oxygen font-weight-bold ">
                {t('dashboard.videoGrapherPage.description')}
              </span>
              <span>{videoGrapher?.description}</span>
            </div>
          </div>
        </div>
      </Collapsible>
      <Collapsible title={t('dashboard.videoGrapherPage.companyInformation')}>
        <div>
          <span className="text-oxygen font-weight-bold ">{`${t(
            'dashboard.videoGrapherPage.companyName',
          )} : `}</span>
          <span>{videoGrapher?.companyName}</span>
        </div>
        <div>
          <span className="text-oxygen font-weight-bold ">{`${t(
            'dashboard.videoGrapherPage.immatriculation',
          )} : `}</span>
          <span>{videoGrapher?.immatriculation}</span>
        </div>
        <div>
          <span className="text-oxygen font-weight-bold ">{`${t(
            'dashboard.videoGrapherPage.email',
          )} : `}</span>
          <span>{videoGrapher?.companyEmail}</span>
        </div>
        <div>
          <span className="text-oxygen font-weight-bold ">
            {t('dashboard.videoGrapherPage.phoneNumber')} :
          </span>
          <span>{videoGrapher?.companyPhone}</span>
        </div>
        <div>
          <span className="text-oxygen font-weight-bold ">
            {t('dashboard.videoGrapherPage.rib')} :
          </span>
          <span>{videoGrapher?.RIB}</span>
        </div>
      </Collapsible>
    </>
  );
};

export default VideoGrapherInformation;
