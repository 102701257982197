import React from 'react';

import Slider from '@mui/material/Slider';
import alphabet from '../../../constants/alphabet';
import { useRangeInput } from './useRangeInput';

interface SliderAlphabetProps {
  onChangeLetters: (val: string) => void;
  isVideoPage?: boolean;
}
const SliderAlphabet: React.FC<SliderAlphabetProps> = ({ onChangeLetters, isVideoPage }) => {
  const { selectedLetter, t, onChange } = useRangeInput(onChangeLetters);

  return (
    <div className={`${isVideoPage ? 'w-100' : 'w-100 w-lg-70'} mt-4 mt-md-0`}>
      {!isVideoPage && (
        <span
          className="text-secondary text-oxygen"
          style={{ fontSize: '2vh', fontWeight: 'bold' }}
        >
          {t('Gallery.midSection.slideLetterTitle')}
        </span>
      )}

      <div className="container bg-secondary" style={{ height: '3.75rem', zIndex: 1 }}>
        <Slider
          step={null}
          marks={alphabet.map((el, index) => ({
            value: index + 65,
            label: el,
          }))}
          min={65}
          max={65 + alphabet.length - 1}
          value={alphabet.indexOf(selectedLetter || 'A') + 65}
          onChange={(_, value) => typeof value === 'number' && onChange(value)}
          sx={{
            color: 'var(--primary)',
            '& .MuiSlider-thumb': {
              backgroundColor: 'white',
              '&:hover': {
                boxShadow: '0px 0px 0px 8px #ed213a57',
              },
            },
            '& .MuiSlider-rail': {
              opacity: 1,
              height: 2,
            },
            '& .MuiSlider-track': {
              height: 2,
            },
            '& .MuiSlider-markLabel': {
              color: 'white',
              fontFamily: 'Inter',
              [`&[data-index='0'],&[data-index='25'],&[data-index='${alphabet.indexOf(
                selectedLetter || 'A',
              )}']`]: {
                color: 'var(--primary)',
              },
            },
            '& .MuiSlider-markLabel:first-of-type': {
              color: 'blue',
            },
            '& .MuiSlider-mark': {
              display: 'none',
            },
          }}
        />
      </div>
    </div>
  );
};

export default SliderAlphabet;
