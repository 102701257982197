import { useFieldArray, useFormContext } from 'react-hook-form';
export const useVideoVersionsForm = (name: string) => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: `${name}.videoVersions`,
  });

  return { fields };
};
