/* eslint-disable max-lines */
import { GroupTypeBase, Styles } from 'react-select';
import { ISelectorOption } from 'types/ISelectorOption';
import { CSSObject } from '@emotion/serialize';

const CountryCodeStyle: Partial<
  Styles<ISelectorOption, boolean, GroupTypeBase<ISelectorOption>>
> = {
  container: (provided): CSSObject =>
    ({
      ...provided,
      width: 'auto',
    } as CSSObject),
  menu: (provided): CSSObject =>
    ({
      ...provided,

      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 1)',
      borderColor: '#EA384D',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '5px',
      marginTop: '1px',
    } as CSSObject),
  menuList: (provided): CSSObject =>
    ({
      ...provided,
      width: '100%',
      color: '#EA384D',
      textAlign: 'center',
      background: '#F6F6F6',
      cursor: 'pointer',
      font: 'normal normal bold 14px/30px Oxygen',
      height: '100px',
    } as CSSObject),
  singleValue: (provided): CSSObject =>
    ({
      ...provided,
      margin: '0px',
      width: '100%',
      color: '#EA384D',
      textAlign: 'center',
      font: 'normal normal bold 18px/30px Oxygen',
    } as CSSObject),
  valueContainer: (provided): CSSObject => ({
    ...(provided as CSSObject),

    margin: '0px',
    padding: '0px',
    width: 'auto',
    justifyContent: 'center',
    display: 'flex',
  }),
  option: (state): CSSObject => {
    const defaultControlStyles: CSSObject = {
      width: '100%',
      boxSizing: 'border-box',
      padding: '5px 0px',
      fontSize: '1rem',
    };
    const focusedControlStyles: CSSObject = {
      backgroundColor: 'rgba(247, 148, 28, .3)',
      color: 'inherit',
      cursor: 'default',
      display: 'block',
      fontSize: 'inherit',
      padding: '8px 12px',
      width: '100%',
      userSelect: 'none',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      boxSizing: 'border-box',
    };
    const selectedControlStyles: CSSObject = {
      backgroundColor: 'rgba(247,148, 28, .7)',
      color: 'inherit',
      cursor: 'default',
      display: 'block',
      fontSize: 'inherit',
      padding: '8px 12px',
      width: '100%',
      userSelect: 'none',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      boxSizing: 'border-box',
    };

    if (state.isSelected) return selectedControlStyles;
    if (state.isFocused) return focusedControlStyles;

    return defaultControlStyles as CSSObject;
  },
  // indicatorsContainer: (): CSSObject => ({
  //   'div:nth-of-type(1n)': {
  //     color: '#ea384d',
  //   },

  // }),

  dropdownIndicator: (base) => ({
    ...base,

    color: '#EA384D',
    padding: '0px',
    paddingTop: '8px',
    cursor: 'pointer',
    '&:hover': {
      color: '#EA384D',
    },
  }),

  control: (state): CSSObject => {
    const defaultControlStyle: CSSObject = {
      backgroundColor: '#F6F6F6',
      marginTop: '8px',
      border: '0.5px solid #D84852',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row-reverse',
      width: '100%',
      height: '2.4rem',
      cursor: 'pointer',
    };

    const focusedControlStyles: CSSObject = {
      backgroundColor: 'rgba(0, 0, 0, 1)',
      borderColor: 'rgba(255, 255, 255, 1)',
      borderRadius: 5,
      borderStyle: 'solid',
      borderWidth: 1,
      boxShadow: '0 0 10px rgba(247, 148, 28, .1)',
      display: 'flex',
      flexWrap: 'wrap',
      width: 'auto',
      cursor: 'pointer',
    };

    return state.isFocused ? focusedControlStyles : defaultControlStyle;
  },
};

export const errorStyles: Partial<
  Styles<ISelectorOption, boolean, GroupTypeBase<ISelectorOption>>
> = {
  option: (provided, state) => CountryCodeStyle.option?.(provided, state) || {},
  control: () => {
    const controlHasErrorStyle: CSSObject = {
      alignItems: 'center',
      backgroundColor: 'hsl(0, 0%, 100%)',
      borderColor: '#ed4c78',
      borderRadius: 4,
      borderStyle: 'solid',
      borderWidth: 1,
      boxShadow: '0 0 10px rgba(247, 148, 28, .1)',
      cursor: 'default',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: 47,
      outline: '0 !important',
      position: 'relative',
      transition: 'all 100ms',
      boxSizing: 'border-box',
    };

    return controlHasErrorStyle;
  },
};
export default CountryCodeStyle;
