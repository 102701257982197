import { convertASCIIToChar } from 'client/helpers/convertASCIItoChar';
import { useDebounce } from 'client/hooks/useDebounce';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export const useRangeInput = (onChangeLetters: (debouncedSearchTerm: string) => void) => {
  const [t] = useTranslation();
  const [selectedLetter, setSelectedLetter] = useState<undefined | string>(undefined);

  const debouncedSearchTerm = useDebounce(selectedLetter as string, 500);

  const onChange = useCallback((val: number) => {
    const letter = convertASCIIToChar(val);

    setSelectedLetter(letter);
  }, []);

  useEffect(() => {
    onChangeLetters(debouncedSearchTerm);
  }, [debouncedSearchTerm, selectedLetter, onChangeLetters]);

  return { selectedLetter, t, onChange };
};
