import { googleFacebookLogin } from 'client/_redux/actions/auth';
import { useCallback } from 'react';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  useGoogleLogin,
} from 'react-google-login';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const isGoogleLoginResponse = (
  response: GoogleLoginResponse | GoogleLoginResponseOffline,
): response is GoogleLoginResponse =>
  typeof (response as GoogleLoginResponse).tokenId === 'string';

export interface ILoginFormValues {
  login: string;
  password: string;
}

export const useSignIn = (onSubmit: SubmitHandler<ILoginFormValues>) => {
  const formMethods = useForm<ILoginFormValues>({ shouldFocusError: true });
  const { handleSubmit } = formMethods;
  const _onSubmit: SubmitHandler<ILoginFormValues> = (data) => {
    onSubmit(data);
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const onGoogleLoginSuccess = useCallback(
    (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if (!isGoogleLoginResponse(response)) return;
      dispatch(googleFacebookLogin(response.tokenId, 'google', () => push('/video-library')));
    },
    [dispatch, push],
  );

  const onFacebookLoginSuccess = useCallback(
    (res: { accessToken: string } | undefined) => {
      if (!res?.accessToken) return;
      dispatch(googleFacebookLogin(res.accessToken, 'facebook', () => push('/video-library')));
    },
    [dispatch, push],
  );

  const { loaded: googleLoaded, signIn: googleSignIn } = useGoogleLogin({
    accessType: 'offline',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    clientId: process.env.RAZZLE_GOOGLE_WEB_CLIENT_ID!,
    uxMode: 'popup',
    onSuccess: onGoogleLoginSuccess,
    // eslint-disable-next-line no-console
    onFailure: console.error,
  });

  return {
    formMethods,
    handleSubmit,
    _onSubmit,
    t,
    googleLoaded,
    googleSignIn,
    onFacebookLoginSuccess,
  };
};
