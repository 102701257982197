/* eslint-disable max-len */
import * as React from 'react';

const User = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.95 50.19" {...props}>
    <defs>
      <style>
        {
          '.cls-1,.cls-2{fill:none;stroke-linecap:round;stroke-linejoin:round;}.cls-1{stroke-width:3px;}.cls-2{stroke-width:2px;}'
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Calque_1" data-name="Calque 1">
        <path
          className="cls-1"
          d="M19.16,25.65a11.68,11.68,0,0,0,8.54-3.54,11.71,11.71,0,0,0,3.53-8.54A11.69,11.69,0,0,0,27.7,5,11.68,11.68,0,0,0,19.16,1.5,11.68,11.68,0,0,0,10.62,5a11.66,11.66,0,0,0-3.54,8.53,11.68,11.68,0,0,0,3.54,8.54A11.68,11.68,0,0,0,19.16,25.65Z"
        />
        <path
          className="cls-1"
          d="M35.89,47.18a6,6,0,0,1-4.35,1.51H7.41a6,6,0,0,1-4.35-1.51A5.43,5.43,0,0,1,1.5,43c0-.94,0-1.87.09-2.76A27.59,27.59,0,0,1,2,37.4a21,21,0,0,1,.69-2.81,12.81,12.81,0,0,1,1.06-2.41,7.94,7.94,0,0,1,1.45-1.9A5.79,5.79,0,0,1,7.05,29.1a6.78,6.78,0,0,1,2.31-.44,6.58,6.58,0,0,1,.58.35L12,30.34a14.36,14.36,0,0,0,3.32,1.49,13.31,13.31,0,0,0,8.28,0,14,14,0,0,0,3.32-1.49L29,29a6.25,6.25,0,0,1,.59-.35,6.78,6.78,0,0,1,2.31.44,5.79,5.79,0,0,1,1.89,1.18,8.2,8.2,0,0,1,1.45,1.9,12.81,12.81,0,0,1,1.06,2.41A21,21,0,0,1,37,37.4a27.59,27.59,0,0,1,.37,2.85h0c.06.89.09,1.82.09,2.76A5.43,5.43,0,0,1,35.89,47.18Z"
        />
        <circle cx={30.01} cy={44.21} r={1} />
        <line className="cls-2" x1={7.08} y1={44.21} x2={27.04} y2={44.21} />
      </g>
    </g>
  </svg>
);

export default User;
