import { useDispatch } from 'react-redux';
import { addPost } from 'client/_redux/actions/post';
import IPostForm from 'client/models/IPostForm';
import { useForm, SubmitHandler } from 'react-hook-form';
import { IFileWithPreview } from 'types/IFileWithPreview';
import { useUploadingProgress } from 'client/hooks/useUpladingProgress';
import { useEffect } from 'react';
import { splitTagsString } from 'client/helpers/splitTagsString';

export const useAddVideoDashboard = () => {
  const formMethods = useForm<IPostForm>({});
  const dispatch = useDispatch();
  const {
    handleSubmit,
    watch,
    formState: { isSubmitSuccessful },
    reset,
  } = formMethods;
  const file = watch('video');
  const { calculatingProgress, uploadingProgress } = useUploadingProgress();
  const onSubmit: SubmitHandler<IPostForm> = async (data) => {
    const post = {
      mainVideo: file as IFileWithPreview[],
      name: data.name,
      tags: splitTagsString(data.tags as string),
      country: data.countryCity.country,
      city: data.countryCity.state,
      description: data.description,
      cinematicStyle: data.cinematicStyle.value,
      musicType: data.musicType.value,
      videoType: data.videoType.value,
      location: { coordinates: [data.countryCity.lng, data.countryCity.lat] },
      shortVideo: data.shortVideo,
    };

    await dispatch(addPost(post, calculatingProgress));
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      calculatingProgress({ loaded: 0, total: 1 });
      reset();
    }
  }, [calculatingProgress, isSubmitSuccessful, reset]);

  return { formMethods, handleSubmit, onSubmit, file, uploadingProgress };
};
