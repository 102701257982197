import { RootState } from 'client/_redux/reducers';
import { updateVideoGrapherStatus, getVideoGrapherById } from 'dashboard/_redux/actions/users';
import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const useVideoGrapherDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [t] = useTranslation();

  const { reports, videoGrapher } = useSelector(({ dashboardVideoGraphers }: RootState) => ({
    videoGrapher: dashboardVideoGraphers.selectedVideoGrapher?.videoGrapher,
    reports: dashboardVideoGraphers.selectedVideoGrapher?.reports,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVideoGrapherById(id));
  }, [dispatch, id]);

  const changeVideoGrapherStatus = useCallback(
    (value: string) => {
      if (!videoGrapher?._id) return;
      dispatch(updateVideoGrapherStatus(videoGrapher?._id, value));
    },
    [dispatch, videoGrapher?._id],
  );

  return {
    videoGrapher,
    changeVideoGrapherStatus,
    reports,
    t,
  };
};
