import Collapsible from 'client/components/Collapsible';
import Validators from 'client/helpers/formValidator';
import Input from 'dashboard/components/Forms/Input';
import React from 'react';
import { useVideoVersionsForm } from './useVideoVersionsForm';
type VideoVersionFormProps = {
  name: string;
  label: string;
};
const VideoVersionsForm = ({ label, name }: VideoVersionFormProps) => {
  const { fields } = useVideoVersionsForm(name);

  return (
    <Collapsible className="mh-50" title={label}>
      <div style={{ maxHeight: '40rem', overflowY: 'auto', overflowX: 'hidden' }}>
        {fields.map((field, index) => (
          <div key={field.id} className="row no-gutters">
            <Input
              label={`${field.resolution} , ${field.framerate} ,${field.format}`}
              name={`${name}.videoVersions.${index}.price`}
              className="p-2 w-50"
              validate={Validators([{ validation: 'numeric' }])}
            />
            <Input
              label={`${field.resolution} , ${field.framerate} ,${field.format} (sans filigrane)`}
              name={`${name}.videoVersions.${index}.nonWatermarkedPrice`}
              className="p-2 w-50"
              validate={Validators([{ validation: 'numeric' }])}
            />
          </div>
        ))}
      </div>
    </Collapsible>
  );
};

export default VideoVersionsForm;
