import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

type IProps = {
  filterText?: string;
  onFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  onAddButtonClick?: () => void;
  onPressChangeStatus?: () => void;
  status?: string;
};
const FilterInput = ({
  filterText,
  onFilter,
  onAddButtonClick,
  onPressChangeStatus,
  status,
}: IProps) => {
  const [t] = useTranslation();

  return (
    <div className="row">
      <div className="col-md-6 col-sm-6 col-12 input-group ">
        <input
          type="text"
          className="form-control"
          placeholder={`${t('dataTable.search')}`}
          value={filterText}
          onChange={onFilter}
        />
      </div>
      <div className="col-md-6 col-sm-6 col-12">
        {onAddButtonClick && (
          <button onClick={onAddButtonClick} type="button" className="btn btn-dark btn-xs">
            {t('dataTable.add')}
          </button>
        )}
        {onPressChangeStatus && (
          <button onClick={onPressChangeStatus} type="button" className="btn btn-dark btn-xs">
            {status === 'APPROVED' ? t('misc.approved') : t('misc.pending')}
          </button>
        )}
      </div>
    </div>
  );
};

export default FilterInput;
