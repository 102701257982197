import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';
import { IUserFormUpdate } from 'types/IUserFormUpdate';
import { getFormDataFile } from 'client/helpers/getFormDataFile';
import { IUserInvoice } from 'server/models/UserInvoice';

export const updateProfile = async (value: IUserFormUpdate) => {
  const response = await clientApi.patch('/users', value);

  return response;
};

export const updateAvatar = async (value: { avatar: File }) => {
  const reqBody = new FormData();

  getFormDataFile(reqBody, value);

  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await clientApi.patch('/users/update-avatar', reqBody, config);

  return response;
};
export const getUserReports = () => async () => {
  const response = await clientApi.get<IUserInvoice>(`videoGrapher/getReports`);

  return response.data;
};
export const createWithdrawRequest = () => async () => {
  const response = await clientApi.get('/videographer/withdraw-request');

  return response;
};
