import { IThunkAction } from 'types/IThunkAction';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { IPostData } from 'client/models/IPost';

import * as PostService from 'client/_redux/services/Post';
import {
  AddPostTypes,
  deletePostTypes,
  fetchVideoGrapherPosts,
  getAllPostsTypes,
  GetPostByIdTypes,
} from 'client/_redux/types/postTypes';
import { toast } from 'react-toastify';
import { errorHandler } from 'client/helpers/errorHandler';
import IPostForm from 'client/models/IPostForm';
import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';
import { UploadProgressPayload } from 'types/IUploadingProgress';
import { UserReportTypes } from 'client/_redux/types/userReportTypes';

import * as VideoGrapherService from 'client/_redux/services/user';
export const addPost: (
  values: IPostData,
  uploadProgress: (payload: UploadProgressPayload) => void,
) => IThunkAction = (values, uploadProgress) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const config: AxiosRequestConfig = {
      headers: {
        ...clientApi.defaults.headers,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        uploadProgress({ loaded, total });
      },
    };
    const addData = await PostService.addPost()(values, config);

    dispatch({ type: AddPostTypes.ADD_POST_SUCCESS, payload: addData.data });
    dispatch(getPostByVideoGrapher());
    toast('Ajout success ! ', { type: 'success' });
  } catch (error) {
    dispatch({ type: AddPostTypes.ADD_POST_FAILURE });

    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const deletePostById: (postId: string) => IThunkAction = (postId) => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    await PostService.deletePostById()(postId);

    dispatch(getPostByVideoGrapher());

    toast('Vidéo effacée avec succès', { type: 'success' });
  } catch (error) {
    dispatch({ type: deletePostTypes.POST_DELETE_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getAllPosts = (filter?: {
  countryLetter?: string;
  category?: string;
}): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const allPosts = await PostService.getAllPosts(filter)();

    dispatch({ type: getAllPostsTypes.POSTS_SUCCESS, payload: allPosts.data });
  } catch (error) {
    dispatch({ type: getAllPostsTypes.POSTS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getPostByVideoGrapher = (filter?: {
  countryLetter?: string;
  category?: string;
  keyword?: string;
}): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const allPosts = await PostService.getPostByVideoGrapher()(filter);

    dispatch({
      type: fetchVideoGrapherPosts.FETCH_VIDEOGRAPHER_POSTS_SUCCESS,
      payload: allPosts.data,
    });
    dispatch(getUserReports());
  } catch (error) {
    dispatch({ type: fetchVideoGrapherPosts.FETCH_VIDEOGRAPHER_POSTS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const updatePost: (values: Partial<IPostForm>, postId: string) => IThunkAction = (
  values,
  postId,
) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await PostService.updatePost()(values, postId);

    dispatch(getPostByVideoGrapher());
    toast('Informations mises à jours', { type: 'success' });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: AddPostTypes.UPDATE_POST_FAILURE });
  }

  dispatch(stopLoading());
};
export const getPostDetailsById = (id: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const allPosts = await PostService.getPostById()(id);

    dispatch({
      type: GetPostByIdTypes.POST_BYID_SUCCESS,
      payload: allPosts.data,
    });
  } catch (error) {
    dispatch({ type: GetPostByIdTypes.POST_BYID_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getUserReports = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const reports = await VideoGrapherService.getUserReports()();

    dispatch({
      type: UserReportTypes.GET_REPORTS_SUCCESS,
      payload: reports,
    });
  } catch (error) {
    dispatch({ type: UserReportTypes.GET_REPORTS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
