import { VideoGraphersTypes } from 'dashboard/_redux/types/videoGraphersTypes';
import { WithdrawDashboardTypes } from 'dashboard/_redux/types/withdrawDashboardTypes';
import { emptyPaginationPayload } from 'server/constants/emptyPaginationPayload';
import { IUser } from 'server/models/User';
import { IUserInvoice } from 'server/models/UserInvoice';
import { IWithdrawRequest } from 'server/models/WithdrawRequest';
import { IReduxAction } from 'types/IReduxAction';
import { PaginatedResponse } from 'types/Posts';
export interface IVideoGrapherState {
  loading: boolean;
  loaded: boolean;
  videoGraphers: PaginatedResponse<IUser>;
  selectedVideoGrapher: { videoGrapher?: IUser; reports: IUserInvoice[] };
  withdrawRequests: PaginatedResponse<IWithdrawRequest>;
}

const initialState: IVideoGrapherState = {
  loading: false,
  loaded: false,
  videoGraphers: emptyPaginationPayload,
  withdrawRequests: emptyPaginationPayload,
  selectedVideoGrapher: { videoGrapher: undefined, reports: [] },
};

export default (state = initialState, { type, payload }: IReduxAction): IVideoGrapherState => {
  switch (type) {
    case VideoGraphersTypes.FETCH_USERS_SUCCESS:
      return { ...state, loaded: true, videoGraphers: payload };
    case VideoGraphersTypes.UPDATE_VIDEOGRAPHER_SUCCESS: {
      const itemIndex = state.withdrawRequests.items.findIndex((el) => el._id === payload._id);

      if (itemIndex !== -1) {
        state.withdrawRequests.items[itemIndex].status = payload.status;
      }

      return {
        ...state,
        loaded: true,
      };
    }
    case WithdrawDashboardTypes.FETCH_WITHDRAW_SUCCESS:
      return { ...state, loaded: true, withdrawRequests: payload };
    case VideoGraphersTypes.GET_VIDEOGRAPHER_BYID_SUCCESS:
      return { ...state, loaded: true, selectedVideoGrapher: payload };
    case VideoGraphersTypes.UPDATE_VIDEOGRAPHER_STATUS_SUCCESS:
      return {
        ...state,
        loaded: true,
        selectedVideoGrapher: { ...state.selectedVideoGrapher, videoGrapher: payload },
      };

    default:
      return state;
  }
};
