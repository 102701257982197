/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import ReactFullpage, { fullpageApi } from '@fullpage/react-fullpage';
import useSliderIdicator from './useSliderIdicator';
import ScrollDirectionIndicator from '../ScrollDirectionIndicator';

const SlideIndicator: React.FC<{
  buttonText: string;
  textColor?: string;
  anchors: string[];
  setFullPageApi?: (api: fullpageApi) => void;
}> = ({ children, buttonText, textColor, anchors, setFullPageApi }) => {
  const { childrenArray } = useSliderIdicator(children);

  return (
    <ReactFullpage
      // sectionSelector="the-slide-selector"
      licenseKey="A47983B8-A6D74CF4-A4DD8D76-981FC9F9"
      // anchors to hide the last anchror (footer)
      anchors={anchors}
      scrollingSpeed={1000}
      navigation
      // debug
      responsive={1192}
      showActiveTooltip
      scrollOverflow
      scrollOverflowReset
      fitToSection={false}
      navigationTooltips={childrenArray.map(
        (_, index) =>
          `<div class="index-indicator">
            <h4 class="pr-4">
              ${(index + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 })}
            </h4>
            <span>
              /${(childrenArray.length - 1).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })}
            </span>
          </div>`,
      )}
      render={({ fullpageApi }) => (
        // eslint-disable-next-line no-sequences
        setFullPageApi?.(fullpageApi),
        (
          <ReactFullpage.Wrapper>
            {childrenArray.map((child, i) => (
              <section
                key={`${i + 1}`}
                className="section position-relative fp-auto-height-responsive fp-auto-height"
                // style={{ zIndex: i === 0 ? 2 : 1 }}
              >
                {child}
                {i === 0 && (
                  <ScrollDirectionIndicator
                    textColor={textColor}
                    onClick={() => fullpageApi.moveSectionDown()}
                    isGallery={false}
                    text={buttonText}
                    isHorizontal={false}
                    className="z-index-2 left-50 mt-n10"
                  />
                )}
              </section>
            ))}
          </ReactFullpage.Wrapper>
        )
      )}
    />
  );
};

export default SlideIndicator;
