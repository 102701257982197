import React from 'react';
import HandScroller from '../SVG/HandScroller';
import Mouse from '../SVG/Mouse';

interface IProps {
  isHorizontal: boolean;
  isGallery: boolean;
  text: string;
  textColor?: string;
  className?: string;
  onClick?: () => void;
}

const ScrollDirectionIndicator: React.FC<IProps> = ({
  className,
  isHorizontal,
  isGallery,
  text,
  textColor,
  onClick,
}) => (
  <div
    className={`top-0 z-index-1000 ${
      isGallery ? 'scroll-indicator-container-gallery' : 'scroll-indicator-container'
    } ${isHorizontal ? 'scroll-indicator-horizontal' : ''} ${className || ''}`}
  >
    <div className="d-flex align-content-center ">
      <div className="mr-3 d-flex align-items-center">
        {isGallery ? (
          <HandScroller width="90px" height="100px" />
        ) : (
          <Mouse width="60px" height="75px" />
        )}
      </div>
      <button
        onClick={onClick}
        type="button"
        className={`scroll-indicator-button text-oxygen font-size-1 font-weight-normal mb-2 mt-2 ${
          textColor || ''
        }`}
      >
        {text}
      </button>
    </div>
  </div>
);

export default ScrollDirectionIndicator;
