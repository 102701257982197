import React, { InputHTMLAttributes } from 'react';

export interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string;
  onChange: (checked: boolean) => void;
  checked?: boolean;
}

const SwitchBox: React.FC<IProps> = ({ checked, label, onChange, id, ...props }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div className="form-group p-0 m-0">
      <div className="custom-control custom-switch">
        <input
          {...{ ...props, id }}
          onChange={handleInputChange}
          type="checkbox"
          className="custom-control-input"
          defaultChecked={checked}
        />
        <label className="custom-control-label " htmlFor={id}>
          <span className="d-flex justify-content-between align-items-center">{label}</span>
        </label>
      </div>
    </div>
  );
};

export default SwitchBox;
