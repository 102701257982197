import FormImagePicker from 'client/components/Form/FormImagePicker';
import Input from 'client/components/Form/Input';
import ProgressBar from 'client/components/ProgressBar';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { TFunction } from 'react-i18next';
type VideosUploaderProps = {
  accountApprovementVideos: File[];
  videoLinks: string[];
  uploadingProgress: number;
  t: TFunction<'translation'>;
  videoLinkInputs: number;
  handleVideoLinksChange: (index: number, link: string) => void;
  handleVideoLinkAdd: () => void;
};
const VideoUploader = ({
  accountApprovementVideos,
  videoLinks,
  uploadingProgress,
  t,
  videoLinkInputs,
  handleVideoLinksChange,
  handleVideoLinkAdd,
}: VideosUploaderProps) => (
  <div className=" d-lg-flex justify-content-lg-between justify-content-center align-content-center">
    <div className="d-flex align-items-center px-3 justify-content-center">
      {accountApprovementVideos?.length || 0 + videoLinks.length || 0}
      <span className="text-primary-lighter">/3</span>
    </div>
    <div className="w-100 font-text-info-condition ">
      <div className=" d-flex justify-content-between">
        <div className="col-lg-7">
          {t('LoginForm.JoinVideos')}
          <span className="text-primary-lighter">*</span>
        </div>

        <div className=" d-flex ml-3 align-items-center mt-2 mt-lg-0">
          <FormImagePicker
            className="bg-secondary px-2 ml-2 py-2 text-primary-lighter "
            name="videos"
            dropzoneClassName="u-dropzone-button"
            labelClassName="text-primary-lighter ml-1"
            leftIcon
            acceptedExtensions="video/mp4 , video/wmv , video/avi"
            label={t('LoginForm.Add')}
            maxFiles={3}
          />
        </div>
      </div>
      <div className="d-flex mt-3 row no-gutters">
        <div className="col-md-7 row no-gutters d-flex justify-content-center">
          {accountApprovementVideos?.map((video) => (
            <ProgressBar
              key={video?.name}
              checkProgressComplet
              progress={uploadingProgress}
              className="mr-2"
              widthBar={accountApprovementVideos?.length > 1 ? '50px' : '100px'}
            />
          ))}
        </div>
        <div className="mb-lg-0 mb-2 col-md-5">
          <FormImagePicker
            name="videos"
            maxFiles={3}
            label={`${t('imagePicker.dragPrompt')}`}
            dropzoneClassName="u-dropzone"
            labelClassName="dataSize text-primary-lighter"
            acceptedExtensions="video/mp4 , video/wmv , video/avi"
            className="bg-transparent p-2 border-primary"
          />
        </div>
      </div>
      <div className=" mt-4 d-flex justify-content-between align-content-center flex-wrap">
        <div className="col-lg-7 col-12">
          <div className="mb-2">
            <Input
              validate={Validators([{ validation: 'url' }])}
              name="links"
              onChange={(e) => handleVideoLinksChange(0, (e.target as HTMLInputElement).value)}
              key="link-0"
              type="text"
              placeholder={t('LoginForm.AddLinkVideo')}
              className="form-control mt-2 font-size-input align-items-center
                            text-center border-primary font-weight-normal  
                            bg-secondary-lighter my-auto input-placeholder-light-grey rounded text-light-white"
            />
          </div>
          {new Array(videoLinkInputs).fill(0).map((_, index) => (
            <div className="mb-2">
              <Input
                validate={Validators([{ validation: 'url' }])}
                name="links"
                onChange={(e) =>
                  handleVideoLinksChange(index + 1, (e.target as HTMLInputElement).value)
                }
                key={_}
                type="text"
                placeholder={t('LoginForm.AddLinkVideo')}
                className="form-control mt-2 font-size-input align-items-center
                            text-center border-primary font-weight-normal  
                            bg-secondary-lighter my-auto input-placeholder-light-grey rounded text-light-white"
              />
            </div>
          ))}
        </div>
        <button
          type="button"
          className="bg-transparent shadow-none border-0 d-flex  ml-3 align-items-center mt-2 mt-lg-0 "
          onClick={handleVideoLinkAdd}
        >
          <i className=" fas fa-plus-circle fa-lg text-primary-lighter" />
          <span className="pl-3 mr-5 text-primary-lighter"> {t('LoginForm.Add')}</span>
        </button>
      </div>
    </div>
  </div>
);

export default VideoUploader;
