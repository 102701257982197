import { useEffect, useState } from 'react';
import { RootState } from 'client/_redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getPostByVideoGrapher } from 'client/_redux/actions/post';
import { useTranslation } from 'react-i18next';
import { useIsVideoGrapherApproved } from 'client/hooks/useIsVideographerApproved';
export const useVideoLibrary = () => {
  const { currentUser, userReports, isAbleToWithdraw } = useSelector(
    ({ auth }: RootState) => ({
      currentUser: auth.currentUser,
      userReports: auth.userReports,
      isAbleToWithdraw: auth.isAbleToWithdraw,
    }),
  );
  const videoGrapherPosts = useSelector(
    ({ post: { videoGrapherPosts } }: RootState) => videoGrapherPosts,
  );

  const [countryLetter, setCountryLetter] = useState('');
  const [category, setCategory] = useState('RECENT');

  const onSearchChange = (value: string) => {
    dispatch(getPostByVideoGrapher({ keyword: value }));
  };

  const OnSwitchChange = (value: string) => {
    setCategory(value);
    value === 'RECENT' && dispatch(getPostByVideoGrapher({ category: value }));
  };
  const OnLetterChange = (letter: string) => {
    if (letter !== countryLetter) {
      dispatch(getPostByVideoGrapher({ countryLetter: letter }));
    }
    setCountryLetter(letter);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    const getPosts = async () => {
      await dispatch(getPostByVideoGrapher());
    };

    getPosts();
  }, [dispatch]);

  const [t] = useTranslation();
  const isApproved = useIsVideoGrapherApproved();

  return {
    currentUser,
    OnSwitchChange,
    isApproved,
    OnLetterChange,
    videoGrapherPosts,
    onSearchChange,
    countryLetter,
    userReports,
    category,
    t,
    isAbleToWithdraw,
  };
};
