/* eslint-disable max-lines */
import SliderIndicator from 'client/components/SliderIndicator';
import FAQSection from 'client/containers/FAQSection';
import NavBarContainer from 'client/containers/NavBarContainer';
import SectionContainer from 'client/containers/SectionContainer';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pattern from 'client/components/SVG/Pattern';
import { Link } from 'react-router-dom';
import Footer from 'client/containers/Footer';
import { fullpageApi } from '@fullpage/react-fullpage';

const HowToPage: React.FC = () => {
  const [t] = useTranslation();
  const [fullPageApi, setFullPageApi] = useState<fullpageApi | null>(null);
  const downloadReamButton = (
    <div className="d-flex flex-wrap justify-content-md-start justify-content-center">
      <Link className="section-button alt-primary" type="button" to="/">
        {t('navigationButtons.telechargerReam')}
      </Link>
    </div>
  );

  return (
    <>
      <SliderIndicator
        anchors={['1', '2', '3', '4', '5', '6', '7', 'last']}
        buttonText={t('navBar.commentCaMarche')}
        setFullPageApi={setFullPageApi}
      >
        <>
          <NavBarContainer />
          <SectionContainer
            title={t('HowToPage.section1.title')}
            backgroundStyle="dark-image-background"
            miscComponent={
              <div className="w-75 mt-sm-2 mb-sm-3">
                <img src="/assets/svg/custom/how-to/first-section.svg" alt="how-to first" />
              </div>
            }
            firstDescription={t('HowToPage.section1.subtitle')}
            secondDescription={t('HowToPage.section1.description')}
            titleStyle="text-white"
            subtitleStyle="text-primary-lighter subtitle-text-semiBold"
            descriptionStyle="text-white-70"
            mobileTopMargin="80"
          >
            <Pattern
              className="red-pattern"
              width="900px"
              style={{ position: 'absolute', top: '-35%', right: '75%', opacity: '0.45' }}
            />
            <div className="d-flex flex-wrap justify-content-md-start justify-content-center mt-5">
              <Link className="section-button alt-primary mr-md-5" to="/">
                {t('navigationButtons.telechargerReam')}
              </Link>
              <button
                type="button"
                className="p-0 section-button alt-tertiary"
                onClick={fullPageApi?.moveSectionDown}
              >
                {t('navigationButtons.voirLesEtapes')}
              </button>
            </div>
          </SectionContainer>
        </>
        <SectionContainer
          title={t('HowToPage.section2.title')}
          backgroundStyle="white-background"
          miscComponent={
            <div className="w-75 mt-sm-2 mb-sm-3">
              <img src="/assets/svg/custom/how-to/second-section.svg" alt="how-to second" />
            </div>
          }
          number={1}
          numberStyle="section-number-alt"
          firstDescription={t('HowToPage.section2.subtitle')}
          secondDescription={t('HowToPage.section2.description')}
          titleStyle="text-dark"
          subtitleStyle="text-primary-lighter subtitle-text-medium"
          descriptionStyle="text-dark"
          isReversed
        >
          {downloadReamButton}
        </SectionContainer>
        <SectionContainer
          title={t('HowToPage.section3.title')}
          backgroundStyle="dark-background"
          miscComponent={
            <div className="w-75 mt-sm-2 mb-sm-3">
              <img src="/assets/svg/custom/how-to/third-section.svg" alt="how-to third" />
            </div>
          }
          number={2}
          numberStyle="section-number"
          firstDescription={t('HowToPage.section3.subtitle')}
          secondDescription={t('HowToPage.section3.description')}
          titleStyle="text-white"
          subtitleStyle="text-primary-lighter subtitle-text-medium"
          descriptionStyle="text-white-70"
        >
          {downloadReamButton}
          <Pattern className="red-pattern red-pattern-position" width="900px" />
        </SectionContainer>
        <SectionContainer
          title={t('HowToPage.section4.title')}
          backgroundStyle="white-background"
          miscComponent={
            <div className="w-75 mt-sm-2 mb-sm-3">
              <img src="/assets/svg/custom/how-to/fourth-section.svg" alt="how-to fourth" />
            </div>
          }
          number={3}
          numberStyle="section-number-alt"
          firstDescription={t('HowToPage.section4.subtitle')}
          secondDescription={t('HowToPage.section4.description')}
          titleStyle="text-dark"
          subtitleStyle="text-primary-lighter subtitle-text-medium"
          descriptionStyle="text-dark"
          isReversed
        >
          {downloadReamButton}
          <Pattern className="red-pattern red-pattern-position-section-3" width="900px" />
        </SectionContainer>
        <SectionContainer
          title={t('HowToPage.section5.title')}
          backgroundStyle="dark-background"
          miscComponent={
            <div className="w-75 mt-sm-2 mb-sm-3">
              <img src="/assets/svg/custom/how-to/first-section.svg" alt="how-to fifth" />
            </div>
          }
          number={4}
          numberStyle="section-number"
          firstDescription={t('HowToPage.section5.subtitle')}
          secondDescription={t('HowToPage.section5.description')}
          titleStyle="text-white"
          subtitleStyle="text-primary-lighter subtitle-text-medium"
          descriptionStyle="text-white-70"
        />
        <SectionContainer
          title={<div className="">{t('HowToPage.section6.title')}</div>}
          backgroundStyle="white-background"
          miscComponent={
            <div className="w-75 mt-sm-2 mb-sm-3">
              <img src="/assets/svg/custom/how-to/sixth-section.svg" alt="how-to sixth" />
            </div>
          }
          number={5}
          numberStyle="section-number-alt"
          firstDescription={t('HowToPage.section6.subtitle')}
          secondDescription={t('HowToPage.section6.description')}
          titleStyle="text-dark"
          subtitleStyle="text-primary"
          isReversed
          descriptionStyle="text-dark"
        >
          {downloadReamButton}
          <Pattern className="red-pattern red-pattern-position-section-3" width="900px" />
        </SectionContainer>
        <FAQSection />
        <Footer />
      </SliderIndicator>
    </>
  );
};

export default HowToPage;
