import React from 'react';

import NavBarProfile from 'client/components/UserProfile/NavBarProfile';
import { IUser } from 'server/models/User';
import { IUserInvoice } from 'server/models/UserInvoice';
import { useFormatVideoGrapherGrade } from 'client/hooks/useFormatVideoGrapherGrade';
import ProfileTab from './Tabs/ProfileTab';
import SettingsTab from './Tabs/SettingsTab';
import WalletTab from './Tabs/WalletTab';
import CardHeader from '../../components/UserProfile/CardHeader';
import { useProfileContainer } from './useProfileContainer';

type ProfileContainerProps = {
  currentUser: IUser;
  userReports: IUserInvoice[];
  isAbleToWithdraw: boolean;
};
const ProfileContainer = ({
  currentUser,
  userReports,
  isAbleToWithdraw,
}: ProfileContainerProps) => {
  const { indexPage, t, handleIndex, handleLogout } = useProfileContainer();
  const grade = useFormatVideoGrapherGrade(currentUser.grade);

  return (
    <div className="d-flex justify-content-center">
      <div className="profile-card border-success mb-3 mb-xl-0 d-flex flex-column w-xl-100 w-lg-60  w-md-80 w-100 ">
        <div className="card-header bg-transparent ">
          <CardHeader profileName={currentUser?.username} lvlProfile={grade} />
        </div>
        <div className="card-body ">
          <NavBarProfile handleIndex={handleIndex} indexPage={indexPage} />
          <div className="d-flex flex-column align-items-center justify-content-between main-body w-100 ">
            {indexPage === 0 && <ProfileTab currentUser={currentUser} />}
            {indexPage === 1 && (
              <WalletTab
                userReports={userReports}
                currentUser={currentUser}
                isAbleToWithdraw={isAbleToWithdraw}
              />
            )}
            {indexPage === 2 && <SettingsTab />}
          </div>
        </div>
        <div className="card-footer">
          <div className=" text-center ">
            <button onClick={handleLogout} type="button" className="btn-deconnexion">
              {t('ProfileContainer.deconnexion')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileContainer;
