import { updateProfile } from 'client/_redux/actions/auth';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

type UseSwitchRowProps = {
  name: string;
};

const useSwitchRow = ({ name }: UseSwitchRowProps) => {
  const formMethods = useForm();

  const { watch } = formMethods;
  const inputValue: boolean = watch(name);
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof inputValue === 'boolean') {
      const values = { isNotificationEnabled: inputValue };

      dispatch(updateProfile(values));
    }
  }, [inputValue, dispatch]);

  return { formMethods };
};

export default useSwitchRow;
