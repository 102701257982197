import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { useEffect, useCallback } from 'react';
import { IVideoFormValues } from 'dashboard/_redux/reducers/videos';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteVideoDashboard } from 'dashboard/_redux/actions/videos';
import { withConfirmation } from 'client/helpers/withConfirmation';
import { IPostDashboardForm } from '.';

export const usePostDashboardForm = ({ onSubmit, defaultValues }: IPostDashboardForm) => {
  const formMethods = useForm<IVideoFormValues>({
    shouldFocusError: true,
    defaultValues,
  });
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { handleSubmit, reset, control } = formMethods;

  const iconFields = useFieldArray({
    control,
    name: 'icons',
  });

  const _onSubmit: SubmitHandler<IVideoFormValues> = useCallback(
    async (data) => {
      onSubmit(data);
    },
    [onSubmit],
  );

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  const onDelete = useCallback(() => {
    withConfirmation(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dispatch(deleteVideoDashboard(defaultValues._id!, () => push('/dashboard/videos')));
    });
  }, [dispatch, defaultValues._id, push]);

  return {
    _onSubmit,
    handleSubmit,
    formMethods,
    iconFields,
    t,
    onDelete,
  };
};
