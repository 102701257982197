/* eslint-disable max-len */
/* eslint-disable max-lines */
import { Link } from 'react-router-dom';
import React from 'react';
import { ValidationRules, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

type InputOrAreaProps = React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>;

export interface IInputProps extends InputOrAreaProps {
  toggleShowPassword?: () => void;
  type?: string;
  isPasswordShown?: boolean;
  id?: string;
  label?: string;
  forgotPasswordMessage?: string;
  forgotPasswordLink?: string;
  rememberMeMessage?: string;
  rightIcon?: JSX.Element;
  leftIcon?: JSX.Element;
  name: string;
  validate?: ValidationRules;
  className?: string;
  isVideo?: boolean;
}

const Input: React.FC<IInputProps> = ({
  id,
  name,
  label,
  type,
  forgotPasswordLink,
  rightIcon,
  leftIcon,
  forgotPasswordMessage,
  validate,
  className,
  isVideo,
  rememberMeMessage,
  ...props
}) => {
  const isPassword = type === 'password';
  // const [isPasswordShown, toggleShowPassword] = React.useState(false);
  // const handletoggleShowPassword = (): void => {
  //   toggleShowPassword(!isPasswordShown);
  // };
  const passwordFieldType = 'password';
  const { errors, register } = useFormContext();
  const fieldError = get(errors, name);
  // const handleMouseDownPassword = (
  //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  // ): void => {
  //   event.preventDefault();
  // };

  return (
    <div
      data-testid="inputFormGroup"
      className={`${
        isVideo ? 'd-flex justify-content-between align-items-center' : 'form-group mb-0 '
      } ${fieldError ? 'u-has-error' : ''}`}
    >
      <div
        className={` input-group ${isVideo ? '' : 'form-group '} ${
          fieldError ? 'u-has-error' : ''
        }`}
      >
        {!isVideo && (
          <label className="form-label" htmlFor={id}>
            <span className="d-flex justify-content-between align-items-center">{label}</span>
          </label>
        )}
        <div className={`${isVideo ? '' : 'input-group'}`}>
          {leftIcon && (
            <div className="input-group-prepend ">
              <span
                className={`input-group-text 
            ${fieldError ? 'border-danger' : ''}
            `}
                id="basic-addon2"
              >
                {leftIcon}
              </span>
            </div>
          )}
          {type === 'textarea' ? (
            <textarea
              {...props}
              ref={validate ? register(validate) : register}
              name={name}
              className={`${
                isVideo
                  ? ' is-video-input font-weight-bold'
                  : 'form-control  mt-2 font-size-input  form-control text-center border-primary font-weight-normal '
              } ${className} ${fieldError ? 'is-invalid' : ''}`}
            />
          ) : (
            <input
              // style={{ font: 'normal normal 300 14px/17px Inter' }}
              name={name}
              {...props}
              ref={validate ? register(validate) : register}
              type={!isPassword ? type : passwordFieldType}
              className={`${
                isVideo
                  ? ' is-video-input font-weight-bold'
                  : 'form-control  mt-2 font-size-input  form-control text-center border-primary font-weight-normal '
              } ${className} ${fieldError ? 'is-invalid' : ''}`}
            />
          )}
          {rightIcon && (
            <div className="input-group-append">
              <span
                className={`input-group-text 
            ${fieldError ? 'border-danger' : ''}
            `}
                id="basic-addon2"
              >
                {rightIcon}
              </span>
            </div>
          )}
        </div>
        {fieldError && (
          <div
            data-testid="inputError"
            className="invalid-feedback"
            style={{ display: 'block' }}
          >
            {fieldError.message}
          </div>
        )}
        {!isVideo && (
          <>
            <div className="form-label mb-0 w-100">
              <span className="d-flex justify-content-between align-items-center ">
                {label}

                {isPassword && forgotPasswordMessage && (
                  <div className="flex-wrap  mt-3 d-flex  w-100 justify-content-end">
                    <div>
                      <Link
                        className="font-size-checkbox-label text-primary-lighter"
                        to={forgotPasswordLink || ''}
                      >
                        {forgotPasswordMessage}
                      </Link>
                    </div>
                  </div>
                )}
              </span>
            </div>
          </>
        )}
      </div>

      {isVideo && <i className="fas fa-chevron-right  chevron-i " />}
    </div>
  );
};

export default Input;
