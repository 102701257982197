import React, { InputHTMLAttributes, useEffect } from 'react';

export interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string;
  onChange: (checked: boolean) => void;
}

const CheckBox: React.FC<IProps> = ({ label, onChange, id, ...props }) => {
  useEffect(() => {
    onChange(false); // eslint-disable-next-line
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div className="form-group mb-0">
      <div className="custom-control custom-checkbox d-inline-flex align-content-center ">
        <input
          {...{ ...props, id }}
          onChange={handleInputChange}
          type="checkbox"
          className="custom-control-input"
        />
        <label className="custom-control-label d-flex " htmlFor={id}>
          <span className="align-self-center font-size-checkbox-label text-secondary font-weight-normal">
            {label}
          </span>
        </label>
      </div>
    </div>
  );
};

export default CheckBox;
