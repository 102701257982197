/* eslint-disable max-len */
import React from 'react';

const YoutubeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 148.6 158.53" {...props}>
    <defs>
      <style>{'.cls-1{fill:#e9394d;}.cls-2{fill:#ffffff;}'}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Calque_1" data-name="Calque 1">
        <path
          className="cls-1"
          d="M130,144.28H18.58A18.56,18.56,0,0,1,0,125.7V32.83A18.55,18.55,0,0,1,18.58,14.25H130A18.55,18.55,0,0,1,148.6,32.83V125.7A18.55,18.55,0,0,1,130,144.28ZM99.69,74.75,64.4,52.26a5.67,5.67,0,0,0-8.67,5v44a5.88,5.88,0,0,0,5.87,5.89,5.65,5.65,0,0,0,2.78-.87L99.79,83.71a4.94,4.94,0,0,0,1.48-1.47,5.39,5.39,0,0,0-1.58-7.49Z"
        />
        <path
          className="cls-2"
          d="M134.33,158.53H14.27a4.25,4.25,0,0,1,0-8.5H134.33a4.25,4.25,0,0,1,0,8.5Z"
        />
        <path
          className="cls-2"
          d="M134.33,8.5H14.27a4.25,4.25,0,0,1,0-8.5H134.33a4.25,4.25,0,0,1,0,8.5Z"
        />
      </g>
    </g>
  </svg>
);

export default YoutubeIcon;
