import { GroupTypeBase, Styles } from 'react-select';
import { ISelectorOption } from 'types/ISelectorOption';
import { CSSObject } from '@emotion/serialize';

const customStyles: Partial<
  Styles<ISelectorOption, boolean, GroupTypeBase<ISelectorOption>>
> = {
  option: (provided, state): CSSObject => {
    const focusedControlStyles: CSSObject = {
      // label: 'option',
      backgroundColor: 'rgba(247, 148, 28, .3)',
      color: 'inherit',
      cursor: 'default',
      display: 'block',
      fontSize: 'inherit',
      padding: '8px 12px',
      width: '100%',
      userSelect: 'none',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      // active: { backgroundColor: 'rgba(247, 148, 28, 0.3)' },
      boxSizing: 'border-box',
    };
    const selectedControlStyles: CSSObject = {
      // label: 'option',
      backgroundColor: 'rgba(247,148, 28, .7)',
      color: 'inherit',
      cursor: 'default',
      display: 'block',
      fontSize: 'inherit',
      padding: '8px 12px',
      width: '100%',
      userSelect: 'none',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      // active: { backgroundColor: 'rgba(247, 148, 28, .7)' },
      boxSizing: 'border-box',
    };

    if (state.isSelected) return selectedControlStyles;
    if (state.isFocused) return focusedControlStyles;

    return provided as CSSObject;
  },
  control: (provided, state): CSSObject => {
    const focusedControlStyles: CSSObject = {
      // label: 'control',
      alignItems: 'center',
      backgroundColor: 'hsl(0, 0%, 100%)',
      borderColor: 'rgba(247, 148, 28, .5)',
      borderRadius: 4,
      borderStyle: 'solid',
      borderWidth: 1,
      boxShadow: '0 0 10px rgba(247, 148, 28, .1)',
      cursor: 'default',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: 47,
      minWidth: 92,
      outline: '0 !important',
      position: 'relative',
      transition: 'all 100ms',
      // '&:hover': {
      //   borderColor: 'rgba(247, 148, 28, .5)',
      // },
      boxSizing: 'border-box',
    };

    return state.isFocused
      ? focusedControlStyles
      : ({ ...provided, minHeight: 47, minWidth: 92 } as CSSObject);
  },
  menu: (provided, state) => ({
    ...provided,
  }),
};

export const errorStyles: Partial<
  Styles<ISelectorOption, boolean, GroupTypeBase<ISelectorOption>>
> = {
  option: (provided, state) => customStyles.option?.(provided, state) || {},
  control: () => {
    const controlHasErrorStyle: CSSObject = {
      alignItems: 'center',
      backgroundColor: 'hsl(0, 0%, 100%)',
      borderColor: '#ed4c78',
      borderRadius: 4,
      borderStyle: 'solid',
      borderWidth: 1,
      boxShadow: '0 0 10px rgba(247, 148, 28, .1)',
      cursor: 'default',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: 47,
      minWidth: 92,
      outline: '0 !important',
      position: 'relative',
      transition: 'all 100ms',
      boxSizing: 'border-box',
    };

    return controlHasErrorStyle;
  },
};

export default customStyles;
