import { useState, useCallback } from 'react';
import { UploadProgressPayload } from 'types/IUploadingProgress';

export const useUploadingProgress = () => {
  const [uploadingProgress, setUploadingProgress] = useState(0);

  const calculatingProgress = useCallback((payload: UploadProgressPayload) => {
    const { loaded, total } = payload;
    const percentCompleted = Math.round((loaded * 100) / total);

    setUploadingProgress(percentCompleted);
  }, []);

  return { uploadingProgress, calculatingProgress };
};
