/* eslint-disable react-hooks/exhaustive-deps */
import { initializeImagePicker } from 'client/helpers/initializeImagePicker';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { IFileWithPreview } from 'types/IFileWithPreview';

type useImagePickerProps = {
  initialValue: string[];
  maxFiles: number;
  acceptedExtensions?: string;
  onChange?: (files: File[]) => void;
};
export const useImagePicker = ({
  initialValue,
  maxFiles,
  onChange,
  acceptedExtensions,
}: useImagePickerProps) => {
  const [t] = useTranslation();
  const [files, setFiles] = useState<IFileWithPreview[]>([]);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (maxFiles === 1)
        setFiles(() => [
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          }),
        ]);
      else
        setFiles((prev) => [
          ...prev,
          ...acceptedFiles
            .filter((_file, index) => prev.length + index + 1 <= maxFiles)
            .map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              }),
            ),
        ]);
    },
    [maxFiles],
  );
  const _initialValue = useRef<string[] | null>(null);

  useEffect(() => {
    if (!(initialValue instanceof Array)) {
      setFiles([]);

      return;
    }
    if (_initialValue.current) return;
    _initialValue.current = initialValue;
    initImagePicker();
  }, [initialValue]);

  const initImagePicker = async () => {
    const initialFiles = await initializeImagePicker(_initialValue.current as string[]);

    setFiles(initialFiles);
  };

  useEffect(() => {
    onChange?.(files);
  }, [files]);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles,
    accept: acceptedExtensions,
    multiple: maxFiles > 1,
  });

  return { getRootProps, getInputProps, t };
};
