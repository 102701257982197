import { IUser } from 'client/models/IUser';
import { AuthTypes } from 'dashboard/_redux/types/authTypes';
import { IToken } from 'types/Auth';
import { IReduxAction } from 'types/IReduxAction';
import { AuthTypes as AuthFontTypes } from 'client/_redux/types/authTypes';

export interface IAuthState {
  isLoggedIn: boolean;
  loading: boolean;
  loaded: boolean;
  error: boolean;

  token?: IToken;
  currentUser?: IUser;
}

const initialState: IAuthState = {
  isLoggedIn: false,
  loading: false,
  loaded: false,
  error: false,
  currentUser: undefined,
};

export default (state = initialState, { type, payload }: IReduxAction): IAuthState => {
  switch (type) {
    case AuthFontTypes.USER_LOGGED_OUT:
      return initialState;
    case AuthTypes.SIGNIN_ADMIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        loaded: true,
        token: payload.token,
        currentUser: payload.currentUser,
      };
    case AuthTypes.SIGNIN_ADMIN:
      return { ...state, isLoggedIn: true, loaded: true, currentUser: payload };

    case AuthTypes.SIGNIN_ANONYMOUS:
      return { ...state, isLoggedIn: false, loaded: true, token: payload };

    default:
      return state;
  }
};
