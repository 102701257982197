/* eslint-disable jsx-a11y/media-has-caption */
import FormSelect from 'dashboard/components/Forms/FormSelect';
import Validators from 'client/helpers/formValidator';
import { getImageUrl } from 'client/helpers/getImageUrl';
import { STATUS_OPTIONS } from 'dashboard/constants/statusOptions';
import * as React from 'react';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import { IUser } from 'server/models/User';
import { IVideoFormValues } from 'dashboard/_redux/reducers/videos';
import IconsVideoForm from 'dashboard/containers/PostDashboardForm/IconsVideoForm';
import { usePostDashboardForm } from './usePostDashboardForm';
import VideoVersionsForm from '../VideoVersionsForm';

export interface IPostDashboardForm {
  onSubmit: SubmitHandler<IVideoFormValues>;
  defaultValues: IVideoFormValues;
}

const PostDashboardForm = ({ onSubmit, defaultValues }: IPostDashboardForm) => {
  const {
    formMethods,
    _onSubmit,
    handleSubmit,
    t,
    iconFields,
    onDelete,
  } = usePostDashboardForm({
    defaultValues,
    onSubmit,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)} className="row">
        <div className="card col-md-6">
          <div className="card-header card-title">
            <h4>{defaultValues.name}</h4>
          </div>
          <div className="card-body">
            {defaultValues.shortVideo && (
              <h5 className="text-bold">{t('dashboard.videosPage.version.long')}</h5>
            )}
            <video
              className="img-fluid pad video-preview-max-height"
              controls
              height={200}
              width={400}
              preload="auto"
              src={getImageUrl(defaultValues.mainVideo?.originalUrl)}
            />
            {defaultValues.shortVideo && (
              <>
                <h5 className="text-bold mt-3">{t('dashboard.videosPage.version.short')}</h5>
                <video
                  className="img-fluid pad video-preview-max-height"
                  controls
                  height={200}
                  width={400}
                  preload="auto"
                  src={getImageUrl(defaultValues.shortVideo.originalUrl)}
                />
              </>
            )}
            <div className="pt-2">
              <h5 className="text-bold">Address</h5>
              <span className="pl-2">
                {`${defaultValues.city ? `${defaultValues.city} ,` : ''}${
                  defaultValues.country
                }`}
              </span>
            </div>
            <div className="pt-2">
              <h5 className="text-bold">tags</h5>
              <span className="pl-2">{defaultValues.tags}</span>
            </div>
            <div className="pt-2">
              <h5 className="text-bold">Description</h5>
              <span className="pl-2">{defaultValues.description}</span>
            </div>
          </div>
          <button
            onClick={onDelete}
            type="button"
            id="basic"
            className="btn btn-soft-primary mx-auto px-5 btn-sm my-3"
          >
            <i className="fas fa-trash mr-1" />
            Effacer
          </button>
        </div>
        <div className="col-md-6">
          <div className="card card -solid">
            <div className="card-body">
              <div className="row">
                <div className="col-6 col-sm-6">
                  <h5 className="text-bold m-3">{t('dashboard.videosPage.status')}</h5>
                  <FormSelect
                    options={STATUS_OPTIONS}
                    name="postStatus"
                    validate={Validators([{ validation: 'required' }])}
                  />
                </div>
                <div className="col-6 col-sm-6">
                  <h5 className="text-bold m-3">Ajouté Par</h5>
                  <ul className="text-bold">
                    <li>{(defaultValues.addedBy as IUser).username}</li>
                    <li>{(defaultValues.addedBy as IUser).email}</li>
                  </ul>
                </div>
              </div>
              <VideoVersionsForm
                name="mainVideo"
                label={t('dashboard.videosPage.version.long')}
              />
              {defaultValues.shortVideo && (
                <VideoVersionsForm
                  name="shortVideo"
                  label={t('dashboard.videosPage.version.short')}
                />
              )}
              <IconsVideoForm {...{ ...iconFields }} />
            </div>
            <div className="row justify-content-center m-3">
              <button className="btn btn-dark btn-xs m-1" type="submit">
                {t('misc.submit')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default PostDashboardForm;
