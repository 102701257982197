import React from 'react';
import { FieldError } from 'react-hook-form';
import { useImagePicker } from './useImagePicker';

export interface IProps {
  maxFiles?: number;
  label?: string;
  onChange?: (files: File[]) => void;
  errors?: FieldError;
  name: string;
  dropzoneClassName?: string;
  placeholderClassName?: string;
  labelClassName?: string;
  className?: string;
  containerClassName?: string;

  acceptedExtensions?: string;
  leftIcon?: boolean;
  rightIcon?: boolean;
}

interface IComponentProps extends IProps {
  initialValue: string[];
}

const ImagePicker: React.FC<IComponentProps> = ({
  name,
  maxFiles = 1,
  label,
  onChange,
  errors,
  initialValue,
  dropzoneClassName,
  placeholderClassName,
  labelClassName,
  containerClassName,
  className,
  leftIcon,
  rightIcon,
  acceptedExtensions = 'image/jpeg, image/png',
}) => {
  const { getRootProps, getInputProps } = useImagePicker({
    initialValue,
    maxFiles,
    onChange,
    acceptedExtensions,
  });

  return (
    <div className={containerClassName || ''}>
      <div
        {...getRootProps()}
        className={`${className || ''} ${dropzoneClassName || 'u-dropzone'} flex-column ${
          errors ? `border-danger` : ''
        }`}
      >
        <input {...getInputProps()} id={name} />
        <span className={`text-center ${placeholderClassName || 'dataSize'}`}>
          {leftIcon && (
            <span className="pt-2 pt-md-0 ml-2 fas fa-plus-circle fa-lg text-primary-lighter" />
          )}

          <span className={`${labelClassName} text-center`}>{label}</span>

          {rightIcon && (
            <span className="pt-2 pt-md-0 ml-2 fas fa-plus-circle fa-lg text-primary-lighter" />
          )}
        </span>
      </div>

      {errors && (
        <div
          data-testid="inputError"
          className="invalid-feedback"
          style={{ display: 'block' }}
        >
          {errors.message}
        </div>
      )}
    </div>
  );
};

export default ImagePicker;
