import SubmitButton from 'client/components/Form/SubmitButton';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import IdCardUploader from './IdCardUploader';
import { useSlideThree } from './useSlideThree';
import VideoUploader from './VideosUploader';

const SlideThree: React.FC = () => {
  const {
    formMethods,
    _onSubmit,
    handleSubmit,
    t,
    loading,
    identityApprovementFiles,
    uploadingProgress,
    accountApprovementVideos,
    videoLinks,
    videoLinkInputs,
    handleVideoLinksChange,
    handleVideoLinkAdd,
  } = useSlideThree();

  return (
    <div className="w-100 flex-column align-content-center justify-content-center">
      <div className="h4 text-secondary-lighter font-weight-normal  text-center ">
        <span className="text-primary-lighter">3.</span>
        {t('LoginForm.FollowingDocuments')}
      </div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(_onSubmit)}>
          <div className=" mt-5 mb-n5 pb-10 pt-3 bg-secondary">
            <div className="mx-auto w-80 text-light-white ">
              <IdCardUploader
                identityApprovementFiles={identityApprovementFiles}
                uploadingProgress={uploadingProgress}
                t={t}
              />
              <hr className="my-4" />
              <VideoUploader
                accountApprovementVideos={accountApprovementVideos}
                videoLinks={videoLinks}
                uploadingProgress={uploadingProgress}
                t={t}
                videoLinkInputs={videoLinkInputs}
                handleVideoLinksChange={handleVideoLinksChange}
                handleVideoLinkAdd={handleVideoLinkAdd}
              />
            </div>
            {loading && (
              <div className="d-flex justify-content-center">
                <div className="loading-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            {!loading && <SubmitButton name={t('LoginForm.Submit')} />}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default SlideThree;
