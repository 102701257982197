import { getImageUrl } from 'client/helpers/getImageUrl';
import { useFormatVideoGrapherGrade } from 'client/hooks/useFormatVideoGrapherGrade';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IUser } from 'server/models/User';

type VideoGrapherDetailsSideBarProps = { videoGrapher: IUser };

const VideoGrapherDetailsSideBar: React.FC<VideoGrapherDetailsSideBarProps> = ({
  videoGrapher,
}) => {
  const [t] = useTranslation();
  const grade = useFormatVideoGrapherGrade(videoGrapher.grade);

  return (
    <div className="col-md-3">
      <div className="card card-primary card-outline">
        <div className="card-body">
          <div className="text-center">
            <img
              className="profile-user-img img-fluid img-circle"
              src={getImageUrl(videoGrapher?.avatar)}
              alt="User profile"
            />
          </div>
          <h3 className="text-center pt-4">{videoGrapher?.username}</h3>
          <h6 className="text-muted text-oxygene text-center pb-4">{grade}</h6>
          <ul className="list-group list-group-unbordered mb-1">
            <li className="list-group-item ">
              <h6>{t('dashboard.videoGrapherPage.balance')}</h6>
              <span className="float-right">{videoGrapher?.salesBalance}</span>
            </li>
            <li className="list-group-item font-size-1">
              <h6> {t('dashboard.videoGrapherPage.downloadedVideo')}</h6>
              <span className="float-right">{videoGrapher?.downloadedVidoGrapherPosts}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VideoGrapherDetailsSideBar;
