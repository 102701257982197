import { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import React, { useRef, useCallback } from 'react';
import { getImageUrl } from 'client/helpers/getImageUrl';
import { CRUDDataTableHandle } from 'dashboard/containers/CRUDDataTable';
import { ActionButtons } from 'dashboard/containers/CRUDDataTable/useTableColumnsWithActions';
import { IWithdrawRequest } from 'server/models/WithdrawRequest';
import { IUser } from 'client/models/IUser';
import { withdrawVideoGrapherBalance } from 'dashboard/_redux/actions/users';

export const useWithdrawRequestList = () => {
  const [t] = useTranslation();
  const dataTableRef = useRef<CRUDDataTableHandle>(null);

  const columns: TableColumn<IWithdrawRequest>[] = [
    {
      name: t('dashboard.videoGrapherPage.avatar'),
      selector: (e) => (
        <img
          alt="avatar"
          id={e._id}
          className="avatar m-2"
          src={getImageUrl((e.user as IUser).avatar)}
        />
      ),
      center: true,
    },
    {
      name: t('dashboard.videoGrapherPage.firstName'),
      selector: (e) => (e.user as IUser).firstName,
      sortable: true,
      center: true,
      sortfield: 'user.firstName',
    },
    {
      name: t('dashboard.videoGrapherPage.lastName'),
      selector: (e) => (e.user as IUser).lastName,
      sortable: true,
      center: true,
      sortfield: 'user.lastName',
    },
    {
      name: t('dashboard.videoGrapherPage.username'),
      selector: (e) => (e.user as IUser).username,
      sortable: true,
      center: true,
      sortfield: 'user.username',
    },
    {
      name: t('dashboard.videoGrapherPage.email'),
      selector: (e) => (e.user as IUser).email,
      sortable: true,
      center: true,
      sortfield: 'user.email',
    },
    {
      name: t('dashboard.videoGrapherPage.balance'),
      selector: (e) => e.amount,
      sortable: true,
      center: true,
      sortfield: 'amount',
    },

    {
      sortfield: 'createdAt',
      name: t('dashboard.videoGrapherPage.createdAt'),
      selector: (e) => (e.createdAt ? format(new Date(e.createdAt), 'DD-MM-YYYY') : ''),
      sortable: true,
    },
    {
      name: t('dataTable.actions'),
      sortable: false,
      center: true,
      cell: (row) => (
        <>
          {row.status === 'PENDING' && (
            <ActionButtons
              item={row}
              key={row._id}
              buttons={[
                {
                  fn: onPressApprove,
                  title: t('dashboard.videoGrapherPage.withdrawBalance'),
                  className: 'btn btn-dark btn-xs mr-1',
                },
              ]}
            />
          )}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      minWidth: '25vw',
    },
  ];
  const dispatch = useDispatch();
  const { withdrawRequests, loaded } = useSelector(
    (state: RootState) => state.dashboardVideoGraphers,
  );
  const onPressApprove = useCallback(
    ({ user, _id }: IWithdrawRequest) => {
      if (!user) return;
      dispatch(withdrawVideoGrapherBalance((user as IUser)._id, _id));
    },
    [dispatch],
  );

  return {
    withdrawRequests,
    loaded,
    columns,
    dataTableRef,
  };
};
