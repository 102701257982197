import * as redux from 'redux';
import * as dashboard from 'dashboard/_redux/reducers';
import auth from './auth';
import init from './init';
import post from './post';
import loading from './loading';

const rootReducer = redux.combineReducers({
  loading,
  auth,
  init,
  post,
  // Dashboard Reducer
  ...dashboard,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
