import * as React from 'react';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import Select, { Props as IComponentProps } from 'dashboard/components/Forms/Select';
import { get } from 'lodash';
import { ISelectorOption } from 'types/ISelectorOption';

interface IProps<T extends unknown>
  extends Omit<IComponentProps<T>, 'errors' | 'onChange' | 'value'> {
  name: string;
  selectClassName?: string;
  validate?: ValidationRules;
  initialValue?: ISelectorOption['value'] | ISelectorOption['value'][];
}

const FormSelect = <T extends unknown>({
  name,
  validate,
  initialValue,
  selectClassName,
  ...selectProps
}: IProps<T>) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      defaultValue={initialValue}
      render={({ onChange, value }) => (
        <Select
          {...selectProps}
          id={name}
          value={value || initialValue}
          onChange={onChange}
          errors={get(errors, name)}
          selectClassName={selectClassName}
        />
      )}
    />
  );
};

export default FormSelect;
