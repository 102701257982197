import { useEffect, useRef, useState } from 'react';
interface IParmas {
  callback?: ({ isOpen }: { isOpen: boolean }) => void;
  open?: boolean;
  title: string;
}
export const useCollapsible = ({ open, callback, title }: IParmas) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<number | undefined>(open ? undefined : 0);
  const ref = useRef<HTMLDivElement>(null);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);

    if (callback) callback({ isOpen: !isOpen });
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    const resizeObserverCallback: ResizeObserverCallback = (el: ResizeObserverEntry[]) => {
      setHeight(el[0].contentRect.height);
    };
    const resizeObserver = new ResizeObserver(resizeObserverCallback);

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);
  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return { handleFilterOpening, isOpen, height, ref };
};
