import React from 'react';
import { GoogleMapsInput } from 'client/components/Form/GoogleMapsInput';
import VideoCardFormFragment from 'client/components/VideoCardFormFragment';
import VideoCardTopSide from 'client/components/VideoCardTopSide';
import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import { FormProvider } from 'react-hook-form';

import IPostForm from 'client/models/IPostForm';
import FormSelect from 'client/components/Form/FormSelect';
import { useVideoCardForm } from './useVideoCardForm';

const VideoCardForm: React.FC<IPostForm> = ({ ...post }) => {
  const {
    component,
    cinematicStyleOptions,
    musicTypeOptions,
    videoTypeOptions,
    t,
    formMethods,
    handleSubmit,
    onSubmit,
  } = useVideoCardForm(post);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-0 px-md-4 pt-0 pt-md-3">
          <VideoCardTopSide date={post?.date} downloads={post?.downloads || 0} />
          <div className="container-fluid col-lg-12 col-sm-12">
            <div className="row align-items-start">
              <VideoCardFormFragment
                name="name"
                title={t('addVideoDashboard.videoName')}
                isText
              >
                <Input
                  name="name"
                  placeholder={t('addVideoDashboard.name')}
                  isVideo
                  validate={Validators([{ validation: 'required' }])}
                  // className="card-info-value-text"
                />
              </VideoCardFormFragment>
              <VideoCardFormFragment
                name="cinematicStyle"
                title={t('addVideoDashboard.cinematographyStyle')}
              >
                <FormSelect
                  defaultValue={{ label: 'Documentary', value: 'DOCUMENTARY' }}
                  name="cinematicStyle"
                  components={component}
                  variant="form"
                  options={cinematicStyleOptions}
                  validate={Validators([{ validation: 'required' }])}
                />
              </VideoCardFormFragment>
            </div>
            <div className="row align-items-start">
              <VideoCardFormFragment
                name="description"
                title={t('addVideoDashboard.description')}
                isText
              >
                <Input
                  name="description"
                  placeholder={t('misc.description')}
                  isVideo
                  validate={Validators([{ validation: 'required' }])}
                />
              </VideoCardFormFragment>
              <VideoCardFormFragment name="musicType" title={t('addVideoDashboard.musicType')}>
                <FormSelect
                  defaultValue={{ label: 'Soul', value: 'SOUL' }}
                  name="musicType"
                  components={component}
                  variant="form"
                  options={musicTypeOptions}
                  validate={Validators([{ validation: 'required' }])}
                />
              </VideoCardFormFragment>
            </div>
            <div className="row align-items-start">
              <VideoCardFormFragment name="tags" title={t('addVideoDashboard.tags')} isText>
                <Input
                  name="tags"
                  placeholder={t('addVideoDashboard.tags')}
                  isVideo
                  validate={Validators([{ validation: 'tags' }])}
                />
              </VideoCardFormFragment>
              <VideoCardFormFragment name="videoType" title={t('addVideoDashboard.videoType')}>
                <FormSelect
                  defaultValue={videoTypeOptions[0]}
                  name="videoType"
                  components={component}
                  variant="form"
                  options={videoTypeOptions}
                  validate={Validators([{ validation: 'required' }])}
                />
              </VideoCardFormFragment>
            </div>
            <div className="row align-items-start">
              <VideoCardFormFragment
                name="countryCity"
                title={t('addVideoDashboard.countryAndCity')}
                isText
              >
                <GoogleMapsInput
                  validate={Validators([
                    { validation: 'required' },
                    { validation: 'validLocation' },
                  ])}
                  name="countryCity"
                  // className="card-info-value-text"
                />
              </VideoCardFormFragment>
            </div>
            <div className="row justify-content-center">
              <button
                type="submit"
                className="section-button alt-primary mt-2 mb-2 pt-0 video-publish-button text-oxygen "
                style={{ zIndex: 0, paddingRight: '5rem', paddingLeft: '5rem' }}
              >
                {t('addVideoDashboard.save')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default VideoCardForm;
