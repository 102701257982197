import ConfirmationModal from 'client/components/ConfirmationAlert';
import i18n from 'locales';
import { confirmAlert } from 'react-confirm-alert';
import React from 'react';

export const withConfirmation = (submit: () => unknown) => {
  confirmAlert({
    customUI: (props) => <ConfirmationModal {...props} />,
    buttons: [
      {
        label: i18n.t('misc.no'),
        onClick: () => null,
      },
      {
        label: i18n.t('misc.yes'),
        onClick: submit,
      },
    ],
  });
};
