import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  colorIcon: string;
  backgroundIcon: string;

  className?: string;
}
const icons = [
  { name: 'facebook-f', url: 'https://www.facebook.com/Ream-104950971988775' },
  { name: 'twitter', url: 'https://twitter.com/ream67598748' },
  { name: 'youtube', url: 'https://www.youtube.com/channel/UCgSTr-oV1H-APwrVk_Hotyw' },
  { name: 'instagram', url: 'https://www.instagram.com/ream_app/?hl=fr' },
];
const SocialMediaButton: React.FC<IProps> = ({ colorIcon, backgroundIcon }) => (
  <div className=" d-flex row  container-button justify-content-between my-auto mr-0">
    {icons.map((icon) => (
      <div
        key={icon.name}
        style={{ width: '32px', height: '32px' }}
        className={`rounded-circle position-relative bg-${backgroundIcon}`}
      >
        <Link className="d-flex icon-button" to={{ pathname: icon.url }} target="_blank">
          <i className={`fab m-auto  fa-${icon.name} text-${colorIcon}`} />
        </Link>
      </div>
    ))}
  </div>
);

export default SocialMediaButton;
