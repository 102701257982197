import AppStore from 'client/components/SVG/AppSore';
import GooglePlay from 'client/components/SVG/GooglePlay';
import Pattern from 'client/components/SVG/Pattern';
import SectionContainer from 'client/containers/SectionContainer';
import { openShareUrl } from 'client/helpers/openShareUrl';
import React from 'react';
import { useTranslation } from 'react-i18next';

const HomePageLandingSection: React.FC = () => {
  const [t] = useTranslation();

  return (
    <SectionContainer
      mobileTopMargin="80"
      title={t('HomePageLandingSection.title')}
      backgroundStyle="dark-image-background"
      contentClassName="pt-10"
      miscComponent={
        <div className="d-flex align-items-center justify-content-center">
          <img
            className="smartphone-mockup"
            src="/assets/svg/custom/homePage/Smartphone-white-mockup.png"
            alt="video maker first"
          />
        </div>
      }
      firstDescription={
        <div className="h3 mt-2">
          <span className="text-white subtitle-text-semiBold subtitle-section">
            {t('HomePageLandingSection.enjoyTheMoment')}{' '}
          </span>
          <span className="text-primary-lighter subtitle-text-semiBold subtitle-section">
            {t('HomePageLandingSection.and')}{' '}
          </span>
          <span className="text-white subtitle-text-semiBold subtitle-section">
            {t('HomePageLandingSection.sublimeYourMemories')}{' '}
          </span>
          <span className="text-primary-lighter subtitle-text-semiBold subtitle-section">
            {t('HomePageLandingSection.vacationOr')}{' '}
          </span>
          <span className="text-white subtitle-text-semiBold subtitle-section">
            {t('HomePageLandingSection.trip')}
          </span>
          <span className="text-primary-lighter subtitle-text-semiBold subtitle-section">
            {t('HomePageLandingSection.exploreTheWorld')}
          </span>
        </div>
      }
      secondDescription={t('HomePageLandingSection.homePageDescription')}
      titleStyle="text-white"
      descriptionStyle="text-white-70 h4"
    >
      <Pattern
        className="red-pattern"
        width="900px"
        style={{ position: 'absolute', top: '-25%', right: '80%', opacity: '0.45' }}
      />
      <div className="d-flex flex-wrap media-container">
        <div className="mr-5 ">
          <GooglePlay
            onClick={() => openShareUrl(process.env.RAZZLE_GOOGLE_PLAY_LINK)}
            height="65px"
          />
        </div>
        <div>
          <AppStore
            // onClick={() => openShareUrl(process.env.RAZZLE_APPLE_STORE_LINK)}
            height="65px"
          />
        </div>
      </div>
      <div className="mt-2 mb-5">
        <span className="text-white-70 description-section">
          {t('HomePageLandingSection.lastUpdate')} 01/06/2021:{' '}
        </span>
        <span className="text-primary-lighter description-section">Version 1.0</span>
        <span className="text-white-70 description-section">
          <br />
          {t('HomePageLandingSection.participate')}
        </span>
        <span className="text-primary-lighter description-section">
          {' '}
          {t('HomePageLandingSection.version')} 2.0
        </span>
      </div>
    </SectionContainer>
  );
};

export default HomePageLandingSection;
