import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';
import { getFromData } from 'client/helpers/getFromData';
import { splitTagsString } from 'client/helpers/splitTagsString';
import { IPostData } from 'client/models/IPost';
import IPostForm from 'client/models/IPostForm';

const baseUrl = '/posts';

export const addPost = () => async (values: IPostData, config: AxiosRequestConfig) => {
  const formData = new FormData();

  getFromData(formData, values);
  const response = await clientApi.post(baseUrl, formData, config);

  return response;
};
export const getPostById = () => async (postId: string) => {
  const response = await clientApi.get(`${baseUrl}/${postId}`);

  return response;
};
export const getPostByVideoGrapher = () => async (filter?: {
  countryLetter?: string;
  category?: string;
  keyword?: string;
}) => {
  const response = await clientApi.get(`${baseUrl}/byVideoGrapher/`, {
    params: {
      countryLetter: filter?.countryLetter,
      category: filter?.category,
      keyword: filter?.keyword,
    },
  });

  return response;
};
export const deletePostById = () => async (postId: string) => {
  const response = await clientApi.delete(`${baseUrl}/delete/${postId}`);

  return response;
};
export const updatePost = () => async (
  { countryCity, ...postData }: Partial<IPostForm>,
  postId?: string,
) => {
  const post = {
    ...postData,
    tags: splitTagsString(postData.tags as string),
    cinematicStyle: postData.cinematicStyle?.value,
    musicType: postData.musicType?.value,
    videoType: postData.videoType?.value,
    location: {
      coordinate: [countryCity?.lng, countryCity?.lat],
    },
    country: countryCity?.country || '',
    city: countryCity?.state || '',
  };
  const response = await clientApi.patch(`${baseUrl}/update/${postId}`, {
    postData: post,
    id: postId,
  });

  return response;
};

export const getAllPosts = (filter?: {
  countryLetter?: string;
  category?: string;
}) => async () => {
  let params;

  if (filter?.countryLetter)
    params = { countryLetter: filter?.countryLetter, category: filter?.category };
  else params = { category: filter?.category };

  return await clientApi.get(`${baseUrl}/get-posts`, {
    params,
  });
};
