/* eslint-disable max-len */
import { LogoReam } from 'client/components/SVG';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SocialMediaButton from 'client/components/SocialMediaButton';
import { Link } from 'react-router-dom';
import GooglePlay from 'client/components/SVG/GooglePlay';
import AppStore from 'client/components/SVG/AppSore';
import { openShareUrl } from 'client/helpers/openShareUrl';

const Footer = () => {
  const [t] = useTranslation();

  return (
    <div className="border-top border-primary col text-white p-0 bg-secondary">
      <div className=" d-flex flex-column flex-lg-row pt-6">
        <div className="col-lg-3 d-flex justify-content-center border-primary">
          <LogoReam width="174px" height="50px" />
        </div>

        <div className=" pb-5 col-lg-5 col-sm-12 p-sm-3 p-md-0 p-lg-0 justify-content-md-center ">
          <div className="pl-lg-0 descrption-text font-weight-light col-12 mb-0 mb-xl-5 pt-5 pt-lg-0">
            {t('Footer.description')}
          </div>
          <div className="row d-flex justify-content-sm-between justify-content-center pb-lg-5 pb-md-2 pl-3 pl-sm-3 pl-lg-0 pr-sm-3 pr-lg-0 pt-2">
            <div className=" col-sm-4 col-12 ">
              <span className="h4 text-primary-lighter font-weight-normal">
                {t('Footer.siégeSocial')}
              </span>
              <div className="location-text font-weight-lighter">{t('Footer.adresse')}</div>
              <div className="location-text font-weight-lighter">{t('Footer.city')}</div>
            </div>
            <div className="col-sm-4 col-12">
              <span className="h4 text-primary-lighter font-weight-normal">
                {t('Footer.joindre')}
              </span>
              <div className="location-text font-weight-lighter">00 33 6 60 80 75 68 </div>
              <div className="location-text font-weight-lighter">{t('Footer.horaire')}</div>
            </div>
            <div className=" col-sm-4 mt-3 d-flex align-items-center col-12 ">
              <a
                href="mailto:contact@ream-movie.com"
                style={{ fontSize: '1rem' }}
                className="btn  text-center  border border-white w-100 stext-oxygen"
              >
                {t('Footer.contact')}
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-3 mt-3 mt-lg-0 ">
          <div className="d-flex mb-4 justify-content-lg-end justify-content-center">
            <SocialMediaButton colorIcon="dark" backgroundIcon="white" />
          </div>

          <div className="footer-links-container  flex-wrap justify-content-center">
            <div className="d-flex flex-wrap justify-content-lg-end justify-content-center align-items-center w-100">
              <div className="text-right mb-2">
                <GooglePlay
                  onClick={() => openShareUrl(process.env.RAZZLE_GOOGLE_PLAY_LINK)}
                  width="auto"
                  height="50px"
                />
              </div>
              <div className="text-right mb-2 ml-lg-4 ml-2">
                <AppStore
                  // onClick={() => openShareUrl(process.env.RAZZLE_APPLE_STORE_LINK)}
                  height="50px"
                  width="auto"
                />
              </div>
            </div>

            <div className="footer-links w-auto d-flex flex-wrap justify-content-center justify-content-lg-end justify-content-xxl-between ">
              <div className="footer-links-first ">
                <Link
                  to="/how-to"
                  className="text-right text-white  location-text text-nowrap py-2"
                >
                  {t('navBar.commentCaMarche')}
                </Link>
                <Link to="/gallery" className="text-right text-white location-text ">
                  {t('navBar.galerie')}
                </Link>
              </div>
              <div className="footer-links-second ml-5">
                <Link
                  to="/video-maker"
                  className="text-right text-white location-text text-nowrap  py-2"
                >
                  {t('navBar.devenirVideaste')}
                </Link>
                <div className="text-right location-text ">{t('Footer.news')} </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" d-flex second-footer-section align-items-center  justify-content-center py-3">
        <div className="col-lg-8 col-10 d-lg-flex justify-content-center flex-wrap ">
          <div>
            <i className="far fa-copyright mr-1" />
            {t('Footer.copyRight')}
          </div>
          <div className="px-lg-11 py-lg-0 py-3">
            <Link className="link-style" to="/legal-notice">
              {t('Footer.legal')}
            </Link>
          </div>

          <Link className="link-style" to="/terms-and-conditions">
            {t('Footer.conditions')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
