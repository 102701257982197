export enum AddPostTypes {
  ADD_POST_SUCCESS = 'ADD_POST_SUCCESS',
  ADD_POST_FAILURE = 'ADD_POST_FAILURE',
  UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS',

  UPDATE_POST_FAILURE = 'UPDATE_POST_FAILURE',
}
export enum GetPostTypes {
  POST_SUCCESS = 'POST_SUCCESS',

  RESET_POST = 'RESET_POST',

  POST_FAILURE = 'POST_FAILURE',
}
export enum getAllPostsTypes {
  POSTS_SUCCESS = 'POSTS_SUCCESS',
  POSTS_FAILURE = 'POSTS_FAILURE',
}
export enum fetchVideoGrapherPosts {
  FETCH_VIDEOGRAPHER_POSTS_SUCCESS = 'FETCH_VIDEOGRAPHER_POSTS_SUCCESS',
  FETCH_VIDEOGRAPHER_POSTS_FAILURE = 'FETCH_VIDEOGRAPHER_POSTS_FAILURE',
}

export enum deletePostTypes {
  POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS',
  POST_DELETE_FAILURE = 'POST_DELETE_FAILURE',
}
export enum GetPostByIdTypes {
  POST_BYID_SUCCESS = 'POST_BYID_SUCCESS',
  POST_BYID_FAILURE = 'POST_BYID_FAILURE',
}
