import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { signOut } from 'client/_redux/actions/auth';
import { useCallback } from 'react';
export const useNavBarContainer = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  const handleDisconnect = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  return { isLoggedIn, handleDisconnect, t };
};
