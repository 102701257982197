import React, { useState, useCallback } from 'react';
interface IDataProps {
  pictureUrl: string;
  body: string;
}
interface IProps {
  data: IDataProps[];
}

const TimonialsSlides: React.FC<IProps> = ({ data }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleClick = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center pb-3">
      <div className="d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center w-100">
        <div>
          <img
            src={data[selectedIndex].pictureUrl}
            alt="pic"
            className="picture-round-circle image-border-pic align-self-center m-1"
          />
        </div>

        <div>
          <p className="text-white testimonial-text text-oxygen">
            <span className="text-secondary">&quot;</span>
            {data[selectedIndex].body}
            <span className="text-secondary">&quot;</span>
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-center my-3">
        {data.map((e, index) => (
          <div
            key={`${e.pictureUrl + index}`}
            onClick={() => handleClick(index)}
            className={`select-case mx-5  ${selectedIndex === index ? 'active' : ''}`}
          />
        ))}
      </div>
    </div>
  );
};

export default TimonialsSlides;
