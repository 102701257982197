import NotFoundHeader from 'client/components/NotFoundHeader';
import * as React from 'react';
import SEO from 'client/components/SEO';

const NotFound: React.FC = () => (
  <>
    <SEO title="Not Found" description="Contenu introuvable" />
    <NotFoundHeader />
  </>
);

export default NotFound;
