/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import Footer from 'client/containers/Footer';
import NavBarContainer from 'client/containers/NavBarContainer';
const TermsAndConditionsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  return (
    <>
      <div>
        <NavBarContainer isDarkBackground />
      </div>

      <main
        id="content"
        role="main"
        style={{ overflow: 'hidden' }}
        className="position-relative"
      >
        <div
          className="container col-12 col-sm-7"
          style={{ marginTop: '10rem', marginBottom: '7rem' }}
        >
          <p
            style={{
              marginTop: '0pt',
              marginBottom: '8pt',
              textAlign: 'center',
              pageBreakBefore: 'always',
              lineHeight: '107%',
              fontSize: '22pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              CONDITIONS D’UTILISATIONS
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              textAlign: 'center',
              lineHeight: '107%',
              fontSize: '22pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              1 - Acceptation des conditions
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              1.a Vous acceptez les conditions générales établies ci-dessous, ainsi que toutes
              les conditions modifiées de manière occasionnelle et vous vous engagez à les
              respecter.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              1.b Ream peut apporter des modifications aux présentes conditions d’utilisation à
              tout moment. Si vous continuez à accéder ou à utiliser l’application et le site
              web Ream, cela signifie que vous acceptez ces changements. Pensez à vérifier
              régulièrement les conditions d’utilisation en suivant le lien proposé sur la page
              de connexion de l’application Ream et également sur la page d’accueil du site. En
              cas de non-respect de votre part de l’une des conditions d’utilisation à accéder
              ou à utiliser l’application et le site web sera automatiquement révoqué.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              2 - Propriété intellectuelle, licence limitée pour les utilisateurs
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              2.a Vous pouvez accéder ou utiliser l’application et le site web sous réserve de
              votre respect des présentes conditions d’utilisation, de tout contrat de licence
              avec Ream applicable et de la loi. Ream demeure seul titulaire de tous les
              droits, titres et intérêts liés au sites web ou à l’application et se réserve
              tous les droits non expressément accordés dans le cadre des présentes conditions
              d’utilisation. Ream peut modifier, remplacer ou interrompre le site web et
              l’application ou une partie de celles-ci à tout moment, pour quelque raison que
              ce soit, avec ou sans préavis et à sa seule discrétion.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              2.b Tout le contenu du site web et de l’application est protéger par le droit
              d’auteur ou par d’autres lois et traités en matière de propriété intellectuelle.
              Toute utilisation non autorisée de quelque contenu de Ream que ce soit, constitue
              une infraction à ces lois et aux conditions d’utilisation. Vous consentez à ne
              pas copier, republier, mettre dans un cadre, référencer par un lien, télécharger,
              transmettre, modifier, adapter, utiliser comme une base pour des œuvres dérivées,
              louer, prêter, vendre, attribuer, distribuer, exposer, mettre en scène, proposer
              sous licence ou sous-licence, ou rétro concevoir le site web, l’application ou
              les contenus de Ream. Vous consentez également à ne pas employer de méthodes
              d’extraction de données, de robots ou tout autre moyen de collecte et/ou d’images
              en lien avec le site web et l’application Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              2.c Vous n’avez pas l’autorisation de supprimer les filigranes ou avis de droit
              d’auteur présents dans le contenu de Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              3 - Marque de Ream
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              3.a Vous vous engagez à ne pas utiliser les marques de Ream d’une qui pourrait
              ternir ou dénigrer ces marques et leur nuire.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              3.b Vous consentez à ne pas contester ni remettre en cause de quelque manière que
              ce soit (par le biais d’une action en justice ou autre), ni aider ou encourager
              un tiers ou une entité à contester ou à remettre en cause la validité des marques
              de Ream et les droits sur les marques revendiqués par Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              3.c Vous consentez à n’utiliser aucune marque de Ream ni aucune variante de
              celle-ci (y compris une version mal orthographiée) en tant que nom de domaine ou
              comme élément d’un nom de domaine quel que soit le domaine de premier niveau, ni
              comme méta balise, mot-clé ou autre type d’élément ou de données de
              programmation.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              3.d Vous ne pouvez à aucun moment adopter où utiliser sans l’accord écrit
              préalable de Ream, un mot ou une marque similaire aux marques de Ream, ou
              susceptibles d’être confondus avec celles-ci.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              3.e L’aspect et le fonctionnement du site web ou de l’application de Ream, y
              compris les en-têtes de page, les graphismes personnalisés, les icônes et les
              scripts sont des marques de commerce ou de service de Ream et ne peuvent pas être
              copiés, imités ni utilisés que ce soit en partie ou en totalité sans l’accord
              écrit préalable de Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              3.f Toutes les autres marques, tous les autres noms de produits et de société
              ainsi que tous les autres logos utilisés ou figurant sur le site web et
              l’application de Ream appartiennent à leur détenteur respectif.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              3.g Vous ne pouvez pas utiliser une marque de Ream, un logo, une image ou autre
              élément graphique appartenant à Ream pour établir un lien vers le site web ou
              l’application de Ream sans l’accord écrit préalable de Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              3.h Vous ne pouvez pas attirer l’attention sur le site web et l’application de
              Ream ou une image, ou créer un lien direct vers ces derniers sans l’accord écrit
              préalable de Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              4 - Informations communiquées par vos soins
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              4.a Ream (ou tout tiers agissant en son nom) peut recueillir des informations
              relatives à votre utilisation du site ou de l’application Ream. Les plates-formes
              tierces qui vous redirigent vers le site web ou l’application Ream peuvent
              recueillir des informations liées à
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>
              votre utilisation desdites plates-formes et les communiquées à Ream sous réserve
              de votre accord avec les plateformes tierces applicables.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              4.b Ream utilisera et protégera vos informations personnelles, comme vos noms et
              adresses, conformément à la déclaration de confidentialité de Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              5 - Votre contenu{' '}
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.a Concernant les images, les séquences vidéo et audio, les textes ou tout autre
              contenu que vous chargez ou publiez sur le site web ou l’application Ream, vous
              déclarez et garantissez que vous êtes détenteur de tous les droits vous
              permettant de soumettre votre contenu sur le site web ou l’application Ream et
              d’accorder les licences ci-décrites. Ream ne sera aucunement obligé d’obtenir des
              licences auprès de tiers ni de verser de redevances à des tiers pour votre
              contenu. Votre contenu n’enfreint pas les droits de tiers, y compris les droits
              de propriété intellectuelle et ceux relatifs à la vie privée.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.b En mettant votre contenu en ligne, vous accordez à Ream une licence et un
              droit limités, mondiaux, non exclusifs et exempts de redevance autorisant la
              copie, la transmission, la distribution, la diffusion au grand public,
              l’affichage (via tout support connu à l’heure actuelle ou créé par la suite) et
              la création d’œuvres dérivées à partir de votre contenu afin de vous permettre de
              modifier et de diffuser votre contenu à l’aide du site web ou de l’application
              Ream et d’archiver ou de protéger votre contenu contre les litiges, poursuites
              judiciaires ou enquêtes. Les licences et droits ci-dessus seront valables tant
              que vous maintiendrez votre contenu sur le site web ou l’application Ream; si
              vous le supprimez dès ces deux supports, lesdites licences et lesdits droits
              seront résiliés à l’issue d’une période raisonnable d’un point de vue commercial.
              Indépendamment de ce qui précède, la licence d’archivage/protection à des fins
              légales perdurera sans limite de temps.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.c Vous ne pouvez pas charger, publier ni transmettre d’enregistrement vidéo,
              textuel, audio, d’images ou tout autre contenu qui :
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              • Enfreint les droits d’auteur ou tout autre droit de propriété intellectuelle
              appartenant à des tiers, ou tout droit relatif à la publicité et la vie privée ;
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              • Contient des contenus de nature pornographique, diffamatoire, illicite ou
              immorale.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>• Exploite des mineurs ; </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              • Représente des actes illicites ou violents ;
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              • Dépeint la cruauté des animaux ou la violence envers les animaux ;
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              • Fait la promotion de programmes frauduleux ou donne lieu à une réclamation pour
              publicité mensongère ou concurrence déloyale
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
            <span style={{ fontFamily: 'Oxygen' }}>;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              • Enfreint toute législation ou réglementation.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              5.d Vous ne pouvez utiliser aucun contenu de Ream pour quelque fin que ce soit
              sans avoir au préalable obtenu une licence d’utilisation du contenu de Ream en
              question. Toute utilisation de votre part du contenu de Ream sera régie par le
              contrat de licence.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              6 - Actions en cas de violation de droit / signalements dans le cadre du DMCA
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              6.a Si vous pensez qu’une image ou tout autre support mit à disposition par Ream
              enfreint un droit d’auteur vous appartenant ou contrôlé par vous, vous pouvez en
              informer Ream de la manière indiquée dans notre Politique de signalement des
              atteintes aux droits d’auteur dans le cadre du DMCA.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              7 - Limitations{' '}
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              7.a Vous convenez que vous ne devez pas enfreindre les droits de Ream ou d’un
              tiers (y compris les droits à la vie privée et à la divulgation d’informations)
              ou injurier, diffamer, harceler, traquer ou menacer une tierce personne.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              8 - Restriction et cessation d’utilisation
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              8.a À tout moment, Ream peut bloquer, restreindre, désactiver, suspendre ou
              résilier votre accès à une partie ou à la totalité du site web et de
              l’application Ream ou des contenus de Ream, à sa discrétion, sans avis préalable
              ni responsabilité à votre égard. À l’entière discrétion de Ream, tout
              comportement de votre part restreignant ou empêchant toute autre personne ou
              entité d’utiliser ou de bénéficier des deux supports est strictement interdit et
              pourrait entraîner la résiliation de votre accès à ceux-ci sans préavis.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              9 - Liens vers des sites tiers
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              9.a Si le site web et l’application Ream sont accessible depuis une plate-forme
              tierce, ou s’ils fournissent des liens vers une plate-forme tierce ou permettent
              à un tiers de fournir un lien de sa plate-forme vers nos deux supports, vous
              comprenez et acceptez que Ream ne formule aucune garantie d’aucune sorte,
              explicite ou implicite, et n’accepte aucune responsabilité quant au contenu ou
              aux pratiques de ces tiers ou de leurs plates-formes. Ces plates-formes ne sont
              pas sous le contrôle de Ream et Ream fournit et/ou autorise ces liens uniquement
              pour votre confort. L’inclusion d’un lien n’implique pas d’affiliation,
              d’approbation ou d’adoption de la part de Ream.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              10 - Garanties et limitations de responsabilité
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              10.a Votre utilisation du site web et l’application Ream est à vos propres
              risques. Les deux supports est fourni par Ream selon les présentes conditions
              d’utilisation « en l’état » et sans garantie d’aucune sorte, explicite ou
              implicite, statuaire ou autre. Ream décline expressément toute garantie de toute
              sorte, explicite ou implicite, concernant un service disponible sur ses deux
              supports, y compris, mais sans s’y limiter, les garanties implicites de qualité
              marchande, d’adéquation à un usage particulier et d’absence de contrefaçon, ainsi
              que toute autre garantie prévue par une loi. Sans limiter la portée de ce qui
              précède, Ream ne donne aucune garantie que : (i) les deux supports répondront à
              vos besoins ; (ii) l’accès aux deux supports ne sera pas interrompu ; (iii) la
              qualité des deux supports répondront à vos attentes ; et (iv) les erreurs ou
              défauts des deux supports, des services ou de la documentation seront rectifiés.
              Ream ne déclare et ne garantit aucunement que les deux supports seront autorisés
              dans votre juridiction, qu’un élément de votre contenu envoyé par vos soins sera
              disponible par l’intermédiaire des deux supports ou sera conservé par les deux
              supports, que les deux supports continueront de prendre en charge une
              fonctionnalité donnée. Si une réclamation ou une limitation de responsabilité ne
              s’applique pas, toutes les garanties applicables expresses, implicites et
              statutaires seront valables pendant une période de trente (30) jours après la
              date à laquelle vous avez utilisé les deux supports pour la première fois, et
              aucune garantie ne s’appliquera au terme de cette période.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              11 - Indemnisation
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              11.a Vous acceptez de défendre, d’indemniser et de protéger Ream, ses filiales,
              ses sociétés affiliées, ses concédants, ses employés, ses agents, ses
              fournisseurs d’information tiers, ses contributeurs et ses sous-traitants
              indépendants en ce qui concerne les réclamations, dommages-intérêts, coûts,
              responsabilités et dépenses (y compris, mais sans s’y limiter, les frais
              raisonnables d’avocat) découlant de votre comportement, de votre utilisation ou
              de votre incapacité à utiliser le site web ou l’application Ream, toute violation
              de votre part, qu’elle soit avérée ou présumée, des conditions d’utilisation des
              deux supports ou de toute clause ou garantie incluse dans les présentes, votre
              utilisation non autorisée des contenus de Ream ou toute violation de votre part
              des droits d’autrui.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '11pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '14pt',
            }}
          >
            <span className="text-dark" style={{ fontFamily: 'Oxygen', fontWeight: 'bold' }}>
              12 - Divers
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              12.a Les présentes Conditions d’utilisation sont régies par la législation de la
              Suisse et doivent être interprétées conformément à cette dernière, et ce sans
              tenir compte des conflits de principes juridiques.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              12.b En cas de conflit entre les présentes conditions d’utilisation et tout
              contrat de licence que vous concluez avec Ream, les modalités du contrat de
              licence prévaudront.
            </span>
          </p>
          <p
            style={{
              marginTop: '8pt',
              marginBottom: '8pt',
              lineHeight: '107%',
              fontSize: '12pt',
            }}
          >
            <span style={{ fontFamily: 'Oxygen' }}>
              12.c Les présentes conditions d’utilisation ne doivent pas être interprétées
              comme étant à l’encontre ou en faveur de l’une ou l’autre des parties. La nullité
              ou l’inapplicabilité de quelque partie que ce soient des présentes conditions
              d’utilisation ne doit pas affecter la validité ou l’applicabilité de cette équité
            </span>
            <span style={{ fontFamily: 'Oxygen' }}>.</span>
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default TermsAndConditionsPage;
